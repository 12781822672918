import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getUserProfile, getSelectedProjectIdAndLocationId } from "../../../../Reducer/selectors/DashboardSelector";
import NAHeader from "../Header/NAHeader";
import HubIcon from '@mui/icons-material/Hub';
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import styles from "./ChooseDevices.module.css";
import { setProjectIdAndLocationId, getMouthLabLogs, setSelectedPageName, setShowCMDrawer, setShowMEDrawer, setShowQMDrawer, setIsCalibrationOngoing, setPCCPatientListPageNumber, setPCCPatientListPageSize } from "../../../../Actions";
import IconButton from "@material-ui/core/IconButton";
import { RiCloseCircleFill } from "react-icons/ri";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MLImg from '../../../../Assets/Images/Aidar-Hand-Device-Sketch-01.png';
import { setSelectedMlId } from "../../../../Actions";

function ChooseDevices({ mouthLabLogs }) {

    const profile = useSelector(getUserProfile);
    const [mouthLabPatientLogs, setMouthLabPatientLogs] = useState([]);
    const dispatch = useDispatch();
    //const mouthLabLogs = useSelector(getMouthLabLogselector);
    const [showDialog, setShowDialog] = useState(false);
    const [curSubjectId, setCurSubjectId] = useState();
    const [curDeviceId, setCurDeviceId] = useState();
    const [openModalMenu, setOpenModalMenu] = useState(false);
    const [showModal, setShowModal] = useState();
    const [formState, setFormState] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedML, setSelectedML] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(
            setProjectIdAndLocationId({
                projectId: profile.projects[0].id,
                locationId: profile.locations[0].id,
            }));
        dispatch(getMouthLabLogs({ projectId: profile.projects[0].id, locationId: profile.locations[0].id }));
        dispatch(setSelectedPageName('logs'));
    }, [profile]);

    useEffect(() => {
        if (selectedTab === 1) {
            let updateList = mouthLabLogs.filter(log => log.subjectId ? true : false);
            setMouthLabPatientLogs(updateList);
        } else {
            let updateList = mouthLabLogs.filter(log => log.subjectId ? false : true);
            setMouthLabPatientLogs(updateList);
        }
    }, [selectedTab, mouthLabLogs]);

    const openMenu = (subjectId, deviceId) => {
        setSelectedML(deviceId);
        setOpenModalMenu(true);
        setShowModal(true);
        setCurSubjectId(subjectId);
        setCurDeviceId(deviceId);
    }

    const closeMenu = () => {
        setShowModal(false);
        changeState();
    }

    const changeState = () => {
        setFormState(!formState);
    }

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const pairTheMl = () => {
        //console.log("THE ML SELECTED: ", selectedML);
        dispatch(setSelectedMlId(selectedML));
        dispatch(setPCCPatientListPageNumber(1));
        dispatch(setPCCPatientListPageSize("20"));
        navigate(`/subjects/` + profile.projects[0].id + `/` + profile.locations[0].id);
        dispatch(setShowCMDrawer(false));
        dispatch(setShowMEDrawer(false));
        dispatch(setShowQMDrawer(false));
        dispatch(setIsCalibrationOngoing(false));
    }

    const devicelogs1 = mouthLabPatientLogs.map((logs) => (
        <div onClick={selectedTab === 0 ? () => { openMenu(logs.subjectId, logs.deviceId); } : () => { }}>
            <Grid item xs={12}>
                <div className={selectedTab === 0 ? styles.cardHighRiskA : styles.cardHighRiskUA}>
                    <div className={styles.iconDivStyles}>
                        {selectedTab === 0 ? <CheckCircleIcon className={styles.highRiskIconA} size={35} /> : <CancelIcon className={styles.highRiskIconUA} size={35} />}
                    </div>
                    <div className={styles.countDivStyles}>
                        <div className={selectedTab === 0 ? styles.patientCountHighRiskA : styles.patientCountHighRiskUA}>{"ML - " + logs.deviceId}</div>
                    </div>
                </div>
            </Grid>
        </div>
    ));


    const AntTab = withStyles((theme) => ({
        root: {
            textTransform: "none",
            paddingTop: `10px`,
            color: "#4E253A",
            background: `#e9ecef`,
            minWidth: "50%",
            borderRight: "0px solid #cccaca",
            fontSize: `20px`,
            fontFamily: ["GTWalsheimProRegular"].join(","),
            fontWeight: theme.typography.fontWeightBold,
            "&:last-child": {
                borderRight: `none`,
            },
            "&:hover": {
                color: "#4E253A",
                //opacity: 1,
            },
            "&:focus": {
                color: "#4E253A",
            },
            //selected tab styles
            "&$selected": {
                color: "#fff",
                backgroundColor: "#4E253A",
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        selected: {},
    }))((props) => <Tab disableRipple {...props} />);

    const AntTabs = withStyles({
        root: {
            boxShadow: "0px 1px 5px 0px #cccaca",
            alignItems: "right",
            marginRight: `40px`,
            marginLeft: `10%`,
            marginTop: `20px`,
            borderTopRightRadius: `10px`,
            borderTopLeftRadius: `10px`,
            backgroundColor: `#E3E3E4`,
            width: "80%",
        },
        indicator: {
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: "100%",
                width: "100%",
                backgroundColor: "#4E253A",
            },
        },
    })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

    return (
        <>
            <NAHeader userEmail={profile.email} />
            <div>
                <Grid container spacing={2} className={styles.mlLogWrapper}>
                    <Grid item xs={12} xl={12} lg={12} md={12} sm={12} className={styles.NAMLLogsHeader}>
                        <HubIcon style={{ color: "#4E253A", height: "36px", width: "36px" }}></HubIcon>
                        <div className={styles.NApageTitle}> Device Assignment</div>
                    </Grid>
                    <div className={styles.MLInstructions}>
                        <div style={{ fontSize: '28px', fontWeight: 'bold' }}>Please choose from available devices for this session</div>
                        <div style={{ fontSize: '18px', color: 'grey', fontStyle: 'italic' }}>Note: Once selected please avoid changing the device between sessions.</div>
                    </div>

                    <AntTabs value={selectedTab} onChange={handleChange} aria-label="ant example" variant="fullWidth">
                        <AntTab label="Available" />
                        <AntTab label="Unavailable" />
                    </AntTabs>

                    <div className={styles.TableWrapper}>
                        <Grid container className={styles.TableWrapperGrid} spacing={2} style={{ maxHeight: '60vh', overflowY: 'scroll', padding: "20px" }}>
                            {devicelogs1}
                        </Grid>
                    </div>
                </Grid>

                {/* MouthLab Assignment */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={styles.modal}
                    open={showModal}
                    onClose={closeMenu}
                >
                    <div className={styles.paper}>
                        <div className={styles.menuTitle}>
                            <div className={styles.titleText}> MouthLab Selection </div>
                            <div>
                                <IconButton aria-label="delete">
                                    <RiCloseCircleFill size={29} onClick={closeMenu} style={{ color: "#4E253A" }} />
                                </IconButton>
                            </div>
                        </div>
                        <div className={styles.deviceSelectionWarning}>
                            <h4 style={{ paddingLeft: '10px', color: '#7C596A', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>Are you sure you would like to select <h2 className={styles.selectedML}>{"ML " + selectedML}</h2>?</h4>
                        </div>
                        <div className={styles.mouthLabImgDiv}>
                            <img src={MLImg} className={styles.mlImg} />
                        </div>

                        <div className={styles.buttonContainer}>
                            <button onClick={closeMenu} className={styles.cancelButton}>
                                Cancel
                            </button>
                            <button onClick={pairTheMl} className={styles.submitButton}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </Modal>
                {/* MouthLab Assignment */}
            </div>
        </>
    );
} export default ChooseDevices;
