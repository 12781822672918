import React from "react";
import { Formik, Form } from "formik";
import styles from "./CalibrationForm.module.css";
import Grid from "@material-ui/core/Grid";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import FormikControl from "../../../../../FormControls/FormikControl";
import CalibrationSubmission from "../../../../../API/BpCalibration/CalibrationSubmission";
import { useSelector } from "react-redux";
import { getAddSubjectSelector } from "../../../../../../Reducer/selectors/PatientSelector";

export default function CalibrationForm(props) {
  // Patient information from context
  //const patientInfo = useContext(PatientContext);

  //let subjectId = patientInfo.subjectId;

  // Fetching Subject GUID from local storage
  const addSubjectResponse = useSelector(getAddSubjectSelector);
  const subjectGUID = addSubjectResponse && addSubjectResponse.id;

  // console.log("CalibrationForm: subjectGUID = ", subjectGUID);
  let curPatientId = useParams();
  // console.log("patient GUID from context", curPatientId);

  const initialValues = {
    timestamp: new Date().toJSON(),
    id: curPatientId.patientId ? curPatientId.patientId : subjectGUID,
    systolic: "",
    diastolic: "",
  };

  const validationSchema = yup.object({
    systolic: yup
      .string('systolic')
      .required('Please enter a value'),
    diastolic: yup
      .string('diastolic')
      .required('Please enter a value'),
  });
  // console.log("initial values: ", initialValues);

  const handleSubmit = (values) => {
    // console.log("calibration form submission", values);

    CalibrationSubmission(values);
    props.onNextStep();
  };

  return (
    <div className={styles.wrapperDiv}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <FormikControl
                  control="input"
                  type="number"
                  label="Systolic"
                  name="systolic"
                  required="true"
                  // placeholder="e.g., John"
                />
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <FormikControl
                  control="input"
                  type="number"
                  label="Diastolic"
                  name="diastolic"
                  required="true"
                  // placeholder="e.g., John"
                />
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                {/* <div style={{ textAlign: "center" }}> */}
                <button
                  type="submit"
                  // onClick={handleCalibration}
                  className={styles.stepperButton}
                >
                  Calibrate
                </button>
                {/* </div> */}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
