import axios from "axios";
import domainCF from "../domainCF.js";

export default function getAvailableSubjectsByProjectAndLocationForDevice(projectId, locationId, deviceId) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_AVAILABLE_SUBJECTS = `${domainCF}/api/getAvailableSubjectsByProjectAndLocationForDevice?projectId=${projectId}&locationId=${locationId}&deviceId=${deviceId}`;

  return axios.get(GET_AVAILABLE_SUBJECTS, { headers }).then(function (response) {
    //console.log("THIS IS All PATIENTS", response.data);
    return response.data;
  });
}
