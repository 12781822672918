import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "./FormikControl";

export default function FormikContainer() {
  // Radio button options object
  const radioOp = [
    { key: "r1 race", value: "R1 Race" },
    { key: "r2 Race", value: "R2 Race" },
    { key: "r3 race", value: "R3 Race" },
    { key: "r4 Race", value: "R4 Race" },
  ];

  const checkboxOptions = [
    { key: "RR", value: "RR" },
    { key: "GTA", value: "vice" },
    { key: "NFS", value: "car" },
    { key: "COD", value: "gun" },
  ];

  const dropdownOptions = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
  ];

  const selectOptions = [
    { key: "select the game", value: "" },
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
  ];

  const dynamicSelectOptions = [
    "Select",
    "AIDAR001",
    "AIDAR003",
    "AIDAR004",
    "BT041",
    "BT101",
    "BT009",
    "TEST001",
    "LF",
    "InteprodUser1",
    "RT123",
    "BT0014",
    "ADR8676",
    "ADR11111",
    "BT0015",
    "BT0016",
    "CastorTest005",
    "CastorTest1",
    "CastorTest2",
    "castor98",
    "castor45",
    "castor23",
    "ADR569",
    "ADR8729",
    "AR501",
    "ADR1211",
    "Random007",
    "ADR2222",
    "castorteastAdd",
    "castorTest110002",
    "ADR0027",
    "abcdefg",
    "",
  ];

  const initialValues = {
    email: "",
    textareaName: "",
    radioValue: "",
    checkBoxValue: "",
    dropdownValue: "",
    selectValue: "",
    dynamicSelectValue: "",
  };
  const validationSchema = Yup.object({});
  const onclickSubmit = (values) => {
    //console.log("This is submitted values", values);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onclickSubmit}
    >
      {(formik) => (
        <Form>
          <div
            style={{
              marginLeft: `50px`,
              marginTop: `100px`,
              backgroundColor: `#ECECEC`,
            }}
          >
            <FormikControl
              control="input"
              type="email"
              label="Email"
              name="email"
            />

            <FormikControl
              control="textarea"
              type="text"
              label="Text Area"
              name="textareaName"
            />

            <FormikControl
              control="radio"
              label="radio button"
              name="radioValue"
              options={radioOp}
            />

            <FormikControl
              control="checkbox"
              label="check box"
              name="checkBoxValue"
              options={checkboxOptions}
            />

            <FormikControl
              value="selectValue"
              control="select"
              label="Select "
              name="selectValue"
              options={selectOptions}
              //onChange={handleChange}
            />

            <FormikControl
              value="selectValue"
              control="multiselect"
              label="multi select "
              name="selectValue"
              options={selectOptions}
              //onChange={handleChange}
            />

            <FormikControl
              // value="dropdownValue"
              control="dropdown"
              label="Drop down "
              name="dropdownValue"
              options={dropdownOptions}
              onChange={(e) => {
                //console.log("dropdown event", e);
              }}
            />

            <FormikControl
              value="dynamicSelectValue"
              control="dynamicSelect"
              label="dynamicSelect "
              name="dynamicSelectValue"
              options={dynamicSelectOptions}
              //onChange={handleChange}
            />

            <button type="submit">Submit</button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
