import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./QuickViewVitals.module.css";
import { ReactComponent as TempIcon } from "./../../../../../Assets/icons/01-Temp.svg";
import { ReactComponent as BPIcon } from "./../../../../../Assets/icons/02-BP.svg";
import { ReactComponent as RRIcon } from "./../../../../../Assets/icons/03-RR.svg";
import { ReactComponent as PRIcon } from "./../../../../../Assets/icons/04-PR.svg";
import { ReactComponent as HRIcon } from "./../../../../../Assets/icons/05-HR.svg";
import { ReactComponent as SPOIcon } from "./../../../../../Assets/icons/06-SpO2.svg";
import { ReactComponent as HRVIcon } from "./../../../../../Assets/icons/07-HRV.svg";
import { ReactComponent as PEFIcon } from "./../../../../../Assets/icons/08-PEF.svg";
import { ReactComponent as FEVIcon } from "./../../../../../Assets/icons/09-FEV1.svg";

const useStyles = makeStyles({
  highRiskCard: {
    height: 80,
    background: "#FFFF",
    borderColor: "#283593",
    color: "#283593",
  },

  cardContentStyleHigh: {
    color: "#283593",
    textAlign: "right",
  },

  textFont: {
    fontSize: 15,
    textTransform: "uppercase",
  },
});

export default function QuickViewVitals(props) {
  const classes = useStyles();

  return (
    <div className={styles.backColor}>
      <Grid
        container
        spacing={2}
        // justify="center"
        style={{ padding: "20px 20px 80px 20px", height: "100%", width: "100%" }}
      >
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.cardHighRisk}>
            <div className={styles.iconDivStyles}>
              <TempIcon className={styles.highRiskIcon} size={40} />
            </div>
            <div className={styles.countDivStyles}>
              <div className={styles.patientCountHighRisk}>{props.temp}</div>
              <div className={styles.patientCatagoryHighRisk}>Temp (F)</div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.cardHighRisk}>
            <div className={styles.iconDivStyles}>
              <BPIcon className={styles.highRiskIcon} size={40} />
            </div>
            <div className={styles.countDivStyles}>
              <div className={styles.patientCountHighRisk}>
                {props.sbp + "/" + props.dbp}
              </div>
              <div className={styles.patientCatagoryHighRisk}>BP (mmHg)</div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.cardHighRisk}>
            <div className={styles.iconDivStyles}>
              <RRIcon className={styles.highRiskIcon} size={40} />
            </div>
            <div className={styles.countDivStyles}>
              <div className={styles.patientCountHighRisk}>
                {props.breathingRate}
              </div>
              <div className={styles.patientCatagoryHighRisk}>
                RR (br/min)
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.cardHighRisk}>
            <div className={styles.iconDivStyles}>
              <PRIcon className={styles.highRiskIcon} size={40} />
            </div>
            <div className={styles.countDivStyles}>
              <div className={styles.patientCountHighRisk}>
                {props.pulseRate}
              </div>
              <div className={styles.patientCatagoryHighRisk}>PR (bpm)</div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.cardHighRisk}>
            <div className={styles.iconDivStyles}>
              <HRIcon className={styles.highRiskIcon} size={40} />
            </div>
            <div className={styles.countDivStyles}>
              <div className={styles.patientCountHighRisk}>
                {props.heartRate}
              </div>
              <div className={styles.patientCatagoryHighRisk}>HR (bpm)</div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.cardHighRisk}>
            <div className={styles.iconDivStyles}>
              <SPOIcon className={styles.highRiskIcon} size={40} />
            </div>
            <div className={styles.countDivStyles}>
              <div className={styles.patientCountHighRisk}>{props.spo2}</div>
              <div className={styles.patientCatagoryHighRisk}>SpO2 (%)</div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.cardHighRisk}>
            <div className={styles.iconDivStyles}>
              <HRVIcon className={styles.highRiskIcon} size={40} />
            </div>
            <div className={styles.countDivStyles}>
              <div className={styles.patientCountHighRisk}>{props.hrv}</div>
              <div className={styles.patientCatagoryHighRisk}>HRV (ms)</div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.cardHighRisk}>
            <div className={styles.iconDivStyles}>
              <PEFIcon className={styles.highRiskIcon} size={40} />
            </div>
            <div className={styles.countDivStyles}>
              <div className={styles.patientCountHighRisk}>{props.pef}</div>
              <div className={styles.patientCatagoryHighRisk}>
                PEF (L/sec)
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.cardHighRisk}>
            <div className={styles.iconDivStyles}>
              <FEVIcon className={styles.highRiskIcon} size={40} />
            </div>
            <div className={styles.countDivStyles}>
              <div className={styles.patientCountHighRisk}>{props.fev1}</div>
              <div className={styles.patientCatagoryHighRisk}>FEV1 (L)</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.timeStampDiv}>{props.timestamp} </div>
          {/* <div className={styles.timeStampStyles}> {props.timestamp} </div> */}
        </Grid>

        {/*
           <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
            <div className={styles.cardHighRisk}>
              <div className={styles.iconDivStyles}>
                <RiTempHotLine className={styles.highRiskIcon} size={50} />
              </div>
              <div className={styles.countDivStyles}>
                <div className={styles.patientCountHighRisk}>{props.fvc}</div>
                <div className={styles.patientCatagoryHighRisk}>FVC (L)</div>
              </div>
            </div>
          </Grid> 
*/}
        {/* 
        <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
          <div className={styles.cardHighRisk}>
            <div className={styles.iconDivStyles}>
              <RiLungsLine className={styles.highRiskIcon} size={50} />
            </div>
            <div className={styles.countDivStyles}>
              <div className={styles.patientCountHighRisk}>
                {props.patientVitals.fev1_fvc}
              </div>
              <div className={styles.patientCatagoryHighRisk}>FEV1/FVC (%)</div>
            </div>
          </div>
        </Grid>
*/}
      </Grid>
    </div>
  );
}
