import React from "react";
import styles from "./FAQsAidi.module.css";
import Divider from "@material-ui/core/Divider";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

export default function FAQsPrivacy() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <div className={styles.faqTitle}>Privacy and Security</div>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel1" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={styles.question}>Aidar’s Privacy Policy</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            This Site is owned and operated by Aidar Health Inc. or one or more of its affiliates (collectively referred to as “Aidar” “us“, “we” or “our“).
            Aidar knows that you care about how information about you is used and shared. This Policy explains what we may do with information that we collect
            from or about you when you use our website at www.aidarconnect.com or certain other affiliated websites (Sites) that link to or post this Privacy
            Policy.
            <br></br>
            <br></br>Certain uses or disclosures of personal information may be governed by other privacy notices. At times we may collect personal information
            about you that constitutes Protected Health Information (PHI) pursuant to the Health Insurance Portability and Accountability Act (HIPAA). Our use
            and disclosure of PHI is subject to our, or our customer’s as applicable, HIPAA Notice of Privacy Practices and not this Privacy Policy.<br></br>
            <br></br>
            By visiting our Aidar’s Sites you consent to the collection, use, and transfer of your personal data in accordance with this Privacy Policy.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel2" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <div className={styles.question}>Information collected about you</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            In this Privacy Policy, “personal data” means information that may identify you as an individual directly or indirectly. Personal Data does not
            include information that has been “deidentified” in such a way that your identity cannot reasonably be determined.<br></br>
            <br></br>
            If you visit our Sites, we may collect information that personally identifies you, including your name, telephone number, and email address
            (“personal data“) from you, only if you provide us with such information.<br></br>
            <br></br>
            We will indicate if the collection and provision of certain categories of personal data is mandatory. For any such categories, we may not be able to
            provide you with the services on our Sites if you do not provide us with the required information.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel3" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <div className={styles.question}>When you visit our Sites</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            Aidar collects industry-standard data from everyone who visits our Sites — even if you don’t have an Aidar Connect account. This includes log data
            that automatically records information about your visit, such as your browser type, operating system, the URL of the page that referred you, the
            different actions you performed, and the IP address you used to access pages on the Sites. We use this type of information to provide you with an
            experience that’s relevant to your location based on the IP address, to prevent Site misuse, and to ensure the Sites are working properly. We also
            collect data from cookies. To see the full list of cookies we use and how we use them, please read our Cookie Policy below.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel4" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <div className={styles.question}>Use of your personal data</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            You consent to Aidar collecting, using, and disclosing your personal data for the following purposes:
            <ul>
              <li>responding to, handling, and processing your queries, requests, applications, complaints, and feedback;</li>
              <li>
                subject to your consent, sending you notifications and informing you about new features, products, or services we think you would be interested
                in. You may withdraw your consent by contacting us at the email address provided below or follow the steps to unsubscribe which are presented in
                communications you receive from us;
              </li>
              <li>
                data and logs are used in research to understand and improve the Aidar’s Sites; to troubleshoot the Aidar’s Sites; to detect and protect against
                error, fraud, or other criminal activity; and to protect the security or integrity of the Aidar’s Sites;
              </li>
              <li>
                complying with any applicable laws, regulations, codes of practice, guidelines, or rules, or to assist in law enforcement and investigations
                conducted by any governmental and/or regulatory authority; and
              </li>
              <li>any other incidental business purposes related to or in connection with the above.</li>
            </ul>
            <br></br>
            In the event of a corporate sale, merger, reorganization, dissolution, or similar event, your personal data may be part of the assets that are
            transferred (“business asset transaction“). You consent to such transfer and you acknowledge and agree that any successor or acquirer of Aidar or
            its assets will continue to have the right to use your personal data and other information in accordance with the terms of this Privacy Policy.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel5" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <div className={styles.question}>Retention of your personal data</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            We will only retain your personal data until it is reasonable to assume that such retention no longer serves the purpose for which your personal
            data was collected and is no longer necessary for legal or business purposes.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel6" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <div className={styles.question}>Access to your personal data</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            We will use all reasonable endeavors to keep your personal data accurate, complete, up-to-date, relevant, and not misleading. Please contact us at
            privacy@aidar.com if you would like to access the personal data that we hold about you, correct any personal data that is inaccurate, incomplete, or
            out-of-date, withdraw your consent to the collection, use, and disclosure of your personal data or to request that your personal data be deleted. We
            will use reasonable endeavors to provide a complete list of your personal data and correct or delete your personal data within a reasonable period
            of receipt of your request.<br></br>
            <br></br>
            If you would like to request that we delete all personal data, please contact us at privacy@aidar.com. Our ability to comply with your deletion
            request is subject to any applicable legal or other requirement to maintain certain records of your personal data. In that regard, please note that
            the deletion of your personal data from our database may result in us not being able to provide you with some services on our Sites.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel7"} onChange={handleChange("panel7")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel7" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <div className={styles.question}>Storage and security of your personal data</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            We will use all reasonable endeavors to maintain the security of your personal data and to protect your personal data from misuse, interference, and
            loss and against unauthorized collection, copying, access, modification, or disclosure. We will destroy or anonymize any personal data we hold about
            you which is no longer required under the terms of this Privacy Policy.<br></br>
            <br></br>
            Due to the nature of the internet, we do not provide any guarantee or warranty regarding the security of your personal data during transmission to
            or storage by us and you acknowledge that you disclose your personal data to us at your own risk. Please contact us immediately if you become aware
            or have reason to believe there has been any unauthorized use of your personal data in connection with Aidar’s Sites. The personal data you provide
            to us will be transferred to and stored with a cloud service provider with servers that are located in various jurisdictions.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel8"} onChange={handleChange("panel8")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel8" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <div className={styles.question}>Cookie Policy</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            Some of the information that we collect will not personally identify you but will instead track your use of the Sites so that we can better
            understand how the Sites are used by end users and in turn enhance and improve your experience in using the Sites. This information can be obtained
            through the use of cookies. Cookies are small data file transferred to your device that recognizes and identifies your device and allows your device
            to ‘remember’ information from the Sites for future use. We may collect technical information from your web browser or mobile device or your use of
            our services through a web browser or mobile device, for example, location data and certain characteristics of, and performance data about your
            device, carrier/operating system including device and connection type and IP address. Unless you have elected to remain anonymous through your
            device and/or the web browser, the above- mentioned information may be collected and used by us automatically through your use of the Sites.
            <br></br>
            <br></br>
            You have a number of options to control or limit how we and our partners use cookies and similar technologies, including for advertising.<br></br>
            <ul>
              <li>Although most browsers and devices accept cookies by default, their settings usually </li>
              <li>allow you to clear or decline cookies. If you disable cookies, however, some of the features of the Sites may not function properly.</li>
              <li>
                To prevent your data from being used by Google Analytics, you can install Google’s opt- out browser add-on by visiting
                https://tools.google.com/dlpage/gaoptout.
              </li>
              <li>
                Check your mobile device for settings that control ads based on your interactions with the applications on your device. For example, on your iOS
                device, enable the “Limit Ad Tracking” setting, and on your Android device, enable the “Opt out of Ads Personalization” setting.
              </li>
            </ul>
            The Sites do not respond to Do Not Track signals because we do not track our users over time and across third-party websites to provide targeted
            advertising.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel9"} onChange={handleChange("panel9")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel9" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <div className={styles.question}>Changes to our Privacy Policy</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            Aidar reserves the right to amend all or any part of this Privacy Policy. Any changes will be notified through the Sites and/or, where appropriate,
            through e-mail notification. Your continued use of the Sites with us after any such changes are notified to you constitutes your agreement to this
            Privacy Policy as amended.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel10"} onChange={handleChange("panel10")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel10" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <div className={styles.question}>Links to our other Websites and Applications</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            The Sites may have links to other websites or applications. We are not responsible for the security or privacy of any information collected by such
            websites and, while we do not permit those websites to track your use of the Aidar’s Sites, we are unable to control whether such tracking
            mechanisms are implemented by those apps or websites. You should exercise caution and review the privacy statements applicable to the third-party
            websites and services you use. The use of online tracking mechanisms by those third-party websites and services is subject to those third parties’
            own privacy policies, and not this Privacy Policy.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel11"} onChange={handleChange("panel11")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel11" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
        >
          <div className={styles.question}>Effect of Privacy Policy</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            This Privacy Policy applies in conjunction with any other policies, notices, contractual clauses and consent statements that apply in relation to
            the collection, use and disclosure of your personal data by us.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
    </div>
  );
}
