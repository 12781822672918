import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

const PatientMedicationTab = forwardRef((props, ref) => {

    return (
        <>
            PatientMedicationTab
        </>
    );
});

export default PatientMedicationTab;