import styles from "./PatientFamilyTab.module.css";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Select from 'react-dropdown-select';
import { setPRFamilyInfo, setRegistrationStatus, setShowFamilyInfoLoader } from "../../../../Actions/UtilActions";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPatientGUID, getFamilyInfoLoader, getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import CloseIcon from '@mui/icons-material/Close';
import { getFamilyFormEdit1, getFamilyFormEdit2, getFamilyFormEdit3, getPatientInfoSelector } from "../../../../Reducer/selectors/PatientSelector";
import { getPatientInfo, setFamilyFormEdit1, setFamilyFormEdit2, setFamilyFormEdit3, updateFamilyInfo } from "../../../../Actions";
import { getSelectedSubjectId } from "../../../../Reducer/selectors/DashboardSelector";
import CircularProgress from "@material-ui/core/CircularProgress";

const PatientFamilyTab = forwardRef((props, ref) => {

    const handleFocus = (event) => event.target.select();
    const dispatch = useDispatch();

    const curRegStatus = useSelector(getRegistrationStatus);

    const patientInfoObj = useSelector(getPatientInfoSelector);
    //console.log(patientInfoObj);

    const [contactsCount, setContactsCount] = useState(patientInfoObj.familyContacts ? patientInfoObj.familyContacts.length : 0);
    const [showSecContact, setShowSecContact] = useState(false);
    const [showTerContact, setShowTerContact] = useState(false);

    const [prefixC1, setPrefixC1] = useState([patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.prefix ? patientInfoObj.familyContacts[0].contactInfo.prefix : '' : '']);
    const [firstNameC1, setFirstNameC1] = useState(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.firstName ? patientInfoObj.familyContacts[0].contactInfo.firstName : '' : '');
    const [suffixC1, setSuffixC1] = useState([patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.suffix ? patientInfoObj.familyContacts[0].contactInfo.suffix : '' : '']);
    const [lastNameC1, setLastNameC1] = useState(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.lastName ? patientInfoObj.familyContacts[0].contactInfo.lastName : '' : '');
    const [relC1, setRelC1] = useState(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].relationship ? patientInfoObj.familyContacts[0].relationship : '' : '');
    const [bioSexC1, setBioSexC1] = useState([patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.gender ? patientInfoObj.familyContacts[0].contactInfo.gender : '' : '']);
    const [emailC1, setEmailC1] = useState(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.email ? patientInfoObj.familyContacts[0].contactInfo.email : '' : '');
    const [phNumC1, setPhNumC1] = useState(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.phone ? patientInfoObj.familyContacts[0].contactInfo.phone : '' : '');
    const [conTypeC1, setConTypeC1] = useState([patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactType ? patientInfoObj.familyContacts[0].contactType : '' : '']);
    const [comModeC1, setComModeC1] = useState([patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.prefCommMode ? patientInfoObj.familyContacts[0].contactInfo.prefCommMode : '' : '']);

    const [prefixC2, setPrefixC2] = useState([patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.prefix : '']);
    const [firstNameC2, setFirstNameC2] = useState(patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.firstName : '');
    const [suffixC2, setSuffixC2] = useState([patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.suffix : '']);
    const [lastNameC2, setLastNameC2] = useState(patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.lastName : '');
    const [relC2, setRelC2] = useState(patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].relationship : '');
    const [bioSexC2, setBioSexC2] = useState([patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.gender : '']);
    const [emailC2, setEmailC2] = useState(patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.email : '');
    const [phNumC2, setPhNumC2] = useState(patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.phone : '');
    const [conTypeC2, setConTypeC2] = useState([patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactType : '']);
    const [comModeC2, setComModeC2] = useState([patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.prefCommMode : '']);

    const [prefixC3, setPrefixC3] = useState([patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.prefix : '']);
    const [firstNameC3, setFirstNameC3] = useState(patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.firstName : '');
    const [suffixC3, setSuffixC3] = useState([patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.suffix : '']);
    const [lastNameC3, setLastNameC3] = useState(patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.lastName : '');
    const [relC3, setRelC3] = useState(patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].relationship : '');
    const [bioSexC3, setBioSexC3] = useState([patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.gender : '']);
    const [emailC3, setEmailC3] = useState(patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.email : '');
    const [phNumC3, setPhNumC3] = useState(patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.phone : '');
    const [conTypeC3, setConTypeC3] = useState([patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactType : '']);
    const [comModeC3, setComModeC3] = useState([patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.prefCommMode : '']);

    const [consentSel1, setConsentSel1] = useState(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].consent ? patientInfoObj.familyContacts[0].consent === true ? "Yes" : "No" : "No" : "No");
    const [consentSel2, setConsentSel2] = useState(patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].consent ? patientInfoObj.familyContacts[1].consent === true ? "Yes" : "No" : "No" : "No");
    const [consentSel3, setConsentSel3] = useState(patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].consent ? patientInfoObj.familyContacts[2].consent === true ? "Yes" : "No" : "No" : "No");
    //console.log('familyConsent: ', consentSel);

    const [showReqWarn, setShowReqWarn] = useState(false);

    const [isFirstNameC1Fill, setIsFirstNameC1Fill] = useState(true);
    const [isLastNameC1Fill, setIsLastNameC1Fill] = useState(true);
    const [isBioSexC1Fill, setIsBioSexC1Fill] = useState(true);
    const [isPhNumC1Fill, setIsPhNumC1Fill] = useState(true);
    const [isEmailC1Fill, setIsEmailC1Fill] = useState(true);
    const [isRelC1Fill, setIsRelC1Fill] = useState(true);
    const [isConTypeC1Fill, setIsConTypeC1Fill] = useState(true);
    const [isComModeC1Fill, setIsComModeC1Fill] = useState(true);

    const [isFirstNameC2Fill, setIsFirstNameC2Fill] = useState(true);
    const [isLastNameC2Fill, setIsLastNameC2Fill] = useState(true);
    const [isBioSexC2Fill, setIsBioSexC2Fill] = useState(true);
    const [isPhNumC2Fill, setIsPhNumC2Fill] = useState(true);
    const [isEmailC2Fill, setIsEmailC2Fill] = useState(true);
    const [isRelC2Fill, setIsRelC2Fill] = useState(true);
    const [isConTypeC2Fill, setIsConTypeC2Fill] = useState(true);
    const [isComModeC2Fill, setIsComModeC2Fill] = useState(true);

    const [isFirstNameC3Fill, setIsFirstNameC3Fill] = useState(true);
    const [isLastNameC3Fill, setIsLastNameC3Fill] = useState(true);
    const [isBioSexC3Fill, setIsBioSexC3Fill] = useState(true);
    const [isPhNumC3Fill, setIsPhNumC3Fill] = useState(true);
    const [isEmailC3Fill, setIsEmailC3Fill] = useState(true);
    const [isRelC3Fill, setIsRelC3Fill] = useState(true);
    const [isConTypeC3Fill, setIsConTypeC3Fill] = useState(true);
    const [isComModeC3Fill, setIsComModeC3Fill] = useState(true);

    const isFamilyFormEditable1 = useSelector(getFamilyFormEdit1);
    const isFamilyFormEditable2 = useSelector(getFamilyFormEdit2);
    const isFamilyFormEditable3 = useSelector(getFamilyFormEdit3);

    const selectedSubjectId = useSelector(getSelectedSubjectId);
    const currSubjectGUID = useSelector(getCurrentPatientGUID);

    const familyInfoLoader = useSelector(getFamilyInfoLoader);

    const [showConsent2, setShowConsent2] = useState(false);
    const [showConsent3, setShowConsent3] = useState(false);

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const checkAndShowContacts = () => {
        if (patientInfoObj.familyContacts[1] && patientInfoObj.familyContacts[1].contactInfo.firstName !== '') {
            setShowSecContact(true);
        } else {
            setShowSecContact(false);
        }
        if (patientInfoObj.familyContacts[2] && patientInfoObj.familyContacts[2].contactInfo.firstName !== '') {
            setShowTerContact(true);
        } else {
            setShowTerContact(false);
        }
    };

    useEffect(() => {
        checkAndShowContacts();
        setContactsCount(patientInfoObj.familyContacts ? patientInfoObj.familyContacts.length : 0);
    }, []);

    const setAllFields1 = () => {
        setPrefixC1([patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.prefix ? patientInfoObj.familyContacts[0].contactInfo.prefix : '' : '']);
        setFirstNameC1(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.firstName ? patientInfoObj.familyContacts[0].contactInfo.firstName : '' : '');
        setSuffixC1([patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.suffix ? patientInfoObj.familyContacts[0].contactInfo.suffix : '' : '']);
        setLastNameC1(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.lastName ? patientInfoObj.familyContacts[0].contactInfo.lastName : '' : '');
        setRelC1(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].relationship ? patientInfoObj.familyContacts[0].relationship : '' : '');
        setBioSexC1([patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.gender ? patientInfoObj.familyContacts[0].contactInfo.gender : '' : '']);
        setEmailC1(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.email ? patientInfoObj.familyContacts[0].contactInfo.email : '' : '');
        setPhNumC1(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.phone ? patientInfoObj.familyContacts[0].contactInfo.phone : '' : '');
        setConTypeC1([patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactType ? patientInfoObj.familyContacts[0].contactType : '' : '']);
        setComModeC1([patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].contactInfo.prefCommMode ? patientInfoObj.familyContacts[0].contactInfo.prefCommMode : '' : '']);
        setConsentSel1(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].consent ? patientInfoObj.familyContacts[0].consent === true ? "Yes" : "No" : "No" : "No");
    };

    const setAllFields2 = () => {
        setPrefixC2([patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.prefix ? patientInfoObj.familyContacts[1].contactInfo.prefix : '' : '']);
        setFirstNameC2(patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.firstName ? patientInfoObj.familyContacts[1].contactInfo.firstName : '' : '');
        setSuffixC2([patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.suffix ? patientInfoObj.familyContacts[1].contactInfo.suffix : '' : '']);
        setLastNameC2(patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.lastName ? patientInfoObj.familyContacts[1].contactInfo.lastName : '' : '');
        setRelC2(patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].relationship ? patientInfoObj.familyContacts[1].relationship : '' : '');
        setBioSexC2([patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.gender ? patientInfoObj.familyContacts[1].contactInfo.gender : '' : '']);
        setEmailC2(patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.email ? patientInfoObj.familyContacts[1].contactInfo.email : '' : '');
        setPhNumC2(patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.phone ? patientInfoObj.familyContacts[1].contactInfo.phone : '' : '');
        setConTypeC2([patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactType ? patientInfoObj.familyContacts[1].contactType : '' : '']);
        setComModeC2([patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].contactInfo.prefCommMode ? patientInfoObj.familyContacts[1].contactInfo.prefCommMode : '' : '']);
        setConsentSel2(patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].consent ? patientInfoObj.familyContacts[1].consent === true ? "Yes" : "No" : "No" : "No");
    };

    const setAllFields3 = () => {
        setPrefixC3([patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.prefix ? patientInfoObj.familyContacts[2].contactInfo.prefix : '' : '']);
        setFirstNameC3(patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.firstName ? patientInfoObj.familyContacts[2].contactInfo.firstName : '' : '');
        setSuffixC3([patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.suffix ? patientInfoObj.familyContacts[2].contactInfo.suffix : '' : '']);
        setLastNameC3(patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.lastName ? patientInfoObj.familyContacts[2].contactInfo.lastName : '' : '');
        setRelC3(patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].relationship ? patientInfoObj.familyContacts[2].relationship : '' : '');
        setBioSexC3([patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.gender ? patientInfoObj.familyContacts[2].contactInfo.gender : '' : '']);
        setEmailC3(patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.email ? patientInfoObj.familyContacts[2].contactInfo.email : '' : '');
        setPhNumC3(patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.phone ? patientInfoObj.familyContacts[2].contactInfo.phone : '' : '');
        setConTypeC3([patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactType ? patientInfoObj.familyContacts[2].contactType : '' : '']);
        setComModeC3([patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].contactInfo.prefCommMode ? patientInfoObj.familyContacts[2].contactInfo.prefCommMode : '' : '']);
        setConsentSel3(patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].consent ? patientInfoObj.familyContacts[0].consent === true ? "Yes" : "No" : "No" : "No");
    };

    const resetC1ReqFills = () => {
        setIsFirstNameC1Fill(true);
        setIsLastNameC1Fill(true);
        setIsBioSexC1Fill(true);
        setIsPhNumC1Fill(true);
        setIsEmailC1Fill(true);
        setIsRelC1Fill(true);
        setIsConTypeC1Fill(true);
        setIsComModeC1Fill(true);
    };

    const resetC2ReqFills = () => {
        setIsFirstNameC2Fill(true);
        setIsLastNameC2Fill(true);
        setIsBioSexC2Fill(true);
        setIsPhNumC2Fill(true);
        setIsEmailC2Fill(true);
        setIsRelC2Fill(true);
        setIsConTypeC2Fill(true);
        setIsComModeC2Fill(true);
    };

    const resetC3ReqFills = () => {
        setIsFirstNameC3Fill(true);
        setIsLastNameC3Fill(true);
        setIsBioSexC3Fill(true);
        setIsPhNumC3Fill(true);
        setIsEmailC3Fill(true);
        setIsRelC3Fill(true);
        setIsConTypeC3Fill(true);
        setIsComModeC3Fill(true);
    };

    const resetReqFills = () => {
        resetC1ReqFills();
        resetC2ReqFills();
        resetC3ReqFills();
    };

    const optionsPrefix = [
        { value: 'Dr.', label: 'Dr.' },
        { value: 'Miss.', label: 'Miss.' },
        { value: 'Mr.', label: 'Mr.' },
        { value: 'Mrs.', label: 'Mrs.' },
        { value: 'Ms.', label: 'Ms.' },
    ];

    const optionsSuffix = [
        { value: 'II', label: 'II' },
        { value: 'III', label: 'III' },
        { value: 'IV', label: 'IV' },
        { value: 'Jr.', label: 'Jr.' },
        { value: 'Sr.', label: 'Sr.' },
    ];

    const optionsBioSex = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Unknown', label: 'Unknown' },
    ];

    const optionsConType = [
        { value: 'Care Conference Person', label: 'Care Conference Person' },
        { value: 'Primary Contact', label: 'Primary Contact' },
        { value: 'Secondary Contact', label: 'Secondary Contact' },
        { value: 'Third Contact', label: 'Third Contact' },
        { value: 'Guardian of Person', label: 'Guardian of Person' },
        { value: 'Guardian of Assets', label: 'Guardian of Assets' },
        { value: 'Health Care Surrogate', label: 'Health Care Surrogate' },
        { value: 'POA-Financial', label: 'POA-Financial' },
        { value: 'POA-Care', label: 'POA-Care' },
        { value: 'This contact receives satisfaction survey', label: 'This contact receives satisfaction survey' },
        { value: 'Resident makes health care decisions', label: 'Resident makes health care decisions' },
        { value: 'Financial Responsible Party', label: 'Financial Responsible Party' },
    ];

    const optionsComModeC1 = [
        { value: 'Email', label: 'Email' },
        { value: 'Phone', label: 'Phone' },
        { value: 'Both', label: 'Both' },
    ];

    const customContentRendererMulti = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            return (
                <div>
                    Selected <b>{state.values.length}</b> out of{" "}
                    <b>{props.options.length}</b>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    };

    const customContentRenderer = ({ props, state }) => {
        console.log("state: ", state);

        if (state.values && state.values[0] && state.values[0].label) {
            if (state.values && state.values.length > 0) {
                if (state.values[0].label.length > 25) {
                    return (
                        <div>
                            {state.values[0].label.slice(0, 25)}...
                        </div>
                    );
                } else {
                    return (
                        <div>{state.values[0].label}</div>
                    );
                }
            }
        } else {
            if (state.values && state.values[0] && state.values.length > 0) {
                if (state.values[0].length > 25) {
                    return (
                        <div>
                            {state.values[0].slice(0, 25)}...
                        </div>
                    );
                } else {
                    return (
                        <div>{state.values[0]}</div>
                    );
                }
            }
        }
    };


    const onAddNewContact = () => {
        if (contactsCount < 3) {
            let temp = contactsCount + 1;
            setContactsCount(temp);
        } else {
            setContactsCount(1);
        }
    };

    const onAddSecContact = (event) => {
        event.preventDefault();
        if (firstNameC1 !== '') {
            dispatch(setFamilyFormEdit1(false));
            setShowSecContact(true);
            dispatch(setFamilyFormEdit2(true));
            setShowConsent2(true);
            setContactsCount(2);
        }
    };

    const onAddTerContact = (event) => {
        event.preventDefault();
        if (firstNameC2 !== '') {
            dispatch(setFamilyFormEdit2(false));
            setShowTerContact(true);
            dispatch(setFamilyFormEdit3(true));
            setShowConsent3(true);
            setContactsCount(3);
        }
    };

    const deleteContact = () => {
        if (contactsCount === 2) {
            resetC2ReqFills();
            setContactsCount(1);
        } else if (contactsCount === 3) {
            resetC3ReqFills();
            setContactsCount(2);
        } else {
            let temp = contactsCount - 1;
            setContactsCount(temp);
        }
    };

    const deleteSecContact = (event) => {
        event.preventDefault();
        setShowSecContact(false);
        resetC2ReqFills();
        setContactsCount(1);
    };

    const deleteTerContact = (event) => {
        event.preventDefault();
        setShowTerContact(false);
        resetC3ReqFills();
        setContactsCount(2);
    };

    const getStringFromSelectArray = (arr) => {
        const len = arr.length;
        let res = "";
        for (let i = 0; i < len; i++) {
            if (res.length > 0) {
                res = res + ", " + arr[i].value;
            } else {
                res = res + arr[i].value;
            }
        }
        return res;
    };

    const checkIfContact1IsDone = () => {
        if ((firstNameC1 !== '') && (lastNameC1 !== '')) {
            resetC1ReqFills();
            return true;
        } else {
            resetC1ReqFills();
            if (firstNameC1 === '') {
                setIsFirstNameC1Fill(false);
            };
            if (lastNameC1 === '') {
                setIsLastNameC1Fill(false);
            };
            return false;
        }
    };

    const checkIfContact2IsDone = () => {
        if (contactsCount > 1) {
            if ((firstNameC2 !== '') && (lastNameC2 !== '')) {
                resetC2ReqFills();
                return true;
            } else {
                resetC2ReqFills();
                if (firstNameC2 === '') {
                    setIsFirstNameC2Fill(false);
                };
                if (lastNameC2 === '') {
                    setIsLastNameC2Fill(false);
                };
                return false;
            }
        } else {
            return true;
        }
    };

    const checkIfContact3IsDone = () => {
        if (contactsCount > 2) {
            if ((firstNameC3 !== '') && (lastNameC3 !== '')) {
                resetC3ReqFills();
                return true;
            } else {
                resetC3ReqFills();
                if (firstNameC3 === '') {
                    setIsFirstNameC3Fill(false);
                };
                if (lastNameC3 === '') {
                    setIsLastNameC3Fill(false);
                };
                return false;
            }
        } else {
            return true;
        }
    };

    const getContactsPayload = () => {
        if (firstNameC3 !== '' && lastNameC3 !== '') {
            return (
                [
                    {
                        id: patientInfoObj.familyContacts ? patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].id : null : null,
                        prefix: prefixC1[0] ? prefixC1[0].value : '',
                        firstName: firstNameC1,
                        suffix: suffixC1[0] ? suffixC1[0].value : '',
                        lastName: lastNameC1,
                        relationship: relC1,
                        gender: bioSexC1[0] ? bioSexC1[0].value : '',
                        email: emailC1,
                        phone: phNumC1,
                        contactType: getStringFromSelectArray(conTypeC1),
                        prefCommMode: getStringFromSelectArray(comModeC1),
                        consent: consentSel1 === 'Yes' ? true : false,
                        rank: "Primary"
                    },
                    {
                        id: patientInfoObj.familyContacts ? patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].id : null : null,
                        prefix: prefixC2[0] ? prefixC2[0].value : '',
                        firstName: firstNameC2,
                        suffix: suffixC2[0] ? suffixC2[0].value : '',
                        lastName: lastNameC2,
                        relationship: relC2,
                        gender: bioSexC2[0] ? bioSexC2[0].value : '',
                        email: emailC2,
                        phone: phNumC2,
                        contactType: getStringFromSelectArray(conTypeC2),
                        prefCommMode: getStringFromSelectArray(comModeC2),
                        consent: consentSel2 === 'Yes' ? true : false,
                        rank: "Secondary"
                    },
                    {
                        id: patientInfoObj.familyContacts ? patientInfoObj.familyContacts[2] ? patientInfoObj.familyContacts[2].id : null : null,
                        prefix: prefixC3[0] ? prefixC3[0].value : '',
                        firstName: firstNameC3,
                        suffix: suffixC3[0] ? suffixC3[0].value : '',
                        lastName: lastNameC3,
                        relationship: relC3,
                        gender: bioSexC3[0] ? bioSexC3[0].value : '',
                        email: emailC3,
                        phone: phNumC3,
                        contactType: getStringFromSelectArray(conTypeC3),
                        prefCommMode: getStringFromSelectArray(comModeC3),
                        consent: consentSel3 === 'Yes' ? true : false,
                        rank: "Tertiary"
                    }
                ]
            );
        } else if (firstNameC2 !== '' && lastNameC2 !== '') {
            return (
                [
                    {
                        id: patientInfoObj.familyContacts ? patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].id : null : null,
                        prefix: prefixC1[0] ? prefixC1[0].value : '',
                        firstName: firstNameC1,
                        suffix: suffixC1[0] ? suffixC1[0].value : '',
                        lastName: lastNameC1,
                        relationship: relC1,
                        gender: bioSexC1[0] ? bioSexC1[0].value : '',
                        email: emailC1,
                        phone: phNumC1,
                        contactType: getStringFromSelectArray(conTypeC1),
                        prefCommMode: getStringFromSelectArray(comModeC1),
                        consent: consentSel1 === 'Yes' ? true : false,
                        rank: "Primary"

                    },
                    {
                        id: patientInfoObj.familyContacts ? patientInfoObj.familyContacts[1] ? patientInfoObj.familyContacts[1].id : null : null,
                        prefix: prefixC2[0] ? prefixC2[0].value : '',
                        firstName: firstNameC2,
                        suffix: suffixC2[0] ? suffixC2[0].value : '',
                        lastName: lastNameC2,
                        relationship: relC2,
                        gender: bioSexC2[0] ? bioSexC2[0].value : '',
                        email: emailC2,
                        phone: phNumC2,
                        contactType: getStringFromSelectArray(conTypeC2),
                        prefCommMode: getStringFromSelectArray(comModeC2),
                        consent: consentSel2 === 'Yes' ? true : false,
                        rank: "Secondary"
                    }
                ]
            );
        } else {
            return (
                [
                    {
                        id: patientInfoObj.familyContacts ? patientInfoObj.familyContacts[0] ? patientInfoObj.familyContacts[0].id : null : null,
                        prefix: prefixC1[0] ? prefixC1[0].value : '',
                        firstName: firstNameC1,
                        suffix: suffixC1[0] ? suffixC1[0].value : '',
                        lastName: lastNameC1,
                        relationship: relC1,
                        gender: bioSexC1[0] ? bioSexC1[0].value : '',
                        email: emailC1,
                        phone: phNumC1,
                        contactType: getStringFromSelectArray(conTypeC1),
                        prefCommMode: getStringFromSelectArray(comModeC1),
                        consent: consentSel1 === 'Yes' ? true : false,
                        rank: "Primary"
                    }
                ]
            );
        }
    };

    const setTabInfo = async () => {

        const payload = {
            subjectId: selectedSubjectId.selectedSubjectId,
            familyContacts: getContactsPayload(),
        };
        console.log("familyInfoTab payload: ", payload);
        dispatch(setShowFamilyInfoLoader(true));
        dispatch(updateFamilyInfo(payload));
        dispatch(setFamilyFormEdit1(false));
        dispatch(setFamilyFormEdit2(false));
        dispatch(setFamilyFormEdit3(false));
        setShowReqWarn(false);
        await sleep(3000);
        dispatch(getPatientInfo(currSubjectGUID));
    };

    const checkIfPageConditionsMet = () => {
        if (checkIfContact1IsDone() && checkIfContact2IsDone() && checkIfContact3IsDone()) {
            //console.log('conditions met!');
            setShowReqWarn(false);
            return true;
        } else {
            //console.log('conditions not met!');
            setShowReqWarn(true);
            return false;
        }
    };

    const handleSaveAndContinue = () => {
        if (checkIfPageConditionsMet()) {
            setTabInfo();
        }
    };

    const onValueChange1 = (event) => {
        // Updating the state with the selected radio button's value
        setConsentSel1(event.target.value);
    };

    const onValueChange2 = (event) => {
        // Updating the state with the selected radio button's value
        setConsentSel2(event.target.value);
    };

    const onValueChange3 = (event) => {
        // Updating the state with the selected radio button's value
        setConsentSel3(event.target.value);
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });


    const editFamilyForm1 = (event) => {
        event.preventDefault();
        dispatch(setFamilyFormEdit1(true));
    };

    const editFamilyForm2 = (event) => {
        event.preventDefault();
        dispatch(setFamilyFormEdit2(true));
    };

    const editFamilyForm3 = (event) => {
        event.preventDefault();
        dispatch(setFamilyFormEdit3(true));
    };

    const cancelEdit1 = () => {
        setAllFields1();
        setShowReqWarn(false);
        dispatch(setFamilyFormEdit1(false));
        checkAndShowContacts();
    };

    const handleSaveFamily1 = () => {
        handleSaveAndContinue();
    };

    const cancelEdit2 = () => {
        setAllFields2();
        setShowReqWarn(false);
        dispatch(setFamilyFormEdit2(false));
        checkAndShowContacts();
    };

    const handleSaveFamily2 = () => {
        //console.log(contactsCount);
        handleSaveAndContinue();
    };

    const cancelEdit3 = () => {
        setAllFields3();
        setShowReqWarn(false);
        dispatch(setFamilyFormEdit3(false));
        checkAndShowContacts();
    };

    const handleSaveFamily3 = () => {
        handleSaveAndContinue();
    };


    return (
        <>
            {familyInfoLoader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) : (<>
                <div className={styles.containerDiv}>
                    <div className={styles.dumDiv}>

                        {contactsCount >= 0 && (<>
                            <div className={styles.headerDiv}>
                                <div></div>
                                <div>Primary</div>
                                <div className={styles.editButtonDiv}>
                                    <div className={!isFamilyFormEditable1 ? styles.editButton : styles.editButtonClicked} onClick={event => editFamilyForm1(event)}>Edit</div>
                                </div>
                            </div>
                            <div className={styles.contentDiv}>
                                <div className={styles.column1}>
                                    <div className={styles.fieldDiv2}>
                                        <div className={styles.subFieldDiv}>
                                            <div className={styles.subFieldName}>Prefix</div>
                                            <div className={styles.subFieldInput}>
                                                <Select
                                                    contentRenderer={customContentRenderer}
                                                    disabled={!isFamilyFormEditable1}
                                                    className={isFamilyFormEditable1 ? styles.dropdownE : styles.dropdown}
                                                    values={prefixC1}
                                                    onChange={setPrefixC1}
                                                    options={optionsPrefix}
                                                    color="#4E253A"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.subFieldDiv}>
                                            <div className={styles.subFieldName}>First Name </div>
                                            <div className={styles.subFieldInput}>
                                                <input type="text" value={firstNameC1} onChange={(e) => setFirstNameC1(e.currentTarget.value)} disabled={!isFamilyFormEditable1} className={isFamilyFormEditable1 ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.fieldDiv2}>
                                        <div className={styles.subFieldDiv}>
                                            <div className={styles.subFieldName}>Last Name </div>
                                            <div className={styles.subFieldInput}>
                                                <input type="text" value={lastNameC1} onChange={(e) => setLastNameC1(e.currentTarget.value)} disabled={!isFamilyFormEditable1} className={isFamilyFormEditable1 ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                            </div>
                                        </div>
                                        <div className={styles.subFieldDiv}>
                                            <div className={styles.subFieldName}>Suffix</div>
                                            <div className={styles.subFieldInput}>
                                                <Select
                                                    contentRenderer={customContentRenderer}
                                                    disabled={!isFamilyFormEditable1}
                                                    className={isFamilyFormEditable1 ? styles.dropdownE : styles.dropdown}
                                                    values={suffixC1}
                                                    onChange={setSuffixC1}
                                                    options={optionsSuffix}
                                                    color="#4E253A"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.column2}>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Sex at Birth
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                className={isFamilyFormEditable1 ? styles.dropdown2E : styles.dropdown2}
                                                disabled={!isFamilyFormEditable1}
                                                values={bioSexC1}
                                                onChange={setBioSexC1}
                                                options={optionsBioSex}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Relationship to Patient
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <input type="text" value={relC1} onChange={(e) => setRelC1(e.currentTarget.value)} disabled={!isFamilyFormEditable1} className={isFamilyFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.column3}>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Email Address
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <input type="text" value={emailC1} onChange={(e) => setEmailC1(e.currentTarget.value)} disabled={!isFamilyFormEditable1} className={isFamilyFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Phone Number
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <input type="text" value={phNumC1} onChange={(e) => setPhNumC1(e.currentTarget.value)} disabled={!isFamilyFormEditable1} className={isFamilyFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.column4}>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Contact Type
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                className={isFamilyFormEditable1 ? styles.dropdown2E : styles.dropdown2}
                                                disabled={!isFamilyFormEditable1}
                                                values={conTypeC1}
                                                onChange={setConTypeC1}
                                                options={optionsConType}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Preferred mode of communication
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                className={isFamilyFormEditable1 ? styles.dropdown2E : styles.dropdown2}
                                                disabled={!isFamilyFormEditable1}
                                                values={comModeC1}
                                                onChange={setComModeC1}
                                                options={optionsComModeC1}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={styles.consentDiv}>
                                <div className={styles.consentLabel}>Consent<span style={{ color: 'red', paddingLeft: "2px" }}>*</span></div>
                                <div className={styles.consentContent}>
                                    Allow staff involved in my care to get access to my medical records from my prior caregivers, and to share my medical records with other providers who can assist my current or future care.
                                </div>
                                <div className={styles.consentButtons}>
                                    <label>
                                        <input
                                            type="radio"
                                            value="No"
                                            checked={consentSel1 === "No"}
                                            onChange={onValueChange1}
                                            disabled={!isFamilyFormEditable1}
                                            style={{ accentColor: '#AA346F' }}
                                        />
                                        No
                                    </label>

                                    <label>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            checked={consentSel1 === "Yes"}
                                            onChange={onValueChange1}
                                            disabled={!isFamilyFormEditable1}
                                            style={{ accentColor: '#AA346F' }}
                                        />
                                        Yes
                                    </label>
                                </div>
                            </div>

                            {isFamilyFormEditable1 && (!showSecContact && !showTerContact) && (<>
                                <div className={styles.divFooter}>
                                    <div className={styles.addNewDiv}>
                                        <div className={styles.addNewContButton} onClick={(event) => onAddSecContact(event)}>
                                            + Add Another Contact
                                        </div>
                                    </div>
                                    {/* {showReqWarn && <div className={styles.consentWarningDiv}>
                                    Please fill out the required fields marked with an *
                                </div>} */}
                                </div>
                            </>)}

                            {isFamilyFormEditable1 && (<div className={styles.pageButtonsDiv}>
                                <div className={styles.cancelButton} onClick={cancelEdit1}>Cancel</div>
                                <div className={styles.saveButton} onClick={handleSaveFamily1}>Save</div>
                            </div>)}

                        </>)}


                        {showSecContact && (<>
                            <div className={styles.headerDiv}>
                                <div></div>
                                <div>Secondary</div>
                                <div className={styles.editButtonDiv}>
                                    <div className={!isFamilyFormEditable2 ? styles.editButton : styles.editButtonClicked} onClick={event => editFamilyForm2(event)}>Edit</div>
                                </div>
                            </div>
                            <div className={styles.contentDiv}>
                                <div className={styles.column1}>
                                    <div className={styles.fieldDiv2}>
                                        <div className={styles.subFieldDiv}>
                                            <div className={styles.subFieldName}>Prefix</div>
                                            <div className={styles.subFieldInput}>
                                                <Select
                                                    contentRenderer={customContentRenderer}
                                                    disabled={!isFamilyFormEditable2}
                                                    className={isFamilyFormEditable2 ? styles.dropdownE : styles.dropdown}
                                                    values={prefixC2}
                                                    onChange={setPrefixC2}
                                                    options={optionsPrefix}
                                                    color="#4E253A"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.subFieldDiv}>
                                            <div className={styles.subFieldName}>First Name </div>
                                            <div className={styles.subFieldInput}>
                                                <input type="text" value={firstNameC2} onChange={(e) => setFirstNameC2(e.currentTarget.value)} disabled={!isFamilyFormEditable2} className={isFamilyFormEditable2 ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.fieldDiv2}>
                                        <div className={styles.subFieldDiv}>
                                            <div className={styles.subFieldName}>Last Name </div>
                                            <div className={styles.subFieldInput}>
                                                <input type="text" value={lastNameC2} onChange={(e) => setLastNameC2(e.currentTarget.value)} disabled={!isFamilyFormEditable2} className={isFamilyFormEditable2 ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                            </div>
                                        </div>
                                        <div className={styles.subFieldDiv}>
                                            <div className={styles.subFieldName}>Suffix</div>
                                            <div className={styles.subFieldInput}>
                                                <Select
                                                    contentRenderer={customContentRenderer}
                                                    disabled={!isFamilyFormEditable2}
                                                    className={isFamilyFormEditable2 ? styles.dropdownE : styles.dropdown}
                                                    values={suffixC2}
                                                    onChange={setSuffixC2}
                                                    options={optionsSuffix}
                                                    color="#4E253A"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.column2}>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Sex at Birth
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                className={isFamilyFormEditable2 ? styles.dropdown2E : styles.dropdown2}
                                                disabled={!isFamilyFormEditable2}
                                                values={bioSexC2}
                                                onChange={setBioSexC2}
                                                options={optionsBioSex}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Relationship to Patient
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <input type="text" value={relC2} onChange={(e) => setRelC2(e.currentTarget.value)} disabled={!isFamilyFormEditable2} className={isFamilyFormEditable2 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.column3}>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Email Address
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <input type="text" value={emailC2} onChange={(e) => setEmailC2(e.currentTarget.value)} disabled={!isFamilyFormEditable2} className={isFamilyFormEditable2 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Phone Number
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <input type="text" value={phNumC2} onChange={(e) => setPhNumC2(e.currentTarget.value)} disabled={!isFamilyFormEditable2} className={isFamilyFormEditable2 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.column4}>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Contact Type
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                className={isFamilyFormEditable2 ? styles.dropdown2E : styles.dropdown2}
                                                disabled={!isFamilyFormEditable2}
                                                values={conTypeC2}
                                                onChange={setConTypeC2}
                                                options={optionsConType}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Preferred mode of communication
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                className={isFamilyFormEditable2 ? styles.dropdown2E : styles.dropdown2}
                                                disabled={!isFamilyFormEditable2}
                                                values={comModeC2}
                                                onChange={setComModeC2}
                                                options={optionsComModeC1}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.consentDiv}>
                                <div className={styles.consentLabel}>Consent<span style={{ color: 'red', paddingLeft: "2px" }}>*</span></div>
                                <div className={styles.consentContent}>
                                    Allow staff involved in my care to get access to my medical records from my prior caregivers, and to share my medical records with other providers who can assist my current or future care.
                                </div>
                                <div className={styles.consentButtons}>
                                    <label>
                                        <input
                                            type="radio"
                                            value="No"
                                            checked={consentSel2 === "No"}
                                            onChange={onValueChange2}
                                            disabled={!isFamilyFormEditable2}
                                            style={{ accentColor: '#AA346F' }}
                                        />
                                        No
                                    </label>

                                    <label>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            checked={consentSel2 === "Yes"}
                                            onChange={onValueChange2}
                                            disabled={!isFamilyFormEditable2}
                                            style={{ accentColor: '#AA346F' }}
                                        />
                                        Yes
                                    </label>
                                </div>
                            </div>

                            {(isFamilyFormEditable2 && showSecContact && !showTerContact) && (<>
                                <div className={styles.divFooter}>
                                    <div className={styles.addNewDiv}>
                                        <div className={styles.addNewContButton} onClick={(event) => onAddTerContact(event)}>
                                            + Add Another Contact
                                        </div>
                                    </div>
                                    {/* {showReqWarn && <div className={styles.consentWarningDiv}>
                                    Please fill out the required fields marked with an *
                                </div>} */}
                                </div>
                            </>)}

                            {isFamilyFormEditable2 && (<div className={styles.pageButtonsDiv}>
                                <div className={styles.cancelButton} onClick={cancelEdit2}>Cancel</div>
                                <div className={styles.saveButton} onClick={handleSaveFamily2}>Save</div>
                            </div>)}

                        </>)}


                        {showTerContact && (<>
                            <div className={styles.headerDiv}>
                                <div></div>
                                <div>Tertiary</div>
                                <div className={styles.editButtonDiv}>
                                    <div className={!isFamilyFormEditable3 ? styles.editButton : styles.editButtonClicked} onClick={event => editFamilyForm3(event)}>Edit</div>
                                </div>
                            </div>
                            <div className={styles.contentDiv}>
                                <div className={styles.column1}>
                                    <div className={styles.fieldDiv2}>
                                        <div className={styles.subFieldDiv}>
                                            <div className={styles.subFieldName}>Prefix</div>
                                            <div className={styles.subFieldInput}>
                                                <Select
                                                    contentRenderer={customContentRenderer}
                                                    disabled={!isFamilyFormEditable3}
                                                    className={isFamilyFormEditable3 ? styles.dropdownE : styles.dropdown}
                                                    values={prefixC3}
                                                    onChange={setPrefixC3}
                                                    options={optionsPrefix}
                                                    color="#4E253A"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.subFieldDiv}>
                                            <div className={styles.subFieldName}>First Name </div>
                                            <div className={styles.subFieldInput}>
                                                <input type="text" value={firstNameC3} onChange={(e) => setFirstNameC3(e.currentTarget.value)} disabled={!isFamilyFormEditable3} className={isFamilyFormEditable3 ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.fieldDiv2}>
                                        <div className={styles.subFieldDiv}>
                                            <div className={styles.subFieldName}>Last Name </div>
                                            <div className={styles.subFieldInput}>
                                                <input type="text" value={lastNameC3} onChange={(e) => setLastNameC3(e.currentTarget.value)} disabled={!isFamilyFormEditable3} className={isFamilyFormEditable3 ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                            </div>
                                        </div>
                                        <div className={styles.subFieldDiv}>
                                            <div className={styles.subFieldName}>Suffix</div>
                                            <div className={styles.subFieldInput}>
                                                <Select
                                                    contentRenderer={customContentRenderer}
                                                    disabled={!isFamilyFormEditable3}
                                                    className={isFamilyFormEditable3 ? styles.dropdownE : styles.dropdown}
                                                    values={suffixC3}
                                                    onChange={setSuffixC3}
                                                    options={optionsSuffix}
                                                    color="#4E253A"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.column2}>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Sex at Birth
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                className={isFamilyFormEditable3 ? styles.dropdown2E : styles.dropdown2}
                                                disabled={!isFamilyFormEditable3}
                                                values={bioSexC3}
                                                onChange={setBioSexC3}
                                                options={optionsBioSex}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Relationship to Patient
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <input type="text" value={relC3} onChange={(e) => setRelC3(e.currentTarget.value)} disabled={!isFamilyFormEditable3} className={isFamilyFormEditable3 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.column3}>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Email Address
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <input type="text" value={emailC3} onChange={(e) => setEmailC3(e.currentTarget.value)} disabled={!isFamilyFormEditable3} className={isFamilyFormEditable3 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Phone Number
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <input type="text" value={phNumC3} onChange={(e) => setPhNumC3(e.currentTarget.value)} disabled={!isFamilyFormEditable3} className={isFamilyFormEditable3 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.column4}>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Contact Type
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                className={isFamilyFormEditable3 ? styles.dropdown2E : styles.dropdown2}
                                                disabled={!isFamilyFormEditable3}
                                                values={conTypeC3}
                                                onChange={setConTypeC3}
                                                options={optionsConType}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.fieldDiv1}>
                                        <div className={styles.fieldName}>
                                            Preferred mode of communication
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                className={isFamilyFormEditable3 ? styles.dropdown2E : styles.dropdown2}
                                                disabled={!isFamilyFormEditable3}
                                                values={comModeC3}
                                                onChange={setComModeC3}
                                                options={optionsComModeC1}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.consentDiv}>
                                <div className={styles.consentLabel}>Consent<span style={{ color: 'red', paddingLeft: "2px" }}>*</span></div>
                                <div className={styles.consentContent}>
                                    Allow staff involved in my care to get access to my medical records from my prior caregivers, and to share my medical records with other providers who can assist my current or future care.
                                </div>
                                <div className={styles.consentButtons}>
                                    <label>
                                        <input
                                            type="radio"
                                            value="No"
                                            checked={consentSel3 === "No"}
                                            onChange={onValueChange3}
                                            disabled={!isFamilyFormEditable3}
                                            style={{ accentColor: '#AA346F' }}
                                        />
                                        No
                                    </label>

                                    <label>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            checked={consentSel3 === "Yes"}
                                            onChange={onValueChange3}
                                            disabled={!isFamilyFormEditable3}
                                            style={{ accentColor: '#AA346F' }}
                                        />
                                        Yes
                                    </label>
                                </div>
                            </div>
                            {showTerContact && (<>
                                <div className={styles.divFooter}>
                                    {/* <div className={styles.addNewDiv}>
                                    <div className={styles.addNewContButton} onClick={onAddNewContact}>
                                        + Add Another Contact
                                    </div>
                                </div> */}
                                    {/* {showReqWarn && <div className={styles.consentWarningDiv}>
                                    Please fill out the required fields marked with an *
                                </div>} */}
                                    {isFamilyFormEditable3 && (<div className={styles.pageButtonsDiv}>
                                        <div className={styles.cancelButton} onClick={cancelEdit3}>Cancel</div>
                                        <div className={styles.saveButton} onClick={handleSaveFamily3}>Save</div>
                                    </div>)}
                                </div>
                            </>)}
                        </>)}

                    </div>
                </div>
            </>)}
        </>
    );
});

export default PatientFamilyTab;