import axios from "axios";
import domainCF from "../domainCF.js"

export default async function AssignDeviceWithUsagePlan(assignment_data) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  //console.log("AssignDeviceWithUsagePlan: assignment_data: ", assignment_data);
  const POST_ASSIGN_MOUTHLAB_TO_SUBJECT = `${domainCF}/api/assignDeviceWithUsagePlan`;
  const dataAsParams = `subjectId=${assignment_data.subjectId}&deviceId=${assignment_data.deviceId}&usesPerDay=${assignment_data.usage}`;
  try {
    const response = await axios.post(POST_ASSIGN_MOUTHLAB_TO_SUBJECT, dataAsParams, { headers });
    //console.log(`Response from ${POST_ASSIGN_MOUTHLAB_TO_SUBJECT}...`, response);
    return { response: response };
  } catch (error) {
    //console.log(`Error from ${POST_ASSIGN_MOUTHLAB_TO_SUBJECT}...`, error, error.response);
    return { error: error };
  }

}
