import axios from "axios";
import domainCF from "../domainCF.js"

export default function IsCalibrationSuccess(subject_id) {
  //console.log("in calibration check api call", subject_id);
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_IS_CALIBRATION_SUCCESSFUL_URL = `${domainCF}/api/isCalibrationSuccessful?patientGuid=${subject_id}`;

  return axios
    .get(GET_IS_CALIBRATION_SUCCESSFUL_URL, { headers })
    .then(function (response) {
      console.log("THIS IS CAL SUCCESS RESPONSE...", response.data);
      //return [response.data.payload.vitals, response.data.payload.xValues];
      return response.data;
    });
}

