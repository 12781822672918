import React, { useContext, useState} from "react";
import styles from "../PatientsPresentation/PatientsList/PatientsList.module.css";
import Grid from "@material-ui/core/Grid";
import ADIcon from "../../../../Assets/Images/Aidar-Connect-Logo.png";
import { BsFillSunFill } from "react-icons/bs";
import { BsCloudSunFill } from "react-icons/bs";
import { FaCloudMoon } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";

export default function Header(props) {
  const userProfile = useSelector(getUserProfile); 
  let today = new Date();
  var hour = today.getHours();
  return (
    <div className={styles.accContainer}>
      <Grid container>
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.displayContainer}>
            <div className={styles.userGreetings}>
              {(() => {
                if (hour < 12) {
                  return (
                    <div className={styles.userGreetingsMessage}>
                      <div className={styles.userGreetingIcon}>
                        <BsCloudSunFill />
                      </div>
                      Good Morning, {userProfile.firstName}
                    </div>
                  );
                } else if (hour >= 12 && hour <= 17) {
                  return (
                    <div className={styles.userGreetingsMessage}>
                      <div className={styles.userGreetingIcon}>
                        <BsFillSunFill />
                      </div>
                      Good Afternoon, {userProfile.firstName}
                    </div>
                  );
                } else if (hour >= 17 && hour <= 24) {
                  return (
                    <div className={styles.userGreetingsMessage}>
                      <div className={styles.userGreetingIcon}>
                        <FaCloudMoon />
                      </div>
                      Good Evening, {userProfile.firstName}
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
