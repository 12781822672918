import styles from "./PayerAndConditionInfoReview.module.css";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Select from 'react-dropdown-select';
import Tooltip from "@material-ui/core/Tooltip";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { setPRPayerInfo, setRegistrationStatus } from "../../../../Actions/UtilActions";
import { useDispatch, useSelector } from "react-redux";
import { getPRPayerInfo, getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import { useNavigate } from "react-router-dom";
import { getSelectedProjectIdAndLocationId } from "../../../../Reducer/selectors/DashboardSelector";
import { getFacilityBPHDropdownListSelector, getFacilityBPHSelector } from "../../../../Reducer/selectors/PatientSelector";
import { getFacilityBPHDropdownList } from "../../../../Actions";
import Backdrop from '@mui/material/Backdrop';
import Modal from "@material-ui/core/Modal";
import logoutImg from '../../../../Assets/Images/Logout.png';
import Grid from "@material-ui/core/Grid";

const PayerAndConditionReview = forwardRef((props, ref) => {

    const handleFocus = (event) => event.target.select();
    const dispatch = useDispatch();

    const curRegStatus = useSelector(getRegistrationStatus);

    const payerInfo = useSelector(getPRPayerInfo);

    const [payerType, setPayerType] = useState([{ value: payerInfo.payerType, label: payerInfo.payerType }]);
    const [payerTypeOther, setPayerTypeOther] = useState(payerInfo.payerTypeOther);
    const [priPayerName, setPriPayerName] = useState(payerInfo.priPayerName);
    const [medicareBeneId, setMedicareBeneId] = useState(payerInfo.medicareBeneId);
    const [payerRel, setPayerRel] = useState(payerInfo.payerRel);
    const [medicaidBeneId, setMedicaidBeneId] = useState(payerInfo.medicaidBeneId);
    const [managedCareId, setManagedCareId] = useState(payerInfo.managedCareId);
    const [priPayerPh, setPriPayerPh] = useState(payerInfo.priPayerPh);
    const [edu, setEdu] = useState([{ value: payerInfo.edu, label: payerInfo.edu }]);
    const [occupation, setOccupation] = useState(payerInfo.occupation);
    const [empStatus, setEmpStatus] = useState([{ value: payerInfo.empStatus, label: payerInfo.empStatus }]);
    const [religion, setReligion] = useState(payerInfo.religion);
    const [marStatus, setMarStatus] = useState([{ value: payerInfo.marStatus, label: payerInfo.marStatus }]);
    const [ethnicity, setEthnicity] = useState([{ value: payerInfo.ethnicity, label: payerInfo.ethnicity }]);
    const [smokeStatus, setSmokeStatus] = useState([{ value: payerInfo.smokeStatus, label: payerInfo.smokeStatus }]);
    const [race, setRace] = useState([{ value: payerInfo.race, label: payerInfo.race }]);
    const [primLang, setPrimLang] = useState(payerInfo.primLang);
    const [primCond, setPrimCond] = useState(payerInfo.primCond);
    const [primProg, setPrimProg] = useState([{ value: payerInfo.primProg, label: payerInfo.primProg }]);
    const [interpNeed, setInterpNeed] = useState([{ value: payerInfo.interpNeed, label: payerInfo.interpNeed }]);
    const [secCond, setSecCond] = useState(payerInfo.secCond);
    const [secProg, setSecProg] = useState([{ value: payerInfo.secProg, label: payerInfo.secProg }]);
    const [height, setHeight] = useState(payerInfo.height);
    const [terCond, setTerCond] = useState(payerInfo.terCond);
    const [weight, setWeight] = useState(payerInfo.weight);
    const [otherCom, setOtherCom] = useState(payerInfo.otherCom);
    const [billPhy, setBillPhy] = useState([{ value: payerInfo.billPhyId, label: payerInfo.billPhy }]);

    const [showReqWarn, setShowReqWarn] = useState(false);

    const [isPayerTypeFill, setIsPayerTypeFill] = useState(true);
    const [isPrimCondFill, setIsPrimCondFill] = useState(true);
    const [isPrimProgFill, setIsPrimProgFill] = useState(true);
    const [isInterpNeed, setIsInterpNeed] = useState(true);
    const [isMedicareId, setIsMedicareId] = useState(true);
    const [isBillPhyFill, setIsBillPhyFill] = useState(true);

    const [cancelRegistration, setCancelRegistration] = useState(false);

    const [isPayerFormEditable1, setIsPayerFormEditable1] = useState(false);
    const [isPayerFormEditable2, setIsPayerFormEditable2] = useState(false);
    const [isPayerFormEditable3, setIsPayerFormEditable3] = useState(false);

    const editPayerForm1 = (event) => {
        event.preventDefault();
        setIsPayerFormEditable1(true);
    };

    const editPayerForm2 = (event) => {
        event.preventDefault();
        setIsPayerFormEditable2(true);
    };

    const editPayerForm3 = (event) => {
        event.preventDefault();
        setIsPayerFormEditable3(true);
    };

    const clearAllPayerFields = () => {
        setPayerType([]);
        setPayerTypeOther('');
        setPriPayerName('');
        setMedicareBeneId('');
        setPayerRel('');
        setMedicaidBeneId('');
        setManagedCareId('');
        setPriPayerPh('');
        setEdu([]);
        setOccupation('');
        setEmpStatus([]);
        setReligion('');
        setMarStatus([]);
        setEthnicity([]);
        setSmokeStatus([]);
        setRace([]);
        setPrimLang('');
        setPrimCond('');
        setPrimProg([]);
        setBillPhy([]);
        setInterpNeed([]);
        setSecCond('');
        setSecProg([]);
        setHeight('');
        setTerCond('');
        setWeight('');
        setOtherCom('');
        resetReqFills();
    };

    const resetReqFills = () => {
        setIsPayerTypeFill(true);
        setIsPrimCondFill(true);
        setIsPrimProgFill(true);
        setIsInterpNeed(true);
        setIsMedicareId(true);
        setIsBillPhyFill(true);
    };

    useImperativeHandle(ref, () => {
        return {
            clearAllPayerFields: clearAllPayerFields,
        };
    });

    const optionsPayerType = [
        { value: 'Managed Care', label: 'Managed Care' },
        { value: 'Medicare A', label: 'Medicare A' },
        { value: 'Medicare B', label: 'Medicare B' },
        { value: 'Medicaid', label: 'Medicaid' },
        { value: 'Private', label: 'Private' },
        { value: 'Other', label: 'Other' },
    ];

    const optionsEdu = [
        { value: '8th Grade/less', label: '8th Grade/less' },
        { value: 'High School', label: 'High School' },
        { value: 'Bachelor Degree', label: 'Bachelor Degree' },
        { value: 'Graduate Degree', label: 'Graduate Degree' },
        { value: 'No Schooling', label: 'No Schooling' },
        { value: 'Technical or Trade School', label: 'Technical or Trade School' },
        { value: 'Some College', label: 'Some College' },
    ];

    const optionsMarStatus = [
        { value: 'Single', label: 'Single' },
        { value: 'Divorced', label: 'Divorced' },
        { value: 'Married', label: 'Married' },
        { value: 'Never Married', label: 'Never Married' },
        { value: 'Not Assessed', label: 'Not Assessed' },
        { value: 'Separated', label: 'Separated' },
        { value: 'Widowed', label: 'Widowed' },
    ];

    const optionsEthnicity = [
        { value: 'Hispanic', label: 'Hispanic' },
        { value: 'Non-Hispanic', label: 'Non-Hispanic' },
        { value: 'Others', label: 'Others' },
    ];

    const optionsSmokeStatus = [
        { value: 'Frequent', label: 'Frequent' },
        { value: 'Occasional', label: 'Occasional' },
        { value: 'Non-Smoker', label: 'Non-Smoker' },
    ];

    const optionsRace = [
        { value: 'American Indian or Alaska Native', label: 'American Indian or Alaska Native' },
        { value: 'Asian', label: 'Asian' },
        { value: 'Black or African American', label: 'Black or African American' },
        { value: 'Hispanic or Latino', label: 'Hispanic or Latino' },
        { value: 'Native Hawaiian', label: 'Native Hawaiian' },
        { value: 'White', label: 'White' },
        { value: 'Others', label: 'Others' },
    ];

    const optionsEmpStatus = [
        { value: 'Student', label: 'Student' },
        { value: 'Unemployed', label: 'Unemployed' },
        { value: 'Full-time', label: 'Full-time' },
        { value: 'Part-time', label: 'Part-time' },
        { value: 'Self-Employed', label: 'Self-Employed' },
        { value: 'Retired', label: 'Retired' },
        { value: 'Unable to Work', label: 'Unable to Work' },
    ];

    const optionsPrimProg = [
        { value: 'Remote Patient Monitoring (RPM)', label: 'Remote Patient Monitoring (RPM)' },
        { value: 'Chronic Care Management (CCM)', label: 'Chronic Care Management (CCM)' },
        { value: 'Transitional Care Management (TCM)', label: 'Transitional Care Management (TCM)' },
        { value: 'Behavioral Health Integration (BHI)', label: 'Behavioral Health Integration (BHI)' },
        { value: 'Others', label: 'Others' },
    ];

    const optionsInterpNeed = [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
        { value: 'Unable to be determined', label: 'Unable to be determined' },
    ];

    const optionsSecProg = [
        { value: 'Remote Patient Monitoring (RPM)', label: 'Remote Patient Monitoring (RPM)' },
        { value: 'Chronic Care Management (CCM)', label: 'Chronic Care Management (CCM)' },
        { value: 'Transitional Care Management (TCM)', label: 'Transitional Care Management (TCM)' },
        { value: 'Behavioral Health Integration (BHI)', label: 'Behavioral Health Integration (BHI)' },
        { value: 'Others', label: 'Others' },
    ];

    const optionsBillPhy = useSelector(getFacilityBPHDropdownListSelector);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(10),
            border: '1px solid #dadde9',
        },
    }));

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const markStepCompleted = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: true,
            step3: curRegStatus.step3,
            step4: curRegStatus.step4,
            step5: curRegStatus.step5,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
    };

    const setTabInfo = () => {

        const payload = {
            payerType: payerType[0] ? payerType[0].value : '',
            payerTypeOther: payerTypeOther,
            priPayerName: priPayerName,
            medicareBeneId: medicareBeneId,
            payerRel: payerRel,
            medicaidBeneId: medicaidBeneId,
            managedCareId: managedCareId,
            priPayerPh: priPayerPh,
            edu: edu[0] ? edu[0].value : '',
            occupation: occupation,
            empStatus: empStatus[0] ? empStatus[0].value : '',
            religion: religion,
            marStatus: marStatus[0] ? marStatus[0].value : '',
            ethnicity: ethnicity[0] ? ethnicity[0].value : '',
            smokeStatus: smokeStatus[0] ? smokeStatus[0].value : '',
            race: race[0] ? race[0].value : '',
            primLang: primLang,
            primCond: primCond,
            primProg: primProg[0] ? primProg[0].value : '',
            interpNeed: interpNeed[0] ? interpNeed[0].value : '',
            secCond: secCond,
            secProg: secProg[0] ? secProg[0].value : '',
            height: height,
            terCond: terCond,
            weight: weight,
            otherCom: otherCom,
            billPhyId: billPhy[0] ? billPhy[0].value : '',
            billPhy: billPhy[0] ? billPhy[0].label : '',
        };
        console.log("payerInfoTab payload: ", payload);
        dispatch(setPRPayerInfo(payload));
    };

    const checkIfPageConditionsMet = () => {
        if ((primProg[0] && primProg[0].value !== '') && (billPhy[0] && billPhy[0].value !== '')) {
            console.log('conditions met!');
            resetReqFills();
            setShowReqWarn(false);
            return true;
        } else {
            console.log('conditions not met!');
            resetReqFills();
            // if (!payerType[0] || payerType[0].value === '') {
            //     setIsPayerTypeFill(false);
            // };
            // if (!interpNeed[0] || interpNeed[0].value === '') {
            //     setIsInterpNeed(false);
            // };
            // if (primCond === '') {
            //     setIsPrimCondFill(false);
            // };
            if (!primProg[0] || primProg[0].value === '') {
                setIsPrimProgFill(false);
            };
            if (!billPhy[0] || billPhy[0].value === '') {
                setIsBillPhyFill(false);
            };
            // if (medicareBeneId === '') {
            //     setIsMedicareId(false);
            // };
            setShowReqWarn(true);
            return false;
        }
    };

    const handleSave1 = () => {
        if (checkIfPageConditionsMet()) {
            setTabInfo();
            setIsPayerFormEditable1(false);
        }
    };

    const handleSave2 = () => {
        if (checkIfPageConditionsMet()) {
            setTabInfo();
            setIsPayerFormEditable2(false);
        }
    };

    const handleSave3 = () => {
        if (checkIfPageConditionsMet()) {
            setTabInfo();
            setIsPayerFormEditable3(false);
        }
    };

    const navigate = useNavigate();
    const projLoc = useSelector(getSelectedProjectIdAndLocationId);
    const selectedProjectId = projLoc.selectedProjectId;
    const selectedLocationId = projLoc.selectedLocationId;

    const handleBackCLick = () => {
        //clearAllPayerFields();
        const newRegStatus = {
            step1: false,
            step2: false,
            step3: curRegStatus.step3,
            step4: curRegStatus.step4,
            step5: curRegStatus.step5,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
        props.tabChange("1");
        //navigate('/subjects/' + selectedProjectId + '/' + selectedLocationId);
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    const closeCancelRegistration = () => {
        setCancelRegistration(false);
    };

    const handleCancelRegistration = () => {
        setCancelRegistration(true);
    };

    const handleCancelRegistrationSuccess = () => {
        const payload = {
            step1: false,
            step2: false,
            step3: false,
            step4: false,
            step5: false,
            review: false,
        }
        dispatch(setRegistrationStatus(payload));
        clearAllPayerFields();
        navigate('/subjects/' + selectedProjectId + '/' + selectedLocationId);
    };


    const handleCancel1 = () => {
        setIsPayerFormEditable1(false);
    };

    const handleCancel2 = () => {
        setIsPayerFormEditable2(false);
    };

    const handleCancel3 = () => {
        setIsPayerFormEditable3(false);
    };

    return (
        <div className={styles.rootDiv}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={cancelRegistration}
            ></Backdrop>
            <div className={styles.containerDiv}>
                <div className={styles.headerDiv}>
                    <div></div>
                    <div>Payer Information</div>
                    <div className={styles.editButtonDiv}>
                        <div className={!isPayerFormEditable1 ? styles.editButton : styles.editButtonClicked} onClick={event => editPayerForm1(event)}>Edit</div>
                    </div>
                </div>
                <div className={styles.dumDiv}>
                    <div className={styles.contentDiv}>
                        <div className={styles.column1}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Payer Type
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={styles.dropdown2}
                                        values={payerType}
                                        onChange={setPayerType}
                                        options={optionsPayerType}
                                        color="#4E253A"
                                        disabled={!isPayerFormEditable1}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    If Others, Please Specify
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={payerTypeOther} disabled={!isPayerFormEditable1} onChange={(e) => setPayerTypeOther(e.currentTarget.value)} className={isPayerFormEditable1 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column2}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Primary Payer Name
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={priPayerName} disabled={!isPayerFormEditable1} onChange={(e) => setPriPayerName(e.currentTarget.value)} className={isPayerFormEditable1 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Medicare Beneficiary ID
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={medicareBeneId} disabled={!isPayerFormEditable1} onChange={(e) => setMedicareBeneId(e.currentTarget.value)} className={isPayerFormEditable1 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column3}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName2}>
                                    Primary Payer ID or Relationship
                                    <div className={styles.infoButton}>
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <div className={styles.circlesListItem} > Indicated payer ID or the relationship with the Patient </div>
                                                </React.Fragment>
                                            }
                                        >
                                            <InfoOutlinedIcon style={{ height: '14px', width: '14px', fill: "#A040AB", marginRight: "5px", marginLeft: "5px" }} />
                                        </HtmlTooltip>
                                    </div>
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={payerRel} disabled={!isPayerFormEditable1} onChange={(e) => setPayerRel(e.currentTarget.value)} className={isPayerFormEditable1 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Medicaid Beneficiary ID
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={medicaidBeneId} disabled={!isPayerFormEditable1} onChange={(e) => setMedicaidBeneId(e.currentTarget.value)} className={isPayerFormEditable1 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column4}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Primary Payer Phone Number
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={priPayerPh} disabled={!isPayerFormEditable1} onChange={(e) => setPriPayerPh(e.currentTarget.value)} className={isPayerFormEditable1 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Managed Care ID
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={managedCareId} disabled={!isPayerFormEditable1} onChange={(e) => setManagedCareId(e.currentTarget.value)} className={isPayerFormEditable1 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {isPayerFormEditable1 && <div className={styles.pageButtonsDiv}>
                        {/* <div className={styles.backButton} onClick={handleCancel1}>Cancel</div> */}
                        <div className={styles.saveButton} onClick={handleSave1}>Save</div>
                    </div>}
                </div>
            </div>


            <div className={styles.containerDiv}>
                <div className={styles.headerDiv}>
                    <div></div>
                    <div>Sociodemographic Information</div>
                    <div className={styles.editButtonDiv}>
                        <div className={!isPayerFormEditable2 ? styles.editButton : styles.editButtonClicked} onClick={event => editPayerForm2(event)}>Edit</div>
                    </div>
                </div>
                <div className={styles.dumDiv}>
                    <div className={styles.contentDiv}>
                        <div className={styles.column1}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Education
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={styles.dropdown2}
                                        values={edu}
                                        onChange={setEdu}
                                        options={optionsEdu}
                                        color="#4E253A"
                                        disabled={!isPayerFormEditable2}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Occupation
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={occupation} disabled={!isPayerFormEditable2} onChange={(e) => setOccupation(e.currentTarget.value)} className={isPayerFormEditable2 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column2}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Employment Status
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={styles.dropdown2}
                                        values={empStatus}
                                        onChange={setEmpStatus}
                                        options={optionsEmpStatus}
                                        color="#4E253A"
                                        disabled={!isPayerFormEditable2}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Religion
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={religion} disabled={!isPayerFormEditable2} onChange={(e) => setReligion(e.currentTarget.value)} className={isPayerFormEditable2 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column3}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Marital Status
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={styles.dropdown2}
                                        values={marStatus}
                                        onChange={setMarStatus}
                                        options={optionsMarStatus}
                                        color="#4E253A"
                                        disabled={!isPayerFormEditable2}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Ethnicity
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={styles.dropdown2}
                                        values={ethnicity}
                                        onChange={setEthnicity}
                                        options={optionsEthnicity}
                                        color="#4E253A"
                                        disabled={!isPayerFormEditable2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column4}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Smoking Status
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={styles.dropdown2}
                                        values={smokeStatus}
                                        onChange={setSmokeStatus}
                                        options={optionsSmokeStatus}
                                        color="#4E253A"
                                        disabled={!isPayerFormEditable2}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Race
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={styles.dropdown2}
                                        values={race}
                                        onChange={setRace}
                                        options={optionsRace}
                                        color="#4E253A"
                                        disabled={!isPayerFormEditable2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {isPayerFormEditable2 && <div className={styles.pageButtonsDiv}>
                        {/* <div className={styles.backButton} onClick={handleCancel2}>Cancel</div> */}
                        <div className={styles.saveButton} onClick={handleSave2}>Save</div>
                    </div>}
                </div>
            </div>


            <div className={styles.containerDiv}>
                <div className={styles.headerDiv}>
                    <div></div>
                    <div>Programs</div>
                    <div className={styles.editButtonDiv}>
                        <div className={!isPayerFormEditable3 ? styles.editButton : styles.editButtonClicked} onClick={event => editPayerForm3(event)}>Edit</div>
                    </div>
                </div>
                <div className={styles.dumDiv}>
                    <div className={styles.contentDiv}>
                        <div className={styles.column1}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Primary Language
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={primLang} disabled={!isPayerFormEditable3} onChange={(e) => setPrimLang(e.currentTarget.value)} className={isPayerFormEditable3 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            {/* <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Primary Condition
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={primCond} onChange={(e) => setPrimCond(e.currentTarget.value)} className={styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div> */}

                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Interpreter Needed
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={styles.dropdown2}
                                        values={interpNeed}
                                        onChange={setInterpNeed}
                                        options={optionsInterpNeed}
                                        color="#4E253A"
                                        menuIsOpen={true}
                                        disabled={!isPayerFormEditable3}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className={styles.column2}>

                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Height (in.)
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={height} disabled={!isPayerFormEditable3} onChange={(e) => setHeight(e.currentTarget.value)} className={isPayerFormEditable3 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>

                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Weight (lbs.)
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={weight} disabled={!isPayerFormEditable3} onChange={(e) => setWeight(e.currentTarget.value)} className={isPayerFormEditable3 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>

                        </div>
                        <div className={styles.column31}>

                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Primary Program <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={isPrimProgFill ? styles.dropdown2 : styles.dropdownReq2}
                                        values={primProg}
                                        onChange={setPrimProg}
                                        options={optionsPrimProg}
                                        color="#4E253A"
                                        disabled={!isPayerFormEditable3}
                                    />
                                </div>
                            </div>

                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Secondary Program
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={styles.dropdown2}
                                        values={secProg}
                                        onChange={setSecProg}
                                        options={optionsSecProg}
                                        color="#4E253A"
                                        disabled={!isPayerFormEditable3}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className={styles.column41}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Billing Physician <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={isBillPhyFill ? styles.dropdown2 : styles.dropdownReq2}
                                        values={billPhy}
                                        onChange={setBillPhy}
                                        options={optionsBillPhy}
                                        color="#4E253A"
                                        disabled={!isPayerFormEditable3}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {showReqWarn && <div className={styles.consentWarningDiv}>
                        Please fill out the required fields marked with an *
                    </div>}
                    {isPayerFormEditable3 && <div className={styles.pageButtonsDiv}>
                        {/* <div className={styles.backButton} onClick={handleCancel3}>Cancel</div> */}
                        <div className={styles.saveButton} onClick={handleSave3}>Save</div>
                    </div>}
                </div>
            </div>
        </div>
    );
});

export default PayerAndConditionReview;