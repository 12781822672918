import React, { useState, useContext } from "react";
import styles from "./SignUp.module.css";
import Grid from "@mui/material/Grid";

import SignupForm from "../FormControls/SignupForm/SignupForm.js"

export default function SignUp() {
  return (
    <div>
      <div className={styles.loginWrapper}>
        <Grid
          item
          className={styles.loginFormContainer}
        >
          <div className={styles.loginCard}>
            <SignupForm />
          </div>
        </Grid>
      </div>
    </div>
  );
}
