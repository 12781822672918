import axios from "axios";
import domainCF from "../domainCF.js";

export default async function PostUnassignMouthLabFromSubject(operation_data) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const POST_UNASSIGN_MOUTHLAB_FROM_SUBJECT = `${domainCF}/api/unassignDeviceFromSubject`;
  const dataAsParams = `deviceId=${operation_data.deviceId}`;
  try {
    const response = await axios.post(POST_UNASSIGN_MOUTHLAB_FROM_SUBJECT, dataAsParams, { headers });
    console.log(`Response from ${POST_UNASSIGN_MOUTHLAB_FROM_SUBJECT}...`, response);
    return { response: response };
  } catch (error) {
    console.log(`Error from ${POST_UNASSIGN_MOUTHLAB_FROM_SUBJECT}...`, error, error.response);
    return { error: error };
  }
}
