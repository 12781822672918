import React from "react";
import styles from "./EcgGraph.module.css";
import { Line } from "react-chartjs-2";
import { FaHeartbeat } from "react-icons/fa";
import { MdZoomOutMap } from "react-icons/md";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default function EcgGraph(props) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const timeZoneFromDayjs = dayjs.tz.guess();

  // console.log("ecgTimestamp", props);
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    ctx.canvas.width = 5000;
    ctx.canvas.height = 250;
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(136, 108, 230,1)");
    gradient.addColorStop(0.5, "rgba(136, 108, 230,0.2)");
    gradient.addColorStop(1, "rgba(136, 108, 230,0)");

    //console.log("THIS IS FROM ECG GRAPH SEE THE PROPS", props);

    return {
      labels: props.xValues,
      datasets: [
        {
          label: "ECG",
          fill: false,
          backgroundColor: "#e63946", // Put the gradient here as a fill color
          borderColor: "#e63946",
          borderWidth: 2,
          pointRadius: 0,
          pointBackgroundColor: "rgba(255, 255, 255)",
          data: props.yValues,
        },
      ],
    };
  };

  var options = {
    responsive: false,
    datasetStrokeWidth: 3,
    pointDotStrokeWidth: 4,
    //scaleLabel: "<%= Number(value).toFixed(0).replace('.', ',') + '°C'%>",

    tooltips: {
      backgroundColor: "rgba(255, 255, 255)",
      bodyFontColor: "rgba(53, 80, 112)",
      titleFontColor: "rgba(53, 80, 112)",
      titleFontSize: 12,
      bodyFontSize: 12,
      bodySpacing: 5,
      bodyAlign: "center",
      titleAlign: "center",

      callbacks: {
        label: function (tooltipItem) {
          return Number(tooltipItem.yLabel);
        },
      },
    },

    scales: {
      xAxes: [
        {
          // type: "time",
          gridLines: {
            //color: "rgba(0, 0, 0, 0)",
            display: true,
          },
          ticks: {
            maxTicksLimit: 150,
            callback: function (value) { if (value === 0) { return null; } else { return value + ' s'; } }
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            //color: "rgba(0, 0, 0, 0)",
            display: true,
            tickMarkLength: 0,
          },

          ticks: {
            min: -1,
            max: 1.5,
            maxTicksLimit: 11,
            display: true,
            callback: function (value) { return value + ' mV '; }
          },
        },
      ],
    },

    legend: {
      display: false,
      position: "top",
      labels: {
        fontColor: "#333",
        usePointStyle: true,
      },
    },
  };
  return (
    <div className={styles.ecgCard}>
      <div className={styles.ecgCardTitle}>
        <div className={styles.ecgCardTitleIconStyle}>
          <FaHeartbeat
            className={styles.ecgCardIconStyles}
            style={{ color: `rgb(199, 31, 55,0.7)` }}
          />
        </div>
        <div
          className={styles.heartRateInformation}
          style={{ color: `rgb(199, 31, 55,0.7)` }}
        >
          Heart Rate {props.heartRate} bpm
        </div>
        <div className={styles.zoomEcgCard}>
          {dayjs.utc(props.ecgTimestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
          {/* <div className={styles.zoomIconStyle}>
            <MdZoomOutMap />
          </div> */}
        </div>
      </div>
      <div className={styles.ecgContainer}>
        <Line data={data} options={options}></Line>
      </div>
    </div>
  );
}

// <div>
//   EcgContainer
//   <p>{props.xValues}</p>
//   <h4>{props.yValues}</h4>
//   <h5>{props.heartRate}</h5>
// </div>

// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import { Line } from "react-chartjs-2";

// // Icons imnport
// import { FaHeartbeat } from "react-icons/fa";
// import { MdZoomOutMap } from "react-icons/md";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const options = {
//   responsive: true,
//   datasetStrokeWidth: 3,
//   pointDotStrokeWidth: 4,
//   pointStyle: "Star",
//   plugins: {
//     legend: {
//       position: "top",
//     },
//     // title: {
//     //   display: true,
//     //   text: "ECG of random patient",
//     // },
//   },
//   tooltips: {
//     backgroundColor: "rgba(255, 255, 255)",
//     bodyFontColor: "rgba(53, 80, 112)",
//     titleFontColor: "rgba(53, 80, 112)",
//     titleFontSize: 12,
//     bodyFontSize: 12,
//     bodySpacing: 5,
//     bodyAlign: "center",
//     titleAlign: "center",

//     callbacks: {
//       label: function (tooltipItem) {
//         return Number(tooltipItem.yLabel);
//       },
//     },
//   },

//   scales: {

//     y: {

//       ticks: {
//         suggestedMin: -1.2,
//         suggestedMax: 1.2,
//         maxTicksLimit: 11,
//         display: false,
//       },

//     },
//   },
// };

// const data = {
//   labels: props.xValues,
//   datasets: [
//     {
//       label: "Dataset 1",
//       data: props.xValues,
//       fill: false,
//       backgroundColor: "#e63946", // Put the gradient here as a fill color
//       borderColor: "#e63946",
//       borderWidth: 2,
//       pointRadius: 0,
//       pointBackgroundColor: "rgba(255, 255, 255)",
//     },
//   ],
// };
// console.log("djhkhfsdhfjhsdjklf", props);
