import React, { useState, useEffect } from "react";
import styles from "./FacilityReportsWrapper.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FacilityReportsHeader from "./FacilityReportsHeader";
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Radio from '@mui/material/Radio';
import CloseIcon from '@mui/icons-material/Close';
import Input from '@material-ui/core/Input';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import { FaSort } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedProjectIdAndLocationId, getUserProfile } from "../../../Reducer/selectors/DashboardSelector";
import { getFacilityReport, getFacilityReportSuccess, setDownloadReportLink } from "../../../Actions";
import { getDownloadReportLink, getFacilityBPHSelector, getFacilityReportSelector, getReportStatusSelector } from "../../../Reducer/selectors/PatientSelector";
import { getReportsApplyStatus, getReportsLoader } from "../../../Reducer/selectors/UtilSelector";
import { setReportsApplyStatus, setShowReportsLoader } from "../../../Actions/UtilActions";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

export default function FacilityReportsWrapper() {

    const dispatch = useDispatch();

    const projectAndLocation = useSelector(getSelectedProjectIdAndLocationId);
    const userProfile = useSelector(getUserProfile);

    const useStyles = makeStyles((theme) => ({
        tooltip: {
            marginTop: 5,
            font: "12px",
            background: "black",
            fontFamily: "GTWalsheimProRegular",
        },
    }));

    const classes = useStyles();

    const [selectedYear, setSelectedYear] = useState("");
    const [yearFilters, setYearFilters] = useState(["2020", "2021", "2022", "2023", "2024"]);
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedMonthNum, setSelectedMonthNum] = useState("");
    const [monthFilters, setMonthFilters] = useState(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);

    const facilityBPH = useSelector(getFacilityBPHSelector);

    const [selectedProgram, setSelectedProgram] = useState("");
    const [programFilters, setProgramFilters] = useState(["RPM", "CCM"]);
    const [selectedProvider, setSelectedProvider] = useState("");
    const [providerFilters, setProviderFilters] = useState(facilityBPH);
    const [selectedCPTCode, setSelectedCPTCode] = useState("");
    const [cptCodeFilters, setCPTCodeFilters] = useState(["99453", "99454", "99457", "99458", "99490", "99439"]);

    const [searched, setSearched] = useState("");

    const [isApplyActive, setIsApplyActive] = useState(false);
    const [isDownloadActive, setIsDownloadActive] = useState(false);

    const downloadReportLink = useSelector(getDownloadReportLink);
    const patientsReportList = useSelector(getFacilityReportSelector);
    const getReportStatus = useSelector(getReportStatusSelector);

    const reportsLoader = useSelector(getReportsLoader);

    const [reportsListSorting, setReportsListSorting] = useState([]);
    const [sortDirection, setSortDirection] = useState(false);

    const applyStatus = useSelector(getReportsApplyStatus);

    useEffect(() => {
        dispatch(setReportsApplyStatus(false));
    }, []);

    useEffect(() => {
        setProviderFilters(facilityBPH);
    }, [facilityBPH]);

    useEffect(() => {
        let filterdReportsList = applySelectedFilters(patientsReportList);
        setReportsListSorting(filterdReportsList);
    }, [patientsReportList, selectedProgram, selectedProvider, selectedCPTCode]);

    const checkForCPTCode = (rep, code) => {

        if (code === "") {
            return true;
        } else if (code === "99453") {
            if (rep.program === "CCM") {
                return false;
            }
            else if (rep.rpm99453 === "--") {
                return true;
            }
        } else if (code === "99454") {
            if (rep.program === "CCM") {
                return false;
            }
            else if (rep.rpm99454 === "--") {
                return true;
            }
        } else if (code === "99457") {
            if (rep.program === "CCM") {
                return false;
            }
            else if (rep.rpm99457 === "--") {
                return true;
            }
        } else if (code === "99458") {
            if (rep.program === "CCM") {
                return false;
            }
            else if (rep.rpm99458 === "--") {
                return true;
            }
        } else if (code === "99490") {
            if (rep.program === "RPM") {
                return false;
            }
            else if (rep.ccm99490 === "--") {
                return true;
            }
        } else if (code === "99439") {
            if (rep.program === "RPM") {
                return false;
            }
            else if (rep.ccm99439 === "--") {
                return true;
            }
        }
    };

    const applySelectedFilters = (reportsList) => {

        let filteredList = reportsList.filter((report) => {
            if (selectedProgram === "" && selectedProvider === "" && selectedCPTCode === "") {
                return true;
            } else if (report.program === selectedProgram && report.userName === selectedProvider && checkForCPTCode(report, selectedCPTCode)) {
                return true;
            } else if (report.program === selectedProgram && selectedProvider === "" && checkForCPTCode(report, selectedCPTCode)) {
                return true;
            } else if (selectedProgram === "" && selectedProvider === "" && checkForCPTCode(report, selectedCPTCode)) {
                return true;
            } else if (selectedProgram === "" && report.userName === selectedProvider && checkForCPTCode(report, selectedCPTCode)) {
                return true;
            }
        });

        return filteredList;
    };

    const clearYearFilter = () => {
        setSelectedYear("");
    };

    const clearMonthFilter = () => {
        setSelectedMonthNum("");
        setSelectedMonth("");
    };

    const clearProgramFilter = () => {
        setSelectedProgram("");
    };

    const clearProviderFilter = () => {
        setSelectedProvider("");
    };

    const clearCPTCodeFilter = () => {
        setSelectedCPTCode("");
    };

    const addToYearFilter = (event) => {
        event.preventDefault();
        setSelectedYear(event.target.name);
    };

    const addToMonthFilter = (event) => {
        event.preventDefault();
        switch (event.target.name) {
            case "Jan":
                setSelectedMonthNum("01");
                setSelectedMonth("Jan");
                break;
            case "Feb":
                setSelectedMonthNum("02");
                setSelectedMonth("Feb");
                break;
            case "Mar":
                setSelectedMonthNum("03");
                setSelectedMonth("Mar");
                break;
            case "Apr":
                setSelectedMonthNum("04");
                setSelectedMonth("Apr");
                break;
            case "May":
                setSelectedMonthNum("05");
                setSelectedMonth("May");
                break;
            case "Jun":
                setSelectedMonthNum("06");
                setSelectedMonth("Jun");
                break;
            case "Jul":
                setSelectedMonthNum("07");
                setSelectedMonth("Jul");
                break;
            case "Aug":
                setSelectedMonthNum("08");
                setSelectedMonth("Aug");
                break;
            case "Sep":
                setSelectedMonthNum("09");
                setSelectedMonth("Sep");
                break;
            case "Oct":
                setSelectedMonthNum("10");
                setSelectedMonth("Oct");
                break;
            case "Nov":
                setSelectedMonthNum("11");
                setSelectedMonth("Nov");
                break;
            case "Dec":
                setSelectedMonthNum("12");
                setSelectedMonth("Dec");
                break;
            default:
                setSelectedMonthNum("");
                setSelectedMonth("");
        }
    };

    const addToProviderFilter = (event) => {
        event.preventDefault();
        setSelectedProvider(event.target.name);
    };

    const addToProgramFilter = (event) => {
        event.preventDefault();
        setSelectedProgram(event.target.name);
    };

    const addToCPTCodeFilter = (event) => {
        event.preventDefault();
        setSelectedCPTCode(event.target.name);
    };

    const RenderYearFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedYear === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedYear === filter.toString()} onClick={(event) => addToYearFilter(event)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    const RenderMonthFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedMonth === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedMonth === filter.toString()} onClick={(event) => addToMonthFilter(event)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    const RenderProgramFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedProgram === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedProgram === filter.toString()} onClick={(event) => addToProgramFilter(event)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    const RenderProviderFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedProvider === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedProvider === filter.toString()} onClick={(event) => addToProviderFilter(event)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    const RenderCPTCodeFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedCPTCode === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedCPTCode === filter.toString()} onClick={(event) => addToCPTCodeFilter(event)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    const YearFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderYearFilters filterList={yearFilters}></RenderYearFilters>
            </div>
        );
    };

    const MonthFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderMonthFilters filterList={monthFilters}></RenderMonthFilters>
            </div>
        );
    };

    const ProgramFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderProgramFilters filterList={programFilters}></RenderProgramFilters>
            </div>
        );
    };

    const ProviderFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderProviderFilters filterList={providerFilters}></RenderProviderFilters>
            </div>
        );
    };

    const CPTCodeFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderCPTCodeFilters filterList={cptCodeFilters}></RenderCPTCodeFilters>
            </div>
        );
    };

    const requestSearch = (event) => {
        event.preventDefault();
        setSearched(event.target.value);
        let searchVal = searched;

        if (!searchVal || searchVal.length === 0) {
            const patientsReportListCopy = patientsReportList.map((x) => x);
            setReportsListSorting(patientsReportListCopy);
            //setPCCFilters(patientListCopy);
        } else {
            let filteredPatientReports = [];
            const patientsReportListCopy = patientsReportList.map((x) => x);
            filteredPatientReports = patientsReportListCopy.filter((row) => {

                return (
                    /* (row.patientId && row.patientId.toString().toLowerCase().includes(searchVal.split(' ').join('').toLowerCase())) || */
                    (row.fullName && row.fullName.toString().toLowerCase().includes(searchVal.toLowerCase())) ||
                    (row.medicareId && row.medicareId.toString().toLowerCase().includes(searchVal)) ||
                    (row.dxCode && row.dxCode.toString().toLowerCase().includes(searchVal.toLowerCase())));
            });
            setReportsListSorting(filteredPatientReports);
            //setPCCFilters(filteredPatients);
        }
    };

    const resetSearch = () => {
        setSearched("");
        const patientsReportListCopy = patientsReportList.map((x) => x);
        setReportsListSorting(patientsReportListCopy);
        //setPCCFilters(patientListCopy);
    };

    const handleStringSorting = (key) => {
        setSortDirection((prevSortDirection) => !prevSortDirection);
        let direction = sortDirection;
        let sortableItem = [...reportsListSorting];
        sortableItem.sort((a, b) => {
            if (direction === false) {
                return a[key]?.localeCompare(b[key]);
            } else {
                return b[key]?.localeCompare(a[key]);
            }
        });
        return setReportsListSorting(sortableItem);
    };

    const handleNumberSorting = (key) => {
        setSortDirection((prevSortDirection) => !prevSortDirection);
        let direction = sortDirection;
        let sortableItem = [...reportsListSorting];
        sortableItem.sort((a, b) => {
            if (direction === false) {
                return a[key] - b[key];
            } else {
                return b[key] - a[key];
            }
        });
        return setReportsListSorting(sortableItem);
    };

    useEffect(() => {
        if (selectedYear !== "" && selectedMonthNum !== "") {
            setIsApplyActive(true);
        } else {
            dispatch(setDownloadReportLink(""));
            dispatch(getFacilityReportSuccess([]));
            dispatch(setReportsApplyStatus(false));
            setIsApplyActive(false);
        }
    }, [selectedYear, selectedMonthNum]);

    useEffect(() => {
        if (downloadReportLink !== '') {
            setIsDownloadActive(true);
        } else {
            setIsDownloadActive(false);
        }
    }, [downloadReportLink]);

    const fetchReport = (event) => {
        event.preventDefault();
        if (isApplyActive) {
            const payload = {
                userId: userProfile.id,
                year: selectedYear,
                month: selectedMonthNum,
                projectId: projectAndLocation.selectedProjectId,
                locationId: projectAndLocation.selectedLocationId,
            };
            dispatch(setShowReportsLoader(true));
            dispatch(getFacilityReportSuccess([]));
            dispatch(setDownloadReportLink(""));
            dispatch(getFacilityReport(payload));
            dispatch(setReportsApplyStatus(true));
            setSelectedProgram("");
            setSelectedCPTCode("");
            setSelectedProvider("");
            setSearched("");
        }
    };

    const downloadReport = (event) => {
        event.preventDefault();
        if (isDownloadActive) {
            window.open(downloadReportLink);
        }
    };

    const CPTCodes = (props) => {
        switch (props.program) {
            case "RPM":
                return (
                    <>
                        <div className={styles.rpmCodesContainer}>
                            <div className={props.rpm99453 === "--" ? styles.rpmUnfulfilled : styles.rpmFulFilled}>99453</div>
                            <div className={props.rpm99454 === "--" ? styles.rpmUnfulfilled : styles.rpmFulFilled}>99454</div>
                            <div className={props.rpm99457 === "--" ? styles.rpmUnfulfilled : styles.rpmFulFilled}>99457</div>
                            <div className={props.rpm99458 === "--" ? styles.rpmUnfulfilled : styles.rpmFulFilled}>99458</div>
                        </div>
                    </>
                );

            case "CCM":
                return (
                    <>
                        <div className={styles.ccmCodesContainer}>
                            <div className={props.ccm99439 === "--" ? styles.ccmUnfulfilled : styles.ccmFulFilled}>99439</div>
                            <div className={props.ccm99490 === "--" ? styles.ccmUnfulfilled : styles.ccmFulFilled}>99490</div>
                        </div>
                    </>
                );

            default:
                return (
                    <>
                    </>
                );
        }
    };

    const padZero = (n) => {
        return (n < 10) ? ("0" + n) : n;
    };

    const reportsTableBody = reportsListSorting.map((report) => {
        return (
            <div>
                <table className={styles.responsive1}>
                    <tbody>
                        <tr>
                            <td className={styles.tableCellContentsFullName}><div className={styles.tableCellContainer}>{report.fullName}</div></td>
                            <td className={styles.tableCellContentsDOB}><div className={styles.tableCellContainer}>{report.dob}</div></td>
                            <td className={styles.tableCellContentsMId}><div className={styles.tableCellContainer}>{report.medicareId}</div></td>
                            <td className={styles.tableCellContentsProgram}><div className={report.program === "RPM" ? styles.tableCellContainerProgramRPM : styles.tableCellContainerProgramCCM}>{report.program}</div></td>
                            <td className={styles.tableCellContentsDXCode}><div className={styles.tableCellContainer}>{report.dxCode}</div></td>
                            <td className={styles.tableCellContentsCPTCode}><div className={styles.tableCellContainer}>{<CPTCodes program={report.program} ccm99439={report.ccm99439} ccm99490={report.ccm99490} rpm99453={report.rpm99453} rpm99454={report.rpm99454} rpm99457={report.rpm99457} rpm99458={report.rpm99458} />}</div></td>
                            <td className={styles.tableCellContentsUsage}><div className={parseInt(report.usage, 10) >= 16 ? styles.tableCellContainerUsageFulFilled : styles.tableCellContainerUsageUnfulFilled}>{report.usage + "/16"}</div></td>
                            <td className={styles.tableCellContentsTimeSpent}><div className={styles.tableCellContainer}>{padZero(Math.trunc(parseInt(report.totalTimeInSeconds, 10) / 60)) + ":" + padZero(parseInt(report.totalTimeInSeconds, 10) % 60)}</div></td>
                        </tr>
                    </tbody>
                </table>
            </div>);
    });

    return (
        <>
            <FacilityReportsHeader></FacilityReportsHeader>
            <div className={styles.containerDiv}>
                <div className={styles.datePickerDiv}>
                    <div className={styles.datePickerIconDiv}>
                        <Tooltip title="Choose another date range" classes={{ tooltip: classes.tooltip }}>
                            <CalendarMonthIcon></CalendarMonthIcon>
                        </Tooltip>
                    </div>

                    <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                        {(popupState) => (
                            <div className={selectedYear != "" ? styles.filterDivSelected : styles.filterDiv} {...bindToggle(popupState)}>
                                <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                                <div className={styles.filterDivLabel} >{selectedYear != "" ? selectedYear : 'Year'}</div>
                                <div>
                                    {selectedYear != "" ? (<CloseIcon onClick={() => clearYearFilter()} style={{ marginTop: '6px', color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>) : (<ExpandMoreIcon style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>)}
                                </div>
                                <Popper {...bindPopper(popupState)} transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper className={styles.filterBackdrop}>
                                                <YearFilterPanel popupState={popupState} />
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </div>
                        )}
                    </PopupState>

                    <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                        {(popupState) => (
                            <div className={selectedMonth != "" ? styles.filterDivSelected : styles.filterDiv} {...bindToggle(popupState)}>
                                <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                                <div className={styles.filterDivLabel} >{selectedMonth != "" ? selectedMonth : 'Month'}</div>
                                <div>
                                    {selectedMonth != "" ? (<CloseIcon onClick={() => clearMonthFilter()} style={{ marginTop: '6px', color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>) : (<ExpandMoreIcon style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>)}
                                </div>
                                <Popper {...bindPopper(popupState)} transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper className={styles.filterBackdrop}>
                                                <MonthFilterPanel popupState={popupState} />
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </div>
                        )}
                    </PopupState>

                    <Divider orientation="vertical" flexItem style={{ marginTop: '8px', marginBottom: '8px' }} />

                    <div className={styles.applyButton} onClick={(e) => fetchReport(e)}>
                        Apply
                    </div>

                    <div className={isDownloadActive ? styles.downloadButton : styles.downloadButtonDisabled} onClick={(e) => downloadReport(e)}>
                        Download
                    </div>
                </div>
            </div>

            <div className={styles.reportDiv}>
                <div className={styles.reportDivHeader}>
                    Monthly Report
                </div>
                <div className={styles.reportDivBody}>
                    <div className={styles.topDiv}>
                        <Input
                            className={styles.searchBar}
                            type="text"
                            placeholder="Search"
                            value={searched}
                            onChange={(event) => setSearched(event.target.value)}
                            onKeyUp={(event) => requestSearch(event)}
                            endAdornment={searched ?
                                <><InputAdornment position="start">
                                    <IconButton
                                        className={styles.cancelButWrapper}
                                    >
                                        <CloseIcon onClick={() => resetSearch()} style={{ color: "#4E253A", height: '15px', width: '15px' }}></CloseIcon>
                                    </IconButton>
                                </InputAdornment> </> :
                                <></>
                            }
                            startAdornment={
                                <InputAdornment position="end">
                                    {<IconButton
                                        sx={{ color: "#4E253A" }}
                                    >
                                        {<SearchIcon style={{ color: "#4E253A" }}></SearchIcon>}
                                    </IconButton>}
                                </InputAdornment>
                            }
                            disableUnderline={true}
                            style={{
                                fontFamily: "GTWalsheimProRegular"
                            }}
                        />

                        <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                            {(popupState) => (
                                <div className={selectedProgram != "" ? styles.filterDivSelected : styles.filterDiv} {...bindToggle(popupState)}>
                                    <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                                    <div className={styles.filterDivLabel} >{selectedProgram != "" ? selectedProgram : 'Program'}</div>
                                    <div>
                                        {selectedProgram != "" ? (<CloseIcon onClick={() => clearProgramFilter()} style={{ marginTop: '6px', color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>) : (<ExpandMoreIcon style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>)}
                                    </div>
                                    <Popper {...bindPopper(popupState)} transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper className={styles.filterBackdrop}>
                                                    <ProgramFilterPanel popupState={popupState} />
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                </div>
                            )}
                        </PopupState>

                        {/* <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                            {(popupState) => (
                                <div className={selectedProvider != "" ? styles.filterDivSelected : styles.filterDiv} {...bindToggle(popupState)}>
                                    <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                                    <div className={styles.filterDivLabel} >{selectedProvider != "" ? selectedProvider : 'Provider'}</div>
                                    <div>
                                        {selectedProvider != "" ? (<CloseIcon onClick={() => clearProviderFilter()} style={{ marginTop: '6px', color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>) : (<ExpandMoreIcon style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>)}
                                    </div>
                                    <Popper {...bindPopper(popupState)} transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper className={styles.filterBackdrop}>
                                                    <ProviderFilterPanel popupState={popupState} />
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                </div>
                            )}
                        </PopupState> */}

                        <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                            {(popupState) => (
                                <div className={selectedCPTCode != "" ? styles.filterDivSelected : styles.filterDiv} {...bindToggle(popupState)}>
                                    <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                                    <div className={styles.filterDivLabel} >{selectedCPTCode != "" ? selectedCPTCode : 'CPT Code'}</div>
                                    <div>
                                        {selectedCPTCode != "" ? (<CloseIcon onClick={() => clearCPTCodeFilter()} style={{ marginTop: '6px', color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>) : (<ExpandMoreIcon style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>)}
                                    </div>
                                    <Popper {...bindPopper(popupState)} transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper className={styles.filterBackdrop}>
                                                    <CPTCodeFilterPanel popupState={popupState} />
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                </div>
                            )}
                        </PopupState>
                    </div>
                    <div className={styles.tableDiv}>
                        <table className={styles.responsive}>
                            <thead>
                                <tr>
                                    <th className={styles.fullNameHeader} onClick={() => handleStringSorting("fullName")}>
                                        <div className={styles.headingContainer1}>
                                            Full Name
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.dobHeader} onClick={() => handleStringSorting("dob")}>
                                        <div className={styles.headingContainer1}>
                                            DOB
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.medicareIDHeader} onClick={() => handleStringSorting("medicareId")}>
                                        <div className={styles.headingContainer1}>
                                            Medicare ID
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.programHeader} onClick={() => handleStringSorting("program")}>
                                        <div className={styles.headingContainer1}>
                                            Program
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.dxCodeHeader}>
                                        <div className={styles.headingContainer1}>
                                            Dx Code
                                        </div>
                                    </th>
                                    <th className={styles.cptCodeHeader}>
                                        <div className={styles.headingContainer1}>
                                            CPT Code
                                        </div>
                                    </th>
                                    <th className={styles.usageHeader} onClick={() => handleNumberSorting("usage")}>
                                        <div className={styles.headingContainer1}>
                                            Usage
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.timeSpentHeader} onClick={() => handleNumberSorting("totalTimeInSeconds")}>
                                        <div className={styles.headingContainer1}>
                                            Time Spent
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div className={styles.reportsTableBody} style={{ maxHeight: '60vh', overflowY: 'scroll', width: '100%' }}>
                            {reportsLoader ?
                                (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) :
                                applyStatus ?
                                reportsListSorting.length > 0 ? getReportStatus ? reportsTableBody :
                                    (<div className={styles.circularProgress}>{"We are unable to generate reports at this moment. Please try again later."}</div>) : 
                                    (<div className={styles.circularProgress}>{"Not enough data for the selected month and year to generate a report."}</div>) :
                                    (<div className={styles.circularProgress}>{"Select year and month and click ‘Apply’ to generate the report."}</div>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}