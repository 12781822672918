import * as Yup from "yup";

export function getExportVitalsData() {
    return EXPORT_VITALS_DATA;
}
const DUMMY_BASELINE_DATA = {};

export function getBaselineFormTemplate() {
  return DUMMY_BASELINE_DATA;
}

const EXPORT_VITALS_DATA = [{},{
    Title: 'Aidar Connect MouthLab Vitals'
}, {}, {
    From: '05/01/2023',
    To: '05/30/2023',
    Generated: '05/31/2023 11:56:57',
    Patient: 'AIDI-285',
    MouthLabId: 3
}
];


const DUMMY_ACCORDION_DATA = [
  // {
  //   accID: "1",
  //   accType: "High Risk",
  //   accLabel: "High Risk Patients",
  //   priority: "high",
  //   priorityID: "1",
  //   studyId: "1",
  //   productID: "1",
  // },
  // {
  //   accID: "2",
  //   accType: "Mod Risk",
  //   accLabel: "Mod Risk Patients",
  //   priority: "Mod",
  //   priorityID: "2",
  //   studyId: "1",
  //   productID: "1",
  // },
  {
    accID: "3",
    accType: "Low Risk",
    accLabel: "All Patients",
    priority: "Low",
    priorityID: "3",
    studyId: "1",
    productID: "1",
  },
];

export function getAccRenderInfo() {
  return DUMMY_ACCORDION_DATA;
}

const DUMMY_PATIENT_LIST_DATA = [
  {
    high: {
      1: [
        {
          id: 10,
          firstName: "Jake",
          lastName: "Heffernan",
          middleName: "T",
          phoneNumber: "123-456-7890",
          gender: "male",
          dob: "1/1/2000",
          ehrId: "200",
          diagnoses: ["sick", "tired"],
          programs: ["hospital"],
          consent: "true",
          registrationStatus: "started",
          emailId: "jheffernan@aidar.com",
          baselineId: 5,
        },
        {
          id: 11,
          firstName: "Jake",
          lastName: "Heffernan",
          middleName: "T",
          phoneNumber: "123-456-7890",
          gender: "male",
          dob: "1/1/2000",
          ehrId: "200",
          diagnoses: ["sick", "tired"],
          programs: ["hospital"],
          consent: "true",
          registrationStatus: "started",
          emailId: "fake@aidar.com",
          baselineId: 4,
        },
        {
          id: 12,
          firstName: "Test",
          lastName: "Subject",
          middleName: "",
          phoneNumber: "555-8888-1234",
          gender: "male",
          dob: "12/31/1999",
          ehrId: "300",
          diagnoses: ["sick", "tired"],
          programs: ["hospital"],
          consent: "true",
          registrationStatus: "started",
          emailId: "TestSubject@email.com",
          baselineId: 0,
        },
        {
          id: 17,
          firstName: "Barack",
          lastName: "Obama",
          middleName: "H",
          phoneNumber: "123-456-7890",
          gender: "male",
          dob: "1/1/2000",
          ehrId: "200",
          diagnoses: ["sick", "tired"],
          programs: ["hospital"],
          consent: "true",
          registrationStatus: "started",
          emailId: "president44@unitedstates.com",
          baselineId: 0,
        },
        {
          id: 18,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "btest@testo.com",
          baselineId: 0,
        },
        {
          id: 19,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "btest4@testo.com",
          baselineId: 0,
        },
        {
          id: 20,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "btest3@testo.com",
          baselineId: 0,
        },
        {
          id: 22,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "gggggg@testo.com",
          baselineId: 0,
        },
        {
          id: 23,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "qqqqq@testo.com",
          baselineId: 0,
        },
        {
          id: 24,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "testtesttest@testo.com",
          baselineId: 0,
        },
        {
          id: 25,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "testtesttest2@testo.com",
          baselineId: 0,
        },
        {
          id: 26,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "v4@testo.com",
          baselineId: 0,
        },
        {
          id: 27,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "d4@testo.com",
          baselineId: 0,
        },
        {
          id: 28,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "pppppppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 29,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "pppppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 31,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "ppppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 32,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "pppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 33,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "ppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 34,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "pppp@testo.com",
          baselineId: 0,
        },
        {
          id: 35,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "aaaaaaaaaaaaaa@testo.com",
          baselineId: 0,
        },
        {
          id: 37,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "A34jjjj@testo.com",
          baselineId: 0,
        },
        {
          id: 39,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "ttt@testo.com",
          baselineId: 0,
        },
        {
          id: 40,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "emailtesting@testo.com",
          baselineId: 0,
        },
        {
          id: 43,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "4444@testo.com",
          baselineId: 0,
        },
        {
          id: 44,
          firstName: "sdds",
          lastName: "asdasd",
          middleName: "sdas",
          phoneNumber: "",
          gender: "Male",
          dob: "2022-04-06",
          ehrId: "332",
          programs: ["RPM"],
          consent: "true",
          emailId: "dfhjdghjf@jkdfhkjdh.com",
          baselineId: 0,
        },
        {
          id: 45,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: ["ADB"],
          consent: "",
          emailId: "efdsf@fsdf.com",
          baselineId: 0,
        },
        {
          id: 46,
          firstName: "asdd",
          lastName: "",
          middleName: "sda",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: ["RPM"],
          consent: "",
          emailId: "sdadas@asdasd.com",
          baselineId: 0,
        },
        {
          id: 47,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: [""],
          consent: "true",
          emailId: "3hhhh@testo.com",
          baselineId: 0,
        },
        {
          id: 48,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: [""],
          consent: "",
          emailId: "klghjlhkghklj@sdfklgjsdfljgl.com",
          baselineId: 0,
        },
        {
          id: 49,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: [""],
          consent: "",
          emailId: "dfjkdshfjhdsk@kukuku.com",
          baselineId: 0,
        },
        {
          id: 50,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: [""],
          consent: "",
          emailId: "tytyyty@tyyy.com",
          baselineId: 0,
        },
        {
          id: 51,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: [""],
          consent: "",
          emailId: "",
          baselineId: 0,
        },
      ],
    },

    2: {
      2: [
        {
          id: 10,
          firstName: "Jake",
          lastName: "Heffernan",
          middleName: "T",
          phoneNumber: "123-456-7890",
          gender: "male",
          dob: "1/1/2000",
          ehrId: "200",
          diagnoses: ["sick", "tired"],
          programs: ["hospital"],
          consent: "true",
          registrationStatus: "started",
          emailId: "jheffernan@aidar.com",
          baselineId: 5,
        },
        {
          id: 11,
          firstName: "Jake",
          lastName: "Heffernan",
          middleName: "T",
          phoneNumber: "123-456-7890",
          gender: "male",
          dob: "1/1/2000",
          ehrId: "200",
          diagnoses: ["sick", "tired"],
          programs: ["hospital"],
          consent: "true",
          registrationStatus: "started",
          emailId: "fake@aidar.com",
          baselineId: 4,
        },
        {
          id: 12,
          firstName: "Test",
          lastName: "Subject",
          middleName: "",
          phoneNumber: "555-8888-1234",
          gender: "male",
          dob: "12/31/1999",
          ehrId: "300",
          diagnoses: ["sick", "tired"],
          programs: ["hospital"],
          consent: "true",
          registrationStatus: "started",
          emailId: "TestSubject@email.com",
          baselineId: 0,
        },
        {
          id: 17,
          firstName: "Barack",
          lastName: "Obama",
          middleName: "H",
          phoneNumber: "123-456-7890",
          gender: "male",
          dob: "1/1/2000",
          ehrId: "200",
          diagnoses: ["sick", "tired"],
          programs: ["hospital"],
          consent: "true",
          registrationStatus: "started",
          emailId: "president44@unitedstates.com",
          baselineId: 0,
        },
        {
          id: 18,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "btest@testo.com",
          baselineId: 0,
        },
        {
          id: 19,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "btest4@testo.com",
          baselineId: 0,
        },
        {
          id: 20,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "btest3@testo.com",
          baselineId: 0,
        },
        {
          id: 22,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "gggggg@testo.com",
          baselineId: 0,
        },
        {
          id: 23,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "qqqqq@testo.com",
          baselineId: 0,
        },
        {
          id: 24,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "testtesttest@testo.com",
          baselineId: 0,
        },
        {
          id: 25,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "testtesttest2@testo.com",
          baselineId: 0,
        },
        {
          id: 26,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "v4@testo.com",
          baselineId: 0,
        },
        {
          id: 27,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "d4@testo.com",
          baselineId: 0,
        },
        {
          id: 28,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "pppppppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 29,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "pppppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 31,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "ppppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 32,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "pppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 33,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "ppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 34,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "pppp@testo.com",
          baselineId: 0,
        },
        {
          id: 35,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "aaaaaaaaaaaaaa@testo.com",
          baselineId: 0,
        },
        {
          id: 37,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "A34jjjj@testo.com",
          baselineId: 0,
        },
        {
          id: 39,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "ttt@testo.com",
          baselineId: 0,
        },
        {
          id: 40,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "emailtesting@testo.com",
          baselineId: 0,
        },
        {
          id: 43,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "4444@testo.com",
          baselineId: 0,
        },
        {
          id: 44,
          firstName: "sdds",
          lastName: "asdasd",
          middleName: "sdas",
          phoneNumber: "",
          gender: "Male",
          dob: "2022-04-06",
          ehrId: "332",
          programs: ["RPM"],
          consent: "true",
          emailId: "dfhjdghjf@jkdfhkjdh.com",
          baselineId: 0,
        },
        {
          id: 45,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: ["ADB"],
          consent: "",
          emailId: "efdsf@fsdf.com",
          baselineId: 0,
        },
        {
          id: 46,
          firstName: "asdd",
          lastName: "",
          middleName: "sda",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: ["RPM"],
          consent: "",
          emailId: "sdadas@asdasd.com",
          baselineId: 0,
        },
        {
          id: 47,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: [""],
          consent: "true",
          emailId: "3hhhh@testo.com",
          baselineId: 0,
        },
        {
          id: 48,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: [""],
          consent: "",
          emailId: "klghjlhkghklj@sdfklgjsdfljgl.com",
          baselineId: 0,
        },
        {
          id: 49,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: [""],
          consent: "",
          emailId: "dfjkdshfjhdsk@kukuku.com",
          baselineId: 0,
        },
        {
          id: 50,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: [""],
          consent: "",
          emailId: "tytyyty@tyyy.com",
          baselineId: 0,
        },
        {
          id: 51,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: [""],
          consent: "",
          emailId: "",
          baselineId: 0,
        },
      ],
    },
    3: {
      3: [
        {
          id: 10,
          firstName: "Jake",
          lastName: "Heffernan",
          middleName: "T",
          phoneNumber: "123-456-7890",
          gender: "male",
          dob: "1/1/2000",
          ehrId: "200",
          diagnoses: ["sick", "tired"],
          programs: ["hospital"],
          consent: "true",
          registrationStatus: "started",
          emailId: "jheffernan@aidar.com",
          baselineId: 5,
        },
        {
          id: 11,
          firstName: "Jake",
          lastName: "Heffernan",
          middleName: "T",
          phoneNumber: "123-456-7890",
          gender: "male",
          dob: "1/1/2000",
          ehrId: "200",
          diagnoses: ["sick", "tired"],
          programs: ["hospital"],
          consent: "true",
          registrationStatus: "started",
          emailId: "fake@aidar.com",
          baselineId: 4,
        },
        {
          id: 12,
          firstName: "Test",
          lastName: "Subject",
          middleName: "",
          phoneNumber: "555-8888-1234",
          gender: "male",
          dob: "12/31/1999",
          ehrId: "300",
          diagnoses: ["sick", "tired"],
          programs: ["hospital"],
          consent: "true",
          registrationStatus: "started",
          emailId: "TestSubject@email.com",
          baselineId: 0,
        },
        {
          id: 17,
          firstName: "Barack",
          lastName: "Obama",
          middleName: "H",
          phoneNumber: "123-456-7890",
          gender: "male",
          dob: "1/1/2000",
          ehrId: "200",
          diagnoses: ["sick", "tired"],
          programs: ["hospital"],
          consent: "true",
          registrationStatus: "started",
          emailId: "president44@unitedstates.com",
          baselineId: 0,
        },
        {
          id: 18,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "btest@testo.com",
          baselineId: 0,
        },
        {
          id: 19,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "btest4@testo.com",
          baselineId: 0,
        },
        {
          id: 20,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "btest3@testo.com",
          baselineId: 0,
        },
        {
          id: 22,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "gggggg@testo.com",
          baselineId: 0,
        },
        {
          id: 23,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "qqqqq@testo.com",
          baselineId: 0,
        },
        {
          id: 24,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "testtesttest@testo.com",
          baselineId: 0,
        },
        {
          id: 25,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "testtesttest2@testo.com",
          baselineId: 0,
        },
        {
          id: 26,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "v4@testo.com",
          baselineId: 0,
        },
        {
          id: 27,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "d4@testo.com",
          baselineId: 0,
        },
        {
          id: 28,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "pppppppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 29,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "pppppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 31,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "ppppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 32,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "pppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 33,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "ppppp@testo.com",
          baselineId: 0,
        },
        {
          id: 34,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "pppp@testo.com",
          baselineId: 0,
        },
        {
          id: 35,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "aaaaaaaaaaaaaa@testo.com",
          baselineId: 0,
        },
        {
          id: 37,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "A34jjjj@testo.com",
          baselineId: 0,
        },
        {
          id: 39,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "ttt@testo.com",
          baselineId: 0,
        },
        {
          id: 40,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "emailtesting@testo.com",
          baselineId: 0,
        },
        {
          id: 43,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: ["RPM", "CCM", "ADB"],
          consent: "true",
          emailId: "4444@testo.com",
          baselineId: 0,
        },
        {
          id: 44,
          firstName: "sdds",
          lastName: "asdasd",
          middleName: "sdas",
          phoneNumber: "",
          gender: "Male",
          dob: "2022-04-06",
          ehrId: "332",
          programs: ["RPM"],
          consent: "true",
          emailId: "dfhjdghjf@jkdfhkjdh.com",
          baselineId: 0,
        },
        {
          id: 45,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: ["ADB"],
          consent: "",
          emailId: "efdsf@fsdf.com",
          baselineId: 0,
        },
        {
          id: 46,
          firstName: "asdd",
          lastName: "",
          middleName: "sda",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: ["RPM"],
          consent: "",
          emailId: "sdadas@asdasd.com",
          baselineId: 0,
        },
        {
          id: 47,
          firstName: "Barak",
          lastName: "Obama",
          middleName: "Hussain",
          phoneNumber: "1234567890",
          gender: "Male",
          dob: "2022-04-07",
          ehrId: "4",
          programs: [""],
          consent: "true",
          emailId: "3hhhh@testo.com",
          baselineId: 0,
        },
        {
          id: 48,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: [""],
          consent: "",
          emailId: "klghjlhkghklj@sdfklgjsdfljgl.com",
          baselineId: 0,
        },
        {
          id: 49,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: [""],
          consent: "",
          emailId: "dfjkdshfjhdsk@kukuku.com",
          baselineId: 0,
        },
        {
          id: 50,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: [""],
          consent: "",
          emailId: "tytyyty@tyyy.com",
          baselineId: 0,
        },
        {
          id: 51,
          firstName: "",
          lastName: "",
          middleName: "",
          phoneNumber: "",
          gender: "",
          dob: "",
          ehrId: "",
          programs: [""],
          consent: "",
          emailId: "",
          baselineId: 0,
        },
      ],
    },
  },
];

export function getAllPatientList() {
  return DUMMY_PATIENT_LIST_DATA;
}
