import React, { useState, useRef, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import StepContent from "@material-ui/core/StepContent";
import Paper from "@material-ui/core/Paper";
import styles from "./BpCalibrationND.module.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from "react-router-dom";
import { getCalibrationStatus, getPCCUserProfileSelector } from "../../../../../Reducer/selectors/NursingDashboardSelector";
import { getSelectedMlIdSelector } from "../../../../../Reducer/selectors/DashboardSelector";
import PostAssignMouthLabToSubject from "../../../../API/PostAssignMouthLabToSubject/PostAssignMouthLabToSubject";
import PostUnassignMouthLabFromSubject from "../../../../API/PostUnassignMouthLabFromSubject/PostUnassignMouthLabFromSubject";

// Component Imports
import CalibrationFormND from "./CalibrationForm/CalibrationFormND";

// Calibration Api
import IsCalibrationSuccess from "../../../../API/BpCalibration/IsCalibrationSuccess";

// contexts import
import { useDispatch, useSelector } from "react-redux";
import { setCalibrationStatus, setIsCalibrationOngoing, setShowCMDrawer } from "../../../../../Actions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        backgroundColor: `transparent`,
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        // marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

function getSteps() {
    return [
        <div className={styles.stepTitle}>
            Measure Blood Pressure (BP) using a BP cuff.
        </div>,
        <div className={styles.stepTitle}>
            Enter reference BP values and click calibrate.
        </div>,
        <div className={styles.stepTitle}>Take a measurement with MouthLab.</div>,
        <div className={styles.stepTitle}>Verifying BP calibration.</div>,
    ];
}

export default function BpCalibrationND() {
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const selectedML = useSelector(getSelectedMlIdSelector);
    const pccUserProfile = useSelector(getPCCUserProfileSelector);
    const calibrationStatus = useSelector(getCalibrationStatus);
    const dispatch = useDispatch();
    const [response, setResponse] = useState({
        statusCode: 0,
        message: "",
    });

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleNextWithPairing = () => {
        let values = {
            deviceId: selectedML,
            subjectId: pccUserProfile.subjectId,
        }

        let payload = {
            complete: false,
            success: false,
        }

        dispatch(setCalibrationStatus(payload));

        PostAssignMouthLabToSubject(values).then((res) => {
            setResponse({
                statusCode: res.response ? res.response.status : res.error.response.status,
                message: res.response ? res.response.data : res.error.response.data,
            });
        });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        dispatch(setIsCalibrationOngoing(true));
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        dispatch(setIsCalibrationOngoing(false));
    };

    const handleResetWithUnpairing = () => {
        PostUnassignMouthLabFromSubject({ deviceId: selectedML }).then((res) => {
            setResponse({
                statusCode: res.response ? res.response.status : res.error.response.status,
                message: res.response ? res.response.data : res.error.response.data,
            });
        });
        setActiveStep(0);
        dispatch(setIsCalibrationOngoing(false));
    };

    useEffect(() => {
        return () => {
            window.addEventListener("beforeunload", function (e) {

                PostUnassignMouthLabFromSubject({ deviceId: selectedML }).then((res) => {
                    setResponse({
                        statusCode: res.response ? res.response.status : res.error.response.status,
                        message: res.response ? res.response.data : res.error.response.data,
                    });
                });
            });
        }
    });

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <StepOneMsg onNextStep={handleNextWithPairing} />;
            case 1:
                return <CalibrationFormND onNextStep={handleNext} onCancel={handleResetWithUnpairing} />;
            case 2:
                return <StepThreeMsg onNextStep={handleNext} onCancel={handleResetWithUnpairing} />;
            case 3:
                return <LastBPStep onReset={handleReset} pccUserProfile={pccUserProfile} />;
            default:
                return "Unknown step";
        }
    }

    return (
        <Grid container>
            <div className={styles.calibrationCard}>
                <Grid item xs={12} xl={4} lg={4} md={4} sm={12}>
                    <div className={styles.formCard}>
                        <div className={styles.stepperStyles}>
                            <Stepper
                                activeStep={activeStep}
                                orientation="vertical"
                                className={styles.stepperStyles}
                            >
                                {steps.map((label, index) => (
                                    <Step key={index}>
                                        <StepLabel>{label}</StepLabel>
                                        <StepContent>
                                            <Typography>{getStepContent(index)}</Typography>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                            {activeStep === steps.length && (
                                <Paper
                                    square
                                    elevation={0}
                                    // className={classes.resetContainer}
                                    style={{ backgroundColor: `#3e1e92`, color: `#fff` }}
                                >
                                    <div className={styles.successMessage}>
                                        All steps completed - Calibration Successful
                                    </div>

                                    <button
                                        onClick={handleReset}
                                        className={styles.stepperButton}
                                    >
                                        Recalibrate
                                    </button>
                                </Paper>
                            )}
                        </div>
                    </div>
                </Grid>
            </div>
        </Grid>
    );
}

// STEP ONE MSG FUNCTION
function StepOneMsg(props) {
    let location = useLocation();
    return (
        <Fragment>
            <div className={styles.msgStyle}>
                Measure the resting blood pressure using a cuff-based BP monitor.
                <br /> Once done, click the 'Next' button.
            </div>
            <div
            // className={classes.actionsContainer}
            >
                <div style={{ textAlign: `left` }}>
                    <button
                        variant="contained"
                        onClick={props.onNextStep}
                        className={styles.stepperButton}
                    >
                        {/* {activeStep === steps.length - 1 ? "Finish" : "Next"} */}
                        Next
                    </button>
                </div>
            </div>
        </Fragment>
    );
}

// STEP ONE MSG FUNCTION
function StepThreeMsg(props) {
    return (
        <Fragment>
            <div className={styles.msgStyle}>
                Proceed to take a measurement with the MouthLab device. For calibration,
                remove the device from the mouth after the first vibration. Once the
                data transmission is complete (when blue LED turns off), click 'Next'
            </div>
            <div
            // className={classes.actionsContainer}
            >
                <div>
                    <button
                        variant="contained"
                        onClick={props.onNextStep}
                        className={styles.stepperButton}
                    >
                        {/* {activeStep === steps.length - 1 ? "Finish" : "Next"} */}
                        Next
                    </button>

                    <button
                        variant="contained"
                        onClick={props.onCancel}
                        className={styles.stepperButton}
                    >
                        {/* {activeStep === steps.length - 1 ? "Finish" : "Next"} */}
                        Cancel
                    </button>
                </div>
            </div>
        </Fragment>
    );
}

// Function of the last step
// This function will call the isCalibrationSuccessful API
// The API will return the calibration success values - boolean (true/false)
// If the returned values is true then shoe success message
// If the returned value is false display message calibration is not successful and
// Display the recalibrate button
// recalibration button resets the form values and directs user to initial steps

// STEP ONE MSG FUNCTION
function LastBPStep(props) {
    let location = useLocation();
    const [displayControl, setDisplayControl] = useState(0);
    const [isCalSuccess, setIsCalSuccess] = useState([]);
    let curSubjectGUID = props.pccUserProfile.guid;
    const dispatch = useDispatch();

    const fetchCalibrationResults = async () => {
        IsCalibrationSuccess(curSubjectGUID).then((res) => {
            setIsCalSuccess(res);
            setDisplayControl(1);
            let payload = {
                complete: true,
                success: res.isCalibrationSuccessful === '1' ? true : false,
            }
            dispatch(setCalibrationStatus(payload));
            // console.log("Is Calibration Success: ", res);
        });
    };


    useInterval(() => {
        fetchCalibrationResults();
        openDrawerAfter5Seconds();
    }, 60000);

    const openDrawerAfter5Seconds = () => {
        setTimeout(() => {
            dispatch(setShowCMDrawer(true));
            props.onReset();
        }, 3000);
    }

    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest function.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    return (
        <Fragment>
            <div className={styles.msgStyle}>
                Please stay on this page until you receive the final verification
                message.
            </div>
            {displayControl === 0 && <CircularProgress style={{ color: `#4E253A` }} />}

            {displayControl === 1 && (
                // <div>Calibration Result - {isCalSuccess.isCalibrationSuccessful}</div>
                <div>
                    {isCalSuccess.isCalibrationSuccessful == 1 ? (<>
                        <div className={styles.msgStyle}>
                            Calibration Successful! {isCalSuccess.ptt}
                        </div>
                    </>
                    ) : (
                        <div>
                            <div className={styles.msgStyle}>
                                Calibration not Successful {isCalSuccess.ptt}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
}
