// Purpose of this context is to call all the required API for the software to function
// at the start of the loading - after authentication
// the context will store the responses of the requests store it in react state and
// make it available to use in other children components when needed

import React, { useState, createContext } from "react";

//API imports
export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const userInformationInitialState = {
    email: "",
    firstName: "",
    lastName: "",
    institutionId: 0,
    projects: {},
    locations: {}
  };
  const [userInformation, setUserInformation] = useState(userInformationInitialState);
  const resetUserInformation = () => {
    setUserInformation(userInformationInitialState);
  }

  const curPlSelectionsInitialState = {
    projectId: null, 
    locationId: null
  };
  const [curPlSelections, setCurPlSelections] = useState(curPlSelectionsInitialState);
  const resetCurPlSelections = () => {
    setCurPlSelections(curPlSelectionsInitialState);
  }

  console.log("User info from the context", userInformation);

  return (
    <UserContext.Provider
      value={{
        userInformation,
        setUserInformation,
        resetUserInformation,
        curPlSelections,
        setCurPlSelections,
        resetCurPlSelections
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
