export function getPCCPatientsListSelector({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.pccPatientsList
    )
        return nursingDashboardReducer.pccPatientsList;
    else return {};
}
export function getPCCPatientsPagingSelector({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.paging
    )
        return nursingDashboardReducer.paging;
    else return {};
}
export function getPCCUserProfileSelector({ nursingDashboardReducer }) {
    if (
        nursingDashboardReducer &&
        nursingDashboardReducer.pccPatientProfile
    )
        return nursingDashboardReducer.pccPatientProfile.pccPatientProfile;
    else return {};
}

export function getPrevMeasurement({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.prevMeasurement
    )
        return nursingDashboardReducer.prevMeasurement;
    else return [];
}

export function getLatestMeasurement({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.latestMeasurement
    )
        return nursingDashboardReducer.latestMeasurement;
    else return [];
}

export function getUploadManualEntryStatus({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.uploadManualEntry
    )
        return nursingDashboardReducer.uploadManualEntry;
    else return false;
}

export function getCalibrationStatus({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.calibrationStatus
    )
        return nursingDashboardReducer.calibrationStatus;
    else return {};
}

export function getPccPatientListPageNumber({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.pccPageNumber
    )
        return nursingDashboardReducer.pccPageNumber;
    else return 1;
}

export function getPccPatientListPageSize({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.pccPageSize
    )
        return nursingDashboardReducer.pccPageSize;
    else return "20";
}

export function getUserHasML({ nursingDashboardReducer }) {
    if (nursingDashboardReducer &&
        nursingDashboardReducer.isUserAlreadyPaired
    )
        return nursingDashboardReducer.isUserAlreadyPaired;
    else return false;
}