import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import styles from "./Thresholds.module.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { getTempEdit, getSbpEdit, getDbpEdit, getPrEdit, getSpo2Edit, getHrEdit, getRrEdit, getFev1Edit, getPefEdit, getSugarEdit, getPainEdit, getWeightEdit, getAllEdit, getDisableAllEditButton, getShowConfirmationBanner, getHrvEdit } from "../../../../Reducer/selectors/PatientSelector";
import { setTempEdit, setSbpEdit, setDbpEdit, setPrEdit, setSpo2Edit, setHrEdit, setRrEdit, setFev1Edit, setPefEdit, setSugarEdit, setPainEdit, setWeightEdit, setDisableAllEditButton, setShowConfirmationBanner, setHrvEdit, setNewThreshold } from "../../../../Actions/PatientActions";
import { getBaselineSelector } from "../../../../Reducer/selectors/PatientSelector.js";
import { getLatestBaseline } from "../../../../Actions";
import { getCurrentPatientGUID, getCurrentPatientSubjectID, getPageLoader } from "../../../../Reducer/selectors/UtilSelector";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { getUserProfile, getUserRole } from "../../../../Reducer/selectors/DashboardSelector.js";
import ClearIcon from '@mui/icons-material/Clear';
import { setShowThresholdsLoader } from "../../../../Actions/UtilActions.js";
import { globalConstants } from "../../../../Constants/GlobalConstants.js";

const ThresholdsParams = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  //let curPatientIdFromPrams = useParams();
  const currentPatientGUID = useSelector(getCurrentPatientGUID);
  const curPatientSubjectId = useSelector(getCurrentPatientSubjectID);
  const userProfile = useSelector(getUserProfile);
  const loader = useSelector(getPageLoader);
  //let currentPatientGUID = curPatientGUID ? curPatientGUID : curPatientIdFromPrams.patientId;
  //  console.log("CURRENT USER GUID: ", currentPatientGUID);

  const showConfrimationBanner = useSelector(getShowConfirmationBanner);

  const subjectThresholdsData = useSelector(getBaselineSelector);
  // console.log("subjectThresholdsData: ", subjectThresholdsData);

  const isAllEditable = useSelector(getAllEdit);
  const isDisableAllEditButton = useSelector(getDisableAllEditButton);
  const isTempEditable = useSelector(getTempEdit);
  const isSbpEditable = useSelector(getSbpEdit);
  const isDbpEditable = useSelector(getDbpEdit);
  const isPrEditable = useSelector(getPrEdit);
  const isHrEditable = useSelector(getHrEdit);
  const isHrvEditable = useSelector(getHrvEdit);
  const isSpo2Editable = useSelector(getSpo2Edit);
  const isRrEditable = useSelector(getRrEdit);
  const isFev1Editable = useSelector(getFev1Edit);
  const isPefEditable = useSelector(getPefEdit);
  const isSugarEditable = useSelector(getSugarEdit);
  const isPainEditable = useSelector(getPainEdit);
  const isWeightEditable = useSelector(getWeightEdit);

  const [iconTempClicked, setIconTempClicked] = useState(true);
  const [iconSbpClicked, setIconSbpClicked] = useState(true);
  const [iconDbpClicked, setIconDbpClicked] = useState(true);
  const [iconPrClicked, setIconPrClicked] = useState(true);
  const [iconSpo2Clicked, setIconSpo2Clicked] = useState(true);
  const [iconHrClicked, setIconHrClicked] = useState(true);
  const [iconHrvClicked, setIconHrvClicked] = useState(true);
  const [iconRrClicked, setIconRrClicked] = useState(true);
  const [iconFev1Clicked, setIconFev1Clicked] = useState(true);
  const [iconPefClicked, setIconPefClicked] = useState(true);
  const [iconSugarClicked, setIconSugarClicked] = useState(true);
  const [iconPainClicked, setIconPainClicked] = useState(true);
  const [iconWeightClicked, setIconWeightClicked] = useState(true);

  const toggleTempIcon = () => {
    dispatch(setTempEdit(!isTempEditable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconTempClicked(!iconTempClicked);
    setTrendStates();
  };
  const toggleSbpIcon = () => {
    dispatch(setSbpEdit(!isSbpEditable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconSbpClicked(!iconSbpClicked);
    setTrendStates();
  };
  const toggleDbpIcon = () => {
    dispatch(setDbpEdit(!isDbpEditable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconDbpClicked(!iconDbpClicked);
    setTrendStates();
  };
  const togglePrIcon = () => {
    dispatch(setPrEdit(!isPrEditable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconPrClicked(!iconPrClicked);
    setTrendStates();
  };
  const toggleSpo2Icon = () => {
    dispatch(setSpo2Edit(!isSpo2Editable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconSpo2Clicked(!iconSpo2Clicked);
    setTrendStates();
  };
  const toggleHrIcon = () => {
    dispatch(setHrEdit(!isHrEditable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconHrClicked(!iconHrClicked);
    setTrendStates();
  };
  const toggleHrvIcon = () => {
    dispatch(setHrvEdit(!isHrvEditable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconHrvClicked(!iconHrvClicked);
    setTrendStates();
  };
  const toggleRrIcon = () => {
    dispatch(setRrEdit(!isRrEditable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconRrClicked(!iconRrClicked);
    setTrendStates();
  };
  const toggleFev1Icon = () => {
    dispatch(setFev1Edit(!isFev1Editable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconFev1Clicked(!iconFev1Clicked);
    setTrendStates();
  };
  const togglePefIcon = () => {
    dispatch(setPefEdit(!isPefEditable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconPefClicked(!iconPefClicked);
    setTrendStates();
  };
  const toggleSugarIcon = () => {
    dispatch(setSugarEdit(!isSugarEditable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconSugarClicked(!iconSugarClicked);
    setTrendStates();
  };
  const togglePainIcon = () => {
    dispatch(setPainEdit(!isPainEditable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconPainClicked(!iconPainClicked);
    setTrendStates();
  };
  const toggleWeightIcon = () => {
    dispatch(setWeightEdit(!isWeightEditable));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconWeightClicked(!iconWeightClicked);
    setTrendStates();
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const [rrHigh, setRrHigh] = useState(subjectThresholdsData.breathingRateHigh);
  const [rrLow, setRrLow] = useState(subjectThresholdsData.breathingRateLow);
  const [rr, setRr] = useState(subjectThresholdsData.breathingRateValue);
  const [dbpHigh, setDbpHigh] = useState(subjectThresholdsData.dbpHigh);
  const [dbpLow, setDbpLow] = useState(subjectThresholdsData.dbpLow);
  const [dbp, setDbp] = useState(subjectThresholdsData.dbpValue);
  const [hrHigh, setHrHigh] = useState(subjectThresholdsData.heartRateHigh);
  const [hrLow, setHrLow] = useState(subjectThresholdsData.heartRateLow);
  const [hr, setHr] = useState(subjectThresholdsData.heartRateValue);
  const [tempHigh, setTempHigh] = useState(subjectThresholdsData.tempHigh);
  const [tempLow, setTempLow] = useState(subjectThresholdsData.tempLow);
  const [temp, setTemp] = useState(subjectThresholdsData.tempValue);
  const [sbpHigh, setSbpHigh] = useState(subjectThresholdsData.sbpHigh);
  const [sbpLow, setSbpLow] = useState(subjectThresholdsData.sbpLow);
  const [sbp, setSbp] = useState(subjectThresholdsData.sbpValue);
  const [prHigh, setPrHigh] = useState(subjectThresholdsData.pulseRateHigh);
  const [prLow, setPrLow] = useState(subjectThresholdsData.pulseRateLow);
  const [pr, setPr] = useState(subjectThresholdsData.pulseRateValue);
  const [pefHigh, setPefHigh] = useState(subjectThresholdsData.pefHigh);
  const [pefLow, setPefLow] = useState(subjectThresholdsData.pefLow);
  const [pef, setPef] = useState(subjectThresholdsData.pefValue);
  const [hrvHigh, setHrvHigh] = useState(subjectThresholdsData.hrvHigh);
  const [hrvLow, setHrvLow] = useState(subjectThresholdsData.hrvLow);
  const [hrv, setHrv] = useState(subjectThresholdsData.hrvValue);
  const [fev1High, setFev1High] = useState(subjectThresholdsData.fev1High);
  const [fev1Low, setFev1Low] = useState(subjectThresholdsData.fev1Low);
  const [fev1, setFev1] = useState(subjectThresholdsData.fev1Value);
  const [spo2High, setSpo2High] = useState(subjectThresholdsData.spo2High);
  const [spo2Low, setSpo2Low] = useState(subjectThresholdsData.spo2Low);
  const [spo2, setSpo2] = useState(subjectThresholdsData.spo2Value);
  const [sugarHigh, setSugarHigh] = useState(subjectThresholdsData.sugarHigh);
  const [sugarLow, setSugarLow] = useState(subjectThresholdsData.sugarLow);
  const [sugar, setSugar] = useState(subjectThresholdsData.sugarValue);
  const [painHigh, setPainHigh] = useState(subjectThresholdsData.painHigh);
  const [painLow, setPainLow] = useState(subjectThresholdsData.painLow);
  const [pain, setPain] = useState(subjectThresholdsData.painValue);
  const [weightHigh, setWeightHigh] = useState(subjectThresholdsData.weightHigh);
  const [weightLow, setWeightLow] = useState(subjectThresholdsData.weightLow);
  const [weight, setWeight] = useState(subjectThresholdsData.weightValue);

  const setNewTrendsWPaylaod = (payload) => {
    setRrHigh(payload.breathingRateHigh);
    setRrLow(payload.breathingRateLow);
    setRr(payload.breathingRateValue);
    setDbpHigh(payload.dbpHigh);
    setDbpLow(payload.dbpLow);
    setDbp(payload.dbpValue);
    setHrHigh(payload.heartRateHigh);
    setHrLow(payload.heartRateLow);
    setHr(payload.heartRateValue);
    setTempHigh(payload.tempHigh);
    setTempLow(payload.tempLow);
    setTemp(payload.tempValue);
    setSbpHigh(payload.sbpHigh);
    setSbpLow(payload.sbpLow);
    setSbp(payload.sbpValue);
    setPrHigh(payload.pulseRateHigh);
    setPrLow(payload.pulseRateLow);
    setPr(payload.pulseRateValue);
    setPefHigh(payload.pefHigh);
    setPefLow(payload.pefLow);
    setPef(payload.pefValue);
    setHrvHigh(payload.hrvHigh);
    setHrvLow(payload.hrvLow);
    setHrv(payload.hrvValue);
    setFev1High(payload.fev1High);
    setFev1Low(payload.fev1Low);
    setFev1(payload.fev1Value);
    setSpo2High(payload.spo2High);
    setSpo2Low(payload.spo2Low);
    setSpo2(payload.spo2Value);
    setSugarHigh(payload.sugarHigh);
    setSugarLow(payload.sugarLow);
    setSugar(payload.sugarValue);
    setPainHigh(payload.painHigh);
    setPainLow(payload.painLow);
    setPain(payload.painValue);
    setWeightHigh(payload.weightHigh);
    setWeightLow(payload.weightLow);
    setWeight(payload.weightValue);
  }

  const setTrendStates = () => {
    setRrHigh(subjectThresholdsData.breathingRateHigh ? subjectThresholdsData.breathingRateHigh : '');
    setRrLow(subjectThresholdsData.breathingRateLow ? subjectThresholdsData.breathingRateLow : '');
    setRr(subjectThresholdsData.breathingRateValue ? subjectThresholdsData.breathingRateValue : '');
    setDbpHigh(subjectThresholdsData.dbpHigh ? subjectThresholdsData.dbpHigh : '');
    setDbpLow(subjectThresholdsData.dbpLow ? subjectThresholdsData.dbpLow : '');
    setDbp(subjectThresholdsData.dbpValue ? subjectThresholdsData.dbpValue : '');
    setHrHigh(subjectThresholdsData.heartRateHigh ? subjectThresholdsData.heartRateHigh : '');
    setHrLow(subjectThresholdsData.heartRateLow ? subjectThresholdsData.heartRateLow : '');
    setHr(subjectThresholdsData.heartRateValue ? subjectThresholdsData.heartRateValue : '');
    setTempHigh(subjectThresholdsData.tempHigh ? subjectThresholdsData.tempHigh : '');
    setTempLow(subjectThresholdsData.tempLow ? subjectThresholdsData.tempLow : '');
    setTemp(subjectThresholdsData.tempValue ? subjectThresholdsData.tempValue : '');
    setSbpHigh(subjectThresholdsData.sbpHigh ? subjectThresholdsData.sbpHigh : '');
    setSbpLow(subjectThresholdsData.sbpLow ? subjectThresholdsData.sbpLow : '');
    setSbp(subjectThresholdsData.sbpValue ? subjectThresholdsData.sbpValue : '');
    setPrHigh(subjectThresholdsData.pulseRateHigh ? subjectThresholdsData.pulseRateHigh : '');
    setPrLow(subjectThresholdsData.pulseRateLow ? subjectThresholdsData.pulseRateLow : '');
    setPr(subjectThresholdsData.pulseRateValue ? subjectThresholdsData.pulseRateValue : '');
    setPefHigh(subjectThresholdsData.pefHigh ? subjectThresholdsData.pefHigh : '');
    setPefLow(subjectThresholdsData.pefLow ? subjectThresholdsData.pefLow : '');
    setPef(subjectThresholdsData.pefValue ? subjectThresholdsData.pefValue : '');
    setHrvHigh(subjectThresholdsData.hrvHigh ? subjectThresholdsData.hrvHigh : '');
    setHrvLow(subjectThresholdsData.hrvLow ? subjectThresholdsData.hrvLow : '');
    setHrv(subjectThresholdsData.hrvValue ? subjectThresholdsData.hrvValue : '');
    setFev1High(subjectThresholdsData.fev1High ? subjectThresholdsData.fev1High : '');
    setFev1Low(subjectThresholdsData.fev1Low ? subjectThresholdsData.fev1Low : '');
    setFev1(subjectThresholdsData.fev1Value ? subjectThresholdsData.fev1Value : '');
    setSpo2High(subjectThresholdsData.spo2High ? subjectThresholdsData.spo2High : '');
    setSpo2Low(subjectThresholdsData.spo2Low ? subjectThresholdsData.spo2Low : '');
    setSpo2(subjectThresholdsData.spo2Value ? subjectThresholdsData.spo2Value : '');
    setSugarHigh(subjectThresholdsData.sugarHigh ? subjectThresholdsData.sugarHigh : '');
    setSugarLow(subjectThresholdsData.sugarLow ? subjectThresholdsData.sugarLow : '');
    setSugar(subjectThresholdsData.sugarValue ? subjectThresholdsData.sugarValue : '');
    setPainHigh(subjectThresholdsData.painHigh ? subjectThresholdsData.painHigh : '');
    setPainLow(![null, '', false].includes(subjectThresholdsData.painLow) ? subjectThresholdsData.painLow : '');
    setPain(![null, '', false].includes(subjectThresholdsData.painValue) ? subjectThresholdsData.painValue : '');
    setWeightHigh(subjectThresholdsData.weightHigh ? subjectThresholdsData.weightHigh : '');
    setWeightLow(subjectThresholdsData.weightLow ? subjectThresholdsData.weightLow : '');
    setWeight(subjectThresholdsData.weightValue ? subjectThresholdsData.weightValue : '');
  };

  const submitNewThreshold = (event) => {
    event.preventDefault();
    dispatch(setShowConfirmationBanner(true));
  };

  const sleep = ms => new Promise(r => setTimeout(r, ms));

  const submitNewThresholdFinal = async () => {
    let payload = {
      subjectId: curPatientSubjectId,
      userId: userProfile.id,
      creationDate: new Date(),
      breathingRateHigh: rrHigh,
      breathingRateLow: rrLow,
      breathingRateValue: rr,
      dbpHigh: dbpHigh,
      dbpLow: dbpLow,
      dbpValue: dbp,
      heartRateHigh: hrHigh,
      heartRateLow: hrLow,
      heartRateValue: hr,
      tempHigh: tempHigh,
      tempLow: tempLow,
      tempValue: temp,
      sbpHigh: sbpHigh,
      sbpLow: sbpLow,
      sbpValue: sbp,
      pulseRateHigh: prHigh,
      pulseRateLow: prLow,
      pulseRateValue: pr,
      pefHigh: pefHigh,
      pefLow: pefLow,
      pefValue: pef,
      hrvHigh: hrvHigh,
      hrvLow: hrvLow,
      hrvValue: hrv,
      fev1High: fev1High,
      fev1Low: fev1Low,
      fev1Value: fev1,
      spo2High: spo2High,
      spo2Low: spo2Low,
      spo2Value: spo2,
      sugarHigh: sugarHigh,
      sugarLow: sugarLow,
      sugarValue: sugar,
      painLow: painLow,
      painHigh: painHigh,
      painValue: pain,
      weightHigh: weightHigh,
      weightLow: weightLow,
      weightValue: weight,
    };
    // console.log("submitNewThreshold payload: ", payload);

    dispatch(setNewThreshold(payload));
    dispatch(setShowThresholdsLoader(true));
    await sleep(3000);
    dispatch(getLatestBaseline({ subjectGuid: currentPatientGUID }));
  }

  useEffect(() => {
    setTrendStates();
  }, []);

  const userRole = useSelector(getUserRole);
  const disableAllEdits = (userRole === globalConstants.USER_ROLE_CARE_MANAGER);

  // useEffect(() => {
  //   dispatch(setShowThresholdsLoader(true));
  //   dispatch(getLatestBaseline({ subjectGuid: currentPatientGUID }));
  // }, [currentPatientGUID]);

  const handleFocus = (event) => event.target.select();

  const TempIconDiv = () => (!disableAllEdits &&
    (<div className={!isSbpEditable || !isDbpEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconTempClicked ? <div className={styles.editButton} onClick={toggleTempIcon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : (<div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon style={{ height: "15px", width: "15px" }} onClick={toggleTempIcon} /></div>)}
      </HtmlTooltip>
    </div>)
  );
  const SbpIconDiv = () => (!disableAllEdits &&
    (<div className={!isTempEditable || !isDbpEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconSbpClicked ? <div className={styles.editButton} onClick={toggleSbpIcon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : <div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon style={{ height: "15px", width: "15px" }} onClick={toggleSbpIcon} /></div>}
      </HtmlTooltip>
    </div>)
  );
  const DbpIconDiv = () => (!disableAllEdits &&
    (<div className={!isSbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconDbpClicked ? <div className={styles.editButton} onClick={toggleDbpIcon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : <div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon style={{ height: "15px", width: "15px" }} onClick={toggleDbpIcon} /></div>}
      </HtmlTooltip>
    </div>)
  );
  const PrIconDiv = () => (!disableAllEdits &&
    (<div className={!isSbpEditable || !isDbpEditable || !isTempEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconPrClicked ? <div className={styles.editButton} onClick={togglePrIcon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : <div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon style={{ height: "15px", width: "15px" }} onClick={togglePrIcon} /></div>}
      </HtmlTooltip>
    </div>)
  );
  const Spo2IconDiv = () => (!disableAllEdits &&
    (<div className={!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconSpo2Clicked ? <div className={styles.editButton} onClick={toggleSpo2Icon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : <div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon onClick={toggleSpo2Icon} style={{ height: "15px", width: "15px" }} /></div>}
      </HtmlTooltip>
    </div>)
  );
  const HrIconDiv = () => (!disableAllEdits &&
    (<div className={!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isHrvEditable || !isSpo2Editable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconHrClicked ? <div className={styles.editButton} onClick={toggleHrIcon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : <div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon onClick={toggleHrIcon} style={{ height: "15px", width: "15px" }} /></div>}
      </HtmlTooltip>
    </div>)
  );
  const HrvIconDiv = () => (!disableAllEdits &&
    (<div className={!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isHrEditable || !isSpo2Editable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconHrvClicked ? <div className={styles.editButton} onClick={toggleHrvIcon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : <div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon onClick={toggleHrvIcon} style={{ height: "15px", width: "15px" }} /></div>}
      </HtmlTooltip>
    </div>)
  );
  const RrIconDiv = () => (!disableAllEdits &&
    (<div className={!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconRrClicked ? <div className={styles.editButton} onClick={toggleRrIcon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : <div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon onClick={toggleRrIcon} style={{ height: "15px", width: "15px" }} /></div>}
      </HtmlTooltip>
    </div>)
  );
  const Fev1IconDiv = () => (!disableAllEdits &&
    (<div className={!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconFev1Clicked ? <div className={styles.editButton} onClick={toggleFev1Icon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : <div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon onClick={toggleFev1Icon} style={{ height: "15px", width: "15px" }} /></div>}
      </HtmlTooltip>
    </div>)
  );
  const PefIconDiv = () => (!disableAllEdits &&
    (<div className={!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isSugarEditable || !isPainEditable || !isWeightEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconPefClicked ? <div className={styles.editButton} onClick={togglePefIcon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : <div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon onClick={togglePefIcon} style={{ height: "15px", width: "15px" }} /></div>}
      </HtmlTooltip>
    </div>)
  );
  const SugarIconDiv = () => (!disableAllEdits &&
    (<div className={!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isPainEditable || !isWeightEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconSugarClicked ? <div className={styles.editButton} onClick={toggleSugarIcon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : <div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon onClick={toggleSugarIcon} style={{ height: "15px", width: "15px" }} /></div>}
      </HtmlTooltip>
    </div>)
  );
  const PainIconDiv = () => (!disableAllEdits &&
    (<div className={!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isWeightEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconPainClicked ? <div className={styles.editButton} onClick={togglePainIcon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : <div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon onClick={togglePainIcon} style={{ height: "15px", width: "15px" }} /></div>}
      </HtmlTooltip>
    </div>)
  );
  const WeightIconDiv = () => (!disableAllEdits &&
    (<div className={!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable ? styles.iconEditDisabled : styles.iconEdit}
    >
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={styles.tootlTipHeading}>Edit</div>
          </React.Fragment>
        }
      >
        {showConfrimationBanner ? (<></>) : iconWeightClicked ? <div className={styles.editButton} onClick={toggleWeightIcon} ><EditIcon style={{ height: "15px", width: "15px" }} ></EditIcon></div> : <div className={styles.editOptions}><CheckIcon onClick={event => submitNewThreshold(event)} style={{ height: "15px", width: "15px" }} /><ClearIcon onClick={toggleWeightIcon} style={{ height: "15px", width: "15px" }} /></div>}
      </HtmlTooltip>
    </div>)
  );

  const setAllIconClicked = (val) => {
    // console.log('setAllIconClicked clicked');
    setIconTempClicked(val);
    setIconSbpClicked(val);
    setIconDbpClicked(val);
    setIconPrClicked(val);
    setIconSpo2Clicked(val);
    setIconHrClicked(val);
    setIconHrvClicked(val);
    setIconRrClicked(val);
    setIconFev1Clicked(val);
    setIconPefClicked(val);
    setIconSugarClicked(val);
    setIconPainClicked(val);
    setIconWeightClicked(val);
  };

  useImperativeHandle(ref, () => {
    return {
      setIconTempClicked: setIconTempClicked,
      submitNewThresholdFinal: submitNewThresholdFinal,
      setAllIconClicked: setAllIconClicked,
      setTrendStates: setTrendStates,
      setNewTrendsWPaylaod: setNewTrendsWPaylaod,
    };
  });

  return (
    <div>
      {subjectThresholdsData && (
        <>
          {loader ? (
            <div className={styles.circularProgressDiv} style={{ backgroundColor: `#FFFFFF`, height: `100%` }}>
              <CircularProgress thickness={5.0} style={{ color: `#4E253A` }}></CircularProgress>
            </div>
          ) : (
            <table className={styles.valuesTable}>
              <tbody key="01">
                <tr className={(!isSbpEditable || !isDbpEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>Temperature (F)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={tempLow} onChange={(e) => setTempLow(e.currentTarget.value)} className={isTempEditable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isTempEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}> <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon> </div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={temp} onChange={(e) => setTemp(e.currentTarget.value)} className={isTempEditable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isTempEditable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={tempHigh} onChange={(e) => setTempHigh(e.currentTarget.value)} className={isTempEditable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isTempEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}> {!isSbpEditable || !isDbpEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? null : <TempIconDiv />}
                  </td>
                </tr>
                {/* Systolic BP */}
                <tr className={(!isTempEditable || !isDbpEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>Systolic BP (mmHg)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={sbpLow} onChange={(e) => setSbpLow(e.currentTarget.value)} className={isSbpEditable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isSbpEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}> <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon> </div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={sbp} onChange={(e) => setSbp(e.currentTarget.value)} className={isSbpEditable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isSbpEditable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={sbpHigh} onChange={(e) => setSbpHigh(e.currentTarget.value)} className={isSbpEditable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isSbpEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}> {!isTempEditable || !isDbpEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? null : <SbpIconDiv />}
                  </td>
                </tr>
                {/* Diastolic BP */}
                <tr className={(!isSbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>Diastolic BP (mmHg)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={dbpLow} onChange={(e) => setDbpLow(e.currentTarget.value)} className={isDbpEditable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isDbpEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}><KeyboardArrowLeftIcon></KeyboardArrowLeftIcon></div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={dbp} onChange={(e) => setDbp(e.currentTarget.value)} className={isDbpEditable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isDbpEditable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={dbpHigh} onChange={(e) => setDbpHigh(e.currentTarget.value)} className={isDbpEditable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isDbpEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}> {!isSbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? null : <DbpIconDiv />}
                  </td>
                </tr>
                {/* Pulse Rate */}
                <tr className={(!isSbpEditable || !isDbpEditable || !isTempEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>Pulse Rate (bpm)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={prLow} onChange={(e) => setPrLow(e.currentTarget.value)} className={isPrEditable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isPrEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}><KeyboardArrowLeftIcon></KeyboardArrowLeftIcon></div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={pr} onChange={(e) => setPr(e.currentTarget.value)} className={isPrEditable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isPrEditable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={prHigh} onChange={(e) => setPrHigh(e.currentTarget.value)} className={isPrEditable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isPrEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}> {!isSbpEditable || !isDbpEditable || !isTempEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? null : <PrIconDiv />}
                  </td>
                </tr>
                {/* SpO2 */}
                <tr className={(!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>SpO2 (%)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={spo2Low} onChange={(e) => setSpo2Low(e.currentTarget.value)} className={isSpo2Editable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isSpo2Editable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}><KeyboardArrowLeftIcon></KeyboardArrowLeftIcon></div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={spo2} onChange={(e) => setSpo2(e.currentTarget.value)} className={isSpo2Editable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isSpo2Editable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={spo2High} onChange={(e) => setSpo2High(e.currentTarget.value)} className={isSpo2Editable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isSpo2Editable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}> {!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? null : <Spo2IconDiv />}
                  </td>
                </tr>
                {/* Heart Rate */}
                <tr className={(!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>Heart Rate (bpm)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={hrLow} onChange={(e) => setHrLow(e.currentTarget.value)} className={isHrEditable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isHrEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}><KeyboardArrowLeftIcon></KeyboardArrowLeftIcon></div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={hr} onChange={(e) => setHr(e.currentTarget.value)} className={isHrEditable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isHrEditable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={hrHigh} onChange={(e) => setHrHigh(e.currentTarget.value)} className={isHrEditable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isHrEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}>
                    {!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? null : <HrIconDiv />}
                  </td>
                </tr>
                {/* Heart Rate Variability */}
                <tr className={(!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isHrEditable || !isSpo2Editable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>Heart Rate Variability (ms)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={hrvLow} onChange={(e) => setHrvLow(e.currentTarget.value)} className={isHrvEditable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isHrvEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}><KeyboardArrowLeftIcon></KeyboardArrowLeftIcon></div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={hrv} onChange={(e) => setHrv(e.currentTarget.value)} className={isHrvEditable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isHrvEditable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={hrvHigh} onChange={(e) => setHrvHigh(e.currentTarget.value)} className={isHrvEditable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isHrvEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}>
                    {!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isHrEditable || !isSpo2Editable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? null : <HrvIconDiv />}
                  </td>
                </tr>
                {/* Resp. Rate */}
                <tr className={(!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>Resp. Rate (br/min)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={rrLow} onChange={(e) => setRrLow(e.currentTarget.value)} className={isRrEditable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isRrEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}><KeyboardArrowLeftIcon></KeyboardArrowLeftIcon></div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={rr} onChange={(e) => setRr(e.currentTarget.value)} className={isRrEditable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isRrEditable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={rrHigh} onChange={(e) => setRrHigh(e.currentTarget.value)} className={isRrEditable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isRrEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}>
                    {!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? null : <RrIconDiv />}
                  </td>
                </tr>
                {/* FEV1 */}
                <tr className={(!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>FEV1 (L)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={fev1Low} onChange={(e) => setFev1Low(e.currentTarget.value)} className={isFev1Editable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isFev1Editable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}><KeyboardArrowLeftIcon></KeyboardArrowLeftIcon></div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={fev1} onChange={(e) => setFev1(e.currentTarget.value)} className={isFev1Editable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isFev1Editable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={fev1High} onChange={(e) => setFev1High(e.currentTarget.value)} className={isFev1Editable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isFev1Editable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}>
                    {!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isPefEditable || !isSugarEditable || !isPainEditable || !isWeightEditable ? null : <Fev1IconDiv />}
                  </td>
                </tr>
                {/* PEF */}
                <tr className={(!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isSugarEditable || !isPainEditable || !isWeightEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>PEF (L/sec)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={pefLow} onChange={(e) => setPefLow(e.currentTarget.value)} className={isPefEditable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isPefEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}><KeyboardArrowLeftIcon></KeyboardArrowLeftIcon></div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={pef} onChange={(e) => setPef(e.currentTarget.value)} className={isPefEditable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isPefEditable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={pefHigh} onChange={(e) => setPefHigh(e.currentTarget.value)} className={isPefEditable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isPefEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}>
                    {!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isSugarEditable || !isPainEditable || !isWeightEditable ? null : <PefIconDiv />}
                  </td>
                </tr>
                {/* Blood Sugar */}
                <tr className={(!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isPainEditable || !isWeightEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>Blood Sugar (mg/dL)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={sugarLow} onChange={(e) => setSugarLow(e.currentTarget.value)} className={isSugarEditable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isSugarEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}><KeyboardArrowLeftIcon></KeyboardArrowLeftIcon></div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={sugar} onChange={(e) => setSugar(e.currentTarget.value)} className={isSugarEditable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isSugarEditable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={sugarHigh} onChange={(e) => setSugarHigh(e.currentTarget.value)} className={isSugarEditable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isSugarEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}>
                    {!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isPainEditable || !isWeightEditable ? null : <SugarIconDiv />}
                  </td>
                </tr>
                {/* Pain Level */}
                <tr className={(!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isWeightEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>Pain Level (0-10)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={painLow} onChange={(e) => setPainLow(e.currentTarget.value)} className={isPainEditable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isPainEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}><KeyboardArrowLeftIcon></KeyboardArrowLeftIcon></div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={pain} onChange={(e) => setPain(e.currentTarget.value)} className={isPainEditable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isPainEditable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={painHigh} onChange={(e) => setPainHigh(e.currentTarget.value)} className={isPainEditable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isPainEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}>
                    {!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isWeightEditable ? null : <PainIconDiv />}
                  </td>
                </tr>
                {/* Weight  */}
                <tr className={(!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable) && isAllEditable ? styles.rowWrapperDisabled : styles.rowWrapper}>
                  <td className={styles.param}>Weight (Lbs)</td>
                  <td className={styles.paramValue}>
                    <div>
                      <input type="number" value={weightLow} onChange={(e) => setWeightLow(e.currentTarget.value)} className={isWeightEditable ? styles.inputLowValue : styles.inputLowValueFocused}
                        disabled={isWeightEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                    <div className={styles.lowSign}><KeyboardArrowLeftIcon></KeyboardArrowLeftIcon></div>
                  </td>
                  <td className={styles.baselineWrapper}>
                    <input type="number" value={weight} onChange={(e) => setWeight(e.currentTarget.value)} className={isWeightEditable ? styles.inputValue : styles.inputValueFocused}
                      disabled={isWeightEditable} onFocus={(e) => handleFocus(e)} />
                  </td>
                  <td className={styles.paramValue}>
                    <div className={styles.highSign}> <KeyboardArrowRightIcon /> </div>
                    <div>
                      <input type="number" value={weightHigh} onChange={(e) => setWeightHigh(e.currentTarget.value)} className={isWeightEditable ? styles.inputHighValue : styles.inputHighValueFocused}
                        disabled={isWeightEditable} onFocus={(e) => handleFocus(e)} />
                    </div>
                  </td>
                  <td className={styles.iconTd}>
                    {!isSbpEditable || !isDbpEditable || !isTempEditable || !isPrEditable || !isSpo2Editable || !isHrEditable || !isHrvEditable || !isRrEditable || !isFev1Editable || !isPefEditable || !isSugarEditable || !isPainEditable ? null : <WeightIconDiv />}
                  </td>
                </tr>

              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
});

export default ThresholdsParams;
