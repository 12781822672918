import { createAction } from "@reduxjs/toolkit";
import { utilsConstants } from "../Constants/UtilConstants";

export const setShowLoader = createAction(
  utilsConstants.SET_SHOW_LOADER,
  (payload) => ({ payload })
);

export const setCurrentPatientGUID = createAction(
  utilsConstants.SET_CURRENT_PATIENT_GUID,
  (payload) => ({ payload })
);

export const setCurrentPatientSubjectID = createAction(
  utilsConstants.SET_CURRENT_PATIENT_SUBJECTID,
  (payload) => ({ payload })
);

export const setAllPatientsList = createAction(
  utilsConstants.SET_ALL_PATIENTS_LIST,
  (payload) => ({ payload })
);

export const setShowPatientProfilePageLoader = createAction(
  utilsConstants.SET_SHOW_PATIENT_PROFILE_PAGE_LOADER,
  (payload) => ({ payload })
);

export const setShowPatientTimerDrawerLoader = createAction(
  utilsConstants.SET_SHOW_PATIENT_TIMER_DRAWER_LOADER,
  (payload) => ({ payload })
);

export const setShowPatientGraphsLoader = createAction(
  utilsConstants.SET_SHOW_PATIENT_GRAPHS_LOADER,
  (payload) => ({ payload })
);

export const setShowPatientVitalsLoader = createAction(
  utilsConstants.SET_SHOW_PATIENT_VITALS_LOADER,
  (payload) => ({ payload })
);

export const setShowPatientNotesLoader = createAction(
  utilsConstants.SET_SHOW_PATIENT_NOTES_LOADER,
  (payload) => ({ payload })
);

export const setShowPatientAidiLoader = createAction(
  utilsConstants.SET_SHOW_PATIENT_AIDI_LOADER,
  (payload) => ({ payload })
);

export const setShowAidiValidationLoader = createAction(
  utilsConstants.SET_SHOW_AIDI_VALIDATION_LOADER,
  (payload) => ({ payload })
);

export const setShowAssignMlLoader = createAction(
  utilsConstants.SET_SHOW_ASSIGN_ML_LOADER,
  (payload) => ({ payload })
);

export const setShowThresholdsLoader = createAction(
  utilsConstants.SET_SHOW_THRESHOLDS_LOADER,
  (payload) => ({ payload })
);

export const setShowPatientQuickViewLoader = createAction(
  utilsConstants.SET_SHOW_QUICKVIEW_LOADER,
  (payload) => ({ payload })
);

export const setPatientMainTab = createAction(
  utilsConstants.SET_PATIENT_MAINTAB,
  (payload) => ({ payload })
);

export const setSpo2BrExpand = createAction(
  utilsConstants.SET_SPO2_BR_EXPAND,
  (payload) => ({ payload })
);

export const setHrHrvExpand = createAction(
  utilsConstants.SET_HR_HRV_EXPAND,
  (payload) => ({ payload })
);

export const setBpExpand = createAction(
  utilsConstants.SET_BP_EXPAND,
  (payload) => ({ payload })
);

export const setTempPrExpand = createAction(
  utilsConstants.SET_TEMP_PR_EXPAND,
  (payload) => ({ payload })
);

export const setFevPefExpand = createAction(
  utilsConstants.SET_FEV_PEF_EXPAND,
  (payload) => ({ payload })
);

export const setGWPExpand = createAction(
  utilsConstants.SET_G_W_P_EXPAND,
  (payload) => ({ payload })
);

export const setRegistrationStatus = createAction(
  utilsConstants.SET_REGISTRATION_STATUS,
  (payload) => ({ payload })
);

export const setRegistrationStep = createAction(
  utilsConstants.SET_REGISTRATION_STEP,
  (payload) => ({ payload })
);

export const setPRGeneralInfo = createAction(
  utilsConstants.SET_PR_GENERAL_INFO,
  (payload) => ({ payload })
);

export const setPRPayerInfo = createAction(
  utilsConstants.SET_PR_PAYER_INFO,
  (payload) => ({ payload })
);

export const setPRFamilyInfo = createAction(
  utilsConstants.SET_PR_FAMILY_INFO,
  (payload) => ({ payload })
);

export const setPRCareTeamInfo = createAction(
  utilsConstants.SET_PR_CARE_TEAM_INFO,
  (payload) => ({ payload })
);

export const setPRFacilityInfo = createAction(
  utilsConstants.SET_PR_FACILITY_INFO,
  (payload) => ({ payload })
);

export const setShowPRLoader = createAction(
  utilsConstants.SET_SHOW_PR_LOADER,
  (payload) => ({ payload })
);

export const setPRSuccess = createAction(
  utilsConstants.SET_PR_SUCCESS,
  (payload) => ({ payload })
);

export const setPRMessage = createAction(
  utilsConstants.SET_PR_MESSAGE,
  (payload) => ({ payload })
);

export const setShowEmailExistsLoader = createAction(
  utilsConstants.SET_SHOW_EMAIL_EXISTS_LOADER,
  (payload) => ({ payload })
);

export const setShowTrendsLoader = createAction(
  utilsConstants.SET_SHOW_TRENDS_LOADER,
  (payload) => ({ payload })
);

export const setShowDiagnosesLoader = createAction(
  utilsConstants.SET_SHOW_DIAGNOSES_LOADER,
  (payload) => ({ payload })
);

export const setShowReportsLoader = createAction(
  utilsConstants.SET_SHOW_REPORTS_LOADER,
  (payload) => ({ payload })
);

export const setShowGeneralInfoLoader = createAction(
  utilsConstants.SET_SHOW_GENERAL_INFO_LOADER,
  (payload) => ({ payload })
);

export const setShowPayerInfoLoader = createAction(
  utilsConstants.SET_SHOW_PAYER_INFO_LOADER,
  (payload) => ({ payload })
);

export const setShowFacilityInfoLoader = createAction(
  utilsConstants.SET_SHOW_FACILITY_INFO_LOADER,
  (payload) => ({ payload })
);

export const setShowFamilyInfoLoader = createAction(
  utilsConstants.SET_SHOW_FAMILY_INFO_LOADER,
  (payload) => ({ payload })
);

export const setShowCareteamInfoLoader = createAction(
  utilsConstants.SET_SHOW_CARETEAM_INFO_LOADER,
  (payload) => ({ payload })
);

export const setReportsApplyStatus = createAction(
  utilsConstants.SET_REPORTS_APPLY_STATUS,
  (payload) => ({ payload })
);