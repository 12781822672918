import React, { useEffect, useState } from "react";
import styles from "./DeviceItem.module.css";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import IconButton from "@material-ui/core/IconButton";
import AssignMouthlab from "../Actions/AssignMouthlab";
import UnassignMl from "../../../HomePage/DeviceLog/MouthLabLogs/MLActions/UnassignMl";
import GetSubjectDevices from "../../../../API/GetSubjectDevices/GetSubjectDevices";

export default function DeviceItem(props) {

  // Modal Menu Controls
  const [assignmentModal, setAssignmentModal] = useState();
  const [removeDeviceModal, setRemoveDeviceModal] = useState();

  useEffect(()=> {
    if(!props.deviceName){
      // console
      // GetSubjectDevices(props.subjectId).then((res) => {
      //   if(res.deviceId === "") {
      //     res.deviceId = props.noDevice.deviceId;
      //     res.deviceType = props.noDevice.deviceType;
      //     res.usesPerDay = props.noDevice.usesPerDay;
      //   } 
      //   props.setSubjectDetailsResponse(res);
      //   console.log("Subject Details....", res);
      // });
    }
  },[])

  const openAssignMenu = () => {
    setAssignmentModal(true);
  };
  const closeAssignMenu = () => {
    setAssignmentModal(false);
  };
  const openUnassignMenu = () => {
    setRemoveDeviceModal(true);
  };
  const closeUnassignMenu = () => {
    setRemoveDeviceModal(false);
  };

  return (
    <div className={styles.deviceItemContainer}>
      <div className={styles.itemCellContainer}>
        <div className={styles.itemCellHeader}>Device Name</div>
        <div className={styles.itemCellElement}>
          {props.deviceId === "None" || props.deviceId === "" || props.deviceId === "Undefined" ? "--" : `${props.deviceName}`}
        </div>
      </div>
      <div className={styles.itemCellContainer}>
        <div className={styles.itemCellHeader}>Usage per day</div>
        <div className={styles.itemCellElement}>
          {props.deviceId === "None" || props.deviceId === "" || props.deviceId === "Undefined" ? "--" : `${props.usage} measurements`}
        </div>
      </div>
      <div className={styles.itemCellContainer}>
        <div className={styles.itemCellHeader}>Device ID</div>
        <div className={styles.itemCellElement}>{props.deviceId}</div>
      </div>
      <div className={styles.actionsContainer}>
        {/* <div className={styles.itemCellHeader}></div> */}
        <div className={styles.actionButtonContainer}>
          {props.deviceId === "None" || props.deviceId === "" || props.deviceId === "Undefined" ? (
            <button
              type="button"
              className={styles.assignButton}
              onClick={openAssignMenu}
            >
              Assign Now
            </button>
          ) : (
            <button
              type="button"
              className={styles.removeButton}
              onClick={openUnassignMenu}
            >
              Unassign
            </button>
          )}
        </div>
      </div>

      {/* MouthLab Assignment */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={assignmentModal}
        onClose={closeAssignMenu}
      >
        <div className={styles.paper}>
          <div className={styles.menuTitle}>
            <div className={styles.titleText}>Assign MouthLab</div>
            <div>
              <IconButton aria-label="delete">
                <RiCloseCircleFill size={29} onClick={closeAssignMenu} style={{color: "#4E253A"}} />
              </IconButton>
            </div>
          </div>

          <div className={styles.patientListDiv}>
            <Grid container>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                 <AssignMouthlab
                  currentSubjectID={props.subjectId}
                  closeModal={closeAssignMenu}
                /> 
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>

      {/* MouthLab Unassignment */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={removeDeviceModal}
        onClose={closeUnassignMenu}
      >
        <div className={styles.paper}>
          <div className={styles.menuTitle}>
            <div className={styles.titleText}>Unassign MouthLab</div>
            <div>
              <IconButton aria-label="delete">
                <RiCloseCircleFill size={25} onClick={closeUnassignMenu} />
              </IconButton>
            </div>
          </div>

          <div className={styles.patientListDiv}>
            <Grid container>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <UnassignMl
                  currentSubjectId={props.subjectId}
                  currentDeviceId={props.deviceId}
                  closeModal={closeUnassignMenu}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
}
