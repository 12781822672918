import React from "react";
import { Field, ErrorMessage } from "formik";
import styles from "./Select.module.css";
import InputErrorMessage from "../InputError/InputErrorMessage";

export default function Select(props) {
  const { label, name, options, value, ...rest } = props;

  const items = options.map((option) => {
    return (
      <option
        key={option.value}
        value={option.value}
        className={styles.dropDownItems}
      >
        {option.key}
      </option>
    );
  });

  return (
    <div>
      <div>
        <label htmlFor={name} className={styles.labelDivStyles}>
          <div className={styles.labelStyles}>{label}</div>
        </label>
        <Field
          as="select"
          id={name}
          name={name}
          value={value.name}
          {...rest}
          className={styles.selectDropdown}
        >
          {/* {options.map((option) => {
          return (
            <option
              key={option.value}
              value={option.value}
              className={styles.dropDownItems}
            >
              {option.key}
            </option>
          );
        })} */}
          <option key={""} value={""}>
            Select
          </option>
          {items}
        </Field>

        <ErrorMessage name={name} component={InputErrorMessage} />
      </div>
    </div>
  );
}
