import React, { useState, createContext } from "react";
import subDays from "date-fns/subDays";

export const DateContext = createContext();

export const DateProvider = ({ children }) => {
  const [fromDate, setFromDate] = useState(subDays(new Date(), 30));
  const [toDate, setToDate] = useState(new Date());

  return (
    <DateContext.Provider
      value={{
        fromDate,
        setFromDate,
        toDate,
        setToDate,
      }}
    >
      {children}
    </DateContext.Provider>
  );
};
