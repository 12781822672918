import axios from "axios";
import domainCF from "../domainCF.js";
import { useDispatch } from "react-redux";
import { setShowPatientProfilePageLoader } from "../../../Actions/UtilActions.js";

export default function GetPatientInfo(patientGuid) {

  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const GET_PATIENT_INFO = `${domainCF}/api/getSubjectByGuid?subjectGuid=${patientGuid}`;

  return axios.get(GET_PATIENT_INFO, { headers }).then(function (response) {
    //console.log("GetPatientInfo.. response: ", response.data.subjects[0]);
    return response.data.subjects[0];
  });
}
