import React, { useContext } from "react";
import { Card, CardContent, Typography, CardMedia } from "@material-ui/core";
import CardActionArea from "@mui/material/CardActionArea";
import { UserContext } from "../../../Store/UserContext.js";
import styles from "./ProjectLocation.module.css";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector.js";
import Divider from "@mui/material/Divider";
import Header from "../Header/Header";
import defaultImg from "../../../../Assets/Images/default.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { setProjectIdAndLocationId, resetProjectIdAndLocationId, setSelectedPageName, setProjectName, setLocationName } from "../../../../Actions/DashboardActions";

function ChooseProjectLocation() {
  const dispatch = useDispatch();
  const profile = useSelector(getUserProfile);
  let navigate = useNavigate();
  const { setUserInformation } = useContext(UserContext);

  useEffect(() => {
    if (profile && profile.email) {
      setUserInformation({
        email: profile.email,
        firstName: profile.firstName,
        lastName: profile.lastName,
        institutionId: profile.institutionId,
        projects: profile.projects,
        locations: profile.locations,
        selectedProjectId: "",
        selectedLocationId: "",
      });
    }
  }, [profile]);
  
  useEffect(() => {
    dispatch(resetProjectIdAndLocationId());
    dispatch(setSelectedPageName('projects'))
  }, []);

  if (profile && !profile.email && !profile.projects) {
    return (
      <div className={styles.circularProgress}>
        <CircularProgress thickness={5.0} style={{ color: `#4E253A`}} />
      </div>
    );
  }

  const selectProject = (pId, lId, pName, lName) => {
    dispatch(setProjectName(pName));
    dispatch(setLocationName(lName));
    dispatch(
      setProjectIdAndLocationId({
        projectId: pId,
        locationId: lId,
      })
    );
    navigate("/subjects/" + pId + "/" + lId, {
      state: {
        userEmail: profile.email,
        userFirstName: profile.firstName,
        userLastName: profile.lastName,
      },
    });
  };
  return (
    <div>
      <Header userEmail={profile.email} />
      <div className={styles.wrapper}>
        {profile && !profile.projects && profile.email && (
          <div className={styles.containerForMessage}>
            <div className={styles.msgNoIPL}>
              You are yet to be assigned to a Project. <br />
              For further assistance, please call <span className={styles.phoneNum}>+1(443)875-6456</span>{" "}
            </div>
          </div>
        )}
        {profile.projects &&
          profile.projects.length &&
          profile.projects.map((prj) => {
            let image = defaultImg;
            return (
              <div className={styles.cardWrapper} key={prj.id}>
                <Card raised={true}>
                  <CardMedia component="img" height="170" image={image} alt={prj.key} />
                  <CardContent>
                    <Typography variant="h4" style={{ fontFamily: "GTWalsheimProRegular" }} align="center">
                      {prj.name}
                    </Typography>
                    {/* <Typography variant="body1">describes the study</Typography> */}
                  </CardContent>
                  <Divider variant="middle" />
                  {prj.locations.map((loc) => {
                    return (
                      <CardActionArea
                        className={styles.locations}
                        key={loc.id}
                        onClick={
                          () => selectProject(prj.id, loc.id, prj.name, loc.name)
                          //   dispatchEvent(setProjectIdAndLocationid);
                          //   navigate('/subjects/'+parseInt(`${prj.id}`)+'/'+parseInt(`${loc.id}`),
                          //       {state: {userEmail: profile.email,
                          //         userFirstName: userContext.userInformation.firstName,
                          //         userLastName: userContext.userInformation.lastName}}
                          //   );
                        }
                      >
                        <Typography style={{ fontFamily: "GTWalsheimProRegular" }} className={styles.locText}>
                          {loc.name}
                        </Typography>
                      </CardActionArea>
                    );
                  })}
                </Card>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default ChooseProjectLocation;
