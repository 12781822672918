export function getPageLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.loader
    )
        return utilReducer.loader;
    else return false;
}

export function getCurrentPatientGUID({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.currPatientGUID
    )
        return utilReducer.currPatientGUID;
    else return "";
}

export function getCurrentPatientSubjectID({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.currPatientSubjectID
    )
        return utilReducer.currPatientSubjectID;
    else return "";
}

export function getAllPatientsList({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.allPatientsList
    )
        return utilReducer.allPatientsList;
    else return [];
}

export function getPatientProfilePageLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientProfilePageLoader
    )
        return utilReducer.patientProfilePageLoader;
    else return false;
}

export function getPatientTimerDrawerLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientTimerDrawerLoader
    )
        return utilReducer.patientTimerDrawerLoader;
    else return false;
}

export function getPatientGraphsLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientGraphsLoader
    )
        return utilReducer.patientGraphsLoader;
    else return false;
}

export function getPatientAidiLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientAidiLoader
    )
        return utilReducer.patientAidiLoader;
    else return false;
}

export function getAidiValidationLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.aidiValidationLoader
    )
        return utilReducer.aidiValidationLoader;
    else return false;
}

export function getPatientVitalsLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientVitalsLoader
    )
        return utilReducer.patientVitalsLoader;
    else return false;
}

export function getPatientNotesLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientNotesLoader
    )
        return utilReducer.patientNotesLoader;
    else return false;
}

export function getAssignMlLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.assignMlLoader
    )
        return utilReducer.assignMlLoader;
    else return false;
}

export function getThresholdsLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.thresholdsLoader
    )
        return utilReducer.thresholdsLoader;
    else return false;
}

export function getPRLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.prLoader
    )
        return utilReducer.prLoader;
    else return false;
}

export function getPatientQuickViewLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientQuickViewLoader
    )
        return utilReducer.patientQuickViewLoader;
    else return false;
}

export function getEmailExistsLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.emailExistsLoader
    )
        return utilReducer.emailExistsLoader;
    else return false;
}

export function getTrendsLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.trendsLoader
    )
        return utilReducer.trendsLoader;
    else return false;
}

export function getDiagnosesLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.diagnosesLoader
    )
        return utilReducer.diagnosesLoader;
    else return false;
}

export function getReportsLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.reportsLoader
    )
        return utilReducer.reportsLoader;
    else return false;
}

export function getGeneralInfoLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.generalInfoLoader
    )
        return utilReducer.generalInfoLoader;
    else return false;
}

export function getPayerInfoLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.payerInfoLoader
    )
        return utilReducer.payerInfoLoader;
    else return false;
}

export function getFacilityInfoLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.facilityInfoLoader
    )
        return utilReducer.facilityInfoLoader;
    else return false;
}

export function getFamilyInfoLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.familyInfoLoader
    )
        return utilReducer.familyInfoLoader;
    else return false;
}

export function getCareteamInfoLoader({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.careteamInfoLoader
    )
        return utilReducer.careteamInfoLoader;
    else return false;
}

export function getReportsApplyStatus({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.reportsApplyStatus
    )
        return utilReducer.reportsApplyStatus;
    else return false;
}

export function getPatientMainTab({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.patientMainTab
    )
        return utilReducer.patientMainTab;
    else return 0;
}

export function getSpo2BrExpand({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.spo2BrExpand
    )
        return utilReducer.spo2BrExpand;
    else return false;
}

export function getHrHrvExpand({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.hrHrvExpand
    )
        return utilReducer.hrHrvExpand;
    else return false;
}

export function getBpExpand({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.bpExpand
    )
        return utilReducer.bpExpand;
    else return false;
}

export function getTempPrExpand({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.tempPrExpand
    )
        return utilReducer.tempPrExpand;
    else return false;
}

export function getFevPefExpand({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.fevPefExpand
    )
        return utilReducer.fevPefExpand;
    else return false;
}

export function getGWPExpand({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.gwpExpand
    )
        return utilReducer.gwpExpand;
    else return false;
}

export function getRegistrationStatus({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.regStatus
    )
        return utilReducer.regStatus;
    else return {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        review: false,
    };
}

export function getRegistrationStep({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.regStep
    )
        return utilReducer.regStep;
    else return "1";
}

export function getPRGeneralInfo({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.prGeneralInfo
    )
        return utilReducer.prGeneralInfo;
    else return {
        ehrId: '',
        prefix: '',
        firstName: '',
        middleName: '',
        suffix: '',
        lastName: '',
        prevName: '',
        dob: '',
        ssn: '',
        bioSex: '',
        genderIdentity: '',
        sexOrientation: '',
        phNum: '',
        email: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        dobObj: '',
    };
}

export function getPRPayerInfo({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.prPayerInfo
    )
        return utilReducer.prPayerInfo;
    else return {
        payerType: '',
        payerTypeOther: '',
        priPayerName: '',
        medicareBeneId: '',
        payerRel: '',
        medicaidBeneId: '',
        managedCareId: '',
        priPayerPh: '',
        edu: '',
        occupation: '',
        empStatus: '',
        religion: '',
        marStatus: '',
        ethnicity: '',
        smokeStatus: '',
        race: '',
        primLang: '',
        primCond: '',
        primProg: '',
        interpNeed: '',
        secCond: '',
        secProg: '',
        height: '',
        terCond: '',
        weight: '',
        otherCom: '',
    };
}

export function getPRFamilyInfo({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.prFamilyInfo
    )
        return utilReducer.prFamilyInfo;
    else return {
        prefixC1: '',
        firstNameC1: '',
        suffixC1: '',
        lastNameC1: '',
        relC1: '',
        bioSexC1: '',
        emailC1: '',
        phNumC1: '',
        conTypeC1: [],
        comModeC1: [],

        prefixC2: '',
        firstNameC2: '',
        suffixC2: '',
        lastNameC2: '',
        relC2: '',
        bioSexC2: '',
        emailC2: '',
        phNumC2: '',
        conTypeC2: [],
        comModeC2: [],

        prefixC3: '',
        firstNameC3: '',
        suffixC3: '',
        lastNameC3: '',
        relC3: '',
        bioSexC3: '',
        emailC3: '',
        phNumC3: '',
        conTypeC3: [],
        comModeC3: [],

        consentSel: '',
    };
}

export function getPRCareTeamInfo({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.prCareTeamInfo
    )
        return utilReducer.prCareTeamInfo;
    else return {
        prefixC1: '',
        firstNameC1: '',
        suffixC1: '',
        lastNameC1: '',
        specC1: '',
        profC1: '',
        npiC1: '',
        emailC1: '',
        phNumC1: '',
        comModeC1: [],

        prefixC2: '',
        firstNameC2: '',
        suffixC2: '',
        lastNameC2: '',
        specC2: '',
        profC2: '',
        npiC2: '',
        emailC2: '',
        phNumC2: '',
        comModeC2: [],

        prefixC3: '',
        firstNameC3: '',
        suffixC3: '',
        lastNameC3: '',
        specC3: '',
        profC3: '',
        npiC3: '',
        emailC3: '',
        phNumC3: '',
        comModeC3: [],
    };
}

export function getPRFacilityInfo({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.prFacilityInfo
    )
        return utilReducer.prFacilityInfo;
    else return {
        facilityName: '',
        facilityNum: '',
        facilityType: '',
        bedNum: '',
        actCode: '',
        unit: '',
        floor: '',
        roomNum: '',
        admDate: '',
        barcode: '',
        admDateObj: '',
    };
};

export function getPRSuccess({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.prSuccess
    )
        return utilReducer.prSuccess;
    else return false;
};

export function getPRMessage({ utilReducer }) {
    if (
        utilReducer &&
        utilReducer.prMessage
    )
        return utilReducer.prMessage;
    else return '';
};