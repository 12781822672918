import React from "react";
import styles from "./FAQsAidi.module.css";
import Divider from "@material-ui/core/Divider";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

export default function FAQsAC() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <div className={styles.faqTitle}>Aidar Connect</div>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel1" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={styles.question}>Do I need to be a qualified healthcare professional to access the platform?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>Yes, access to the platform shall be provided following the establishment of a contract with Aidar Health.</div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel2" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <div className={styles.question}>Is there an expiration for the email verification code generated during sign-up?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>The verification code generated during sign-up does not expire.</div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel3" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <div className={styles.question}>What steps should I take if I don't receive an email verification code?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            During sign up, you can request for the verification code to be resent to your entered email. If you still haven't received it, then please reach
            out to Aidar Health at +1(443)875-6456 or email us at contact@aidar.com.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel4" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <div className={styles.question}>What should I do if I entered the wrong email address during sign up?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            Sign up on the platform once again with the correct email address. Please inform Aidar Health of the correct email address to be authorized. You can
            reach us at +1(443)875-6456 or email us at contact@aidar.com.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel5" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <div className={styles.question}>What is the estimated time for user authorization after signing up?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>The user authorization process for the Aidar Connect Dashboard typically takes around 1 to 2 business days.</div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel6" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <div className={styles.question}>What is the recommended frequency for changing my password?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            Aidar suggests changing your password every 3-4 months and avoiding the reuse of any previous passwords for security purposes.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel7"} onChange={handleChange("panel7")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel7" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <div className={styles.question}>How should I proceed if I cannot find the relevant project location?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            If you are unable to locate the project(s) you are involved with, please reach out to Aidar Health at +1(443)875-6456 or email us at
            contact@aidar.com. Access will be granted only to the project(s) you are authorized to access by your institution/organization.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel8"} onChange={handleChange("panel8")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel8" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <div className={styles.question}>Does the platform have an automatic log-out feature?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            Yes, the Aidar Connect platform features an automatic log-out function. If there's no activity detected for over 10 minutes, it will log you out. A
            notification is given at the 8-minute mark as a warning before the log-out occurs due to inactivity.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel9"} onChange={handleChange("panel9")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel9" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <div className={styles.question}>Is there an option to include or invite team members to the portal?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            Currently, there isn't a direct feature allowing users to invite team members within the portal. Access to the Aidar Connect platform for team
            members requires notification to the Aidar personnel. Upon their sign-up, authorized users will be determined and granted access by Aidar personnel.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel10"} onChange={handleChange("panel10")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel10" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <div className={styles.question}>What browsers are compatible with the Aidar Connect dashboard?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            The Aidar Connect dashboard is supported on various browsers including Mozilla Firefox, Google Chrome, Safari, and Microsoft Edge.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel11"} onChange={handleChange("panel11")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel11" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
        >
          <div className={styles.question}>What do I need to use the Aidar Connect platform?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            You can use the Aidar Connect platform on any device (for e.g., tablet, laptop, smartphone, etc.) that has a compatible web browser and a Wi-Fi
            internet connection or hotspot. For optimal viewing purposes, we recommend using a device with a screen size of at least 13.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
    </div>
  );
}
