import React from "react";

//Component Imports
import RulesTab from "./RulesTab/RulesTab";

export default function Rules() {
  return (
    <div>
      <RulesTab />
    </div>
  );
}
