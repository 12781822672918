import React, { useState, useEffect, Fragment } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import NDTableHeader from "../TableHeader/NDTableHeader";
import { AiOutlineUserAdd } from "react-icons/ai";
import GetUserPatients from "../../../../API/GetUserPatients/GetUserPatients";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import styles from "../../ProjectLocationCards/ProjectLocation.module.css";
import { getPatientsList } from "../../../../../Actions";
import { useDispatch, useSelector } from "react-redux";
import { getPatientsListSelector } from "../../../../../Reducer/selectors/PatientSelector";
import { setShowLoader } from "../../../../../Actions/UtilActions";
import { getPageLoader } from "../../../../../Reducer/selectors/UtilSelector";
import { getPccPatientListPageSize, getPCCPatientsListSelector, getPCCPatientsPagingSelector } from "../../../../../Reducer/selectors/NursingDashboardSelector";
import { getPCCPatientsList } from "../../../../../Actions/NursingDashboardActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "98%",
    padding: `10px 10px 10px 10px`,
  },
  contentStyle: {
    display: "block",
    alignItems: "center",
  },
  heading: {
    fontFamily: `GTWalsheimProRegular`,
    color: "#F44336",
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: `bold`,
  },
  headingModerate: {
    fontFamily: `GTWalsheimProRegular`,
    color: "#ffb703",
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: `bold`,
  },
  headingStable: {
    fontFamily: `GTWalsheimProRegular`,
    color: "#000000",
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: `bold`,
  },
  acStyles: {
    backgroundColor: `#fff`,
    boxShadow: `0px 2px 5px 0px #6c757d`,
    fontFamily: `GTWalsheimProRegular`,
    borderRadius: `10px !important`,
    position: `relative`,
  },
  contentWrapper: {
    marginLeft: `5px`,
    marginRight: "5px",
  },
  addPatient: {
    backgroundColor: `#fff`,
    boxShadow: `0px 2px 5px 0px #6c757d`,
    fontFamily: `GTWalsheimProRegular`,
    borderRadius: `25px !important`,
    position: `relative`,
    margin: `30px`,
    padding: `18px`,
    fontSize: 16,
    textTransform: "uppercase",
  }
}));

export default function NDPatientAccordion(props) {
  const classes = useStyles();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const patientList = useSelector(getPatientsListSelector);
  const loader = useSelector(getPageLoader);
  const pccPatientList = useSelector(getPCCPatientsListSelector);
  const pccPaging = useSelector(getPCCPatientsPagingSelector);
  //console.log("PCC PATIENT LIST: ", pccPatientList);

// useEffect(() => {
//   if(loader) {
//     return (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>);
//   }
// },[loader]);

  useEffect(() => {
    const payload = {'projectId':props.projectId, 'locationId':props.locationId};
    dispatch(getPatientsList(payload));
    
    const pLoad = {'projectId':props.projectId, 'locationId':props.locationId, 'page': '1', 'pageSize': '20'};
    dispatch(getPCCPatientsList(pLoad));
  }, []);


  if(loader) {
    return (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>);
  }

  if(!patientList === 0) {
    return (
      <Button variant="outlined" startIcon={<AiOutlineUserAdd />} className={classes.addPatient} onClick={() => navigate(`/register`)}>
        Add New Patient
      </Button>
    );  
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Accordion defaultExpanded className={classes.acStyles}>
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: `red` }} />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography
              className={`${props.accId === "1" && classes.heading} ${props.accId === "2" && classes.headingModerate} ${
                props.accId === "3" && classes.headingStable
              }`}
            >
              {props.label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.contentStyle}>
          <div className={classes.contentWrapper}>
              <NDTableHeader 
              patientList={pccPatientList} 
              pId={props.accId} 
              projectId={props.projectId} 
              locationId={props.locationId} 
              paging={pccPaging} />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Fragment>
  );
}
