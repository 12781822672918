import React, { useState, useEffect } from "react";
import styles from "./MlLogs.module.css";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import IconButton from "@material-ui/core/IconButton";
import GetMouthLabLogs from "../../../../../API/GetMouthLabLogs/GetMouthLabLogs";
import AssignMl from "../MLActions/AssignMl";
import UnassignMl from "../MLActions/UnassignMl";
import { FaSort } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedProjectIdAndLocationId, getUserProfile } from "../../../../../../Reducer/selectors/DashboardSelector";
import { getMouthLabLogselector } from "../../../../../../Reducer/selectors/PatientSelector";
import { getMouthLabLogs, setProjectIdAndLocationId, setSelectedPageName } from "../../../../../../Actions";
import { globalConstants } from "../../../../../../Constants/GlobalConstants";
import ChooseDevices from "../../../ChooseDevices/ChooseDevices";
import MlLogsHeader from "./MlLogsHeader";
import { setShowAssignMlLoader } from "../../../../../../Actions/UtilActions";
import { getDisplayLabelWithEhrId, getDisplayLabelWithoutEhrId } from "../../../../../../Utils/GlobalFunctions";

export default function MlLogs() {
  const profile = useSelector(getUserProfile);
  const projAndLoc = useSelector(getSelectedProjectIdAndLocationId);
  const userEmail = profile.email;
  const projectId = projAndLoc.selectedProjectId;
  const locationId = projAndLoc.selectedLocationId;

  const [mouthLabPatientLogs, setMouthLabPatientLogs] = useState([]);
  const [formState, setFormState] = useState(true);
  const [sortDirection, setSortDirection] = useState(false);
  const dispatch = useDispatch();
  const mouthLabLogs = useSelector(getMouthLabLogselector);

  // UI Modal Testing
  const [openModalMenu, setOpenModalMenu] = useState(false);

  useEffect(() => {
    // GetMouthLabLogs(projectId, locationId).then((res) => {
    //   setMouthLabPatientLogs(res.map((x) => x));
    // });
    let proj_id = projectId ? projectId : profile.projects[0].id;
    let loc_id = locationId ? locationId : profile.locations[0].id;
    dispatch(getMouthLabLogs({ projectId: proj_id, locationId: loc_id }));
    dispatch(setSelectedPageName('logs'));
  }, [formState]);

  useEffect(() => {
    setMouthLabPatientLogs(mouthLabLogs);
  }, [mouthLabLogs]);

  // ASSIGN MOUHTLAB MODAL CONTROL STATES AND FUNCTION
  const [showModal, setShowModal] = useState();

  const changeState = () => {
    setFormState(!formState);
  };

  function openMenu(subjectId, deviceId, firstName, lastName, ehrId) {
    dispatch(setShowAssignMlLoader(true));
    setOpenModalMenu(true);
    setShowModal(true);
    setCurSubjectId(subjectId);
    setCurDeviceId(deviceId);
    const label = getDisplayLabelWithEhrId(firstName, lastName, ehrId);
    setCurSubjectLabel(label);
  }

  function closeMenu() {
    setShowModal(false);
    changeState();
  }

  // UNASSIGN MOUHTLAB MODAL CONTROL STATES AND FUNCTION
  const [showDialog, setShowDialog] = useState(false);
  const [curSubjectId, setCurSubjectId] = useState();
  const [curSubjectLabel, setCurSubjectLabel] = useState();
  const [curDeviceId, setCurDeviceId] = useState();

  function openDialogMenu(subjectId, deviceId, firstName, lastName, ehrId) {

    setCurSubjectId(subjectId);
    setCurDeviceId(deviceId);
    setShowDialog(true);
    const label = getDisplayLabelWithEhrId(firstName, lastName, ehrId);
    setCurSubjectLabel(label);
  }

  function closeDialogMenu() {
    setShowDialog(false);
    changeState();
  }

  // Sorting based of device ID
  const handleDeviceSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...mouthLabPatientLogs];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key] - b[key];
      } else {
        return b[key] - a[key];
      }
    });
    return setMouthLabPatientLogs(sortableItem);
  };

  // Sorting based of subject ID
  const handleSubjectSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...mouthLabPatientLogs];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key] - b[key];
      } else {
        return b[key] - a[key];
      }
    });
    return setMouthLabPatientLogs(sortableItem);
  };

  // Sorting based of patient names
  const handleNameSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...mouthLabPatientLogs];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key]?.localeCompare(b[key]);
      } else {
        return b[key]?.localeCompare(a[key]);
      }
    });
    return setMouthLabPatientLogs(sortableItem);
  };

  const devicelogs = mouthLabPatientLogs.map((logs) => (
    <table className={styles.responsive}>
      <tbody key={logs.deviceId}>
        <tr>
          <th className={styles.rowCellSubjectID}>
            {/* <div className={styles.nameDiv}>{!logs.subjectId ? "" : <> ADR -{logs.subjectId}</>}</div> */}
            <div className={styles.nameDiv}>
              {!logs.subjectId ? "" : logs.ehrId ? getDisplayLabelWithEhrId(logs.firstName, logs.lastName, logs.ehrId) : getDisplayLabelWithoutEhrId(logs.firstName, logs.lastName)}
            </div>
          </th>
          <th className={`${logs.subjectId ? styles.deviceStatusNotAvailable : styles.deviceStatus}`}>{logs.subjectId ? <p>Paired</p> : <p>Available</p>}</th>
          <th className={styles.rowCellMouthLabID}>{logs.deviceId}</th>

          <th className={styles.actionHeader}>
            {logs.subjectId ? (
              <div className={styles.contentCardDiv}>
                <div className={styles.buttonDiv}>
                  <button
                    className={styles.removeButton}
                    onClick={() => {
                      openDialogMenu(logs.subjectId, logs.deviceId, logs.firstName, logs.lastName, logs.ehrId);
                    }}
                  >
                    Unassign
                  </button>
                </div>
              </div>
            ) : (
              <div className={styles.contentCardDiv}>
                <div className={styles.buttonDiv}>
                  <button
                    className={styles.assignButton}
                    onClick={() => {
                      openMenu(logs.subjectId, logs.deviceId, logs.firstName, logs.lastName, logs.ehrId);
                    }}
                  >
                    Assign
                  </button>
                </div>
              </div>
            )}
          </th>
        </tr>
      </tbody>
    </table>
  ));

  return (
    <div className={styles.dummyDiv}>
      <MlLogsHeader></MlLogsHeader>
      <Grid container spacing={2} className={styles.mlLogWrapper}>
        {/* <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.pageTitle}> Device Assignment</div>
        </Grid> */}
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.TableWrapper}>
            <table className={styles.tableHeaderStyles}>
              <thead>
                <tr className={styles.headerStyles}>
                  <th className={styles.subjectIdHeader} /* onClick={() => handleNameSorting("lastName")} */>
                    Subject Name
                    {/* <FaSort className={styles.iconsStyleSort} /> */}
                  </th>
                  <th className={styles.statusHeader} onClick={() => handleSubjectSorting("subjectId")}>
                    Status
                    <FaSort className={styles.iconsStyleSort} />
                  </th>
                  <th className={styles.deviceHeader} onClick={() => handleDeviceSorting("deviceId")}>
                    Device ID
                    <FaSort className={styles.iconsStyleSort} />
                  </th>
                  <th className={styles.actionHeader}>Action</th>
                </tr>
              </thead>
            </table>
            <Grid container xs={12} xl={12} lg={12} md={12} sm={12} style={{ maxHeight: '75vh', overflowY: 'scroll', padding: "5px" }}>
              {mouthLabLogs.length === 0 ? (
                <div className={styles.noDataWrapper}>
                  <div className={styles.noData}>
                    <div>No data available</div>
                  </div>
                </div>
              ) : (
                <>
                  {devicelogs}
                </>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>

      {/* MouthLab Assignment */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={showModal}
        onClose={closeMenu}
      >
        <div className={styles.paper}>
          <div className={styles.menuTitle}>
            <div className={styles.titleText}>Assign MouthLab</div>
            <div>
              <IconButton aria-label="delete">
                <RiCloseCircleFill size={29} onClick={closeMenu} style={{ color: "#4E253A" }} />
              </IconButton>
            </div>
          </div>

          <div className={styles.patientListDiv}>
            <Grid container>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <AssignMl currentDeviceId={curDeviceId} closeModal={closeMenu} projectId={projectId} locationId={locationId} />
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
      {/* MouthLab Assignment */}

      {/* MouthLab removal from subject */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={showDialog}
        onClose={closeDialogMenu}
      //disableBackdropClick
      >
        <div className={styles.paperUnassignML}>
          <div className={styles.menuTitle}>
            <div className={styles.titleText}>Unassign MouthLab</div>
            <div>
              <IconButton aria-label="delete">
                <RiCloseCircleFill size={29} onClick={closeDialogMenu} style={{ color: "#4E253A" }} />
              </IconButton>
            </div>
          </div>

          <div className={styles.patientListDiv}>
            <Grid container>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <UnassignMl currentDeviceId={curDeviceId} currentSubjectId={curSubjectId} currentSubjectLabel={curSubjectLabel} closeModal={closeDialogMenu} />
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
  // }
}
