import React from "react";
import styles from "./FAQsAidi.module.css";
import Divider from "@material-ui/core/Divider";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

export default function FAQsGeneral() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <div className={styles.faqTitle}>General</div>
      <Divider style={{ background: '#ede9e9' }} />
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel1" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={styles.question}>Is Aidar Connect HIPAA-compliant?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>Yes, all data capture, storage, and transmission is done on a HIPAA-compliant platform.</div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: '#ede9e9' }} />
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel2" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <div className={styles.question}>Whom should I contact for technical platform issues?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
          For technical assistance, kindly contact Aidar Health's technical team by phone at +1(443)875-6456 or via email at contact@aidar.com. Our team will address your queries within 24 to 48 hours.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: '#ede9e9' }} />
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel3" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <div className={styles.question}>What are Aidar Health’s operating hours?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>Aidar Health’s operating hours are Monday to Friday, 9:30 am-5:30 pm Eastern Standard Time.</div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: '#ede9e9' }} />
      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel4" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <div className={styles.question}>What do I do if my patient has a device-related issue?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
          You can have them contact Aidar Health at +1(443)875-6456 or send us an email at device@aidar.com. Someone from our technical  team will contact you within 24 to 48 hours.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: '#ede9e9' }} />
      <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel5" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <div className={styles.question}>Does the platform offer training videos for user orientation?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
          Certainly, within the Support section, you'll discover demonstration videos and a collection of frequently asked questions (FAQs) to aid in familiarizing yourself with the platform.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: '#ede9e9' }} />
    </div>
  );
}
