import React, { useState, useContext } from "react";
import axios from "axios";
import domainCF from "../domainCF.js";

export default function PostSubjectBaseline(baseline_data) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    //Authorization: `"Bearer ${token}"`,
    Authorization: "Bearer " + token,
  };
  const POST_PATIENT_BASELINE_URL =
    domainCF + "/api/AddBaseline";
  return axios
    .post(POST_PATIENT_BASELINE_URL, baseline_data, { headers })
    .then(function (response) {
      // console.log("THIS IS BASELINE RESPONSE...", response);
      // console.log("THIS IS BASELINE RESPONSE DATA...", response.data);
      return response.data;
    });
}
