export const notesConstants = {
    GET_PATIENT_NOTES: "GET_PATIENT_NOTES",
    GET_PATIENT_NOTES_SUCCESS: "GET_PATIENT_NOTES_SUCCESS",
    GET_PATIENT_NOTES_FAILURE: "GET_PATIENT_NOTES_FAILURE",

    //POST NOTES
    POST_PATIENT_NOTES: "POST_PATIENT_NOTES",
    POST_PATIENT_NOTES_SUCCESS: "POST_PATIENT_NOTES_SUCCESS",
    POST_PATIENT_NOTES_FAILURE: "POST_PATIENT_NOTES_FAILURE",

    //UPDATE NOTES
    UPDATE_PATIENT_NOTE: "UPDATE_PATIENT_NOTES",
    UPDATE_PATIENT_NOTE_SUCCESS: "UPDATE_PATIENT_NOTES_SUCCESS",
    UPDATE_PATIENT_NOTE_FAILURE: "UPDATE_PATIENT_NOTES_FAILURE",

    //Notes tags
    SET_NOTES_TAGS: "SET_NOTES_TAGS",
    SET_POST_NOTES_SUCCESS_FLAG: "SET_POST_NOTES_SUCCESS_FLAG",

    //Selected measurement's timestamp
    SET_SELECTED_TIMESTAMP: "SET_SELECTED_TIMESTAMP",

    //Open Add Notes Panel
    SET_OPEN_ADD_NOTES_PANEL: "SET_OPEN_ADD_NOTES_PANEL",

    ADD_NOTES_TO_RECORD_FROM_TABLE: "ADD_NOTES_TO_RECORD_FROM_TABLE",
    SELECTED_RECORDID_FOR_NOTES: "SELECTED_RECORDID_FOR_NOTES",
};
