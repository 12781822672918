import React from "react";
import Grid from "@material-ui/core/Grid";
import styles from "./RfmGraphContainer.module.css";
import RfmGraph from "../RfmGraph/RfmGraph";
import { getPageLoader } from "../../../../../../../Reducer/selectors/UtilSelector.js";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import NoDataImg from "../../../../../../../Assets/Images/No-Data-PNG.png";

export default function RfmGraphContainer(props) {

  const plotRfmGraph = props.rfmData.map((rfmParams) => (
    <RfmGraph
      key={rfmParams.recordId}
      breathingRate={rfmParams.breathingRate.value}
      xValues={rfmParams.rfm.xValues}
      yValues={rfmParams.rfm.yValues}
      timestamp={rfmParams.timestamp}
    />
  ));
  return (
    <div>
      {props.rfmData.length === 0 ? (
        <div className={styles.noDataWrapper}>
          <img src={NoDataImg} width="650vw" height="530vh" />
          <div className={styles.noData}>No Data Available</div>
        </div>
      ) : (
        <Grid container>
          <Grid item xs={12} xl={12} lg={12} md={12} sm={12} style={{ maxHeight: "65vh", overflowY: "scroll", padding: "5px" }}>
            <div className={styles.responsive}>{plotRfmGraph}</div>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
