import React from "react";
import { Field, ErrorMessage } from "formik";
import styles from "./DynamicSelect.module.css";
import InputErrorMessage from "../InputError/InputErrorMessage";
export default function DynamicSelect(props) {
  const { label, name, options, value, ...rest } = props;

  let items = options.map(function (x) {
    return { key: x, value: x };
  });

  return (
    <div className={styles.labelDivStyles}>
      <label htmlFor={name} className={styles.labelStyles}>
        {label}
      </label>
      <Field
        as="select"
        id={name}
        name={name}
        value={value.name}
        {...rest}
        className={styles.selectDropdown}
      >
        <option key={""} value={""}>
          Select
        </option>
        {items.map((option) => {
          return (
            <option
              key={option.value}
              value={option.value}
              className={styles.dropDownItems}
            >
              {option.key}
            </option>
          );
        })}
      </Field>

      <ErrorMessage name={name} component={InputErrorMessage} />
    </div>
  );
}
