import React, { Fragment } from "react";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import styles from "./ViatlsTableWrapper.module.css";
import Grid from "@mui/material/Grid";

export function ExportPatientVitals(props) {
  return (
    <Fragment>
      <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
        <div className={styles.userQuestionMessage}>Are you sure you would export the selected patient data?</div>
        <div className={styles.userInfoMessage}>This action will download the data as a 'CSV' file</div>

        <div className={styles.buttonContainer}>
          <button type="button" onClick={props.closeModal} className={styles.cancelButton}>
            Cancel
          </button>
          <CSVLink
            data={props.exportData}
            filename={`ADR-${props.subjectId} Vitals (${format(props.startDate, "MM.dd.yy")}-${format(props.endDate, "MM.dd.yy")}).csv`}
            headers={props.headers}
          >
            <button type="submit" className={styles.submitButton} onClick={props.closeModal}>
              Confirm
            </button>
          </CSVLink>
        </div>
      </Grid>
    </Fragment>
  );
}
