import React, { useState, useEffect } from "react";
import styles from "./NDTableHeader.module.css";
import Grid from "@material-ui/core/Grid";
import { FaSort } from "react-icons/fa";
import NDTableBody from "../TableBody/NDTableBody";
import Input from '@material-ui/core/Input';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { RiCloseCircleFill } from "react-icons/ri";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Select } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getPccPatientListPageNumber, getPccPatientListPageSize } from "../../../../../Reducer/selectors/NursingDashboardSelector";
import { searchPccPatient, setPCCPatientListPageNumber, setPCCPatientListPageSize } from "../../../../../Actions";
import { getPCCPatientsList } from "../../../../../Actions";
import { setShowLoader } from "../../../../../Actions/UtilActions";
import { getPageLoader } from "../../../../../Reducer/selectors/UtilSelector";
import { CircularProgress } from "@material-ui/core";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Menu } from "@mui/material";

export default function NDTableHeader(props) {
    const [patientListSorting, setPatientListSorting] = useState([]);
    const [sortDirection, setSortDirection] = useState(false);
    const [searched, setSearched] = useState("");

    const [unitFilters, setUnitFilters] = useState(["All"]);
    const [floorFilters, setFloorFilters] = useState(["All"]);

    const [isUnitsFilter, setIsUnitsFilter] = useState(false);
    const [isFloorsFilter, setIsFloorsFilter] = useState(false);

    const [selectedUnitFilters, setSelectedUnitFilters] = useState([]);
    const [selectedFloorFilters, setSelectedFloorFilters] = useState([]);

    const dispatch = useDispatch();
    const pccPageNumber = useSelector(getPccPatientListPageNumber);
    const loader = useSelector(getPageLoader);

    const pccPageSize = useSelector(getPccPatientListPageSize);

    useEffect(() => {
        const payload = { 'projectId': props.projectId, 'locationId': props.locationId, 'page': pccPageNumber.toString(), 'pageSize': pccPageSize ? pccPageSize.toString() : "20" };
        dispatch(getPCCPatientsList(payload));
    }, [pccPageNumber, pccPageSize]);

    const handleStringSorting = (key) => {
        setSortDirection((prevSortDirection) => !prevSortDirection);
        let direction = sortDirection;
        let sortableItem = [...patientListSorting];
        sortableItem.sort((a, b) => {
            if (direction === false) {
                return a[key].localeCompare(b[key]);
            } else {
                return b[key].localeCompare(a[key]);
            }
        });
        setPCCFilters(sortableItem);
        return setPatientListSorting(sortableItem);
    };

    const handleNumberSorting = (key) => {
        setSortDirection((prevSortDirection) => !prevSortDirection);
        let direction = sortDirection;
        let sortableItem = [...patientListSorting];
        sortableItem.sort((a, b) => {
            if (direction === false) {
                return a[key] - b[key];
            } else {
                return b[key] - a[key];
            }
        });
        return setPatientListSorting(sortableItem);
    };

    const calculateDailyAdherence = (lastTss) => {
        if (!lastTss || lastTss.length === 0) {
            return [false, false, false];
        }

        let adh1 = false;
        let adh2 = false;
        let adh3 = false;

        const currentDate = new Date();
        const currentDayOfMonth = currentDate.getDate();
        const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
        const currentYear = currentDate.getFullYear();

        const next6AM = new Date(currentYear, currentMonth, currentDayOfMonth + 1, 6, 0, 0);
        const curr6AM = new Date(currentYear, currentMonth, currentDayOfMonth, 6, 0, 0);
        const curr2PM = new Date(currentYear, currentMonth, currentDayOfMonth, 14, 0, 0);
        const curr10PM = new Date(currentYear, currentMonth, currentDayOfMonth, 22, 0, 0);

        let i = 0;
        for (i = 0; i < lastTss.length; i++) {
            let t = lastTss[i];
            if (t >= curr6AM && t < curr2PM) {
                adh1 = true;
            } else if (t >= curr2PM && t < curr10PM) {
                adh2 = true;
            } else if (t >= curr10PM && t < next6AM) {
                adh3 = true;
            }
        }

        return [adh1, adh2, adh3];
    };

    useEffect(() => {
        const patientListCopy = props.patientList.map((x) => x);
        const patientListCopy1 = applySelectedFilters(patientListCopy);
        setPatientListSorting(patientListCopy1);
        setPCCFilters(patientListCopy);
    }, [props.patientList, selectedUnitFilters, selectedFloorFilters]);

    const patientTableBody = patientListSorting.map((pat) => {
        const adhValues = calculateDailyAdherence(pat.lastTss);
        return (<NDTableBody
            patientID={pat.patientId}
            fullName={pat.firstName + " " + pat.lastName}
            elapsedHours={pat.elapsedHours}
            adh1={adhValues[0]}
            adh2={adhValues[1]}
            adh3={adhValues[2]}
            patientList={props.patientList}
            room={pat.roomDesc}
            medicalRecordNumber={pat.medicalRecordNumber}
            unitId={pat.unitId}
        />)
    });

    const applySelectedFilters = (patientListCopy) => {
        let filteredList = patientListCopy.filter((patient) => {
            if (selectedUnitFilters.includes("All") || selectedFloorFilters.includes("All")) {
                return true;
            } else if (selectedUnitFilters.includes(patient.unitId.toString()) || selectedFloorFilters.includes(patient.floorId.toString())) {
                return true;
            } else if (selectedUnitFilters.length === 0 && selectedFloorFilters.length === 0) {
                return true;
            }
        });
        return filteredList;
    }

    const requestSearch = (event) => {
        setSearched(event.target.value);
        let searchVal = searched;

        if (!searchVal || searchVal.length === 0) {
            const patientListCopy = props.patientList.map((x) => x);
            setPatientListSorting(patientListCopy);
            setPCCFilters(patientListCopy);
        } else {
            let filteredPatients = [];
            const patientListCopy = props.patientList.map((x) => x);
            filteredPatients = patientListCopy.filter((row) => {
                let fullName = row.firstName + " " + row.lastName;
                return (
                    (row.patientId && row.patientId.toString().toLowerCase().includes(searchVal.split(' ').join('').toLowerCase())) ||
                    (fullName && fullName.toString().toLowerCase().includes(searchVal.toLowerCase())) ||
                    (row.physician && row.physician.toString().toLowerCase().includes(searchVal.toLowerCase())));
            });
            setPatientListSorting(filteredPatients);
            setPCCFilters(filteredPatients);
        }
    };

    const handleCancleSearch = () => {
        setSearched("");
        const patientListCopy = props.patientList.map((x) => x);
        setPatientListSorting(patientListCopy);
        setPCCFilters(patientListCopy);
    }

    const setPCCFilters = (pccPatientsList) => {
        let floFilters = ["All"];
        let uniFilters = ["All"];

        pccPatientsList.map(patient => {

            if (!floFilters.includes(patient.floorId.toString())) {
                floFilters.push(patient.floorId.toString());
            }

            if (!uniFilters.includes(patient.unitId.toString())) {
                uniFilters.push(patient.unitId.toString());
            }
        });

        setFloorFilters(floFilters);
        setUnitFilters(uniFilters);
        //dispatch(setShowLoader(false));
    }

    const RenderUnitsFilters = (props) => {

        return (
            <FormGroup className={styles.filtersDiv}>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={styles.filtersDivText} control={<Checkbox size="small" name={filter.toString()} checked={selectedUnitFilters.includes(filter.toString())} onClick={(event) => addToUnitFilter(event)} />} label={filter.toString()} />)}
            </FormGroup>
        );
    }

    const RenderFloorsFilters = (props) => {

        return (
            <FormGroup className={styles.filtersDiv}>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={styles.filtersDivText} control={<Checkbox size="small" name={filter.toString()} checked={selectedFloorFilters.includes(filter.toString())} onClick={(event) => addToFloorFilter(event)} />} label={filter.toString()} />)}
            </FormGroup>
        );
    }

    const expandFloorFilters = () => {
        setIsFloorsFilter(!isFloorsFilter);
    }

    const expandUnitFilters = () => {
        setIsUnitsFilter(!isUnitsFilter);
    }

    const addToUnitFilter = (event) => {
        if (!selectedUnitFilters.includes(event.target.name)) {
            setSelectedUnitFilters([...selectedUnitFilters, event.target.name]);
        } else if (selectedUnitFilters.includes(event.target.name) && !event.target.checked) {
            const newState = selectedUnitFilters.filter((d) => d !== event.target.name);
            setSelectedUnitFilters(newState);
        }
    }

    const addToFloorFilter = (event) => {
        if (!selectedFloorFilters.includes(event.target.name)) {
            setSelectedFloorFilters([...selectedFloorFilters, event.target.name]);
        } else if (selectedFloorFilters.includes(event.target.name) && !event.target.checked) {
            const newState = selectedFloorFilters.filter((d) => d !== event.target.name);
            setSelectedFloorFilters(newState);
        }
    }

    const clearFilters = () => {
        setSelectedUnitFilters([]);
        setSelectedFloorFilters([]);
    }

    const FilterPanel = (props) => {

        return (
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <div className={styles.iconDivStyles}>
                        <FilterAltOutlinedIcon size={28} />
                    </div>
                    <div className={styles.profile}>Filter</div>
                    <div className={styles.closeIcon}>
                        <IconButton aria-label="delete">
                            <RiCloseCircleFill size={22} style={{ color: "#4E253A" }} onClick={props.popupState.close} />
                        </IconButton>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.patientDataWrapper}>
                        <div className={styles.profileField}>
                            <div className={styles.boldText}>Units</div>
                            <IconButton size="small" onClick={() => expandUnitFilters()}>
                                {isUnitsFilter ? (<KeyboardArrowUpOutlinedIcon></KeyboardArrowUpOutlinedIcon>) : (<KeyboardArrowDownOutlinedIcon></KeyboardArrowDownOutlinedIcon>)}
                            </IconButton>
                        </div>
                        {isUnitsFilter && <RenderUnitsFilters filterList={unitFilters}></RenderUnitsFilters>}

                        <div className={styles.profileField}>
                            <div className={styles.boldText}>Floors</div>
                            <IconButton size="small" onClick={() => expandFloorFilters()}>
                                {isFloorsFilter ? (<KeyboardArrowUpOutlinedIcon></KeyboardArrowUpOutlinedIcon>) : (<KeyboardArrowDownOutlinedIcon></KeyboardArrowDownOutlinedIcon>)}
                            </IconButton>
                        </div>
                        {isFloorsFilter && <RenderFloorsFilters filterList={floorFilters}></RenderFloorsFilters>}

                    </div>
                </div>
                <div className={styles.clearFiltersDiv}>
                    <div className={styles.clearFilterButton} onClick={() => clearFilters()}>
                        Clear all filters
                    </div>
                </div>
            </div>
        );
    }

    const goToNextPage = () => {
        dispatch(setShowLoader(true));
        dispatch(setPCCPatientListPageNumber(pccPageNumber + 1));
        const payload = { 'projectId': props.projectId, 'locationId': props.locationId, 'page': pccPageNumber.toString(), 'pageSize': pccPageSize ? pccPageSize.toString() : "20" };
        dispatch(getPCCPatientsList(payload));
    }

    const goToPrevPage = () => {
        dispatch(setShowLoader(true));
        dispatch(setPCCPatientListPageNumber(pccPageNumber - 1));
        const payload = { 'projectId': props.projectId, 'locationId': props.locationId, 'page': pccPageNumber.toString(), 'pageSize': pccPageSize ? pccPageSize.toString() : "20" };
        dispatch(getPCCPatientsList(payload));
    }

    const handleSelectPageSize = (event) => {
        dispatch(setPCCPatientListPageSize(event.target.value));
        dispatch(setShowLoader(true));
        const payload = { 'projectId': props.projectId, 'locationId': props.locationId, 'page': pccPageNumber.toString(), 'pageSize': pccPageSize ? pccPageSize.toString() : "20" };
        dispatch(getPCCPatientsList(payload));
    }

    const searchPccPatientUsingName = () => {
        dispatch(searchPccPatient(searched));
    }
    const resetSearchPccPatient = () => {
        // setSearched('');
        const payload = { 'projectId': props.projectId, 'locationId': props.locationId, 'page': pccPageNumber.toString(), 'pageSize': pccPageSize ? pccPageSize.toString() : "20" };
        dispatch(getPCCPatientsList(payload));
        handleCancleSearch();
    }
    return (
        <div>
            <div>
                <div className={styles.topDiv}>
                    <Input
                        className={styles.searchBar}
                        type="text"
                        placeholder="Search by 'Name' or 'Medical Record Number'"
                        value={searched}
                        onChange={(event) => setSearched(event.target.value)}
                        // onKeyUp={(event) => requestSearch(event)}
                        endAdornment={searched ?
                            <> <InputAdornment position="start">
                                <IconButton
                                    sx={{ color: "#4E253A" }}
                                >
                                    <SearchIcon onClick={() => searchPccPatientUsingName()} style={{ color: "#4E253A" }}></SearchIcon>
                                </IconButton>
                            </InputAdornment> <InputAdornment position="start">
                                    <IconButton
                                        sx={{ color: "#4E253A" }}
                                    >
                                        <CloseIcon onClick={() => resetSearchPccPatient()} style={{ color: "#4E253A" }}></CloseIcon>
                                    </IconButton> </InputAdornment></> :
                            <></>
                        }
                        startAdornment={
                            <InputAdornment position="end">
                                {<IconButton
                                    sx={{ color: "#4E253A" }}
                                >
                                    {<SearchIcon style={{ color: "#4E253A" }}></SearchIcon>}
                                </IconButton>}
                            </InputAdornment>
                        }
                        disableUnderline="true"
                        style={{
                            fontFamily: "GTWalsheimProRegular"
                        }}
                    />
                    <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                        {(popupState) => (
                            <div className={styles.filterDiv}>
                                <IconButton {...bindToggle(popupState)}>
                                    <FilterAltIcon style={{ color: "#4E253A", height: "30px", width: "30px" }}></FilterAltIcon>
                                </IconButton>
                                <Popper {...bindPopper(popupState)} transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper className={styles.filterBackdrop}>
                                                <FilterPanel popupState={popupState} />
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </div>
                        )}
                    </PopupState>

                </div>
                <p></p>
                <Grid container>
                    <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                        <table className={styles.responsive}>
                            <thead>
                                <tr>
                                    <th className={styles.nameHeader} onClick={() => handleStringSorting("firstName")}>
                                        Full Name
                                        <FaSort className={styles.iconsStyleSort} />
                                    </th>
                                    <th className={styles.patientIDHeader} onClick={() => handleNumberSorting("medicalRecordNumber")}>
                                        Medical Record Number
                                        <FaSort className={styles.iconsStyleSort} />
                                    </th>
                                    <th className={styles.patientIDHeader} onClick={() => handleNumberSorting("roomDesc")}>
                                        Room
                                        <FaSort className={styles.iconsStyleSort} />
                                    </th>
                                    <th className={styles.dailyMeasurementsHeader}>
                                        Daily Measurements
                                    </th>
                                    <th className={styles.elapsedTimeHeader} onClick={() => handleStringSorting("elapsedHours")}>
                                        Elapsed Hours
                                        <FaSort className={styles.iconsStyleSort} />
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <Grid item xs={12} xl={12} lg={12} md={12} sm={12} style={{ maxHeight: '40vh', overflowY: 'scroll' }}>
                            {loader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) : patientTableBody}
                        </Grid>
                    </Grid>
                    <div className={styles.bottomNavDiv}>
                        <div className={styles.navButttonDiv}>
                            <button disabled={pccPageNumber === 1} className={pccPageNumber === 1 ? styles.navButtonsDisable : styles.navButtons} onClick={() => goToPrevPage()}>
                                Previous
                            </button>
                        </div>
                        <div className={styles.pageSizeNumberDiv}>
                            <div className={styles.pageNumberDiv}>
                                <InputLabel style={{ fontSize: "12px", fontFamily: "GTWalsheimProRegular" }}>Page Size:</InputLabel>
                            </div>
                            <div className={styles.pageSizeDiv}>
                                <Select
                                    value={pccPageSize}
                                    onChange={(event) => handleSelectPageSize(event)}
                                >
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="20">20</MenuItem>
                                    <MenuItem value="50">50</MenuItem>
                                    <MenuItem value="100">100</MenuItem>
                                    <MenuItem value="200">200</MenuItem>
                                </Select>
                            </div>
                            <div className={styles.pageNumberDiv}>
                                <InputLabel style={{ fontSize: "12px", fontFamily: "GTWalsheimProRegular" }}>Page:</InputLabel>
                            </div>
                            <div className={styles.pageNumberDiv}>
                                {pccPageNumber}
                            </div>
                        </div>
                        <div className={styles.navButttonDiv}>
                            <button disabled={props.paging && !props.paging.hasMore} className={props.paging && !props.paging.hasMore ? styles.navButtonsDisable : styles.navButtons} onClick={() => goToNextPage()}>
                                Next
                            </button>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
}
