import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import styles from "./RulesTab.module.css";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRulesWrapperId } from "../../../../../Actions";
import { getSelectedRulesTab } from "../../../../../Reducer/selectors/DashboardSelector";

const AntTabs = withStyles({
  root: {
    boxShadow: "0px 1px 5px 0px #cccaca",
    alignItems: "right",
    marginRight: `20px`,
    marginLeft: `20px`,
    fontWeight: "bold",
    borderBottomRightRadius: `10px`,
    borderBottomLeftRadius: `10px`,
    backgroundColor: `#E3E3E4`,
  },
  indicator: {
    // backgroundColor: "#1890ff",
    // maxWidth: 50,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: "99%",
      width: "100%",
      backgroundColor: "#4E253A",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    paddingTop: `10px`,
    color: "#7C596A",
    // borderTopLeftRadius : `20px`,
    // borderTopRightRadius : `20px`,
    background: `#e9ecef`,
    minWidth: 72,
    borderRight: "1px solid #B0B0B0",
    fontSize: `15px`,
    fontWeight: "bold",
    fontFamily: ["GTWalsheimProRegular"].join(","),
    "&:last-child": {
      borderRight: `none`,
    },
    "&:hover": {
      //color: '#40a9ff',
      //opacity: 1,
    },
    //selected tab text styles
    "&$selected": {
      color: "#4E253A",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#4E253A",
    },
    //selected tab styles
    "&$selected": {
      color: "#4E253A",
      backgroundColor: "#fff",
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  demo2: {
    backgroundColor: "#2e1534",
    alignItems: "center",
  },
}));

export default function RulesTab() {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);

  const navigate = useNavigate();

  // useEffect(() => {
  //     setSelectedTab(0);
  // }, []);

  useEffect(() => {
    if (selectedTab === 0) {
      navigate('patientDevices');
    } else if (selectedTab === 1) {
      navigate('calibration');
    }
  }, [selectedTab]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.root}>
      <>
        <div className={classes.demo1}>
          <AntTabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="ant example"
            variant="fullWidth"
          >
            {/* <AntTab label="Status" component={NavLink} to="patientPrograms" /> */}
            <AntTab label="Devices" component={NavLink} to="patientDevices" />
            <AntTab label="Calibration" component={NavLink} to="calibration" />
            {/* <AntTab label="Threshold" component={NavLink} to="baseline" /> */}
          </AntTabs>
          <Typography className={classes.padding} />

          <div style={{ alignItems: "center", textAlign: "-webkit-center" }}>
            {/* {selectedTab === 0 && <BpCalibration />}
            {selectedTab === 1 && <BaselineWrapper />} */}

            <Outlet />
          </div>
        </div>
      </>
    </div>
  );
}
