import React from "react";
import styles from "./FAQsAidi.module.css";
import Divider from "@material-ui/core/Divider";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

export default function FAQsAidi() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <div className={styles.faqTitle}>AIDI</div>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel1" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={styles.question}>What is Aidar Analytics Engine?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            The Aidar Analytics Engine is an innovative cloud-based system designed to interpret and analyze subtle changes in health. This proprietary engine
            harnesses data from cleared sensors, notably the MouthLab device, to generate a personalized health baseline for each individual. Utilizing an
            automated algorithm, it assesses data in real-time and generates an Aidar Index (AIDI), which measures deviations in the relationship of the vital
            signs from the established baseline.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel2" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <div className={styles.question}>How to interpret the Aidar Index (AIDI)?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            The Aidar Index (AIDI) is a scalar value ranging from 0 to 1. An AIDI value closer to 1 indicates that the relationships among the patient’s vital
            signs (namely heart rate & breathing rate) are highly deviant from the baseline. The AIDI is divided into 3 categories:
            <ul>
              <li>AIDI values ≤ 0.3 indicates little or no change in the relationship among the patient's vital signs as compared to the baseline.</li>
              <li>AIDI &gt; 0.3 and ≤ 0.6 indicates moderate change.</li>
              <li>AIDI &gt; 0.6 - 1.0 indicates significant change.</li>
            </ul>
            The AIDI is intended for daily, intermittent, retrospective review by a qualified practitioner and isn't intended for clinical diagnosis or
            independent assessment. Rather, it is designed as a supplementary tool aiding clinical decisions under the guidance of qualified practitioners.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel3" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <div className={styles.question}>What are the baseline recommendations set by the Aidar Analytics Engine?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            The Aidar Analytics Engine necessitates a minimum of 10 measurements logged within a consecutive 14-day period, exhibiting minimal to no deviation
            from the previous baseline, to propose a new baseline.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel4" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <div className={styles.question}>How often does the Aidar Engine make a recommendation for a new baseline?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            Every 45 days after the establishment of the last set baseline, the Aidar engine evaluates the criteria for 'baseline selection' to be fulfilled.
            Upon meeting the criteria during this period, the engine proceeds to recommend the establishment of a new baseline.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel5" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <div className={styles.question}>Do I have an option to accept or reject the recommended baseline?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            Yes, you have the choice to either accept or reject the proposed baseline. In the event of rejection, you can manually select a new baseline from
            the Heart Rate (HR) and Breathing Rate (BR) graphs by choosing the create "+" icon located at the top right corner.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel6" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <div className={styles.question}>Why is it necessary to update the baseline as time progresses?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            While it's not obligatory to frequently update the baseline, Aidar strongly advises periodic updates of the individualized biometric baseline to
            integrate the most recent physiological data and patterns. Although the autonomous recommendation aims for minimal deviation from the prior
            baseline, healthcare professionals can manually select a baseline at any juncture, particularly if, through independent clinical judgment, there's a
            discernible emergence of a new physiological benchmark in an individual.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel7"} onChange={handleChange("panel7")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel7" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <div className={styles.question}>Is AIDI disease-specific?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            No, AIDI is not designed for specific diseases. It's a disease-agnostic tool dedicated to detecting variations in health based on an individual's
            biometric profile.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel8"} onChange={handleChange("panel8")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel8" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <div className={styles.question}>Is it possible to establish a baseline that predates a more recent one?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            Yes, you have the flexibility to create a new baseline using historical data, even preceding the date range of measurements utilized for the most
            recent one.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel9"} onChange={handleChange("panel9")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel9" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <div className={styles.question}>What is the maximum duration available for visualizing AIDI trends?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            You can select a duration of up to 4 months or 120 days to visualize the AIDI trend. This maximum duration applies to the input variables for
            AIDI—heart rate and respiratory rate—as well.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel10"} onChange={handleChange("panel10")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel10" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <div className={styles.question}>Are there alerts or notifications available for patients with higher AIDI values?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            The platform currently does not offer alerts or notifications for patients whose AIDI values reach the moderate or high range.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
      <Accordion expanded={expanded === "panel11"} onChange={handleChange("panel11")} style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={expanded === "panel11" ? <CloseOutlinedIcon style={{ color: `#7C596A` }} /> : <AddOutlinedIcon style={{ color: `#7C596A` }} />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
        >
          <div className={styles.question}>My patient took multiple measurements with the MouthLab device, but I don't see them all in the AIDI tab. Why?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.answer}>
            Only those measurements which have a valid heart rate and breathing rate measurement will be displayed in the AIDI graphical and tabular view.
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider style={{ background: "#ede9e9" }} />
    </div>
  );
}
