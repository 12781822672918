export function getUserProfile({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.userProfile
  )
    return dashboardReducer.userProfile;
  else return {};
}

export function getUserRole({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.userProfile && dashboardReducer.userProfile.role
  )
    return dashboardReducer.userProfile.role;
  else return '';
}

export function getSelectedProjectIdAndLocationId({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.selectedProjectId && dashboardReducer.selectedLocationId
  )
    return { selectedProjectId: dashboardReducer.selectedProjectId, selectedLocationId: dashboardReducer.selectedLocationId };
  else return {};
}

export function getSelectedMlVitalsTab({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.mlvitalsWrapperId
  )
    return { selectedMlVitalsTab: dashboardReducer.mlvitalsWrapperId };
  else return {};
}

export function getSelectedRulesTab({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.rulesWrapperId
  )
    return { selectedRulesTab: dashboardReducer.rulesWrapperId };
  else return {};
}

export function getSelectedTrendsTab({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.trendsWrapperId
  )
    return { selectedTrendsTab: dashboardReducer.trendsWrapperId };
  else return {};
}

export function getSelectedSubjectId({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.selectedSubjectId
  )
    return { selectedSubjectId: dashboardReducer.selectedSubjectId };
  else return {};
}

export function getSelectedPageName({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.selectedPageName
  )
    return dashboardReducer.selectedPageName;
  else return {};
}

export function getShowNotesPanel({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.notesPanel
  )
    return dashboardReducer.notesPanel;
  else return false;
}

export function getShowNotesDrawer({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.notesDrawer
  )
    return dashboardReducer.notesDrawer;
  else return false;
}

export function getShowTimerDrawer({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.timerDrawer
  )
    return dashboardReducer.timerDrawer;
  else return false;
}

export function getShowQMDrawer({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.qmDrawer
  )
    return dashboardReducer.qmDrawer;
  else return false;
}

export function getShowMEDrawer({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.meDrawer
  )
    return dashboardReducer.meDrawer;
  else return false;
}

export function getShowCMDrawer({ dashboardReducer }) {
  if (
    dashboardReducer &&
    dashboardReducer.cmDrawer
  )
    return dashboardReducer.cmDrawer;
  else return false;
}

export function getSelectedMlIdSelector({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.selectedMlId
  )
    return dashboardReducer.selectedMlId;
  else return "";
}

export function getSelectedPCCPatientSelector({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.selectedPCCPatient
  )
    return dashboardReducer.selectedPCCPatient;
  else return {};
}

export function getIsCalOngoing({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.isCalibrationOngoing
  )
    return dashboardReducer.isCalibrationOngoing;
  else return false;
}

export function getTimerState({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.timerState
  )
    return dashboardReducer.timerState;
  else return 0;
}

export function getIsTimerRunning({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.isTimerRunning
  )
    return dashboardReducer.isTimerRunning;
  else return false;
}

export function getTimerValue({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.timerValue
  )
    return dashboardReducer.timerValue;
  else return 0;
}

export function getTimerId({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.timerId
  )
    return dashboardReducer.timerId;
  else return '';
}

export function getShowTimerNotCompleteDialog({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.showTimerNotCompleteDialog
  )
    return dashboardReducer.showTimerNotCompleteDialog;
  else return false;
}

export function getShouldAbortTimer({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.shouldAbortTimer
  )
    return dashboardReducer.shouldAbortTimer;
  else return false;
}

export function getAbortTimerRedirectLink({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.abortTimerRedirectLink
  )
    return dashboardReducer.abortTimerRedirectLink;
  else return "";
}

export function getProjectName({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.projectName
  )
    return dashboardReducer.projectName;
  else return "";
}

export function getLocationName({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.locationName
  )
    return dashboardReducer.locationName;
  else return "";
}

export function getCurSubjectName({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.curSubjectName
  )
    return dashboardReducer.curSubjectName;
  else return "";
}

export function getCurSubjectStatus({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.curSubjectStatus
  )
    return dashboardReducer.curSubjectStatus;
  else return "";
}

export function getIsGetPatientsAPIFailure({ dashboardReducer }) {
  if (dashboardReducer &&
    dashboardReducer.isGetPatientsAPIFailure
  )
    return dashboardReducer.isGetPatientsAPIFailure;
  else return false;
}