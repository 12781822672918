import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import styles from "./GWPWrapper.module.css";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from "@material-ui/core";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import RenderSpo2BR from "../CvTrendsUpdated/RenderSpo2BR";
import RenderGWP from "../CvTrendsUpdated/RenderGWP";
import { getGWPExpand, getTrendsLoader } from "../../../../../../../Reducer/selectors/UtilSelector";
import { setGWPExpand } from "../../../../../../../Actions/UtilActions";
import { getGwpComputedSelector } from "../../../../../../../Reducer/selectors/PatientSelector";
import { CircularProgress } from "@material-ui/core";


export default function GWPWrapper() {

    const dispatch = useDispatch();
    const isExpandedView = useSelector(getGWPExpand);
    const isGwpComputed = useSelector(getGwpComputedSelector);

    const trendsLoader = useSelector(getTrendsLoader);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    return (
        <>
            <div className={styles.aidiGraphWrapper}>
                <div className={styles.containerHeader}>
                    <div className={styles.aidiGraphHeadingDiv}>
                        {/* <div className={styles.aidiIconWrapper}>
                            <GraphicEqIcon style={{ color: "#4E253A", height: "18px", width: "18px" }} />
                        </div> */}
                        Glucose, Weight and Pain
                        {/* <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <div className={styles.tootlTipHeading}>Glucose, Weight and Pain</div>
                                </React.Fragment>
                            }
                        >
                            <InfoOutlinedIcon color="#ffffff" style={{ height: '20px', width: '20px', fill: "#7C596A", marginRight: "5px", marginLeft: "10px" }} />
                        </HtmlTooltip> */}
                    </div>
                    <div className={styles.expandAidiButton}>
                        <IconButton style={{ padding: "8px" }} KeyboardArrowUpIcon onClick={() => dispatch(setGWPExpand(!isExpandedView))}> <div style={{ height: '18px', width: '18px', backgroundColor: "#fbf4f7", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>{isExpandedView ? (<KeyboardArrowUpIcon style={{ height: '20px', width: '20px', fill: "#4E253A" }}></KeyboardArrowUpIcon>) : (<KeyboardArrowDownIcon style={{ height: '20px', width: '20px', fill: "#4E253A" }}></KeyboardArrowDownIcon>)}</div></IconButton>
                    </div>
                </div>
                <div>
                    <div className={styles.dummyDiv}>
                    </div>
                </div>

                {isExpandedView ? trendsLoader ? (<><div className={styles.circularProgress}><CircularProgress thickness={5.0} style={{ color: `#4E253A` }} /></div></>) : isGwpComputed ? (<div className={styles.graphDiv}>
                    <div style={{ width: "100%" }}>
                        <div className={styles.legendDiv}>
                            <div className={styles.brLegendDiv}>
                                <CircleIcon style={{ height: '15px', width: '15px', color: "#F14C00", marginRight: "5px", marginLeft: "5px" }}></CircleIcon> Daily Avg GLucose (mg/dL)
                            </div>
                            <div className={styles.hrLegendDiv}>
                                <CircleIcon style={{ height: '15px', width: '15px', color: "#694FC0", marginRight: "5px", marginLeft: "5px" }}></CircleIcon> Daily Avg Weight (lbs)
                            </div>
                            <div className={styles.hrLegendDiv1}>
                                <CircleIcon style={{ height: '15px', width: '15px', color: "green", marginRight: "5px", marginLeft: "5px" }}></CircleIcon> Daily Avg Pain Level (0 - 10)
                            </div>
                        </div>
                        <RenderGWP></RenderGWP>
                    </div>
                </div>) : (<div className={styles.noDataWrapper}>
                    <div style={{ paddingTop: "20px", paddingBottom: "20px", fontFamily: "GTWalsheimProRegular", fontSize: "22px", fontWeight: "bold" }}>
                        Glucose, Weight and Pain data is not available for the selected dates
                    </div>
                </div>) : (<div style={{ width: "100%" }}></div>)}

            </div>
        </>
    );
}