import React, { useEffect, useState } from "react";
import styles from "./HelpAndSupport.module.css";
import HelpHeader from "./HelpHeader";
import { Icon } from "@material-ui/core";
import phoneIcon from "../../../../Assets/icons/Help_Phone.svg";
import emailIcon from "../../../../Assets/icons/Help_Email.svg";
import webIcon from "../../../../Assets/icons/Help_Website.svg";
import mapIcon from "../../../../Assets/icons/Help_Location.svg";
import HelpVideosFragment from "./HelpVideosFragment";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedPageName } from "../../../../Actions";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Divider from "@material-ui/core/Divider";
import FAQsAidi from "./FAQs/FAQsAidi";
import FAQsPrivacy from "./FAQs/FAQsPrivacy";
import FAQsAC from "./FAQs/FAQsAC";
import FAQsGeneral from "./FAQs/FAQsGeneral";

const useStyles = makeStyles((theme) => ({
  contentStyle: {
    display: "block",
    alignItems: "center",
  },
  acStyles: {
    backgroundColor: `#F9F4FA`,
    position: `relative`,
    padding: 0,
  },
}));

export default function HelpAndSupport() {
  const classes = useStyles();
  const [selectedFaqButton, setSelectedFaqButton] = useState("aidi");
  const [expand, setExpand] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedPageName("help"));
  }, []);

  function getFAQs() {
    switch (selectedFaqButton) {
      case "privacy":
        return <FAQsPrivacy />;
      case "aidarconnect":
        return <FAQsAC />;
      case "aidi":
        return <FAQsAidi />;
      case "general":
        return <FAQsGeneral />;
      default:
        return <FAQsAidi />;
    }
  }

  const showInMapClicked = () => {
    window.open("https://maps.google.com?q=" + "39.22854761667356" + "," + "-76.8215372576719");
  };

  return (
    <>
      <HelpHeader />
      <div className={styles.wrapper}>
        <div className={styles.contactWrapper}>
          <div className={styles.contactTitle}>Contact Us</div>
          <div className={styles.contactBody}>
            <div className={styles.phoneDiv}>
              <Icon>
                <img src={phoneIcon} height={15} width={15} />
              </Icon>
              <div className={styles.phoneNum}>(443) 875-6456</div>
            </div>
            <div className={styles.phoneDiv}>
              <Icon>
                <img src={emailIcon} height={15} width={15} />
              </Icon>
              {/* <div className={styles.phoneNum}>contact@aidar.com</div> */}
              <Link
                to="#"
                onClick={(e) => {
                  window.open("mailto:contact@aidar.com?subject=Aidar Connect Support");
                  e.preventDefault();
                }}
                className={styles.phoneNum}
              >
                contact@aidar.com
              </Link>
            </div>
            <div className={styles.phoneDiv}>
              <Icon>
                <img src={webIcon} height={15} width={15} />
              </Icon>
              <Link
                to="#"
                onClick={(e) => {
                  window.open("https://www.aidar.com");
                  e.preventDefault();
                }}
                className={styles.phoneNum}
              >
                www.aidar.com
              </Link>
            </div>
            <div className={styles.locationDiv} >
              <Icon onClick={() => showInMapClicked()} style={{ cursor: `pointer` }}>
                <img src={mapIcon} height={15} width={15} />
              </Icon>
              <div className={styles.phoneNum} >8920 State Route 108, STE B, Columbia, MD 21045</div>
            </div>
          </div>
        </div>
        <div className={styles.contactWrapper}>
          <div className={styles.contactTitle}>Demo Videos</div>
          <div className={styles.collapseWrapper}>
            <HelpVideosFragment />
          </div>
        </div>
        <div className={styles.contactWrapper} >
          <div className={styles.contactTitle}>Policies & FAQs</div>
          <div className={styles.collapseWrapper}>
            <Accordion expanded={expand} /* className={classes.acStyles} */ style={{ backgroundColor: "#F9F4FA" }} disableGutters elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandCircleDownIcon style={{ color: `#7C596A` }} onClick={() => setExpand(!expand)} />}
                aria-controls="panel2a-content"
              >
                <div className={styles.videoButtonsWrapper}>
                  <div
                    className={selectedFaqButton === "privacy" ? styles.videoButtonSelected : styles.videoButton}
                    onClick={() => setSelectedFaqButton("privacy")}
                  >
                    Privacy and Security
                  </div>
                </div>
                <div className={styles.videoButtonsWrapper}>
                  <div
                    className={selectedFaqButton === "aidarconnect" ? styles.videoButtonSelected : styles.videoButton}
                    onClick={() => setSelectedFaqButton("aidarconnect")}
                  >
                    Aidar Connect
                  </div>
                </div>
                <div className={styles.videoButtonsWrapper}>
                  <div
                    className={selectedFaqButton === "aidi" ? styles.videoButtonSelected : styles.videoButton}
                    onClick={() => setSelectedFaqButton("aidi")}
                  >
                    AIDI
                  </div>
                </div>
                <div className={styles.videoButtonsWrapper}>
                  <div
                    className={selectedFaqButton === "general" ? styles.videoButtonSelected : styles.videoButton}
                    onClick={() => setSelectedFaqButton("general")}
                  >
                    General
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.contentStyle}>
                <Divider style={{ background: '#ede9e9' }} />
                {getFAQs()}
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
}
