import { createAction } from "@reduxjs/toolkit";
import { notesConstants } from "../Constants";

export const getPatientNotes = createAction(
  notesConstants.GET_PATIENT_NOTES,
  (payload) => ({ payload })
);

export const getPatientNotesSuccess = createAction(
  notesConstants.GET_PATIENT_NOTES_SUCCESS,
  (payload) => ({ payload })
);

export const postPatientNotes = createAction(
  notesConstants.POST_PATIENT_NOTES,
  (payload) => ({ payload })
);

export const postPatientNotesSuccess = createAction(
  notesConstants.POST_PATIENT_NOTES_SUCCESS,
  (payload) => ({ payload })
);

export const updatePatientNote = createAction(
  notesConstants.UPDATE_PATIENT_NOTE,
  (payload) => ({ payload })
);

export const updatePatientNoteSuccess = createAction(
  notesConstants.UPDATE_PATIENT_NOTE_SUCCESS,
  (payload) => ({ payload })
);

export const setNotesTags = createAction(
  notesConstants.SET_NOTES_TAGS,
  (payload) => ({ payload })
);

export const setSelectedTimestamp = createAction(
  notesConstants.SET_SELECTED_TIMESTAMP,
  (payload) => ({ payload })
);

export const setPostNotesSuccessFlag = createAction(
  notesConstants.SET_POST_NOTES_SUCCESS_FLAG,
  (payload) => ({ payload })
);

export const setOpenAddNotesPanel = createAction(
  notesConstants.SET_OPEN_ADD_NOTES_PANEL,
  (payload) => ({ payload })
);

export const addNotesToRecordFromTable = createAction(
  notesConstants.ADD_NOTES_TO_RECORD_FROM_TABLE,
  (payload) => ({ payload })
);

export const selectedRecordIdForNotes = createAction(
  notesConstants.SELECTED_RECORDID_FOR_NOTES,
  (payload) => ({ payload })
);

export const getPatientNotesFailure = createAction(notesConstants.GET_PATIENT_NOTES_FAILURE);

export const postPatientNotesFailure = createAction(notesConstants.POST_PATIENT_NOTES_FAILURE);

export const updatePatientNoteFailure = createAction(notesConstants.UPDATE_PATIENT_NOTE_FAILURE);


