import 'c3/c3.css';
import c3 from "c3";
import 'c3/c3.min.css';
import styles from './RenderBRHRGraph.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBaselineColorsSelector, getBaselineLabelsSelector, getIsAddBaselineMode, getIsAddBaselineModeDone, getNewBaselineED, getNewBaselineSD, getSelectedPatientBRPayload, getSelectedPatientBaselinePayload, getSelectedPatientHRPayload } from '../../../../../Reducer/selectors/AIDISelector';
import { getAidiBaselinePayloadSuccess, setBaselineColors, setBaselineLabels, setIsAddBaselineModeDone, setIsAidiValidating, setNewBaselineED, setNewBaselineSD, setValidationMessage } from '../../../../../Actions';
import { format } from 'date-fns';

export default function RenderBRHRGraph() {

    const dispatch = useDispatch();

    const hrColumnPayload1 = useSelector(getSelectedPatientHRPayload);
    //console.log("hrColumnPayload: ", hrColumnPayload1);
    const hrColumnPayload = hrColumnPayload1.daily_avg_x ? ([hrColumnPayload1.daily_avg_x, hrColumnPayload1.daily_avg, hrColumnPayload1.measurement_1_x, hrColumnPayload1.measurement_1,
    hrColumnPayload1.measurement_2_x, hrColumnPayload1.measurement_2, hrColumnPayload1.measurement_3_x, hrColumnPayload1.measurement_3,
    hrColumnPayload1.measurement_4_x, hrColumnPayload1.measurement_4, hrColumnPayload1.measurement_5_x, hrColumnPayload1.measurement_5,
    hrColumnPayload1.measurement_6_x, hrColumnPayload1.measurement_6, hrColumnPayload1.measurement_7_x, hrColumnPayload1.measurement_7,
    hrColumnPayload1.measurement_8_x, hrColumnPayload1.measurement_8]) : [
        ['daily_avg_x'],
        ['daily_avg'],
        ['measurement_1_x'],
        ['measurement_1'],
        ['measurement_2_x'],
        ['measurement_2'],
        ['measurement_3_x'],
        ['measurement_3'],
        ['measurement_4_x'],
        ['measurement_4'],
        ['measurement_5_x'],
        ['measurement_5'],
        ['measurement_6_x'],
        ['measurement_6'],
        ['measurement_7_x'],
        ['measurement_7'],
        ['measurement_8_x'],
        ['measurement_8'],
    ];
    //console.log("hrColumnPayload: ", hrColumnPayload);


    const brColumnPayload1 = useSelector(getSelectedPatientBRPayload);
    const brColumnPayload = brColumnPayload1.daily_avg_x ? ([brColumnPayload1.daily_avg_x, brColumnPayload1.daily_avg, brColumnPayload1.measurement_1_x, brColumnPayload1.measurement_1,
    brColumnPayload1.measurement_2_x, brColumnPayload1.measurement_2, brColumnPayload1.measurement_3_x, brColumnPayload1.measurement_3,
    brColumnPayload1.measurement_4_x, brColumnPayload1.measurement_4, brColumnPayload1.measurement_5_x, brColumnPayload1.measurement_5,
    brColumnPayload1.measurement_6_x, brColumnPayload1.measurement_6, brColumnPayload1.measurement_7_x, brColumnPayload1.measurement_7,
    brColumnPayload1.measurement_8_x, brColumnPayload1.measurement_8]) : [
        ['daily_avg_x'],
        ['daily_avg'],
        ['measurement_1_x'],
        ['measurement_1'],
        ['measurement_2_x'],
        ['measurement_2'],
        ['measurement_3_x'],
        ['measurement_3'],
        ['measurement_4_x'],
        ['measurement_4'],
        ['measurement_5_x'],
        ['measurement_5'],
        ['measurement_6_x'],
        ['measurement_6'],
        ['measurement_7_x'],
        ['measurement_7'],
        ['measurement_8_x'],
        ['measurement_8'],
    ];
    //console.log("brColumnPayload: ", brColumnPayload);

    const isAddBaselineMode = useSelector(getIsAddBaselineMode);
    const isAddBaselineModeDone = useSelector(getIsAddBaselineModeDone);

    let newBaselineSD = "";
    let newBaselineED = "";


    // let chart_common_regions = [
    //     { start: '2023-10-11', end: '2023-10-15', label: "Baseline 1", paddingY: 20, class: styles.region1 },
    // ];

    let chart_common_regions = useSelector(getSelectedPatientBaselinePayload);
    //console.log("selected patient baseline payload: ", chart_common_regions);

    const baselineLabels = useSelector(getBaselineLabelsSelector);
    const baselineColors = useSelector(getBaselineColorsSelector);


    let chart_hr_bindto = "#chart-hr";
    let chart_hr_padding = {
        right: 40,
    };

    // useEffect(() => {

    //     if (newBaselineSD != "" && newBaselineED != "") {
    //         dispatch(isAddBaselineModeDone(true));
    //     }

    // }, [newBaselineSD, newBaselineED]);

    let chart_hr_data = {
        onclick: function (d, element) {
            if (isAddBaselineMode && d.id === "daily_avg" && !isAddBaselineModeDone) {
                if (newBaselineSD === "") {
                    newBaselineSD = hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)];
                    dispatch(setNewBaselineSD(hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)].toString()));
                    dispatch(setIsAddBaselineModeDone(false));
                    dispatch(setIsAidiValidating(false));
                    dispatch(setValidationMessage(""));
                } else if (newBaselineED === "") {
                    if (hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)].toString() !== newBaselineSD) {
                        newBaselineED = hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)].toString();
                        dispatch(setNewBaselineED(hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)].toString()));
                        let lastBaselineNum = parseInt(baselineLabels[baselineLabels.length - 1].slice(9));
                        let newBaselineLabel = "Baseline " + (lastBaselineNum + 1).toString();
                        const pallete = ["#cdb4db", "#ffafcc", "#f0c39d", "#758bc5", "#69b6c3", "#d272a3"];
                        let newColor = pallete[(lastBaselineNum) % 6];
                        chart_common_regions = [...chart_common_regions,
                        {
                            start: newBaselineSD, end: newBaselineED, label: newBaselineLabel, paddingY: 20, opacity: 1, class: "region" + ((lastBaselineNum + 1) % 6).toString()
                        }];
                        dispatch(getAidiBaselinePayloadSuccess(chart_common_regions));
                        baselineColors.push(newColor);
                        baselineLabels.push(newBaselineLabel);
                        dispatch(setBaselineColors(baselineColors));
                        dispatch(setBaselineLabels(baselineLabels));
                        dispatch(setIsAddBaselineModeDone(true));
                    }
                } else {
                    newBaselineED = "";
                    newBaselineSD = hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)].toString();
                    dispatch(setNewBaselineED(""));
                    dispatch(setNewBaselineSD(hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)].toString()));
                }
            }
        },
        selection: {
            enabled: isAddBaselineMode && !isAddBaselineModeDone,
            multiple: false,
            isselectable: function (d) { if (d.id === 'daily_avg') return true; },
        },
        xs: {
            daily_avg: 'daily_avg_x',
            measurement_1: 'measurement_1_x',
            measurement_2: 'measurement_2_x',
            measurement_3: 'measurement_3_x',
            measurement_4: 'measurement_4_x',
            measurement_5: 'measurement_5_x',
            measurement_6: 'measurement_6_x',
            measurement_7: 'measurement_7_x',
            measurement_8: 'measurement_8_x',
        },
        columns: hrColumnPayload,
        type: "line",
        types: {
            measurement_1: 'scatter',
            measurement_2: 'scatter',
            measurement_3: 'scatter',
            measurement_4: 'scatter',
            measurement_5: 'scatter',
            measurement_6: 'scatter',
            measurement_7: 'scatter',
            measurement_8: 'scatter',
        },
    };

    let chart_hr_zoom = {
        enabled: false,
        type: 'drag',
        rescale: true,
        onzoomend: function (d) { correctBRGraphZoom(d); }
    };

    let prevXLabel = '';

    let chart_hr_axis = {
        x: {
            type: 'timeseries',
            tick: {
                //values: hrColumnPayload[0].slice(1),
                rotate: 0,
                //count: hrColumnPayload[0].slice(1).length,
                //format: '%Y-%m-%d',
                fit: false,
                format: "%e %b",
                // format: function (d) {
                //     let curXLabel = format(d, "do MMM");
                //     if (curXLabel === prevXLabel) {
                //         return '';
                //     } else {
                //         prevXLabel = curXLabel;
                //         return curXLabel;
                //     }
                // },
                centered: true,
            }
        },
        y: {
            tick: {
                centered: true,
            },
            label: {
                text: 'Heart Rate (HR)',
                position: 'outer middle',
            }
        }
    };
    let chart_hr_legend = {
        show: false
    };
    let chart_hr_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                if (isAddBaselineMode) {
                    return "Double click to select";
                } else {
                    return format(d, "yyyy-MM-dd");
                }
            },
            value: function (value, ratio, id) {

                if (isAddBaselineMode) {
                    return value + " BPM";
                } else {
                    return value + " BPM";
                }
            }
        }
    };
    let chart_hr_color = {
        pattern: ['#694FC0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0']
    };
    let chart_hr_size = {
        height: 220,
    };
    let chart_hr_point = {
        show: true,
        r: 4,
        focus: {
            enabled: true,
            r: 6
        },
        select: {
            r: 8
        }
    };
    let chart_hr_grid = {
        y: {
            lines: [
                { value: 20 },
                { value: 30 },
                { value: 40 },
                { value: 50 },
                { value: 60 },
                { value: 70 },
                { value: 80 },
                { value: 90 },
                { value: 100 },
                { value: 110 },
                { value: 120 },
                { value: 130 },
                { value: 140 },
                { value: 150 },
                { value: 160 },
                { value: 170 },
                { value: 180 },
            ]
        }
    };

    const regenerateHRGraph = () => {
        hrGraph = c3.generate({
            bindto: chart_hr_bindto,
            padding: chart_hr_padding,
            data: chart_hr_data,
            zoom: chart_hr_zoom,
            axis: chart_hr_axis,
            legend: chart_hr_legend,
            tooltip: chart_hr_tooltip,
            color: chart_hr_color,
            size: chart_hr_size,
            regions: chart_common_regions,
            point: chart_hr_point,
            grid: chart_hr_grid,
        });
    }

    var hrGraph = c3.generate({
        bindto: chart_hr_bindto,
        padding: chart_hr_padding,
        data: chart_hr_data,
        zoom: chart_hr_zoom,
        axis: chart_hr_axis,
        legend: chart_hr_legend,
        tooltip: chart_hr_tooltip,
        color: chart_hr_color,
        size: chart_hr_size,
        regions: chart_common_regions,
        point: chart_hr_point,
        grid: chart_hr_grid,
    });


    let chart_br_bindto = "#chart-br";
    let chart_br_padding = {
        right: 40,
    };
    const sleep = ms => new Promise(r => setTimeout(r, ms));
    let chart_br_data = {
        onclick: function (d, element) {
            if (isAddBaselineMode && d.id === "daily_avg" && !isAddBaselineModeDone) {
                if (newBaselineSD === "") {
                    newBaselineSD = hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)];
                    dispatch(setNewBaselineSD(hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)].toString()));
                    dispatch(setIsAddBaselineModeDone(false));
                    dispatch(setIsAidiValidating(false));
                    dispatch(setValidationMessage(""));
                } else if (newBaselineED === "") {
                    if (hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)].toString() !== newBaselineSD) {
                        newBaselineED = hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)].toString();
                        dispatch(setNewBaselineED(hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)].toString()));
                        let lastBaselineNum = parseInt(baselineLabels[baselineLabels.length - 1].slice(9));
                        let newBaselineLabel = "Baseline " + (lastBaselineNum + 1).toString();
                        const pallete = ["#cdb4db", "#ffafcc", "#f0c39d", "#758bc5", "#69b6c3", "#d272a3"];
                        let newColor = pallete[(lastBaselineNum) % 6];
                        chart_common_regions = [...chart_common_regions,
                        {
                            start: newBaselineSD, end: newBaselineED, label: newBaselineLabel, paddingY: 20, opacity: 1, class: "region" + ((lastBaselineNum + 1) % 6).toString()
                        }];
                        dispatch(getAidiBaselinePayloadSuccess(chart_common_regions));
                        baselineColors.push(newColor);
                        baselineLabels.push(newBaselineLabel);
                        dispatch(setBaselineColors(baselineColors));
                        dispatch(setBaselineLabels(baselineLabels));
                        dispatch(setIsAddBaselineModeDone(true));
                    }
                } else {
                    newBaselineED = "";
                    newBaselineSD = hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)].toString();
                    dispatch(setNewBaselineED(""));
                    dispatch(setNewBaselineSD(hrColumnPayload[0][hrColumnPayload[0].length - (d.index + 1)].toString()));
                }
            }
        },
        selection: {
            enabled: isAddBaselineMode && !isAddBaselineModeDone,
            multiple: false,
            isselectable: function (d) { if (d.id === 'daily_avg') return true; },
        },
        xs: {
            daily_avg: 'daily_avg_x',
            measurement_1: 'measurement_1_x',
            measurement_2: 'measurement_2_x',
            measurement_3: 'measurement_3_x',
            measurement_4: 'measurement_4_x',
            measurement_5: 'measurement_5_x',
            measurement_6: 'measurement_6_x',
            measurement_7: 'measurement_7_x',
            measurement_8: 'measurement_8_x',
        },
        columns: brColumnPayload,
        type: "line",
        types: {
            measurement_1: 'scatter',
            measurement_2: 'scatter',
            measurement_3: 'scatter',
            measurement_4: 'scatter',
            measurement_5: 'scatter',
            measurement_6: 'scatter',
            measurement_7: 'scatter',
            measurement_8: 'scatter',
        },
    };
    let chart_br_zoom = {
        enabled: false,
        type: 'drag',
        rescale: true,
        onzoomend: function (d) { correctHRGraphZoom(d); },
    };
    let chart_br_axis = {
        x: {
            show: false,
            type: 'timeseries',
            tick: {
                //values: hrColumnPayload[0].slice(1),
                rotate: 0,
                //count: 30,
                //format: '%Y-%m-%d',
                fit: true,
                format: "%e %b",
                centered: false,
            },
        },
        y: {
            tick: {
                centered: true,
            },
            label: {
                text: 'Breathing Rate (BR)',
                position: 'outer-middle',
            }
        }
    };
    let chart_br_legend = {
        show: false
    };
    let chart_br_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                if (isAddBaselineMode) {
                    return "Double click to select";
                } else {
                    return format(d, "yyyy-MM-dd");
                }
            },
            value: function (value, ratio, id) {

                if (isAddBaselineMode) {
                    return value + " BRPM";
                } else {
                    return value + " BRPM";
                }
            }
        }
    };
    let chart_br_color = {
        //pattern: ['#F14C00', '#fa8f5c', '#fc8249', '#fc8249', '#fc8249', '#fc8249', '#fc8249', '#fc8249', '#fc8249']
        pattern: ['#F14C00', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c']
    };
    let chart_br_size = {
        height: 200,
    };
    let chart_br_point = {
        show: true,
        r: 4,
        focus: {
            enabled: true,
            r: 6
        },
        select: {
            r: 8
        }
    };
    let chart_br_grid = {
        y: {
            lines: [
                { value: 8 },
                //{ value: 10 },
                { value: 12 },
                //{ value: 14 },
                { value: 16 },
                //{ value: 14 },
                { value: 20 },
                //{ value: 22 },
                { value: 24 },
                //{ value: 26 },
                { value: 28 },
                //{ value: 30 },
                { value: 32 },
                //{ value: 34 },
                { value: 36 },
                //{ value: 38 },
                { value: 40 },
                //{ value: 42 },
                { value: 44 },
                //{ value: 46 },
                { value: 48 },
            ]
        }
    };

    const regenerateBRGraph = () => {
        brGraph = c3.generate({
            bindto: chart_br_bindto,
            padding: chart_br_padding,
            data: chart_br_data,
            zoom: chart_br_zoom,
            axis: chart_br_axis,
            legend: chart_br_legend,
            tooltip: chart_br_tooltip,
            color: chart_br_color,
            size: chart_br_size,
            regions: chart_common_regions,
            point: chart_br_point,
            grid: chart_br_grid,
        });
    }

    var brGraph = c3.generate({
        bindto: chart_br_bindto,
        padding: chart_br_padding,
        data: chart_br_data,
        zoom: chart_br_zoom,
        axis: chart_br_axis,
        legend: chart_br_legend,
        tooltip: chart_br_tooltip,
        color: chart_br_color,
        size: chart_br_size,
        regions: chart_common_regions,
        point: chart_br_point,
        grid: chart_br_grid,
    });

    const correctBRGraphZoom = (d) => {
        brGraph.zoom(d);
    };

    const correctHRGraphZoom = (d) => {
        hrGraph.zoom(d);
    }

    useEffect(() => {
        regenerateHRGraph();
        regenerateBRGraph();
    }, [hrColumnPayload1, brColumnPayload1]);

    if (brColumnPayload1.daily_avg && hrColumnPayload1.daily_avg && brColumnPayload1.daily_avg.length > 1 && hrColumnPayload1.daily_avg.length > 1) {
        return (
            <div>
                <div id='chart-br' style={{ marginTop: "20px", marginLeft: "10px" }} />
                <div id='chart-hr' style={{ marginLeft: "10px" }} />
            </div>
        );
    } else {
        return (
            <div style={{ paddingTop: "20px", paddingBottom: "20px", fontFamily: "GTWalsheimProRegular", fontSize: "22px", fontWeight: "bold" }}>
                No data available
            </div>
        );
    }

}