import styles from "./GeneralInfoReview.module.css";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import Select from 'react-dropdown-select';
import { Calendar } from "react-date-range";
import { addDays, format } from "date-fns";
import PROFILEPIC from "../../../../Assets/Images/ProfilePic.png";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from "react-redux";
import { getEmailExistsLoader, getPRGeneralInfo, getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import { setPRGeneralInfo, setRegistrationStatus, setShowEmailExistsLoader } from "../../../../Actions/UtilActions";
import { getIfEmailAlreadyExists } from "../../../../Actions";
import { getIfEmailAlreadyExistsSelector } from "../../../../Reducer/selectors/PatientSelector";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { getSelectedProjectIdAndLocationId } from "../../../../Reducer/selectors/DashboardSelector";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Input } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const GeneralInfoReview = forwardRef((props, ref) => {

    const handleFocus = (event) => event.target.select();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const curRegStatus = useSelector(getRegistrationStatus);

    const generalInfo = useSelector(getPRGeneralInfo);

    const [ehrId, setEhrId] = useState(generalInfo.ehrId);
    const [prefix, setPrefix] = useState([{ value: generalInfo.prefix, label: generalInfo.prefix }]);
    const [firstName, setFirstName] = useState(generalInfo.firstName);
    const [middleName, setMiddleName] = useState(generalInfo.middleName);
    const [suffix, setSuffix] = useState([{ value: generalInfo.suffix, label: generalInfo.suffix }]);
    const [lastName, setLastName] = useState(generalInfo.lastName);
    const [prevName, setPrevName] = useState(generalInfo.prevName);
    const [dob, setDob] = useState(new Date(generalInfo.dobObj));
    const [ssn, setSsn] = useState(generalInfo.ssn);
    const [bioSex, setBioSex] = useState([{ value: generalInfo.bioSex, label: generalInfo.bioSex }]);
    const [genderIdentity, setGenderIdentity] = useState([{ value: generalInfo.genderIdentity, label: generalInfo.genderIdentity }]);
    const [sexOrientation, setSexOrientation] = useState([{ value: generalInfo.sexOrientation, label: generalInfo.sexOrientation }]);
    const [phNum, setPhNum] = useState(generalInfo.phNum);
    const [email, setEmail] = useState(generalInfo.email);
    const [address, setAddress] = useState(generalInfo.address);
    const [address2, setAddress2] = useState(generalInfo.address2);
    const [city, setCity] = useState(generalInfo.city);
    const [state, setState] = useState([{ value: generalInfo.state, label: generalInfo.state }]);
    const [zip, setZip] = useState(generalInfo.zip);
    const [country, setCountry] = useState(generalInfo.country);

    const [isCalOpen, setIsCalOpen] = useState(false);

    const [showReqWarn, setShowReqWarn] = useState(false);

    const [isEhrIdFill, setIsEhrIdFill] = useState(true);
    const [isFirstNameFill, setIsFirstNameFill] = useState(true);
    const [isLastNameFill, setIsLastNameFill] = useState(true);
    const [isDobFill, setIsDobFill] = useState(true);
    const [isSsnFill, setIsSsnFill] = useState(true);
    const [isBioSexFill, setIsBioSexFill] = useState(true);
    const [isPhNumFill, setIsPhNumFill] = useState(true);
    const [isEmailFill, setIsEmailFill] = useState(true);
    const [isAddressFill, setIsAddressFill] = useState(true);
    const [isCityFill, setIsCityFill] = useState(true);
    const [isStateFill, setIsStateFill] = useState(true);

    const doesEmailAlreadyExists = useSelector(getIfEmailAlreadyExistsSelector);
    const [emailExistsDialog, setEmailExistsDialog] = useState(false);
    const emailExistsLoader = useSelector(getEmailExistsLoader);
    const [emailExistsFlag, setEmailExistsFlag] = useState(doesEmailAlreadyExists);

    const projLoc = useSelector(getSelectedProjectIdAndLocationId);
    const selectedProjectId = projLoc.selectedProjectId;
    const selectedLocationId = projLoc.selectedLocationId;

    const [cancelRegistration, setCancelRegistration] = useState(false);

    const [isGeneralFormEditable, setIsGeneralFormEditable] = useState(false);

    const editGeneralForm = (event) => {
        event.preventDefault();
        setIsGeneralFormEditable(true);
    };

    useEffect(() => {
        setEmailExistsFlag(doesEmailAlreadyExists);
    }, [doesEmailAlreadyExists]);

    const clearAllGeneralFields = () => {
        setEhrId('');
        setPrefix([]);
        setFirstName('');
        setMiddleName('');
        setSuffix([]);
        setLastName('');
        setPrevName('');
        setDob(new Date());
        setSsn('');
        setBioSex([]);
        setGenderIdentity([]);
        setSexOrientation([]);
        setPhNum('');
        setEmail('');
        setAddress('');
        setAddress2('');
        setCity('');
        setState([]);
        setZip('');
        setCountry('');
        resetReqFills();
    };

    const resetReqFills = () => {
        setIsEhrIdFill(true);
        setIsFirstNameFill(true);
        setIsLastNameFill(true);
        setIsDobFill(true);
        setIsSsnFill(true);
        setIsBioSexFill(true);
        setIsPhNumFill(true);
        setIsEmailFill(true);
        setIsAddressFill(true);
        setIsCityFill(true);
        setIsStateFill(true);
    };

    useImperativeHandle(ref, () => {
        return {
            clearAllGeneralFields: clearAllGeneralFields,
        };
    });

    const [optionsPrefix, setOptionsPrefix] = useState([
        { value: 'Dr.', label: 'Dr.' },
        { value: 'Miss.', label: 'Miss.' },
        { value: 'Mr.', label: 'Mr.' },
        { value: 'Mrs.', label: 'Mrs.' },
        { value: 'Ms.', label: 'Ms.' },
    ]);

    const [optionsSuffix, setOptionsSuffix] = useState([
        { value: 'II', label: 'II' },
        { value: 'III', label: 'III' },
        { value: 'IV', label: 'IV' },
        { value: 'Jr.', label: 'Jr.' },
        { value: 'Sr.', label: 'Sr.' },
    ]);

    const [optionsBioSex, setOptionsBioSex] = useState([
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Unknown', label: 'Unknown' },
    ]);

    const [optionsGenderIdentity, setOptionsGenderIdentity] = useState([
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Female-to-Male (FTM)/Transgender Male/Trans Man', label: 'Female-to-Male (FTM)/Transgender Male/Trans Man' },
        { value: 'Male-to-Female (MTF)/Transgender Female/Trans Woman', label: 'Male-to-Female (MTF)/Transgender Female/Trans Woman' },
        { value: 'Genderqueer, neither exclusively male nor female', label: 'Genderqueer, neither exclusively male nor female' },
        { value: 'Additional gender category or other', label: 'Additional gender category or other' },
        { value: 'Choose not to disclose', label: 'Choose not to disclose' },
    ]);

    const [optionsSexOrientation, setOptionsSexOrientation] = useState([
        { value: 'Lesbian, Gay or Homosexual', label: 'Lesbian, Gay or Homosexual' },
        { value: 'Straight or Heterosexual', label: 'Straight or Heterosexual' },
        { value: 'Bisexual', label: 'Bisexual' },
        { value: 'Don’t Know', label: 'Don’t Know' },
        { value: 'Something Else', label: 'Something Else' },
        { value: 'Choose not to disclose', label: 'Choose not to disclose' },
    ]);

    const [optionsStates, setOptionsStates] = useState([
        { value: 'AL', label: 'AL' },
        { value: 'AK', label: 'AK' },
        { value: 'AR', label: 'AR' },
        { value: 'AZ', label: 'AZ' },
        { value: 'CA', label: 'CA' },
        { value: 'CO', label: 'CO' },
        { value: 'CT', label: 'CT' },
        { value: 'DC', label: 'DC' },
        { value: 'DE', label: 'DE' },
        { value: 'FL', label: 'FL' },
        { value: 'GA', label: 'GA' },
        { value: 'HI', label: 'HI' },
        { value: 'IA', label: 'IA' },
        { value: 'ID', label: 'ID' },
        { value: 'IL', label: 'IL' },
        { value: 'IN', label: 'IN' },
        { value: 'KS', label: 'KS' },
        { value: 'KY', label: 'KY' },
        { value: 'LA', label: 'LA' },
        { value: 'MA', label: 'MA' },
        { value: 'MD', label: 'MD' },
        { value: 'ME', label: 'ME' },
        { value: 'MI', label: 'MI' },
        { value: 'MN', label: 'MN' },
        { value: 'MO', label: 'MO' },
        { value: 'MS', label: 'MS' },
        { value: 'MT', label: 'MT' },
        { value: 'NC', label: 'NC' },
        { value: 'NE', label: 'NE' },
        { value: 'NH', label: 'NH' },
        { value: 'NJ', label: 'NJ' },
        { value: 'NM', label: 'NM' },
        { value: 'NV', label: 'NV' },
        { value: 'NY', label: 'NY' },
        { value: 'ND', label: 'ND' },
        { value: 'OH', label: 'OH' },
        { value: 'OK', label: 'OK' },
        { value: 'OR', label: 'OR' },
        { value: 'PA', label: 'PA' },
        { value: 'RI', label: 'RI' },
        { value: 'SC', label: 'SC' },
        { value: 'SD', label: 'SD' },
        { value: 'TN', label: 'TN' },
        { value: 'TX', label: 'TX' },
        { value: 'UT', label: 'UT' },
        { value: 'VT', label: 'VT' },
        { value: 'VA', label: 'VA' },
        { value: 'WA', label: 'WA' },
        { value: 'WI', label: 'WI' },
        { value: 'WV', label: 'WV' },
        { value: 'WY', label: 'WY' },
        { value: 'Other', label: 'Other' }
    ]);

    //const refOne = useRef([]);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(10),
            border: '1px solid #dadde9',
        },
    }));

    const handleCalClick = (event) => {
        if (isCalOpen) {
            setIsCalOpen(false);
        } else {
            setIsCalOpen(true);
        }
    };


    const handleCalClickCancel = (event) => {
        setIsCalOpen(false);
    };

    // useEffect(() => {
    //     document.addEventListener("keydown", hideOnEscape, true);
    //     document.addEventListener("click", hideOnClickOutside, true);
    // }, []);

    // // hide dropdown on ESC press
    // const hideOnEscape = (e) => {
    //     if (e.key === "Escape") {
    //         handleCalClick(e);
    //     }
    // };

    // // Hide dropdown on outside click
    // const hideOnClickOutside = (e) => {
    //     if (refOne.current && !refOne.current.contains(e.target)) {
    //         handleCalClick(e);
    //     }
    // };

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const markStepCompleted = () => {
        const newRegStatus = {
            step1: true,
            step2: curRegStatus.step2,
            step3: curRegStatus.step3,
            step4: curRegStatus.step4,
            step5: curRegStatus.step5,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
    };

    const setTabInfo = () => {
        const payload = {
            ehrId: ehrId,
            prefix: prefix[0] ? prefix[0].value : '',
            firstName: firstName,
            middleName: middleName,
            suffix: suffix[0] ? suffix[0].value : '',
            lastName: lastName,
            prevName: prevName,
            dob: format(dob, "yyyy-MM-dd"),
            ssn: ssn,
            bioSex: bioSex[0] ? bioSex[0].value : '',
            genderIdentity: genderIdentity[0] ? genderIdentity[0].value : '',
            sexOrientation: sexOrientation[0] ? sexOrientation[0].value : '',
            phNum: phNum,
            email: email,
            address: address,
            address2: address2,
            city: city,
            state: state[0] ? state[0].value : '',
            zip: zip,
            country: country,
            dobObj: dob,
        };
        console.log("generalInfoTab payload: ", payload);
        dispatch(setPRGeneralInfo(payload));
    };

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const openEmailDailog = () => {
        if (emailExistsFlag) {
            console.log('email does exist');
            setEmailExistsDialog(true);
        } else {
            console.log('doesEmailAlreadyExists: ', doesEmailAlreadyExists);
            console.log('email doesnt exist!');
        };
    }

    const checkIfEmailAlreadyExists = async () => {
        let payload = {
            email: email,
        };
        dispatch(getIfEmailAlreadyExists(payload));
        await sleep(3000);
        openEmailDailog();
    };

    const checkIfPageConditionsMet = () => {
        if ((ehrId !== '') && (firstName !== '') && (lastName !== '') && (dob !== '') && (bioSex[0] && bioSex[0].value !== '')) {
            console.log('conditions met!');
            resetReqFills();
            setShowReqWarn(false);
            return true;
        } else {
            console.log('conditions not met!');
            resetReqFills();
            if ((ehrId === '')) {
                setIsEhrIdFill(false);
            };
            if ((firstName === '')) {
                setIsFirstNameFill(false);
            };
            if ((lastName === '')) {
                setIsLastNameFill(false);
            };
            if ((dob === '')) {
                setIsDobFill(false);
            };
            // if ((ssn === '')) {
            //     setIsSsnFill(false);
            // };
            if ((!bioSex[0] || bioSex[0].value === '')) {
                setIsBioSexFill(false);
            };
            // if ((phNum === '')) {
            //     setIsPhNumFill(false);
            // };
            // if ((email === '')) {
            //     setIsEmailFill(false);
            // };
            // if ((address === '')) {
            //     setIsAddressFill(false);
            // };
            // if ((city === '')) {
            //     setIsCityFill(false);
            // };
            // if ((!state[0] || state[0].value === '')) {
            //     setIsStateFill(false);
            // };
            setShowReqWarn(true);
            return false;
        }
    };

    const handleSave = async () => {
        console.log("generalInfoTab handleSaveAndContinue");
        if (checkIfPageConditionsMet()) {
            console.log('conditions met!');
            setTabInfo();
            setIsGeneralFormEditable(false);
            //markStepCompleted();
            //}
        }
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    const handleCancel = () => {
        setIsGeneralFormEditable(false);
    };

    return (
        <div className={styles.rootDiv}>
            <div className={styles.containerDiv}>
                <div className={styles.headerDiv}>
                    <div></div>
                    <div>General Patient Information</div>
                    <div className={styles.editButtonDiv}>
                        <div className={!isGeneralFormEditable ? styles.editButton : styles.editButtonClicked} onClick={event => editGeneralForm(event)}>Edit</div>
                    </div>
                </div>
                <div className={styles.dumDiv}>
                    <div className={styles.contentDiv}>
                        <div className={styles.column1}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    EHR or MRN ID <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                    <div className={styles.infoButton}>
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <div className={styles.circlesList}>
                                                        <div className={styles.circlesListItem}>EHR - Electronic Medical Record (Hospital)</div>
                                                        <div className={styles.circlesListItem}>MRN - Medical Record Number (Nursing)</div>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        >
                                            <InfoOutlinedIcon style={{ height: '14px', width: '14px', fill: "#A040AB", marginRight: "5px", marginLeft: "10px" }} />
                                        </HtmlTooltip>
                                    </div>
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={ehrId} disabled={!isGeneralFormEditable} onChange={(e) => setEhrId(e.currentTarget.value)} className={isGeneralFormEditable ? isEhrIdFill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv2}>
                                <div className={styles.subFieldDiv}>
                                    <div className={styles.subFieldName}>Prefix</div>
                                    <div className={styles.subFieldInput}>
                                        <Select
                                            disabled={!isGeneralFormEditable}
                                            contentRenderer={customContentRenderer}
                                            className={styles.dropdown}
                                            values={prefix}
                                            onChange={setPrefix}
                                            options={optionsPrefix}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                                <div className={styles.subFieldDiv}>
                                    <div className={styles.subFieldName}>First Name <span style={{ color: 'red', paddingLeft: "2px" }}>*</span></div>
                                    <div className={styles.subFieldInput}>
                                        <input type="text" value={firstName} disabled={!isGeneralFormEditable} onChange={(e) => setFirstName(e.currentTarget.value)} className={isGeneralFormEditable ? isFirstNameFill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Middle Name
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={middleName} disabled={!isGeneralFormEditable} onChange={(e) => setMiddleName(e.currentTarget.value)} className={isGeneralFormEditable ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv2}>
                                <div className={styles.subFieldDiv}>
                                    <div className={styles.subFieldName}>Last Name <span style={{ color: 'red', paddingLeft: "2px" }}>*</span></div>
                                    <div className={styles.subFieldInput}>
                                        <input type="text" value={lastName} disabled={!isGeneralFormEditable} onChange={(e) => setLastName(e.currentTarget.value)} className={isGeneralFormEditable ? isLastNameFill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.subFieldDiv}>
                                    <div className={styles.subFieldName}>Suffix</div>
                                    <div className={styles.subFieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={styles.dropdown}
                                            values={suffix}
                                            onChange={setSuffix}
                                            options={optionsSuffix}
                                            color="#4E253A"
                                            disabled={!isGeneralFormEditable}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Preferred Name
                                    {/* <div className={styles.infoButton}>
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <div className={styles.circlesListItem}> Please Indicate if your last name changed </div>
                                                    </React.Fragment>
                                                }
                                            >
                                                <InfoOutlinedIcon style={{ height: '14px', width: '14px', fill: "#A040AB", marginRight: "5px", marginLeft: "10px" }} />
                                            </HtmlTooltip>
                                        </div> */}
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={prevName} disabled={!isGeneralFormEditable} onChange={(e) => setPrevName(e.currentTarget.value)} className={isGeneralFormEditable ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    DOB (YYYY-MM-DD) <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <Input
                                        value={dob === '' ? `` : `${format(dob, "yyyy-MM-dd")}`}
                                        readOnly
                                        className={isGeneralFormEditable ? isDobFill ? styles.inputFieldSelctedDOB : styles.inputFieldSelctedDOBReq : styles.inputFieldSelctedDOBUE}
                                        onClick={(event) => handleCalClick(event)}
                                        disableUnderline={true}
                                        disabled={!isGeneralFormEditable}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(event) => handleCalClick(event)}
                                                    disabled={!isGeneralFormEditable}
                                                >
                                                    <CalendarTodayIcon sx={{ color: "#4E253A", height: "20px !important", weight: "20px !important" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {isCalOpen && (
                                        <div className={styles.datePickerWrapper}>
                                            <Calendar
                                                onChange={item => setDob(item)}
                                                editableDateInputs={false}
                                                showSelectionPreview={true}
                                                months={1}
                                                date={dob}
                                                direction="horizontal"
                                                maxDate={addDays(new Date(), 1)}
                                                color={["#aa346f"]}
                                                disabled={!isGeneralFormEditable}
                                            />
                                            <div className={styles.applyButtonDiv}>
                                                <div className={styles.cancelButton} onClick={(event) => handleCalClickCancel(event)}> Cancel </div>
                                                <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Confirm </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.column2}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    SSN
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" placeholder={"123-45-6789"} value={ssn} disabled={!isGeneralFormEditable} onChange={(e) => setSsn(e.currentTarget.value)} className={isGeneralFormEditable ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Sex at Birth <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={isBioSexFill ? styles.dropdown2 : styles.dropdownReq2}
                                        values={bioSex}
                                        onChange={setBioSex}
                                        options={optionsBioSex}
                                        color="#4E253A"
                                        disabled={!isGeneralFormEditable}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Gender Identity
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={styles.dropdown2}
                                        values={genderIdentity}
                                        onChange={setGenderIdentity}
                                        options={optionsGenderIdentity}
                                        color="#4E253A"
                                        disabled={!isGeneralFormEditable}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Sexual Orientation
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={styles.dropdown2}
                                        values={sexOrientation}
                                        onChange={setSexOrientation}
                                        options={optionsSexOrientation}
                                        color="#4E253A"
                                        disabled={!isGeneralFormEditable}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Phone Number
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" placeholder={"(123)456-7890"} value={phNum} disabled={!isGeneralFormEditable} onChange={(e) => setPhNum(e.currentTarget.value)} className={isGeneralFormEditable ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Email Address
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={email} disabled={!isGeneralFormEditable} onChange={(e) => setEmail(e.currentTarget.value)} className={isGeneralFormEditable ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column3}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Address
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={address} disabled={!isGeneralFormEditable} onChange={(e) => setAddress(e.currentTarget.value)} className={isGeneralFormEditable ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Address Line 2
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={address2} disabled={!isGeneralFormEditable} onChange={(e) => setAddress2(e.currentTarget.value)} className={isGeneralFormEditable ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    City
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={city} disabled={!isGeneralFormEditable} onChange={(e) => setCity(e.currentTarget.value)} className={isGeneralFormEditable ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    State
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={isStateFill ? styles.dropdown2 : styles.dropdownReq2}
                                        values={state}
                                        onChange={setState}
                                        options={optionsStates}
                                        color="#4E253A"
                                        disabled={!isGeneralFormEditable}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Zip Code
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={zip} disabled={!isGeneralFormEditable} onChange={(e) => setZip(e.currentTarget.value)} className={isGeneralFormEditable ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Country
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={country} disabled={!isGeneralFormEditable} onChange={(e) => setCountry(e.currentTarget.value)} className={isGeneralFormEditable ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column4}>
                            <div className={styles.proPicDiv}>
                                <div className={styles.fieldNameP}>
                                    Profile Picture
                                </div>
                                <div className={styles.picUpDiv}>
                                    <div className={styles.picDiv}>
                                        <img src={PROFILEPIC} alt="Profile Picture" className={styles.picStyle} />
                                    </div>
                                    <div className={styles.upButtonsDiv}>
                                        <div className={styles.uploadButton}>
                                            <DriveFolderUploadIcon style={{ height: '30px', width: '30px', fill: "#A040AB" }} />
                                        </div>
                                        <div className={styles.deleteButton}>
                                            <DeleteOutlineIcon style={{ height: '30px', width: '30px', fill: "#A040AB" }} />
                                        </div>
                                    </div>
                                    <div className={styles.warningMsg}>
                                        Please upload image less than 5MB in size
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showReqWarn && <div className={styles.consentWarningDiv}>
                        Please fill out the required fields marked with an *
                    </div>}
                    {isGeneralFormEditable && (<div className={styles.pageButtonsDiv}>
                        {/* <div className={styles.cancelButton1} onClick={handleCancel}>Cancel</div> */}
                        <div className={styles.saveButton} onClick={handleSave}>Save</div>
                    </div>)}
                </div>
            </div>
        </div>
    );
});

export default GeneralInfoReview;