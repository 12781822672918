export const timerConstants = {

    GET_TIMER_INFO: 'GET_TIMER_INFO',
    GET_TIMER_INFO_SUCCESS: 'GET_TIMER_INFO_SUCCESS',
    GET_TIMER_INFO_FAILURE: 'GET_TIMER_INFO_FAILURE',
    ADD_TIMER_WITH_NOTES: 'ADD_TIMER_WITH_NOTES',
    ADD_TIMER_WITH_NOTES_SUCCESS: 'ADD_TIMER_WITH_NOTES_SUCCESS',
    ADD_TIMER_WITH_NOTES_FAILURE: 'ADD_TIMER_WITH_NOTES_FAILURE',
    SET_LATEST_MONTH_TIMER_INFO: 'SET_LATEST_MONTH_TIMER_INFO',
    SET_PREVIOUS_MONTH_TIMER_INFO: 'SET_PREVIOUS_MONTH_TIMER_INFO',
    GET_IS_LAST_TIMER_INCOMPLETE: 'GET_IS_LAST_TIMER_INCOMPLETE',
    GET_IS_LAST_TIMER_INCOMPLETE_SUCCESS: 'GET_IS_LAST_TIMER_INCOMPLETE_SUCCESS',
    GET_IS_LAST_TIMER_INCOMPLETE_FAILURE: 'GET_IS_LAST_TIMER_INCOMPLETE_FAILURE',
}