import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Field, ErrorMessage, setNestedObjectValues } from "formik";
import { TextField } from "@material-ui/core";
import inputStyles from "./DropDown.module.css";

const useStyles = makeStyles({
  root: {
    "& ul": {
      backgroundColor: "#ffffff",
    },
    "& li": {
      fontSize: 15,
    },
    "& .MuiInputBase-input": {
      paddingRight: 5,
    },
  },
});

export default function DropDown(props) {
  const [valueNF, setValueNF] = useState("");
  const { label, name, options, value, ...rest } = props;
  const classes = useStyles();

  const menuprops = {
    classes: {
      paper: classes.root,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    setValueNF(e.target.value);
  };

  return (
    <div>
      <label htmlFor={name} className={inputStyles.labelStyles}>
        {label}
      </label>
      <FormControl className={inputStyles.select}>
        <Select
          disableUnderline
          value={valueNF}
          id={name}
          name={name}
          {...rest}
          onChange={handleOnChange}
          //classes={inputStyles.select }
          className={inputStyles.select}
          MenuProps={menuprops}
          defaultValue=""
        >
          {/* <MenuItem key={""} value={""}>
            No Selected // Or Empty
          </MenuItem> */}

          {options.map((option) => {
            return (
              <MenuItem
                key={option.key}
                value={option.value}
                className={inputStyles.list}
                // ref={null}
                // MenuProps={menuprops}
              >
                {option.value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
