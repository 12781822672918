import React, { useEffect, useState, useContext } from "react";
// import styles from "./AssignMouthLab.module.css";
import styles from "../../../HomePage/DeviceLog/MouthLabLogs/MLActions/AssignMl.module.css"
import { Formik, Form, FieldArray } from "formik";
import * as yup from "yup";
import Grid from "@material-ui/core/Grid";
import FormikControl from "../../../../FormControls/FormikControl";
import { Fragment } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import AssignDeviceWithUsagePlan from "../../../../API/AssignDeviceWithUsagePlan/AssignDeviceWithUsagePlan";
import GetAvailableDevicesByProjectAndLocation from "../../../../API/AvailableMouthlabs/GetAvailableDevices";
import { UserContext } from "../../../../Store/UserContext.js";
import { PatientContext } from "../../../../Store/PatientDataProvider";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMlId } from "../../../../../Actions/DashboardActions.js";
import { getCurrentPatientGUID } from "../../../../../Reducer/selectors/UtilSelector.js";
import { getPatientInfo } from "../../../../../Actions/PatientActions.js";

export default function AssignMouthlab(props) {
  const [availableDevices, setAvailableDevices] = useState([]);
  const [isProgress, setIsProgress] = useState(false);
  const [isTimedMessage, setIsTimedMessage] = useState(false);
  const [response, setResponse] = useState({
    statusCode: 0,
    message: "",
  });

  const dispatch = useDispatch();

  const userContext = useContext(UserContext);
  const projectId = userContext.curPlSelections.projectId;
  const locationId = userContext.curPlSelections.locationId;
  const patientContext = useContext(PatientContext);
  const currPatientGUID = useSelector(getCurrentPatientGUID);
  const usageOptions = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
  ];

  useEffect(() => {
    GetAvailableDevicesByProjectAndLocation(projectId, locationId).then((res) => {
      setAvailableDevices(res.map((x) => x.deviceId));
    });
  }, [projectId, locationId]);

  const initialValues = {
    devices: [
      {
        subjectId: props.currentSubjectID,
        name: "",
        deviceId: "",
        usage: "",
      },
    ],
  };
  const validationSchema = yup.object().shape({
    devices: yup.array().of(
      yup.object().shape({
        deviceId: yup.string().required("Required"),
        usage: yup.string().required("Required"),
      })
    ),
  });

  const handleSubmit = (values) => {
    setIsProgress(true);
    AssignDeviceWithUsagePlan(values.devices[0]).then((res) => {
      dispatch(getPatientInfo(currPatientGUID));
      setResponse({
        statusCode: res.response ? res.response.status : res.error.response.status,
        message: res.response ? res.response.data : res.error.response.data,
      });
      setIsProgress(false);
      setIsTimedMessage(true);
      setTimeout(() => {
        setIsTimedMessage(false);
        patientContext.setIsRefresh(true);
        props.closeModal();
        patientContext.setCurrentMouthLabId(values.devices[0].deviceId);
        console.log('setSelectedMlId');
        dispatch(setSelectedMlId(values.devices[0].deviceId));
      }, 500);
    });
  };

  const btnDisabled = isProgress || isTimedMessage;

  return (
    <Fragment>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ values }) => (
          <Form>
            <FieldArray name="devices">
              {({ insert, remove, push }) => (
                <div>
                  {values.devices.length > 0 &&
                    values.devices.map((device, index) => (
                      <div key={index}>
                        <Grid container>
                          <Grid container>
                            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                              <div className={styles.inputCenterDiv}>
                                <div className={styles.radioLabelForm}>Select MouthLab ID</div>
                                <FormikControl
                                  value="deviceId"
                                  control="dynamicSelect"
                                  label=""
                                  name={`devices.${index}.deviceId`}
                                  options={availableDevices}
                                />
                              </div>
                            </Grid>

                            <Grid container>
                              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                                <div className={styles.inputCenterDiv}>
                                  <div className={styles.radioLabelForm}>Usage per day</div>
                                  <FormikControl
                                    value="usage"
                                    control="dynamicSelect"
                                    label=""
                                    name={`devices.${index}.usage`}
                                    options={usageOptions}
                                  />
                                </div>
                              </Grid>
                            </Grid>

                            {isProgress && (
                              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                                <div className={styles.progressResponseDiv}>
                                  <div className={styles.overlayProgressBar}>
                                    <CircularProgress style={{ color: `#4E253A` }} />
                                    <div className={styles.progressMessage}>Processing, please wait.</div>
                                  </div>
                                </div>
                              </Grid>
                            )}
                            {response.message && (
                              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                                <div className={styles.progressResponseDiv}>
                                  <div className={styles.overlayProgressBar}>
                                    <div className={`${styles.statusMessage} ${response.statusCode == 200 ? styles.smSuccess : styles.smError}`}>
                                      {response.message}
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            )}

                            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                              <div className={styles.buttonContainer}>
                                <button type="button" onClick={props.closeModal} className={styles.cancelButton} disabled={btnDisabled}>
                                  Cancel
                                </button>
                                <button type="submit" className={styles.submitButton} disabled={btnDisabled}>
                                  Confirm
                                </button>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}
