import axios from "axios";
import domainCF from "../domainCF.js";

export default async function PostAssignMouthLabToSubject(assignment_data) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const POST_ASSIGN_MOUTHLAB_TO_SUBJECT = `${domainCF}/api/assignDeviceToSubject`;
  const dataAsParams = `subjectId=${assignment_data.subjectId}&deviceId=${assignment_data.deviceId}`;
  try {
    const response = await axios.post(POST_ASSIGN_MOUTHLAB_TO_SUBJECT, dataAsParams, { headers });
    console.log(`Response from ${POST_ASSIGN_MOUTHLAB_TO_SUBJECT}...`, response);
    return { response: response };
  } catch (error) {
    console.log(`Error from ${POST_ASSIGN_MOUTHLAB_TO_SUBJECT}...`, error, error.response);
    return { error: error };
  }
}
