import { subDays } from "date-fns";

export function getSelectedPatientHRPayload({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.aidiHrPayload
    )
        return aidiReducer.aidiHrPayload;
    else return [];
};

export function getSelectedPatientBRPayload({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.aidiBrPayload
    )
        return aidiReducer.aidiBrPayload;
    else return [];
};

export function getNewBaselineSD({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.newBaselineSD
    )
        return aidiReducer.newBaselineSD;
    else return "";
};

export function getNewBaselineED({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.newBaselineED
    )
        return aidiReducer.newBaselineED;
    else return "";
};

export function getIsAddBaselineMode({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.isAddBaselineMode
    )
        return aidiReducer.isAddBaselineMode;
    else return false;
};

export function getIsAddBaselineModeDone({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.isAddBaselineModeDone
    )
        return aidiReducer.isAddBaselineModeDone;
    else return false;
};

//Aidi Baseline
export function getAllAidiBaselinesSelector({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.aidiBaselines
    )
        return aidiReducer.aidiBaselines;
    else return [];
}

export function postAidiBaselineSelector({ aidiReducer }) {
    if (
        aidiReducer &&
        aidiReducer.aidiBaselines
    )
        return aidiReducer.aidiBaselines;
    else return [];
}

export function postAidiBaselineSuccessorSelector({ aidiReducer }) {
    if (
        aidiReducer
    )
        return aidiReducer.aidiBaselineAdded;
    else return false;
}

export function getSelectedAIDITabId({ aidiReducer }) {
    if (
        aidiReducer.selectedAIDITabId
    )
        return aidiReducer.selectedAIDITabId;
    else return 0;
}

export function getSelectedPatientBaselinePayload({ aidiReducer }) {
    if (
        aidiReducer.aidiBaselinePayload
    )
        return aidiReducer.aidiBaselinePayload;
    else return [];
}

export function getIsThereNewBaselineRec({ aidiReducer }) {
    if (
        aidiReducer.isThereNewBaselineRec
    )
        return aidiReducer.isThereNewBaselineRec;
    else return false;
}

export function getIsAidiValidating({ aidiReducer }) {
    if (
        aidiReducer.isValidating
    )
        return aidiReducer.isValidating;
    else return false;
}

export function getAidiValidationMessage({ aidiReducer }) {
    if (
        aidiReducer.validationMessage
    )
        return aidiReducer.validationMessage;
    else return "";
}

export function getAidiRecordTableSelector({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.aidiRecordsTable
    )
        return aidiReducer.aidiRecordsTable;
    else return [];
}

export function getAidiGraphPayloadSelector({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.aidiGraphPayload
    )
        return aidiReducer.aidiGraphPayload;
    else return {};
}

export function getIsAidiComputedSelector({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.isAidiComputed
    )
        return aidiReducer.isAidiComputed;
    else return false;
}

export function getBaselineLabelsSelector({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.baselineLabels
    )
        return aidiReducer.baselineLabels;
    else return [];
}

export function getBaselineColorsSelector({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.baselineColors
    )
        return aidiReducer.baselineColors;
    else return [];
}

export function getAidiColorsSelector({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.aidiColors
    )
        return aidiReducer.aidiColors;
    else return [];
}

export function getAidiDateRangeSelector({ aidiReducer }) {
    if (aidiReducer &&
        aidiReducer.aidiDateRange
    )
        return aidiReducer.aidiDateRange;
    else return [{
        startDate: subDays(new Date(), 120),
        endDate: new Date(),
        key: "selection",
    }];
}