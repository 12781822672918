import { useDispatch, useSelector } from "react-redux";
import NAHeader from "../HomePage/Header/NAHeader";
import styles from "./PCCPatientDetails.module.css";
import { getIsCalOngoing, getUserProfile } from "../../../Reducer/selectors/DashboardSelector";
import HubIcon from '@mui/icons-material/Hub';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { getSelectedMlIdSelector } from "../../../Reducer/selectors/DashboardSelector";
import { getSubjectHasMl, setSelectedMlId, setShowMEDrawer, setShowQMDrawer } from "../../../Actions";
import WarningIcon from '@mui/icons-material/Warning';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import Paper from "@mui/material/Paper";
import { getPCCUserProfile } from "../../../Actions";
import { useEffect, useState, useRef } from "react";
import { getPCCUserProfileSelector, getUserHasML } from "../../../Reducer/selectors/NursingDashboardSelector";
import { getSelectedPCCPatientSelector } from "../../../Reducer/selectors/DashboardSelector";
import ProfileIcon from "../../../Assets/Images/ProfileIcon.png";
import { ReactComponent as MouthlabIcon } from "../../../Assets/icons/MouthlabIcon.svg";
import Avatar from "@material-ui/core/Avatar";
import { Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import QMPannel from "./QuickMeasurementPannel/QMPannel";
import MEPannel from "./ManualEntryPannel/MEPannel";
import BpCalibrationND from "../PatientRegistration/RegistrationForms/Calibration/BpCalibrationND";
import PostAssignMouthLabToSubject from "../../API/PostAssignMouthLabToSubject/PostAssignMouthLabToSubject";
import PostUnassignMouthLabFromSubject from "../../API/PostUnassignMouthLabFromSubject/PostUnassignMouthLabFromSubject";
import { getPatientVitalsList } from "../../../Actions";
import { getPatientVitalsListSelector } from "../../../Reducer/selectors/PatientSelector";
import { getSelectedProjectIdAndLocationId } from "../../../Reducer/selectors/DashboardSelector";
import { setPrevMeasurement } from "../../../Actions";
import CalibrationMeasurementPannel from "./CalibrationMeasurementPannel/CalibrationMeasurementPannel";
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import IconButton from "@material-ui/core/IconButton";
import UnselectML from "../HomePage/DeviceLog/MouthLabLogs/MLActions/UnselectML";
import Backdrop from '@mui/material/Backdrop';
import Grid from "@material-ui/core/Grid";

export default function PCCPatientDetails() {
    const profile = useSelector(getUserProfile);
    const selectedML = useSelector(getSelectedMlIdSelector);
    const dispatch = useDispatch();
    const selectedPCCPatient = useSelector(getSelectedPCCPatientSelector);
    const pccUserProfile = useSelector(getPCCUserProfileSelector);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedTabProfile, setSelectedTabProfile] = useState(0);
    const ref = useRef(null);
    const ref2 = useRef(null);
    const [isWarningShown, setIsWarningShown] = useState(false);
    const [response, setResponse] = useState({
        statusCode: 0,
        message: "",
    });
    const projLoc = useSelector(getSelectedProjectIdAndLocationId);
    const selectedProjectId = projLoc.selectedProjectId;
    const selectedLocationId = projLoc.selectedLocationId;
    const currMeasurement = useSelector(getPatientVitalsListSelector);
    const [showDialog, setShowDialog] = useState(false);
    const isCalibrationOngoing = useSelector(getIsCalOngoing);
    const isCurrUserPaired = useSelector(getUserHasML);
    const [showDialogforUserPaired, setShowDialogforUserPaired] = useState(false);

    useEffect(() => {
        const payload = {
            subjectId: pccUserProfile.subjectId
        }
        dispatch(getSubjectHasMl(payload));
    }, [pccUserProfile.subjectId]);

    const handlePairAndMeasure = () => {

        const payload = {
            subjectId: pccUserProfile.subjectId
        }
        dispatch(getSubjectHasMl(payload));

        if (isCurrUserPaired) {
            setShowDialogforUserPaired(true);
        } else {
            dispatch(getPatientVitalsList(pccUserProfile.guid));
            dispatch(setPrevMeasurement(currMeasurement));
            let values = {
                deviceId: selectedML,
                subjectId: pccUserProfile.subjectId,
            }

            PostAssignMouthLabToSubject(values).then((res) => {
                setResponse({
                    statusCode: res.response ? res.response.status : res.error.response.status,
                    message: res.response ? res.response.data : res.error.response.data,
                });
            });

            ref.current.handleQuickDrawerOpen();
        }
    };

    const handleManualEntry = () => {
        ref2.current.handleQuickDrawerOpen();
    }

    const unpairML = () => {
        setShowDialog(true);
        // PostUnassignMouthLabFromSubject({ deviceId: selectedML }).then((res) => {

        // });
        // navigate(`/deviceAssignments`);
    }

    function closeDialogMenu(event, reason) {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setShowDialog(false);
        }
    };

    function closeDialogDialogforUserPaired(event, reason) {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setShowDialogforUserPaired(false);
        }
    };


    useEffect(() => {
        const payload = { 'patientId': selectedPCCPatient.patientId, 'projectId': selectedProjectId, 'locationId': selectedLocationId };
        dispatch(getPCCUserProfile(payload));
    }, [selectedPCCPatient]);

    // useEffect(() => {
    //     let subjectGUID = pccUserProfile.guid;
    //     dispatch(getPatientVitalsList(subjectGUID));
    // }, [currMeasurement]);

    useEffect(() => {
        return () => {
            window.addEventListener("beforeunload", function (e) {

                // let confirmationMessage = "o/";
                // (e || window.event).returnValue = confirmationMessage; //Gecko + IE
                // console.log("logout !");
                // return confirmationMessage; //Webkit, Safari, Chrome

                PostUnassignMouthLabFromSubject({ deviceId: selectedML }).then((res) => {
                    setResponse({
                        statusCode: res.response ? res.response.status : res.error.response.status,
                        message: res.response ? res.response.data : res.error.response.data,
                    });
                });
                dispatch(setShowQMDrawer(false));
                dispatch(setShowMEDrawer(false));
            });
        }
    });

    const AntTab = withStyles((theme) => ({
        root: {
            textTransform: "none",
            paddingTop: `10px`,
            color: "#4E253A",
            background: `#ffffff`,
            borderRight: "1px solid #cccaca",
            fontSize: `18px`,
            fontFamily: ["GTWalsheimProRegular"].join(","),
            fontWeight: theme.typography.fontWeightBold,
            "&:last-child": {
                borderRight: `none`,
            },
            "&:hover": {
                color: "#4E253A",
                //opacity: 1,
            },
            "&:focus": {
                color: "#4E253A",
            },
            //selected tab styles
            "&$selected": {
                color: "#fff",
                backgroundColor: "#4E253A",
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        selected: {},
    }))((props) => <Tab disableRipple {...props} />);

    const AntTabs = withStyles({
        root: {
            boxShadow: "0px 1px 5px 0px #cccaca",
            alignItems: "right",
            marginTop: `20px`,
            borderTopRightRadius: `10px`,
            borderTopLeftRadius: `10px`,
            backgroundColor: `#E3E3E4`,
            width: "100%",
        },
        indicator: {
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: "100%",
                width: "100%",
                backgroundColor: "#4E253A",
            },
        },
    })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleChangeTabProfile = (event, newValue) => {
        setSelectedTabProfile(newValue);
    };

    const AntTabs1 = withStyles({
        root: {
            boxShadow: "0px 1px 5px 0px #cccaca",
            alignItems: "right",
            borderBottomRightRadius: `10px`,
            borderBottomLeftRadius: `10px`,
            backgroundColor: `#E3E3E4`,
            marginLeft: `1px`,
        },
        indicator: {
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: "99%",
                width: "100%",
                backgroundColor: "#4E253A",
            },
        },
    })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

    const AntTab1 = withStyles((theme) => ({
        root: {
            textTransform: "none",
            paddingTop: `10px`,
            color: "#7C596A",
            background: `#e9ecef`,
            minWidth: 72,
            borderRight: "1px solid #cccaca",
            fontSize: `16px`,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: ["GTWalsheimProRegular"].join(","),
            "&:last-child": {
                borderRight: `none`,
            },
            "&:hover": {
                color: "#4E253A",
                //opacity: 1,
            },
            "&:focus": {
                color: "#4E253A",
            },
            //selected tab styles
            "&$selected": {
                color: "#4E253A",
                backgroundColor: "#fff",
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        selected: {},
    }))((props) => <Tab disableRipple {...props} />);

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    return (
        <div className={styles.accContainer}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={showDialog || showDialogforUserPaired}
            ></Backdrop>
            <main></main>
            <NAHeader userEmail={profile.email} />
            <div className={styles.selectedMLDiv}>
                <div className={styles.MLIdDiv}>
                    <HubIcon sx={{ fontSize: '35px', color: "#ffffff" }}></HubIcon>
                    ML - {selectedML}
                </div>
                <div className={styles.unpairButton} onClick={unpairML}>
                    Change
                    <CancelOutlinedIcon></CancelOutlinedIcon>
                </div>
            </div>
            <Paper className={styles.paperDiv} varient="outlined" elevation={4}>
                <div className={styles.profileSummary}>
                    <div className={styles.pfpDiv}>
                        <div className={styles.imgDiv}>
                            <Avatar style={{ height: "60px", width: "60px" }} alt="avatar_patient" src={ProfileIcon}></Avatar>
                        </div>
                        <div className={styles.nameAndIdDiv}>
                            <div className={styles.patientName}> <h2 style={{ width: '160px' }}>{selectedPCCPatient.fullName}</h2> </div>
                            <div className={styles.patientId}> {pccUserProfile.patientId} </div>
                        </div>
                    </div>
                    <div className={styles.otherDetails}>
                        <div className={styles.otherDetails1}>
                            <div className={styles.infoLabel}> Location : <div className={styles.infoContent}> {pccUserProfile.unitDesc + pccUserProfile.roomDesc + "-" + pccUserProfile.bedDesc} </div> </div>
                            <div className={styles.infoLabel}> Floor : <div className={styles.infoContent}> {pccUserProfile.floorDesc} </div> </div>
                            <div className={styles.infoLabel}> Gender : <div className={styles.infoContent}> {pccUserProfile.gender} </div> </div>
                        </div>
                        <Divider style={{ width: "100%" }}></Divider>
                        <div className={styles.otherDetails2}>
                            <div className={styles.infoLabel}> Unit ID : <div className={styles.infoContent}> {selectedPCCPatient.unitId} </div> </div>
                            <div className={styles.infoLabel}> Status : <div className={styles.infoContent}> {pccUserProfile.status} </div> </div>
                        </div>
                        <div className={styles.otherDetails2}>
                        </div>
                    </div>
                </div>

                <AntTabs value={selectedTab} onChange={handleChangeTab} aria-label="ant example" variant="fullWidth">
                    <AntTab label="Profile" />
                    <AntTab label="Notes" />
                    <AntTab label="Calibration" />
                    <AntTab label="Medication" />
                </AntTabs>
                {selectedTab === 0 && (<AntTabs1 value={selectedTabProfile} onChange={handleChangeTabProfile} aria-label="ant example" variant="fullWidth">
                    <AntTab1 label="Patient Info" />
                    <AntTab1 label="Physician Info" />
                    <AntTab1 label="Diagnoses" />
                    <AntTab1 label="Allergies" />
                </AntTabs1>)}

                {selectedTab === 0 && <Paper className={styles.patientInfoDiv} varient="outlined" elevation={2} style={{ maxHeight: '36vh', overflowY: 'scroll' }}>
                    {selectedTabProfile === 0 && (
                        <div className={styles.patientInfo}>
                            <div className={styles.patientInfoTitle}>
                                Patient Info
                            </div>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoLabel}>
                                    DOB:
                                </div>
                                <div className={styles.patientInfoContent}>
                                    {pccUserProfile.birthDate}
                                </div>
                            </div>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoLabel}>
                                    Age:
                                </div>
                                <div className={styles.patientInfoContent}>
                                    {getAge(pccUserProfile.birthDate)}
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedTabProfile === 1 && (
                        <div className={styles.patientInfo}>
                            <div className={styles.patientInfoTitle}>
                                Physician Info
                            </div>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoLabel}>
                                    Name:
                                </div>
                                <div className={styles.patientInfoContent}>
                                    {pccUserProfile.physicians[0].firstName + " " + pccUserProfile.physicians[0].lastName}
                                </div>
                            </div>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoLabel}>
                                    Email:
                                </div>
                                <div className={styles.patientInfoContent}>
                                    {pccUserProfile.physicians[0].email ? pccUserProfile.physicians[0].email : "N/A"}
                                </div>
                            </div>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoLabel}>
                                    Phone:
                                </div>
                                <div className={styles.patientInfoContent}>
                                    {pccUserProfile.physicians[0].officePhone ? pccUserProfile.physicians[0].officePhone : "N/A"}
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedTabProfile === 2 && (
                        <div className={styles.patientInfo}>
                            <div className={styles.patientInfoTitle}>
                                Diagnosis
                            </div>
                            {pccUserProfile.diagnoses.length > 0 && pccUserProfile.diagnoses.map((diag) => {
                                return (<>
                                    <div className={styles.patientInfoItem}>
                                        <div className={styles.patientInfoLabel}>
                                            Code:
                                        </div>
                                        <div className={styles.patientInfoContent}>
                                            {diag.icdCode}
                                        </div>
                                    </div>
                                    <div className={styles.patientInfoItem}>
                                        <div className={styles.patientInfoLabel}>
                                            Description:
                                        </div>
                                        <div className={styles.patientInfoContent}>
                                            {diag.icdDesc}
                                        </div>
                                    </div>
                                    <div className={styles.patientInfoItem}>
                                        <div className={styles.patientInfoLabel}>
                                            Onset date:
                                        </div>
                                        <div className={styles.patientInfoContent}>
                                            {new Date(diag.onsetDate).toString()}
                                        </div>
                                    </div>
                                    <div className={styles.patientInfoItem}>
                                        <div className={styles.patientInfoLabel}>
                                            Rank:
                                        </div>
                                        <div className={styles.patientInfoContent}>
                                            {diag.rankDesc}
                                        </div>
                                    </div>
                                </>)
                            })}
                            {pccUserProfile.diagnoses.length === 0 && (
                                <div>
                                    No Diagnoses information available.
                                </div>
                            )}
                        </div>
                    )}

                    {selectedTabProfile === 3 && (
                        <div className={styles.patientInfo}>
                            <div className={styles.patientInfoTitle}>
                                Allergies
                            </div>
                            {pccUserProfile.allergies.length > 0 && pccUserProfile.allergies.map((alg) => {
                                return (<>
                                    <div className={styles.patientInfoItem}>
                                        <div className={styles.patientInfoLabel}>
                                            Allergen:
                                        </div>
                                        <div className={styles.patientInfoContent}>
                                            {alg.allergen ? alg.allergen : "N/A"}
                                        </div>
                                    </div>
                                    <div className={styles.patientInfoItem}>
                                        <div className={styles.patientInfoLabel}>
                                            Reaction Type:
                                        </div>
                                        <div className={styles.patientInfoContent}>
                                            {alg.reactionSubType ? alg.reactionSubType : "N/A"}
                                        </div>
                                    </div>
                                    <div className={styles.patientInfoItem}>
                                        <div className={styles.patientInfoLabel}>
                                            Type:
                                        </div>
                                        <div className={styles.patientInfoContent}>
                                            {alg.type ? alg.type : "N/A"}
                                        </div>
                                    </div>
                                    <div className={styles.patientInfoItem}>
                                        <div className={styles.patientInfoLabel}>
                                            Severity:
                                        </div>
                                        <div className={styles.patientInfoContent}>
                                            {alg.severity ? alg.severity : "N/A"}
                                        </div>
                                    </div>
                                    <br></br>
                                </>)
                            })}
                            {pccUserProfile.allergies.length === 0 && (
                                <div className={styles.patientInfoItem}>
                                    <div className={styles.patientInfoLabel}>
                                        No known allergies.
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Paper>}

                {selectedTab === 2 && <Paper className={styles.patientInfoDiv2} varient="outlined" elevation={2} style={{ maxHeight: '43vh', overflowY: 'scroll' }} >
                    <BpCalibrationND></BpCalibrationND>
                </Paper>}

            </Paper>
            {!isCalibrationOngoing && (<div className={styles.footerDiv}>
                {isWarningShown && (<div className={styles.warningDiv}>
                    <div className={styles.warningIcon}>
                        <WarningIcon sx={{ fontSize: '30px', color: "#F14C00" }}></WarningIcon>
                    </div>
                    <h2>Do not select "Start MouthLab" if you still see a blue light on the status bar.</h2>
                </div>)}
                <div className={styles.footerButtonsDiv}>
                    <div className={styles.footerButtons} onMouseEnter={() => setIsWarningShown(true)} onMouseLeave={() => setIsWarningShown(false)}>
                        <div className={styles.pairButton} onClick={(event) => handlePairAndMeasure(event)}><MouthlabIcon style={{ height: '35px', width: '35px', color: "#ffffff" }}></MouthlabIcon>Start MouthLab</div>
                        <div className={styles.enterManuallyButton} onClick={(event) => handleManualEntry(event)}><TouchAppIcon sx={{ fontSize: '30px', color: "#4E253A" }}></TouchAppIcon>Enter Manually</div>
                    </div>
                </div>
                <QMPannel ref={ref} pccUserProfile={pccUserProfile} />
                <MEPannel ref={ref2} pccUserProfile={pccUserProfile} />
                <CalibrationMeasurementPannel pccUserProfile={pccUserProfile} />
            </div >)}

            {/* MouthLab removal from subject */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={showDialog}
                onClose={(event, reason) => closeDialogMenu(event, reason)}
                hideBackdrop={true}
            >
                <div className={styles.paperUnassignML}>
                    <div className={styles.menuTitle}>
                        <div className={styles.titleText}>Unselect MouthLab</div>
                        <div>
                            <IconButton aria-label="delete">
                                <RiCloseCircleFill size={29} onClick={closeDialogMenu} style={{ color: "#4E253A" }} />
                            </IconButton>
                        </div>
                    </div>

                    <div className={styles.patientListDiv}>
                        <Grid container>
                            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                                <UnselectML currentDeviceId={selectedML} closeModal={closeDialogMenu} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Modal>

            {/* MouthLab removal from subject */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={showDialogforUserPaired}
                onClose={(event, reason) => closeDialogDialogforUserPaired(event, reason)}
                hideBackdrop={true}
            >
                <div className={styles.paperUnassignML}>
                    <div className={styles.menuTitle}>
                        <div className={styles.titleText}>Warning</div>
                        <div>
                            <IconButton aria-label="delete">
                                <RiCloseCircleFill size={29} onClick={closeDialogDialogforUserPaired} style={{ color: "#4E253A" }} />
                            </IconButton>
                        </div>
                    </div>

                    <div className={styles.patientListDiv}>
                        The subject is already assigned to another device.
                    </div>
                </div>
            </Modal>
        </div>
    );
}