import { timerConstants } from "../Constants";

const initialState = {
    timerInfo: {},
    previousMonthTimerInfo: {},
    latestMonthTimerInfo: {},
    isLastTimerIncomplete: false,
};

export function timerReducer(state = initialState, action) {

    switch (action.type) {
        case timerConstants.GET_TIMER_INFO:
            return {
                ...state,
            };
        case timerConstants.GET_TIMER_INFO_SUCCESS:
            return {
                ...state,
                timerInfo: action.payload
            };
        case timerConstants.GET_TIMER_INFO_FAILURE:
            return {
                ...state,
            };
        case timerConstants.ADD_TIMER_WITH_NOTES:
            return {
                ...state,
            };
        case timerConstants.ADD_TIMER_WITH_NOTES_SUCCESS:
            return {
                ...state,
            };
        case timerConstants.ADD_TIMER_WITH_NOTES_FAILURE:
            return {
                ...state,
            };
        case timerConstants.SET_LATEST_MONTH_TIMER_INFO:
            return {
                ...state,
                latestMonthTimerInfo: action.payload
            };
        case timerConstants.SET_PREVIOUS_MONTH_TIMER_INFO:
            return {
                ...state,
                previousMonthTimerInfo: action.payload
            };
        case timerConstants.GET_IS_LAST_TIMER_INCOMPLETE:
            return {
                ...state,
            };
        case timerConstants.GET_IS_LAST_TIMER_INCOMPLETE_SUCCESS:
            return {
                ...state,
                isLastTimerIncomplete: action.payload
            };
        case timerConstants.GET_IS_LAST_TIMER_INCOMPLETE_FAILURE:
            return {
                ...state,
            };
        default:
            return state;
    }
}