import React, { useState, createContext, useContext, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../../Authentication/UserPool";
import { useNavigate } from "react-router-dom";
import { getUserRole } from "../../../Reducer/selectors/DashboardSelector";
import { globalConstants } from "../../../Constants/GlobalConstants";
import { getSelectedMlIdSelector } from "../../../Reducer/selectors/DashboardSelector";
import PostUnassignMouthLabFromSubject from "../../API/PostUnassignMouthLabFromSubject/PostUnassignMouthLabFromSubject";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

// UI Component imports
import LogoutModal from "../../UI/LogoutModal/LogoutModal";
import { UserContext } from "../UserContext";
import { setSelectedMlId } from "../../../Actions";

const AccountContext = createContext();

const Account = (props) => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  const { resetUserInformation, resetCurPlSelections } = useContext(UserContext);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  // Function to implement login feature
  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      console.log('12...', Username, Pool);
      const user = new CognitoUser({ Username, Pool });
      let userID = 10;
      console.log('13...', user)
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("LOGIN DATA", data);
          console.log(Username);
          var token = data.getAccessToken().getJwtToken();
          var idToken = data.getIdToken().getJwtToken();
          sessionStorage.setItem("IdToken", idToken);
          sessionStorage.setItem("tokenAccessJWT", token);

          resolve(data);
          setIsUserAuthenticated(true);
          /* navigate(`/subjects`, { replace: true });  */
          navigate(`/dashboard`, { replace: true });
        },
        onFailure: (err) => {
          console.log("authentication on fail error ", err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          console.log("New password required", data);
          resolve(data);
        },
      });
    });
  };

  const userRole = useSelector(getUserRole);
  const selectedMLId = useSelector(getSelectedMlIdSelector);
  const [response, setResponse] = useState({
    statusCode: 0,
    message: "",
  });

  // Function to logout user
  const logout = () => {

    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      sessionStorage.clear();
      localStorage.clear();
      setIsUserAuthenticated(false);
      resetUserInformation();
      resetCurPlSelections();
      navigate(`/authentication`);
    }
    console.log("USER LOGGED OUT");
  };

  // Session function
  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            resolve(session);
          }
        });
      } else {
        reject();
      }
    });
  };

  // Session timeout user on inactivity
  let WarningTimer;
  let logoutTimer;

  const [isWaringTimerActive, setIsWaringTimerActive] = useState(false);

  const clearTimeouts = () => {
    if (WarningTimer) clearTimeout(WarningTimer);
    if (logoutTimer) clearTimeout(logoutTimer);
    // setIsWarningActive(false);
    //console.log("warning for log out getting reset");
  };

  const handleResetTimeouts = () => {
    setIsWaringTimerActive(false);
    clearTimeouts();
  };

  const setTimeouts = () => {
    WarningTimer = setTimeout(() => {
      setIsWaringTimerActive(true);
      startLogoutTimer();
    }, 480000);
    //console.log("warning for log out getting Set", WarningTimer);
  };

  const startLogoutTimer = () => {
    console.log("Logout Started");
    logoutTimer = setTimeout(() => {
      setIsWaringTimerActive(false);
      logout();
      navigate("/authentication");
    }, 120000);
  };
  useEffect(() => {
    const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

    const resetTimeOuts = () => {
      clearTimeouts();
      setTimeouts();
      setIsWaringTimerActive(false);
      // console.log("this is resetingg thr timer");
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeOuts);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeOuts);
        //clearTimeouts();
      }
    };
  }, []);

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        isUserAuthenticated,
      }}
    >
      {props.children}

      {isWaringTimerActive && <LogoutModal onLogoff={startLogoutTimer} onContinue={handleResetTimeouts} />}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
