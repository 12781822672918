import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { globalConstants } from "../../../../Constants/GlobalConstants";
import { getUserRole } from "../../../../Reducer/selectors/DashboardSelector";
import { setPCCPatientListPageNumber, setPCCPatientListPageSize, setSelectedMlId } from "../../../../Actions";
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector.js";
import * as actions from "../../../../Actions";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currUserProfile = useSelector(getUserProfile);
  const userRole = currUserProfile.role;

  useEffect(() => {
    console.log('userRole: ', userRole);
    if (userRole === globalConstants.USER_ROLE_PATIENT) {
      navigate(`/subjectquickview`, { replace: true });
    }
    else {
      navigate(`/projectlocations`, { replace: true });
    }
  }, []);
  return <div></div>;
}
export default Dashboard;
