import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./RfmWrapper.module.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { useParams } from "react-router-dom";
import { format, compareAsc, addDays, subDays } from "date-fns";
import { DateContext } from "../../../../../Store/DateProvider";
import { PatientContext } from "../../../../../Store/PatientDataProvider";
import RfmGraphContainer from "./RfmGraphContainer/RfmGraphContainer";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { setShowPatientGraphsLoader } from "../../../../../../Actions/UtilActions";
import { getPatientGraphsLoader } from "../../../../../../Reducer/selectors/UtilSelector";
import { CircularProgress } from "@material-ui/core";
import { getRfmPlot } from "../../../../../../Actions";
import { getRfmPlotSelector } from "../../../../../../Reducer/selectors/PatientSelector";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    marginTop: 5,
    font: "12px",
    background: "black",
    fontFamily: "GTWalsheimProRegular",
  },
}));

export default function RfmWrapper() {
  // console.log("RfmWrapper");
  // Date context defined to get access to context information
  const dateInfo = useContext(DateContext);
  const classes = useStyles();

  const dispatch = useDispatch();

  // State to manage the Date pickers inputs
  const [dateRangeApplied, setDateRangeApplied] = useState([
    {
      startDate: dateInfo.fromDate,
      endDate: dateInfo.toDate,
      key: "selection",
    },
  ]);
  const [dateRangeSelected, setDateRangeSelected] = useState(dateRangeApplied);

  var start_date = format(dateRangeSelected[0].startDate, "yyyy-MM-dd");
  var end_date = format(addDays(dateRangeSelected[0].endDate, 1), "yyyy-MM-dd");

  const [isCalOpen, setIsCalOpen] = useState(false);
  const refOne = useRef(null);

  // Patient context defined to get access to context information about patient
  const patientInfo = useContext(PatientContext);

  let subjectId = patientInfo.subjectId;

  // fetching subject id from the url params
  let curPatientId = useParams();

  // State to hold the vitals data received from API
  const subjectRfmPlots = useSelector(getRfmPlotSelector);

  const graphsLoader = useSelector(getPatientGraphsLoader);

  useEffect(() => {
    dispatch(setShowPatientGraphsLoader(true));
    // Following function is created just to call the spi and pass the results back to the main component
    // the API will be called on changes in the  date api context values of from and to dates
    dispatch(getRfmPlot({ subjectGUID: curPatientId.patientId, startDate: start_date, endDate: end_date }));

  }, []);

  const handleCalChanges = (item) => {
    setDateRangeSelected([item.selection]);
  };

  const handleCalClick = (event) => {
    event.preventDefault();
    if (isCalOpen) {
      setDateRangeSelected(dateRangeApplied);
      setIsCalOpen(false);
    } else {
      setDateRangeApplied(dateRangeSelected);
      dateInfo.setFromDate(dateRangeSelected[0].startDate);
      dateInfo.setToDate(dateRangeSelected[0].endDate);
      setIsCalOpen(true);
    }
  };

  const fetchPatientRFMDetials = async () => {
    const tenDaysBeforeEndDate = subDays(dateRangeSelected[0].endDate, 10);
    const compVal = compareAsc(tenDaysBeforeEndDate, dateRangeSelected[0].startDate);
    if (compVal === -1) {
      dateRangeSelected[0].startDate = tenDaysBeforeEndDate;
    };
    setDateRangeApplied(dateRangeSelected);
    setIsCalOpen(false);
    dispatch(setShowPatientGraphsLoader(true));
    dispatch(getRfmPlot({ subjectGUID: curPatientId.patientId, startDate: start_date, endDate: end_date }));
    dateInfo.setFromDate(dateRangeSelected[0].startDate);
    dateInfo.setToDate(dateRangeSelected[0].endDate);

  };

  return (
    <div>
      <div className={styles.containerDiv}>
        <div className={styles.datePickerDiv}>
          <Tooltip title="Choose another date range" classes={{ tooltip: classes.tooltip }}>
            <CalendarMonthIcon onClick={(event) => handleCalClick(event)}></CalendarMonthIcon>
          </Tooltip>
          <div className={styles.dateInputDiv}>
            <input
              value={`${format(dateRangeSelected[0].startDate, "MM/dd/yyyy")}  |  ${format(dateRangeSelected[0].endDate, "MM/dd/yyyy")}`}
              readOnly
              className={styles.dateInput}
              onClick={(event) => handleCalClick(event)}
            />
          </div>
          {/* <div>
            <Tooltip title="View data" classes={{ tooltip: classes.tooltip }}>
              <Button startIcon={<TableChartOutlinedIcon />} onClick={(event) => fetchPatientRFMDetials(event)} className={styles.exportButton}>
                View
              </Button>
            </Tooltip>
          </div> */}
        </div>
        {isCalOpen && (
          <div className={styles.datePickerWrapper} ref={refOne}>
            <DateRangePicker
              onChange={(item) => handleCalChanges(item)}
              editableDateInputs={false}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRangeSelected}
              direction="horizontal"
              rangeColors={["#aa346f"]}
              maxDate={addDays(new Date(), 1)}
            />
            <div className={styles.applyButtonDiv}>
              <div className={styles.cancelButton} onClick={(event) => handleCalClick(event)}> Cancel </div>
              <div className={styles.applyButton} onClick={() => fetchPatientRFMDetials()}> Apply </div>
            </div>
          </div>
        )}
      </div>
      {graphsLoader ? (
        <div className={styles.circularProgressDiv} style={{ backgroundColor: `#FFFFFF`, height: `100%` }}>
          <CircularProgress thickness={5.0} style={{ color: `#4E253A` }}></CircularProgress>
        </div>
      ) : (<RfmGraphContainer rfmData={subjectRfmPlots} />)}
    </div>
  );
}
