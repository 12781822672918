import styles from "./CareTeamInfoReview.module.css";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Select from 'react-dropdown-select';
import { setPRCareTeamInfo, setRegistrationStatus } from "../../../../Actions/UtilActions";
import { useDispatch, useSelector } from "react-redux";
import { getPRCareTeamInfo, getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { getSelectedProjectIdAndLocationId } from "../../../../Reducer/selectors/DashboardSelector";
import Backdrop from '@mui/material/Backdrop';
import Modal from "@material-ui/core/Modal";
import logoutImg from '../../../../Assets/Images/Logout.png';
import Grid from "@material-ui/core/Grid";

const CareTeamInfoReview = forwardRef((props, ref) => {

    const handleFocus = (event) => event.target.select();

    const caregiverInfo = useSelector(getPRCareTeamInfo);
    console.log("caregiverInfo: ", caregiverInfo);
    const [contactsCount, setContactsCount] = useState(caregiverInfo.firstNameC3 !== '' ? 3 : caregiverInfo.firstNameC2 !== '' ? 2 : 1);

    const [showSecContact, setShowSecContact] = useState(contactsCount >= 2);
    const [showTerContact, setShowTerContact] = useState(contactsCount >= 3);

    const dispatch = useDispatch();

    const curRegStatus = useSelector(getRegistrationStatus);

    const [prefixC1, setPrefixC1] = useState([{ value: caregiverInfo.prefixC1, label: caregiverInfo.prefixC1 }]);
    const [firstNameC1, setFirstNameC1] = useState(caregiverInfo.firstNameC1);
    const [suffixC1, setSuffixC1] = useState([{ value: caregiverInfo.suffixC1, label: caregiverInfo.suffixC1 }]);
    const [lastNameC1, setLastNameC1] = useState(caregiverInfo.lastNameC1);
    const [specC1, setSpecC1] = useState(caregiverInfo.specC1);
    const [profC1, setProfC1] = useState([{ value: caregiverInfo.profC1, label: caregiverInfo.profC1 }]);
    const [npiC1, setNpiC1] = useState(caregiverInfo.npiC1);
    const [emailC1, setEmailC1] = useState(caregiverInfo.emailC1);
    const [phNumC1, setPhNumC1] = useState(caregiverInfo.phNumC1);
    const [comModeC1, setComModeC1] = useState([{ value: caregiverInfo.comModeC1, label: caregiverInfo.comModeC1 }]);

    const [prefixC2, setPrefixC2] = useState([{ value: caregiverInfo.prefixC2, label: caregiverInfo.prefixC2 }]);
    const [firstNameC2, setFirstNameC2] = useState(caregiverInfo.firstNameC2);
    const [suffixC2, setSuffixC2] = useState([{ value: caregiverInfo.suffixC2, label: caregiverInfo.suffixC2 }]);
    const [lastNameC2, setLastNameC2] = useState(caregiverInfo.lastNameC2);
    const [specC2, setSpecC2] = useState(caregiverInfo.specC2);
    const [profC2, setProfC2] = useState([{ value: caregiverInfo.profC2, label: caregiverInfo.profC2 }]);
    const [npiC2, setNpiC2] = useState(caregiverInfo.npiC2);
    const [emailC2, setEmailC2] = useState(caregiverInfo.emailC2);
    const [phNumC2, setPhNumC2] = useState(caregiverInfo.phNumC2);
    const [comModeC2, setComModeC2] = useState([{ value: caregiverInfo.prefixC2, label: caregiverInfo.prefixC2 }]);

    const [prefixC3, setPrefixC3] = useState([{ value: caregiverInfo.prefixC3, label: caregiverInfo.prefixC3 }]);
    const [firstNameC3, setFirstNameC3] = useState(caregiverInfo.firstNameC3);
    const [suffixC3, setSuffixC3] = useState([{ value: caregiverInfo.suffixC3, label: caregiverInfo.suffixC3 }]);
    const [lastNameC3, setLastNameC3] = useState(caregiverInfo.lastNameC3);
    const [specC3, setSpecC3] = useState(caregiverInfo.specC3);
    const [profC3, setProfC3] = useState([{ value: caregiverInfo.profC3, label: caregiverInfo.profC3 }]);
    const [npiC3, setNpiC3] = useState(caregiverInfo.npiC3);
    const [emailC3, setEmailC3] = useState(caregiverInfo.emailC3);
    const [phNumC3, setPhNumC3] = useState(caregiverInfo.phNumC3);
    const [comModeC3, setComModeC3] = useState([{ value: caregiverInfo.comModeC3, label: caregiverInfo.comModeC3 }]);

    const [showReqWarn1, setShowReqWarn1] = useState(false);
    const [showReqWarn2, setShowReqWarn2] = useState(false);
    const [showReqWarn3, setShowReqWarn3] = useState(false);

    const [isFirstNameC1Fill, setIsFirstNameC1Fill] = useState(true);
    const [isLastNameC1Fill, setIsLastNameC1Fill] = useState(true);
    const [isProfC1Fill, setIsProfC1Fill] = useState(true);
    const [isEmailC1Fill, setIsEmailC1Fill] = useState(true);
    const [isPhNumC1Fill, setIsPhNumC1Fill] = useState(true);
    const [isComModeC1Fill, setIsComModeC1Fill] = useState(true);

    const [isFirstNameC2Fill, setIsFirstNameC2Fill] = useState(true);
    const [isLastNameC2Fill, setIsLastNameC2Fill] = useState(true);
    const [isProfC2Fill, setIsProfC2Fill] = useState(true);
    const [isEmailC2Fill, setIsEmailC2Fill] = useState(true);
    const [isPhNumC2Fill, setIsPhNumC2Fill] = useState(true);
    const [isComModeC2Fill, setIsComModeC2Fill] = useState(true);

    const [isFirstNameC3Fill, setIsFirstNameC3Fill] = useState(true);
    const [isLastNameC3Fill, setIsLastNameC3Fill] = useState(true);
    const [isProfC3Fill, setIsProfC3Fill] = useState(true);
    const [isEmailC3Fill, setIsEmailC3Fill] = useState(true);
    const [isPhNumC3Fill, setIsPhNumC3Fill] = useState(true);
    const [isComModeC3Fill, setIsComModeC3Fill] = useState(true);

    const [cancelRegistration, setCancelRegistration] = useState(false);

    const [isCaregiverFormEditable1, setIsCaregiverFormEditable1] = useState(false);
    const [isCaregiverFormEditable2, setIsCaregiverFormEditable2] = useState(false);
    const [isCaregiverFormEditable3, setIsCaregiverFormEditable3] = useState(false);

    const clearC1Fields = () => {
        setPrefixC1([]);
        setFirstNameC1('');
        setSuffixC1([]);
        setLastNameC1('');
        setSpecC1('');
        setProfC1([]);
        setNpiC1('');
        setEmailC1('');
        setPhNumC1('');
        setComModeC1([]);
    };

    const clearC2Fields = () => {
        setPrefixC2([]);
        setFirstNameC2('');
        setSuffixC2([]);
        setLastNameC2('');
        setSpecC2('');
        setProfC2([]);
        setNpiC2('');
        setEmailC2('');
        setPhNumC2('');
        setComModeC2([]);
    };

    const clearC3Fields = () => {
        setPrefixC3([]);
        setFirstNameC3('');
        setSuffixC3([]);
        setLastNameC3('');
        setSpecC3('');
        setProfC3([]);
        setNpiC3('');
        setEmailC3('');
        setPhNumC3('');
        setComModeC3([]);
    };

    const clearAllCareTeamFields = () => {
        clearC1Fields();
        clearC2Fields();
        clearC3Fields();
        resetReqFills();
    };

    const resetC1ReqFills = () => {
        setIsFirstNameC1Fill(true);
        setIsLastNameC1Fill(true);
        setIsProfC1Fill(true);
        setIsEmailC1Fill(true);
        setIsPhNumC1Fill(true);
        setIsComModeC1Fill(true);
    };

    const resetC2ReqFills = () => {
        setIsFirstNameC2Fill(true);
        setIsLastNameC2Fill(true);
        setIsProfC2Fill(true);
        setIsEmailC2Fill(true);
        setIsPhNumC2Fill(true);
        setIsComModeC2Fill(true);
    };

    const resetC3ReqFills = () => {
        setIsFirstNameC3Fill(true);
        setIsLastNameC3Fill(true);
        setIsProfC3Fill(true);
        setIsEmailC3Fill(true);
        setIsPhNumC3Fill(true);
        setIsComModeC3Fill(true);
    };

    const resetReqFills = () => {
        resetC1ReqFills();
        resetC2ReqFills();
        resetC3ReqFills();
    };

    useImperativeHandle(ref, () => {
        return {
            clearAllCareTeamFields: clearAllCareTeamFields,
        };
    });

    const optionsPrefix = [
        { value: 'Dr.', label: 'Dr.' },
        { value: 'Miss.', label: 'Miss.' },
        { value: 'Mr.', label: 'Mr.' },
        { value: 'Mrs.', label: 'Mrs.' },
        { value: 'Ms.', label: 'Ms.' },
    ];

    const optionsSuffix = [
        { value: 'II', label: 'II' },
        { value: 'III', label: 'III' },
        { value: 'IV', label: 'IV' },
        { value: 'Jr.', label: 'Jr.' },
        { value: 'Sr.', label: 'Sr.' },
    ];

    const optionsProf = [
        { value: 'Alternate Physician', label: 'Alternate Physician' },
        { value: 'Attending Physician', label: 'Attending Physician' },
        { value: 'Nurse Practitioner', label: 'Nurse Practitioner' },
        { value: 'Physician Assistant', label: 'Physician Assistant' },
        { value: 'Registered Nurse', label: 'Registered Nurse' },
    ];

    const optionsComMode = [
        { value: 'Email', label: 'Email' },
        { value: 'Phone', label: 'Phone' },
        { value: 'Both', label: 'Both' },
    ];

    const customContentRendererMulti = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            return (
                <div>
                    Selected <b>{state.values.length}</b> out of{" "}
                    <b>{props.options.length}</b>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    };

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const onAddSecContact = (event) => {
        event.preventDefault();
        if (checkIfContact1IsDone(false)) {
            setShowSecContact(true);
            setContactsCount(2);
            setShowReqWarn1(false);
            resetC1ReqFills();
            setIsCaregiverFormEditable1(false);
            setIsCaregiverFormEditable2(true);
        }
    };

    const onAddTerContact = (event) => {
        event.preventDefault();
        if (checkIfContact2IsDone(false)) {
            setShowTerContact(true);
            setContactsCount(3);
            setShowReqWarn2(false);
            resetC2ReqFills();
            setIsCaregiverFormEditable2(false);
            setIsCaregiverFormEditable3(true);
        }
    };

    const deleteSecContact = (event) => {
        event.preventDefault();
        setShowSecContact(false);
        clearC2Fields();
        resetC2ReqFills();
        setContactsCount(1);
    };

    const deleteTerContact = (event) => {
        event.preventDefault();
        setShowTerContact(false);
        clearC3Fields();
        resetC3ReqFills();
        setContactsCount(2);
    };


    const getStringFromSelectArray = (arr) => {
        const len = arr.length;
        let res = "";
        for (let i = 0; i < len; i++) {
            if (res.length > 0) {
                res = res + ", " + arr[i].value;
            } else {
                res = res + arr[i].value;
            }
        }
        return res;
    };

    const checkIfContact1IsDone = (flag) => {
        if ((firstNameC1 !== '')) {
            if (!comModeC1[0]) {
                return true;
            } else if (comModeC1[0].value === 'Email') {
                if (emailC1 != '') {
                    return true;
                } else {
                    setShowReqWarn1(true);
                    setIsEmailC1Fill(false);
                    return false;
                }
            } else if (comModeC1[0].value === 'Phone') {
                if (phNumC1 != '') {
                    return true;
                } else {
                    setShowReqWarn1(true);
                    setIsPhNumC1Fill(false);
                    return false;
                }
            } else if (comModeC1[0].value === 'Both') {
                if (emailC1 !== '' && phNumC1 !== '') {
                    return true;
                } else {
                    setShowReqWarn1(true);
                    if (emailC1 === '') {
                        setIsEmailC1Fill(false);
                    }
                    if (phNumC1 === '') {
                        setIsPhNumC1Fill(false);
                    }
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true && flag;
        }
    };

    const checkIfContact2IsDone = (flag) => {
        if ((firstNameC2 !== '')) {
            if (!comModeC2[0]) {
                return true;
            } else if (comModeC2[0].value === 'Email') {
                if (emailC2 != '') {
                    return true;
                } else {
                    setShowReqWarn2(true);
                    setIsEmailC2Fill(false);
                    return false;
                }
            } else if (comModeC2[0].value === 'Phone') {
                if (phNumC2 != '') {
                    return true;
                } else {
                    setShowReqWarn2(true);
                    setIsPhNumC2Fill(false);
                    return false;
                }
            } else if (comModeC2[0].value === 'Both') {
                if (emailC2 !== '' && phNumC2 !== '') {
                    return true;
                } else {
                    setShowReqWarn2(true);
                    if (emailC2 === '') {
                        setIsEmailC2Fill(false);
                    }
                    if (phNumC2 === '') {
                        setIsPhNumC2Fill(false);
                    }
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true && flag;
        }
    };

    const checkIfContact3IsDone = (flag) => {
        if ((firstNameC3 !== '')) {
            if (!comModeC3[0]) {
                return true;
            } else if (comModeC3[0].value === 'Email') {
                if (emailC3 != '') {
                    return true;
                } else {
                    setShowReqWarn3(true);
                    setIsEmailC3Fill(false);
                    return false;
                }
            } else if (comModeC3[0].value === 'Phone') {
                if (phNumC3 != '') {
                    return true;
                } else {
                    setShowReqWarn3(true);
                    setIsPhNumC3Fill(false);
                    return false;
                }
            } else if (comModeC3[0].value === 'Both') {
                if (emailC3 !== '' && phNumC3 !== '') {
                    return true;
                } else {
                    setShowReqWarn3(true);
                    if (emailC3 === '') {
                        setIsEmailC3Fill(false);
                    }
                    if (phNumC3 === '') {
                        setIsPhNumC3Fill(false);
                    }
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true && flag;
        }
    };

    const markStepCompleted = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: curRegStatus.step2,
            step3: curRegStatus.step3,
            step4: true,
            step5: curRegStatus.step5,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
    };

    const setTabInfo = () => {

        const payload = {
            prefixC1: prefixC1[0] ? prefixC1[0].value : '',
            firstNameC1: firstNameC1,
            suffixC1: suffixC1[0] ? suffixC1[0].value : '',
            lastNameC1: lastNameC1,
            specC1: specC1,
            profC1: profC1[0] ? profC1[0].value : '',
            npiC1: npiC1,
            emailC1: emailC1,
            phNumC1: phNumC1,
            comModeC1: getStringFromSelectArray(comModeC1),

            prefixC2: prefixC2[0] ? prefixC2[0].value : '',
            firstNameC2: firstNameC2,
            suffixC2: suffixC2[0] ? suffixC2[0].value : '',
            lastNameC2: lastNameC2,
            specC2: specC2,
            profC2: profC2[0] ? profC2[0].value : '',
            npiC2: npiC2,
            emailC2: emailC2,
            phNumC2: phNumC2,
            comModeC2: getStringFromSelectArray(comModeC2),

            prefixC3: prefixC3[0] ? prefixC3[0].value : '',
            firstNameC3: firstNameC3,
            suffixC3: suffixC3[0] ? suffixC3[0].value : '',
            lastNameC3: lastNameC3,
            specC3: specC3,
            profC3: profC3[0] ? profC3[0].value : '',
            npiC3: npiC3,
            emailC3: emailC3,
            phNumC3: phNumC3,
            comModeC3: getStringFromSelectArray(comModeC3),
        };
        console.log("careTeamInfo payload: ", payload);
        dispatch(setPRCareTeamInfo(payload));
    };

    const checkIfPageConditionsMet = () => {
        if (checkIfContact1IsDone(true) && checkIfContact2IsDone(true) && checkIfContact3IsDone(true)) {
            console.log('conditions met!');
            resetC1ReqFills();
            resetC2ReqFills();
            resetC3ReqFills();
            return true;
        } else {
            console.log('conditions not met!');
            return false;
        }
    };

    const handleSaveAndContinue = () => {
        if (checkIfPageConditionsMet()) {
            props.tabChange("5");
            setTabInfo();
            markStepCompleted();
        }
    };

    const handleSave1 = () => {
        if (checkIfContact1IsDone(true)) {
            setTabInfo();
            setIsCaregiverFormEditable1(false);
        }
    };

    const handleSave2 = () => {
        if (checkIfContact2IsDone(true)) {
            setTabInfo();
            setIsCaregiverFormEditable2(false);
        }
    };

    const handleSave3 = () => {
        if (checkIfContact3IsDone(true)) {
            setTabInfo();
            setIsCaregiverFormEditable3(false);
        }
    };

    const navigate = useNavigate();
    const projLoc = useSelector(getSelectedProjectIdAndLocationId);
    const selectedProjectId = projLoc.selectedProjectId;
    const selectedLocationId = projLoc.selectedLocationId;

    const handleBackCLick = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: curRegStatus.step2,
            step3: false,
            step4: false,
            step5: curRegStatus.step5,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
        props.tabChange("3");

        //navigate('/subjects/' + selectedProjectId + '/' + selectedLocationId);
    };

    const editCaregiverForm1 = (event) => {
        event.preventDefault();
        setIsCaregiverFormEditable1(true);
    };

    const handleCancel1 = () => {
        setIsCaregiverFormEditable1(false);
    };

    const editCaregiverForm2 = (event) => {
        event.preventDefault();
        setIsCaregiverFormEditable2(true);
    };

    const handleCancel2 = () => {
        if (firstNameC2 === "") {
            setShowSecContact(false);
            clearC2Fields();
            resetC2ReqFills();
            setContactsCount(1);
        }
        setIsCaregiverFormEditable2(false);
    };

    const editCaregiverForm3 = (event) => {
        event.preventDefault();
        setIsCaregiverFormEditable3(true);
    };

    const handleCancel3 = () => {
        if (firstNameC3 === "") {
            setShowTerContact(false);
            clearC3Fields();
            resetC3ReqFills();
            setContactsCount(2);
        }
        setIsCaregiverFormEditable3(false);
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    const closeCancelRegistration = () => {
        setCancelRegistration(false);
    };

    const handleCancelRegistration = () => {
        setCancelRegistration(true);
    };

    const handleCancelRegistrationSuccess = () => {
        const payload = {
            step1: false,
            step2: false,
            step3: false,
            step4: false,
            step5: false,
            review: false,
        }
        dispatch(setRegistrationStatus(payload));
        clearAllCareTeamFields();
        navigate('/subjects/' + selectedProjectId + '/' + selectedLocationId);
    };

    return (
        <div className={styles.rootDiv}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={cancelRegistration}
            ></Backdrop>
            <div className={styles.containerDiv}>
                <div className={styles.dumDiv}>

                    {contactsCount >= 1 && (<>
                        <div className={styles.headerDiv}>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.headerTitle}> Primary </div>
                            <div className={styles.dummyDiv} > <div className={!isCaregiverFormEditable1 ? styles.editButton : styles.editButtonClicked} onClick={event => editCaregiverForm1(event)}>Edit</div></div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                className={styles.dropdown}
                                                values={prefixC1}
                                                onChange={setPrefixC1}
                                                options={optionsPrefix}
                                                color="#4E253A"
                                                disabled={!isCaregiverFormEditable1}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstNameC1} disabled={!isCaregiverFormEditable1} onChange={(e) => setFirstNameC1(e.currentTarget.value)} className={isCaregiverFormEditable1 ? isFirstNameC1Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastNameC1} disabled={!isCaregiverFormEditable1} onChange={(e) => setLastNameC1(e.currentTarget.value)} className={isCaregiverFormEditable1 ? isLastNameC1Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                className={styles.dropdown}
                                                values={suffixC1}
                                                onChange={setSuffixC1}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                                disabled={!isCaregiverFormEditable1}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Profession
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isProfC1Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={profC1}
                                            onChange={setProfC1}
                                            options={optionsProf}
                                            color="#4E253A"
                                            disabled={!isCaregiverFormEditable1}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Speciality
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={specC1} disabled={!isCaregiverFormEditable1} onChange={(e) => setSpecC1(e.currentTarget.value)} className={isCaregiverFormEditable1 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        NPI#
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={npiC1} disabled={!isCaregiverFormEditable1} onChange={(e) => setNpiC1(e.currentTarget.value)} className={isCaregiverFormEditable1 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNumC1} disabled={!isCaregiverFormEditable1} onChange={(e) => setPhNumC1(e.currentTarget.value)} className={isCaregiverFormEditable1 ? isPhNumC1Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of communication
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isComModeC1Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={comModeC1}
                                            onChange={setComModeC1}
                                            options={optionsComMode}
                                            color="#4E253A"
                                            disabled={!isCaregiverFormEditable1}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={emailC1} disabled={!isCaregiverFormEditable1} onChange={(e) => setEmailC1(e.currentTarget.value)} className={isCaregiverFormEditable1 ? isEmailC1Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(isCaregiverFormEditable1) && (<>
                            <div className={styles.divFooter}>
                                {showReqWarn1 && <div className={styles.consentWarningDiv}>
                                    Please fill out the required fields.
                                </div>}
                                <div className={styles.pageButtonsDiv}>
                                    {/* <div className={styles.cancelButton} onClick={handleCancel1}>Cancel</div> */}
                                    <div className={styles.saveButton} onClick={handleSave1}>Save</div>
                                </div>
                                {(contactsCount < 2) && (<div className={styles.addNewDiv}>
                                    <div className={styles.addNewContButton} onClick={(event) => onAddSecContact(event)}>
                                        + Add Secondary
                                    </div>
                                </div>)}
                            </div>
                        </>)}
                    </>)}


                    {showSecContact && (<>
                        <div className={styles.headerDiv}>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.headerTitle}> Secondary </div>
                            <div>
                                {(!isCaregiverFormEditable2) && (<div className={!isCaregiverFormEditable2 ? styles.editButton : styles.editButtonClicked} onClick={event => editCaregiverForm2(event)}>Edit</div>)}
                                {(isCaregiverFormEditable2) && (<div className={styles.deleteButton1}><CloseIcon onClick={(event) => deleteSecContact(event)} style={{ height: '20px', width: '20px', fill: "#A040AB" }} /> </div>)} </div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={prefixC2}
                                                onChange={setPrefixC2}
                                                options={optionsPrefix}
                                                color="#4E253A"
                                                disabled={!isCaregiverFormEditable2}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstNameC2} disabled={!isCaregiverFormEditable2} onChange={(e) => setFirstNameC2(e.currentTarget.value)} className={isCaregiverFormEditable2 ? isFirstNameC2Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastNameC2} disabled={!isCaregiverFormEditable2} onChange={(e) => setLastNameC2(e.currentTarget.value)} className={isCaregiverFormEditable2 ? isLastNameC2Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={suffixC2}
                                                onChange={setSuffixC2}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                                disabled={!isCaregiverFormEditable2}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Profession
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isProfC2Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={profC2}
                                            onChange={setProfC2}
                                            options={optionsProf}
                                            color="#4E253A"
                                            disabled={!isCaregiverFormEditable2}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Speciality
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={specC2} disabled={!isCaregiverFormEditable2} onChange={(e) => setSpecC2(e.currentTarget.value)} className={isCaregiverFormEditable2 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        NPI#
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={npiC2} disabled={!isCaregiverFormEditable2} onChange={(e) => setNpiC2(e.currentTarget.value)} className={isCaregiverFormEditable2 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNumC2} disabled={!isCaregiverFormEditable2} onChange={(e) => setPhNumC2(e.currentTarget.value)} className={isCaregiverFormEditable2 ? isPhNumC2Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of communication
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isComModeC2Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={comModeC2}
                                            onChange={setComModeC2}
                                            options={optionsComMode}
                                            color="#4E253A"
                                            disabled={!isCaregiverFormEditable2}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={emailC2} disabled={!isCaregiverFormEditable2} onChange={(e) => setEmailC2(e.currentTarget.value)} className={isCaregiverFormEditable2 ? isEmailC2Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(isCaregiverFormEditable2) && (<>
                            <div className={styles.divFooter}>
                                {showReqWarn2 && <div className={styles.consentWarningDiv}>
                                    Please fill out the required fields.
                                </div>}
                                <div className={styles.pageButtonsDiv}>
                                    {/* <div className={styles.cancelButton} onClick={handleCancel2}>Cancel</div> */}
                                    <div className={styles.saveButton} onClick={handleSave2}>Save</div>
                                </div>
                                {(contactsCount < 3) && (<div className={styles.addNewDiv}>
                                    <div className={styles.addNewContButton} onClick={(event) => onAddTerContact(event)}>
                                        + Add Tertiary
                                    </div>
                                </div>)}
                            </div>
                        </>)}
                    </>)}

                    {showTerContact && (<>
                        <div className={styles.headerDiv}>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.headerTitle}> Tertiary </div>
                            <div>
                                {(!isCaregiverFormEditable3) && (<div className={!isCaregiverFormEditable3 ? styles.editButton : styles.editButtonClicked} onClick={event => editCaregiverForm3(event)}>Edit</div>)}
                                {(isCaregiverFormEditable3) && (<div className={styles.deleteButton1}> <CloseIcon onClick={(event) => deleteTerContact(event)} style={{ height: '20px', width: '20px', fill: "#A040AB" }} /></div>)} </div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={prefixC3}
                                                onChange={setPrefixC3}
                                                options={optionsPrefix}
                                                color="#4E253A"
                                                disabled={!isCaregiverFormEditable3}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstNameC3} disabled={!isCaregiverFormEditable3} onChange={(e) => setFirstNameC3(e.currentTarget.value)} className={isCaregiverFormEditable3 ? isFirstNameC3Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastNameC3} disabled={!isCaregiverFormEditable3} onChange={(e) => setLastNameC3(e.currentTarget.value)} className={isCaregiverFormEditable3 ? isLastNameC3Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={suffixC3}
                                                onChange={setSuffixC3}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                                disabled={!isCaregiverFormEditable3}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Profession
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isProfC3Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={profC3}
                                            onChange={setProfC3}
                                            options={optionsProf}
                                            color="#4E253A"
                                            disabled={!isCaregiverFormEditable3}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Speciality
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={specC3} disabled={!isCaregiverFormEditable3} onChange={(e) => setSpecC3(e.currentTarget.value)} className={isCaregiverFormEditable3 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        NPI#
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={npiC3} disabled={!isCaregiverFormEditable3} onChange={(e) => setNpiC3(e.currentTarget.value)} className={isCaregiverFormEditable3 ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNumC3} disabled={!isCaregiverFormEditable3} onChange={(e) => setPhNumC3(e.currentTarget.value)} className={isCaregiverFormEditable3 ? isPhNumC3Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of communication
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isComModeC3Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={comModeC3}
                                            onChange={setComModeC3}
                                            options={optionsComMode}
                                            color="#4E253A"
                                            disabled={!isCaregiverFormEditable3}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={emailC3} disabled={!isCaregiverFormEditable3} onChange={(e) => setEmailC3(e.currentTarget.value)} className={isCaregiverFormEditable3 ? isEmailC3Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(isCaregiverFormEditable3) && (<>
                            <div className={styles.divFooter}>
                                {showReqWarn3 && <div className={styles.consentWarningDiv}>
                                    Please fill out the required fields.
                                </div>}
                                <div className={styles.pageButtonsDiv}>
                                    {/* <div className={styles.cancelButton} onClick={handleCancel3}>Cancel</div> */}
                                    <div className={styles.saveButton} onClick={handleSave3}>Save</div>
                                </div>
                            </div>
                        </>)}
                    </>)}

                </div>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={cancelRegistration}
                onClose={closeCancelRegistration}
            >
                <div className={styles.paper}>
                    <div className={styles.menuTitle}>Cancel Registration</div>

                    <div className={styles.patientListDiv}>
                        <Grid container>
                            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                                <div className={styles.userQuestionMessage}>All saved changes will be lost. Are you sure you want to exit?</div>

                                <div className={styles.mouthLabImgDiv}>
                                    <img src={logoutImg} loading="lazy" className={styles.mlImg} />
                                </div>

                                <div className={styles.buttonContainer}>
                                    <button type="button" onClick={closeCancelRegistration} className={styles.cancelButtonModal}>
                                        No
                                    </button>
                                    <button type="submit" onClick={handleCancelRegistrationSuccess} className={styles.submitButtonModal}>
                                        Yes
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Modal>

        </div>
    );
});

export default CareTeamInfoReview;