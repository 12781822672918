import React from "react";
import styles from "./LfContainer.module.css";
import Grid from "@material-ui/core/Grid";
import LfGraph from "../LfGraph/LfGraph";
import { getPageLoader } from "../../../../../../../Reducer/selectors/UtilSelector.js"
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import NoDataImg from "../../../../../../../Assets/Images/No-Data-PNG.png";

export default function LfContainer(props) {
  // console.log("LfContainer: props = ", props);
  const loader = useSelector(getPageLoader);

  const plotLfGraph = props.lfData.map((lfParams) => (
    <LfGraph
      key={lfParams.recordId}
      // breathingRate={lfParams.breathingRate.value}
      xValues={lfParams.lf.flow.xValues}
      yValues={lfParams.lf.flow.yValues}
      timestamp={lfParams.timestamp}
      pef={lfParams.pef.value}
    />
  ));
  return (
    <div>
    {loader ? (
      <div className={styles.circularProgress}>
        <CircularProgress thickness={5.0} />
      </div>
    ) : props.lfData.length === 0 ? (
      <div className={styles.noDataWrapper}>
        <img src={NoDataImg} width="650vw" height="530vh" />
        <div className={styles.noData}>No Data Available</div>
      </div>
    ) : (
      <Grid container>
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12} style={{ maxHeight: "65vh", overflowY: "scroll", padding: "5px" }}>
          <div className={styles.responsive}>{plotLfGraph}</div>
        </Grid>
      </Grid>
    )}
  </div>
  );
}
