import React from "react";
import Grid from "@material-ui/core/Grid";
import styles from "./TableHeaderManualVitals.module.css";
import { useSelector } from "react-redux";
import { getBaselineSelector } from "../../../../../../Reducer/selectors/PatientSelector.js";
import ManualVitalsTable from "./ManualVitalsTable";

export default function TableHeaderManualVitals(props) {
    const baseline = useSelector(getBaselineSelector);
//    console.log("baseline: ", baseline);
const vitalsLength = props.patientVitals.length;

  const patientVitalsTable = props.patientVitals.map((allVitals) => {
    const weightRisk = ((baseline && baseline.weightHigh && (allVitals.weight > baseline.weightHigh)) || 
    (baseline && baseline.weightLow && (allVitals.weight < baseline.weightLow))) ? 1 : 0;
    const sugarRisk = ((baseline && baseline.sugarHigh && (allVitals.glucose > baseline.sugarHigh)) || 
    (baseline && baseline.sugarLow && (allVitals.glucose < baseline.sugarLow))) ? 1 : 0;
    const painRisk = baseline && baseline.painHigh && (allVitals.painLevel > baseline.painHigh) ? 1 : 0;

    return (<ManualVitalsTable
      key={allVitals.recordId}
      recordId={allVitals.recordId}
      // mlId={allVitals.deviceId}
      timestamp={allVitals.timestamp}
      hasNotes = {allVitals.hasNotes}
      weight = {allVitals.weight}
      glucose = {allVitals.glucose}
      painLevel = {allVitals.painLevel}
      weightRisk = {weightRisk}
      sugarRisk = {sugarRisk}
      painRisk = {painRisk}
    />
  )});
  return (
    <div>
      <Grid container>
        <Grid item xs={12} xl={12} lg={12} md={10} sm={12}>
          <div className={styles.responsive}>
            <table className={styles.cardStyle}>
              <thead>
                <tr className={styles.header}>
                  {/* <th className={styles.timeStamp}>Date</th> */}
                  <th className={styles.timeStamp}>Time</th>
                  {/* <th className={styles.mouthLabId}>ID</th> */}
                  <th className={styles.temperature}>Weight<br/>(lb)</th>
                  <th className={styles.bp}>Glucose<br/>(mg/dL)</th>
                  <th className={styles.pr}>Pain Level<br/>(0-10)</th>
                  <th className={styles.notes}>Notes</th>
{/* Currently it is not being calculated and hence need not be shown.
                  <th className={styles.fvc}>FVC (L)</th>
                  <th className={styles.fev1fvc}>FEV1/FVC</th>
 */}
                </tr>
              </thead>
            </table>
            {vitalsLength > 0 ? (
              <Grid item xs={12} xl={12} lg={12} md={10} sm={12} style={{ maxHeight: "55vh", overflowY: "scroll" }}>
                {patientVitalsTable}
              </Grid>
            ) : (
              <div className={styles.noVitals}>No data available</div>
            )}
            {/* <Grid item xs={12} xl={12} lg={12} md={10} sm={12} style={{maxHeight: '55vh', overflowY: 'scroll'}}>
            {patientVitalsTable}
            </Grid> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
