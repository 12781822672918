import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";

import DashboardSaga from "./DashboardSaga";
import PatientSaga from "./PatientSaga";
import NotesSaga from "./NotesSaga";
import NursingDashboardSaga from "./NursingDashboardSaga";
import TimerSaga from "./TimerSaga";
import AIDISaga from "./AIDISaga";

export const sagaMiddleware = createSagaMiddleware();
export function* rootSaga() {
    yield all([
        DashboardSaga(),
        PatientSaga(),
        NotesSaga(),
        NursingDashboardSaga(),
        TimerSaga(),
        AIDISaga(),
    ]);
  }