import React from "react";
import axios from "axios";
import domainCF from "../domainCF.js";

export default async function PostSignUpUserToDB(user) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
  };
  const POST_SIGNUP_USER_TO_DB = domainCF + "/api/addUser";
  console.log("PATH: ", POST_SIGNUP_USER_TO_DB);
  console.log('PostSignUpUserToDB payload: ', user);
  const response = await axios
    .post(POST_SIGNUP_USER_TO_DB, user, { headers });
  console.log("THIS IS A RESPONSE...", response);
  console.log("RESPONSE DATA ", response.data);
  console.log("STATUS", response.status);
  return response.data;
}
