import React from "react";
import styles from "./PatientDevices.module.css";

//Components Import
import DevicesWrapper from "./Devices/DevicesWrapper";

export default function PatientDevices() {
  return (
    <div className={styles.wrapperCard}>
      <div className={styles.devicesListContainer}>
        <DevicesWrapper />
      </div>
    </div>
  );
}
