import React, { useState, useEffect } from "react";
import styles from "./TableHeader.module.css";
import Grid from "@material-ui/core/Grid";
import { FaSort } from "react-icons/fa";
import TableBody from "../TableBody/TableBody";
import Input from '@material-ui/core/Input';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import { getIsGetPatientsAPIFailure } from "../../../../../Reducer/selectors/DashboardSelector";

export default function TableHeader(props) {
  const [patientListSorting, setPatientListSorting] = useState([]);
  const [sortDirection, setSortDirection] = useState(false);
  const [searched, setSearched] = useState("");

  const isGetPatientsAPIFailure = useSelector(getIsGetPatientsAPIFailure);

  // console.log(patientListSorting);

  const handleSortRequest = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key] - b[key];
      } else {
        return b[key] - a[key];
      }
    });
    return setPatientListSorting(sortableItem);
  };

  // Sorting Function for mouthLab adherence
  const handleAdherenceSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key] - b[key];
      } else {
        return b[key] - a[key];
      }
    });
    return setPatientListSorting(sortableItem);
  };

  // Sorting based of user names
  const handleNameSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        //return a.contactInfo[key].localeCompare(b.contactInfo[key]);
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleCMSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleSubjectIDSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };


  const handleStatusSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleEhrSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key] - b[key];
      } else {
        return b[key] - a[key];
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleDobSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleGenderSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleMlIdSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
    return setPatientListSorting(sortableItem);
  };

  const handleDiagnosisSorting = (key) => {
    setSortDirection((prevSortDirection) => !prevSortDirection);
    let direction = sortDirection;
    let sortableItem = [...patientListSorting];
    sortableItem.sort((a, b) => {
      if (direction === false) {
        if (direction === false) {
            return a[key].localeCompare(b[key]);
          } else {
            return b[key].localeCompare(a[key]);
          }
/*         if (a[key][0] && b[key][0]) {
          return a[key][0].localeCompare(b[key][0]);
        } else if (a[key][0]) {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (a[key][0] && b[key][0]) {
          return b[key][0].localeCompare(a[key][0]);
        } else if (a[key][0]) {
          return -1;
        } else {
          return 1;
        }
*/
      }
     });
    return setPatientListSorting(sortableItem);
  };

  useEffect(() => {
    const patientListCopy = props.patientList.map((x) => x);
    setPatientListSorting(patientListCopy);
  }, [props.patientList]);

  // Rendering patient list in table body
  const patientTableBody = patientListSorting.map((pat) => (
    <TableBody
      key={pat.guid}
      subjectGUID={pat.guid}
      subjectId={pat.subjectId}
      deviceId={pat.deviceId}
      firstName={pat.firstName}
      lastName={pat.lastName}
      gender={pat.gender}
      adherence={pat.adherence}
      adherence16={pat.adherence16}
      primaryDiagnosis={pat.diagnosis}
      elapsedHours={pat.elapsedHours}
      patientStatus={pat.status}
      registeredBy={pat.registeredBy}
      ehrId={pat.ehrId}
      dob={pat.dob}
      patientList={props.patientList}
    />
  ));

  const requestSearch = (event) => {
    setSearched(event.target.value);
    let searchVal = searched;

    if (!searchVal || searchVal.length === 0) {
      const patientListCopy = props.patientList.map((x) => x);
      setPatientListSorting(patientListCopy);
    } else {
      let filteredPatients = [];
      const patientListCopy = props.patientList.map((x) => x);
      filteredPatients = patientListCopy.filter((row) => {
        let subjectID = 'aidi-' + row.subjectId;
        return (
          (row.status && row.status.toString().toLowerCase().startsWith(searchVal.toLowerCase())) ||
          /*   (subjectID.includes(searchVal.split(' ').join('').toLowerCase())) ||  */
          (row.ehrId && row.ehrId.toLowerCase().includes(searchVal.toLowerCase())) ||
          (row.gender && row.gender.toString().toLowerCase().startsWith(searchVal.toLowerCase())) ||
          /* (row.age && row.age.toString().toLowerCase().startsWith(searchVal.toLowerCase())) || */
          (row.diagnoses && row.diagnoses[0] && row.diagnoses[0].toString().toLowerCase().includes(searchVal.toLowerCase())) ||
          (row.registeredBy && row.registeredBy.toString().toLowerCase().includes(searchVal.toLowerCase())) ||
          (row.lastName && row.lastName.toString().toLowerCase().includes(searchVal.toLowerCase())) ||
          (row.firstName && row.firstName.toString().toLowerCase().includes(searchVal.toLowerCase())));
      });
      setPatientListSorting(filteredPatients);
    }
  };

  const handleCancleSearch = (event) => {
    event.preventDefault();
    setSearched("");
    const patientListCopy = props.patientList.map((x) => x);
    setPatientListSorting(patientListCopy);
  };

  return (
    <div>
      <Input
        className={styles.searchBar}
        type="text"
        placeholder="Search"
        value={searched}
        onChange={(event) => setSearched(event.target.value)}
        onKeyUp={(event) => requestSearch(event)}
        startAdornment={
          <InputAdornment position="start">
            <IconButton className={styles.searchIcon}>
              <SearchIcon style={{ color: "#4E253A" }}></SearchIcon>
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {
              <IconButton className={styles.searchIcon}>
                {searched === "" ? <></> : <CloseIcon style={{ color: "#4E253A" }} onClick={(event) => handleCancleSearch(event)}></CloseIcon>}
              </IconButton>
            }
          </InputAdornment>
        }
        disableUnderline="true"
        style={{
          fontFamily: "GTWalsheimProRegular",
        }}
      />
      <p></p>
      <Grid container>
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <table className={styles.responsive}>
            <thead>
              <tr>
                {/* <th className={styles.sidHeader} onClick={() => handleSubjectIDSorting("subjectId")}>
                  Subject ID
                  <FaSort className={styles.iconsStyleSort} />
                </th> */}
                <th className={styles.nameHeader} onClick={() => handleNameSorting("lastName")}>
                  Name
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                {/* <th className={styles.mlIdHeader} onClick={() => handleMlIdSorting("deviceId")}>
                  ML ID
                  <FaSort className={styles.iconsStyleSort} />
                </th> */}
                <th className={styles.ehrIdHeader} onClick={() => handleEhrSorting("ehrId")}>
                  EHR ID
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.ehrIdHeader} onClick={() => handleDobSorting("dob")}>
                  DOB
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.genderHeader} onClick={() => handleGenderSorting("gender")}>
                  Gender
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.statusHeader} onClick={() => handleStatusSorting("status")}>
                  Status
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.diagnosisHeader} onClick={() => handleDiagnosisSorting("diagnoses")}>
                  Diagnosis
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.diagnosisHeader} onClick={() => handleCMSorting("registeredBy")}>
                  Care Manager
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.goalsHeader} onClick={() => handleAdherenceSorting("adherence")}>
                  {/* ML */} Adherence
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.elapsedTimeHeader} onClick={() => handleSortRequest("elapsedHours")}>
                  Elapsed Hours
                  <FaSort className={styles.iconsStyleSort} />
                </th>
                <th className={styles.buttonTabHeader}></th>
              </tr>
            </thead>
          </table>
          {/* <Grid item xs={12} xl={12} lg={12} md={12} sm={12} style={{ maxHeight: "69vh", overflowY: "scroll" }}>
            {patientTableBody}
          </Grid> */}

          {isGetPatientsAPIFailure ? (<div className={styles.errorMsgsDiv}>
            <div className={styles.noSubjects1}>Network Error: We're having trouble connecting right now.</div>
            <div className={styles.noSubjects2}>Please log out and log back in or try again in a few moments. Thank you for your patience!</div>
          </div>) : patientListSorting.length > 0 ? (
            <Grid item xs={12} xl={12} lg={12} md={12} sm={12} style={{ maxHeight: "69vh", overflowY: "scroll" }}>
              {patientTableBody}
            </Grid>
          ) : searched === '' ? (
            <div className={styles.noSubjects}>Currently there are no patients registered. Please register a new patient.</div>
          ) : (
            <div className={styles.noSubjects}>No patients match the search criteria.</div>
          )}

        </Grid>
      </Grid>
    </div>
  );
}
