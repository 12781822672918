import React, { useEffect, useState } from "react";
import styles from "./AssignMl.module.css";
import { Formik, Form } from "formik";
import Grid from "@material-ui/core/Grid";
import * as yup from "yup";
import FormikControl from "../../../../../FormControls/FormikControl";
import { Fragment } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import AssignDeviceWithUsagePlan from "../../../../../API/AssignDeviceWithUsagePlan/AssignDeviceWithUsagePlan.js";
import getAvailableSubjectsByProjectAndLocationForDevice from "../../../../../API/GetAvailableSubjects/GetAvailableSubjects";
import { useDispatch, useSelector } from "react-redux";
import { setShowAssignMlLoader } from "../../../../../../Actions/UtilActions.js";
import { getAssignMlLoader } from "../../../../../../Reducer/selectors/UtilSelector.js";

export default function AssignMl(props) {
  const dispatch = useDispatch();
  const [availableSubjectsList, setAvailableSubjectsList] = useState([]);
  const [availableSubjectIdsList, setAvailableSubjectIdsList] = useState([]);
  const [subjectMap, setSubjectMap] = useState(new Map());
  const [isProgress, setIsProgress] = useState(false);
  const [isTimedMessage, setIsTimedMessage] = useState(false);
  const usageOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [response, setResponse] = useState({
    statusCode: 0,
    message: "",
  });
  const assignMlLoader = useSelector(getAssignMlLoader);

  let keyValue = {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAvailableSubjectsByProjectAndLocationForDevice(props.projectId, props.locationId, props.currentDeviceId);

        //console.log("subjectMap: ", res.subjectMap);
        setSubjectMap(res.subjectMap);

        const subjectMap = res.subjectMap;
        // Assuming res.subjectMap is an object where keys are subject names and values are subject IDs
        const keyValue = Object.keys(subjectMap).reduce((acc, subjectName) => {

          acc[subjectName] = subjectMap[subjectName];
          return acc;
        }, {});

        setAvailableSubjectsList(Object.keys(keyValue));
        setAvailableSubjectIdsList(Object.values(keyValue));

        dispatch(setShowAssignMlLoader(false));
      } catch (error) {
        console.error("Error fetching available subjects:", error.message);
        // Handle error if needed
        dispatch(setShowAssignMlLoader(false));
      }
    };

    fetchData();
  }, [props.projectId, props.locationId, props.currentDeviceId]);

  // Now you can use keyValue in the rest of your component code
  const initialValues = {
    deviceId: props.currentDeviceId,
    subjectIdKey: "",
    usage: "",
  };

  const validationSchema = yup.object({
    subjectIdKey: yup.string().required("Required"),
    usage: yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    //    setIsProgress(true);
    //console.log("values:", values);
    let payload = {
      deviceId: props.currentDeviceId,
      subjectId: subjectMap[values.subjectIdKey],
      usage: values.usage,
    };
    //console.log("Payload =", payload);
    AssignDeviceWithUsagePlan(payload).then((res) => {
      setResponse({
        statusCode: res.response ? res.response.status : res.error.response.status,
        message: res.response ? res.response.data : res.error.response.data,
      });
      setIsProgress(false);
      setIsTimedMessage(true);
      setTimeout(() => {
        setIsTimedMessage(false);
        props.closeModal();
      }, 500);
    });
  };

  const btnDisabled = isProgress || isTimedMessage;

  if (assignMlLoader) {
    return (<div className={styles.circularProgressDiv} style={{ backgroundColor: `#FFFFFF`, height: `100%`, width: `100%` }}>
      <CircularProgress thickness={5.0} style={{ color: `#4E253A` }}></CircularProgress>
    </div>);
  } else {
    return (
      <Fragment>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {(formik) => (
            <Form>
              <Grid container>
                <Grid container>
                  <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.inputCenterDiv}>
                      <div className={styles.radioLabelForm}>Assign MouthLab {props.currentDeviceId} to Subject Name</div>
                      <FormikControl value="subjectIdKey" control="dynamicSelect" label="" name="subjectIdKey" options={availableSubjectsList} />
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.inputCenterDiv}>
                      <div className={styles.radioLabelForm}>Usage per day</div>
                      <FormikControl value="usage" control="dynamicSelect" label="" name="usage" options={usageOptions} />
                    </div>
                  </Grid>
                </Grid>

                {isProgress && (
                  <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.progressResponseDiv}>
                      <div className={styles.overlayProgressBar}>
                        <CircularProgress style={{ color: `#4E253A` }} />
                        <div className={styles.progressMessage}>Processing, please wait.</div>
                      </div>
                    </div>
                  </Grid>
                )}
                {response.message && (
                  <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.progressResponseDiv}>
                      <div className={styles.overlayProgressBar}>
                        <div className={`${styles.statusMessage} ${response.statusCode == 200 ? styles.smSuccess : styles.smError}`}>{response.message}</div>
                      </div>
                    </div>
                  </Grid>
                )}

                <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.buttonContainer}>
                    <button type="button" onClick={props.closeModal} className={styles.cancelButton} disabled={btnDisabled}>
                      Cancel
                    </button>
                    <button type="submit" className={styles.submitButton} disabled={btnDisabled}>
                      Confirm
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Fragment>
    );
  }
}
