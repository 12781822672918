import React from "react";
import Grid from "@material-ui/core/Grid";
import styles from "./EcgContainer.module.css";
import { getPageLoader } from "../../../../../../../Reducer/selectors/UtilSelector.js";
import { CircularProgress } from "@material-ui/core";
import EcgGraph from "../EcgGraph/EcgGraph";
import { useSelector } from "react-redux";
import NoDataImg from "../../../../../../../Assets/Images/No-Data-PNG.png";

export default function EcgContainer(props) {
  //console.log("ECGGC DATA PASSED DFOWBN", props.ecgData);
  const loader = useSelector(getPageLoader);

  const plotEcgGraph = props.ecgData.map((ecgParams) => (
    <EcgGraph key={ecgParams.recordId} heartRate={ecgParams.heartRate.value} xValues={ecgParams.ecg.xValues} yValues={ecgParams.ecg.yValues} ecgTimestamp={ecgParams.timestamp} />
  ));

  return (
    <div>
      <Grid container>
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12} style={{ maxHeight: "65vh", overflowY: "scroll", padding: "5px" }}>
          <div className={styles.responsive}>{plotEcgGraph}</div>
        </Grid>
      </Grid>
    </div>
  );
}
