import React from "react";
import { Field, ErrorMessage } from "formik";
import styles from "./CheckBox.module.css";
import InputErrorMessage from "../InputError/InputErrorMessage";

export default function CheckBox(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div>
      <label htmlFor={name} className={styles.labelDivStyles}>
        <div className={styles.labelStyles}>{label}</div>
      </label>
      <div>
        <Field name={name} {...rest}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <React.Fragment key={option.key}>
                  <input
                    // className="radInput"
                    type="checkbox"
                    id={option.value}
                    {...field}
                    value={option.value}
                    /* checked={field.value.includes(option.value)} */
                    className={styles.checkInput}
                  />
                  <label
                    htmlFor={option.value}
                    className={styles.checkboxLabel}
                  >
                    {option.key}
                  </label>
                  <ErrorMessage name={name} component={InputErrorMessage} />
                </React.Fragment>
              );
            });
          }}
        </Field>
      </div>
      <ErrorMessage name={name} />
    </div>
  );
}
