import React from "react";
import styles from "./../HelpAndSupport.module.css";

export default function IntroductionVideo(props) {

    return (
        <div className={styles.videoResponsive}>
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${props.embedId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      );
} 
