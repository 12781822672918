import React, { useContext, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import styles from "./TableBody.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import QuickView from "../../QuickView/QuickView";
import { PatientContext } from "../../../../Store/PatientDataProvider";
import CameraIcon from '@mui/icons-material/Camera';
import { setAllPatientsList, setCurrentPatientGUID, setCurrentPatientSubjectID, setPatientMainTab, setShowAidiValidationLoader, setShowLoader, setShowPatientGraphsLoader, setShowPatientProfilePageLoader, setShowPatientTimerDrawerLoader, setShowThresholdsLoader, setShowPatientQuickViewLoader } from "../../../../../Actions/UtilActions";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedProjectIdAndLocationId } from "../../../../../Reducer/selectors/DashboardSelector";
import { setShowNotesPanel, setShowNotesDrawer, setShowTimerDrawer, setAbortTimerRedirectLink, getAidiBrPayload, getAidiHrPayload, getAidiBaselinePayload, setIsAddBaselineMode, setIsAddBaselineModeDone, setIsAidiValidating, getAidiBaselinePayloadSuccess, getAidiBrPayloadSuccess, getAidiHrPayloadSuccess, setIsThereNewBaselineRec, setValidationMessage, getAidiGraphPayload, setSelectedAIDITab, getAidiGraphPayloadSuccess, setBpComputed, setSpo2BrComputed, setHrHrvComputed, setTempPrComputed, setFevPefComputed, setGwpComputed, getLatestBaseline, setCurrSubjectName, setSelectedMlId, getDiagnosesPayload } from "../../../../../Actions";
import { subDays } from "date-fns";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { getIsAddBaselineMode, getIsThereNewBaselineRec } from "../../../../../Reducer/selectors/AIDISelector";
import { getDisplayLabelWithoutEhrId } from "../../../../../Utils/GlobalFunctions";
import domainCF from "../../../../API/domainCF";

export default function TableBody(props) {

  const domainCF_dev = 'https://d37borf687po83.cloudfront.net';
  const domainCF_prod = 'https://d1kstnhfbq78td.cloudfront.net';

//  const first = props.primaryDiagnosis ? props.primaryDiagnosis : "--";
  const fullName = props.firstName + " " + props.lastName;
  // Using forwarding ref to pass function to
  //control quick view drawer operations
  const ref = useRef(null);
  let patientInfo = useContext(PatientContext);
  const dispatch = useDispatch();
  const selectedProjectIdLocationId = useSelector(getSelectedProjectIdAndLocationId);
  const navigate = useNavigate();

  function handleSettingPatientInfo(event) {
    event.preventDefault();
    console.log('handleSettingPatientInfo: ', props);
    resetAidiStates();
    resetTrends();
    dispatch(setShowPatientProfilePageLoader(true));
    dispatch(setShowPatientQuickViewLoader(true));
    dispatch(setShowPatientTimerDrawerLoader(true));
    dispatch(setShowPatientGraphsLoader(true));
    dispatch(setAbortTimerRedirectLink(""));
    dispatch(setCurrentPatientGUID(props.subjectGUID));
    dispatch(setCurrentPatientSubjectID(props.subjectId));
    dispatch(setCurrSubjectName(getDisplayLabelWithoutEhrId(props.firstName, props.lastName, props.subjectId)));
    console.log('setSelectedMlId');
    dispatch(setSelectedMlId(props.deviceId));
    dispatch(setShowThresholdsLoader(true));
    dispatch(getLatestBaseline({ subjectGuid: props.subjectGUID }));
    dispatch(setAllPatientsList(props.patientList));
    patientInfo.setSubjectId(props.subjectId);
    patientInfo.setSubjectGUID(props.subjectGUID);
    patientInfo.setCurrentMouthLabId(props.deviceId);
    patientInfo.setPatientList(props.patientList);
    dispatch(setShowNotesDrawer(false));
    dispatch(setShowTimerDrawer(false));
    dispatch(setShowNotesPanel(false));
    setAidiStates();
    dispatch(setPatientMainTab(0));
    dispatch(getDiagnosesPayload({ subjectId: props.subjectId }));
    navigate(`/patientDetails/${props.subjectGUID}`);
  };

  function handleSettingPatientInfoWORedirect(event) {
    event.preventDefault();
    console.log('handleSettingPatientInfo: ', props);
    resetAidiStates();
    resetTrends();
    dispatch(setShowPatientProfilePageLoader(true));
    dispatch(setShowPatientQuickViewLoader(true));
    dispatch(setShowPatientTimerDrawerLoader(true));
    dispatch(setShowPatientGraphsLoader(true));
    dispatch(setAbortTimerRedirectLink(""));
    dispatch(setCurrentPatientGUID(props.subjectGUID));
    dispatch(setCurrentPatientSubjectID(props.subjectId));
    dispatch(setCurrSubjectName(getDisplayLabelWithoutEhrId(props.firstName, props.lastName, props.subjectId)));
    console.log('setSelectedMlId');
    dispatch(setSelectedMlId(props.deviceId));
    dispatch(setShowThresholdsLoader(true));
    dispatch(getLatestBaseline({ subjectGuid: props.subjectGUID }));
    dispatch(setAllPatientsList(props.patientList));
    patientInfo.setSubjectId(props.subjectId);
    patientInfo.setSubjectGUID(props.subjectGUID);
    patientInfo.setCurrentMouthLabId(props.deviceId);
    patientInfo.setPatientList(props.patientList);
    dispatch(setShowNotesDrawer(false));
    dispatch(setShowTimerDrawer(false));
    dispatch(setShowNotesPanel(false));
    dispatch(getDiagnosesPayload({ subjectId: props.subjectId }));
    setAidiStates();
    dispatch(setPatientMainTab(0));
  };

  function handleSettingQuickViewInfo(event) {
    event.preventDefault();
    dispatch(setShowPatientQuickViewLoader(true));
    dispatch(setCurrentPatientGUID(props.subjectGUID));
    console.log('setSelectedMlId');
    dispatch(setSelectedMlId(props.deviceId));
    dispatch(getLatestBaseline({ subjectGuid: props.subjectGUID }));
    ref.current.handleQuickDrawerOpen();
  };

  const resetTrends = () => {
    dispatch(setBpComputed(false));
    dispatch(setSpo2BrComputed(false));
    dispatch(setHrHrvComputed(false));
    dispatch(setTempPrComputed(false));
    dispatch(setFevPefComputed(false));
    dispatch(setGwpComputed(false));
  }

  const resetAidiStates = () => {
    dispatch(getAidiBaselinePayloadSuccess([]));
    dispatch(getAidiBrPayloadSuccess([]));
    dispatch(getAidiHrPayloadSuccess([]));
    dispatch(getAidiGraphPayloadSuccess({}));
    dispatch(setIsAddBaselineMode(false));
    dispatch(setIsAddBaselineModeDone(false));
    dispatch(setIsAidiValidating(false));
    dispatch(setIsThereNewBaselineRec(false));
    dispatch(setValidationMessage(""));
    dispatch(setShowAidiValidationLoader(false));
  };

  const setAidiStates = () => {
    let payload = {
      subjectId: props.subjectId,
      startDate: (subDays(new Date(), 120)).toISOString().split('T')[0],
      endDate: (new Date()).toISOString().split('T')[0],
    }
    dispatch(getAidiBrPayload(payload));
    dispatch(getAidiHrPayload(payload));
    dispatch(getAidiBaselinePayload(payload));
    dispatch(getAidiGraphPayload(payload));
    dispatch(setSelectedAIDITab(0));
  };

  const handleAidar = (event) => {
    //    ref.current.handleQuickDrawerOpen();
    handleSettingQuickViewInfo(event);
    handleSettingPatientInfoWORedirect(event);
  };

  const bgColorRed = "#F89D9D";
  const bgColorGreen = "#B4F196";

  function SplitTime(hours) {
    var Days = Math.floor(hours / 24);
    var Remainder = hours % 24;
    var Hours = Math.floor(Remainder);
    var Minutes = Math.floor(60 * (Remainder - Hours));
    return { Days: Days, Hours: Hours, Minutes: Minutes };
  }

  var timeResult = "--";
  parseInt(props.elapsedHours) === -1 ? (timeResult = "--") : (timeResult = SplitTime(props.elapsedHours));

  function ColorfulStatus({ children }) {
    var status = { children };
    switch (status.children.props.children) {
      case "Active":
        return <button type="button" className={styles.activeButton}>{children}</button>;
      case "Registered":
        return <button type="button" className={styles.registerButton}>{children}</button>;
      case "Terminated":
        return <button type="button" className={styles.terminateButton}>{children}</button>;
      case "Calibrate":
        return <button type="button" className={styles.calibrateButton}>{children}</button>;
      case "Inactive":
        return <button type="button" className={styles.completedButton}>{children}</button>;
      default:
        return <span style={{ color: "#292f4c" }}>{children}</span>;
    }
  }

  function ColorfulAdherence({ children }) {
    var adherence = { children };
    if (parseInt(adherence.children.props.children) < 8) {
      return <button type="button" className={styles.redButton}>{children}</button>;
    } else if (parseInt(adherence.children.props.children) < 17) {
      return <button type="button" className={styles.yellowButton}>{children}</button>;
    } else
      return <button type="button" className={styles.greenButton}>{children}</button>;
  }

  function getNameWithInitial(fullName) {
    //console.log("fullName = ", fullName);
    var mName = fullName.trim().split(' ').filter(Boolean);
    //console.log("mName:", mName);
    if (mName.length > 3) {
      return mName[0].substring(0, 1) + '.' + mName[1].substring(0, 1) + '.' + mName[2].substring(0, 1) + '. ' + mName.slice(3);
    } else if (mName.length > 2) {
      return mName[0].substring(0, 1) + '.' + mName[1].substring(0, 1) + '. ' + mName.slice(2);
    } else if (mName.length > 1 && mName[0]) {
      return mName[0].substring(0, 1) + '. ' + mName.slice(1);
    }
    else return mName[0];
  }

  function isEmpty(value) {
    return (value == null || value.trim().length === 0);
  }

  function daysInThisMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  return (
    <div>
      <Grid container>
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <table className={styles.responsiveTable}>
            <tbody>
              <tr>
                <td
                  className={props.subjectGUID === patientInfo.subjectGUID ? styles.selectedfirstColumn : styles.firstColumnContainer}
                >
                  {domainCF === domainCF_prod ? (
                    <div className={styles.subjectIdDiv} data-tip data-for="Hurtz">
                      <div className={styles.navlinkStyle} onClick={(event) => handleSettingPatientInfo(event)}>
                        {getDisplayLabelWithoutEhrId(props.firstName, props.lastName, props.subjectId)}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.subjectIdDiv} data-tip data-for="Hurtz">
                      <HtmlTooltip title={props.subjectId}>
                        <div className={styles.navlinkStyle} onClick={(event) => handleSettingPatientInfo(event)}>
                          {getDisplayLabelWithoutEhrId(props.firstName, props.lastName, props.subjectId)}
                        </div>
                      </HtmlTooltip>
                    </div>
                  )}
                </td>
                {/* <td className={props.subjectGUID === patientInfo.subjectGUID ? styles.selectedTableCellContainer : styles.tableCellContainer} onClick={(event) => handleSettingPatientInfo(event)}>
                  <div className={styles.mouthLabIdDiv}>
                    <NavLink to={`/patientDetails/${props.subjectGUID}`} className={styles.navlinkStyle}>
                      {props.deviceId}
                    </NavLink>
                  </div>
                </td> */}
                <td
                  className={props.subjectGUID === patientInfo.subjectGUID ? styles.selectedTableCellContainer : styles.tableCellContainer}
                >
                  <div className={styles.tableCellContents}>
                    <div className={styles.navlinkStyle} onClick={(event) => handleSettingPatientInfo(event)}>
                      {props.ehrId}
                    </div>
                  </div>
                </td>
                <td
                  className={props.subjectGUID === patientInfo.subjectGUID ? styles.selectedTableCellContainer : styles.tableCellContainer}
                >
                  <div className={styles.tableCellContents}>
                    <div className={styles.navlinkStyle} onClick={(event) => handleSettingPatientInfo(event)}>
                      {props.dob}
                    </div>
                  </div>
                </td>
                <td
                  className={props.subjectGUID === patientInfo.subjectGUID ? styles.selectedTableCellContainer : styles.tableCellContainer}
                >
                  <div className={styles.tableCellContents}>
                    <div className={styles.navlinkStyle} onClick={(event) => handleSettingPatientInfo(event)}>
                      {props.gender}
                    </div>
                  </div>
                </td>
                <td
                  className={props.subjectGUID === patientInfo.subjectGUID ? styles.selectedTableCellContainer : styles.tableCellContainer}
                >
                  <ColorfulStatus>
                    <div className={styles.navlinkStyle} onClick={(event) => handleSettingPatientInfo(event)}>
                      {props.patientStatus}
                    </div>
                  </ColorfulStatus>
                </td>
                <td
                  className={props.subjectGUID === patientInfo.subjectGUID ? styles.selectedTableCellContainer : styles.tableCellContainer}
                >
                  <div className={styles.tableCellContents}>
                    <HtmlTooltip title={props.primaryDiagnosis ? props.primaryDiagnosis : ""}>
                      <div className={styles.navlinkStyle} onClick={(event) => handleSettingPatientInfo(event)}>
                        {props.primaryDiagnosis ? props.primaryDiagnosis ? props.primaryDiagnosis.length > 15 ? props.primaryDiagnosis.substring(0, 12) + "..." : props.primaryDiagnosis : "--" : "--"}
                      </div>
                    </HtmlTooltip>
                  </div>
                </td>
                <td
                  className={props.subjectGUID === patientInfo.subjectGUID ? styles.selectedTableCellContainer : styles.tableCellContainer}
                >
                  <div className={styles.tableCellContents}>
                    <div className={styles.navlinkStyle} onClick={(event) => handleSettingPatientInfo(event)}>
                      {props.registeredBy ? getNameWithInitial(props.registeredBy) : ""}
                    </div>
                  </div>
                </td>

                <td
                  className={props.subjectGUID === patientInfo.subjectGUID ? styles.selectedTableCellContainer : styles.tableCellContainer}
                >
                  <ColorfulAdherence>
                    <div className={styles.navlinkStyle} onClick={(event) => handleSettingPatientInfo(event)}>
                      {/* <ProgressBarLine bgColor={`${props.adherence < 60 ? bgColorRed : bgColorGreen}`} completed={props.adherence !== 'null' ? props.adherence : 0} /> */}
                      {props.adherence}/{daysInThisMonth()}
                    </div>
                  </ColorfulAdherence>
                </td>
                <td
                  className={props.subjectGUID === patientInfo.subjectGUID ? styles.selectedLastColumnContainer : styles.lastColumnContainer}
                >
                  <div className={styles.hourElapsedDiv}>
                    <div className={styles.navlinkStyle} onClick={(event) => handleSettingPatientInfo(event)}>
                      {" "}
                      {timeResult === "--"
                        ? "--"
                        : parseInt(props.elapsedHours) > 24
                          ? timeResult.Days + " Days " + timeResult.Hours + " Hours"
                          : timeResult.Hours + " Hours"}
                    </div>
                  </div>
                </td>
                <td className={styles.tableButtonsCell}>
                  <div className={styles.buttonTab}>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <div>Quickview</div>
                        </React.Fragment>
                      }
                    >
                      <button className={styles.buttonStyles} onClick={(event) => handleAidar(event)}>
                        <CameraIcon size={25} className={styles.qvIcon} />
                      </button>
                    </HtmlTooltip>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
      <QuickView ref={ref} />
    </div>
  );
}
