import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./PatientNotes.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { format } from "date-fns";
import { DateContext } from "../../../Store/DateProvider";
import Button from "@mui/material/Button";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getPatientNotesSelector } from "../../../../Reducer/selectors/NotesSelector";
import { getSelectedSubjectId, getUserProfile, getUserRole } from "../../../../Reducer/selectors/DashboardSelector";
import { setOpenAddNotesPanel, setShowNotesDrawer, setShowNotesPanel } from "../../../../Actions";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { getPatientNotes } from "../../../../Actions";
import subDays from "date-fns/subDays";
import Input from '@material-ui/core/Input';
import SendIcon from '@mui/icons-material/Send';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { updatePatientNote } from "../../../../Actions";
import { selectedRecordIdForNotesSelector, getSelectedNotesTimestamp } from "../../../../Reducer/selectors/NotesSelector";
import { useParams } from "react-router-dom";
import GetPatientInfo from "../../../API/GetPatientInfo/GetPatientInfo";
import { setShowLoader, setShowPatientNotesLoader } from "../../../../Actions/UtilActions";
import { postPatientNotes } from "../../../../Actions";
import { getPageLoader, getPatientNotesLoader } from "../../../../Reducer/selectors/UtilSelector";
import { CircularProgress } from "@material-ui/core";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { globalConstants } from "../../../../Constants/GlobalConstants";
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Radio from '@mui/material/Radio';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function PatientNotes() {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const dispatch = useDispatch();
    const [isCalOpen, setIsCalOpen] = useState(false);
    const patientNotes = useSelector(getPatientNotesSelector);
    console.log('patientNotes: ', patientNotes);
    const [notes, setNotes] = useState([]);
    const selectedSubjectId = useSelector(getSelectedSubjectId);
    const refOne = useRef(null);
    const [newNotes, setNewNotes] = useState("");
    const [tagName, setTagName] = useState("");
    const [isRPMSelected, setIsRPMSelected] = useState(false);
    const [isCCMSelected, setIsCCMSelected] = useState(false);
    const [isGeneralSelected, setIsGeneralSelected] = useState(false);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [searched, setSearched] = useState("");
    const selectedRecordId = useSelector(selectedRecordIdForNotesSelector);
    const [patientDetailInfo, setPatientDetailInfo] = useState({});
    const userProfile = useSelector(getUserProfile);
    const [firstName] = useState(userProfile.firstName);
    const [lastName] = useState(userProfile.lastName);
    const userName = firstName + " " + lastName;
    const userId = userProfile.id;
    let curPatientId = useParams();
    const loader = useSelector(getPatientNotesLoader);
    const timeZoneFromDayjs = dayjs.tz.guess();
    const [selectedProgram, setSelectedProgram] = useState("");
    const [programFilters, setProgramFilters] = useState(["RPM", "CCM", "General"]);

    const userRole = useSelector(getUserRole);

    useEffect(() => {
        dispatch(setOpenAddNotesPanel(false));
        dispatch(setShowNotesDrawer(false));
        dispatch(setShowNotesPanel(false));
        dispatch(setShowPatientNotesLoader(true));
        const payload = {
            subjectId: selectedSubjectId.selectedSubjectId,
            recordId: '',
            userRole: userRole,
        }
        dispatch(getPatientNotes(payload));
        dispatch(setOpenAddNotesPanel(false))
    }, []);

    useEffect(() => {
        let filteredNotes = applySelectedFilters(patientNotes);
        setNotes(filteredNotes);
    }, [patientNotes, selectedProgram]);

    useEffect(() => {
        GetPatientInfo(curPatientId.patientId).then((res) => {
            setPatientDetailInfo(res);
        });
    }, [curPatientId.patientId]);

    const [dateRangeApplied, setDateRangeApplied] = useState([
        {
            startDate: subDays(new Date(), 30),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [dateRangeSelected, setDateRangeSelected] = useState(dateRangeApplied);

    const useStyles = makeStyles(() => ({
        tooltip: {
            marginTop: 5,
            font: "12px",
            background: "black",
            fontFamily: "GTWalsheimProRegular",
        },
    }));

    const classes = useStyles();

    const handleCalClick = (event) => {
        event.preventDefault();
        if (isCalOpen) {
            setDateRangeSelected(dateRangeApplied);
            setIsCalOpen(false);
        } else {
            setDateRangeApplied(dateRangeSelected);
            setIsCalOpen(true);
        }
    };

    const fetchPatientNotes = () => {
        setIsCalOpen(false);
        dispatch(setShowPatientNotesLoader(true));
        const payload = {
            subjectId: selectedSubjectId.selectedSubjectId,
            recordId: '',
            userRole: userRole,
        }
        dispatch(getPatientNotes(payload));
    }

    // Hide dropdown on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setIsCalOpen(false);
        }
    };

    const handleCalChanges = (item) => {
        setDateRangeSelected([item.selection]);
    };

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            setIsCalOpen(false);
        }
    };

    const handleAddTag = (tag) => {
        /* if (tagNames.includes(tag)) {
            const filteredTags = tagNames.filter((item) => item !== tag);
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, tag]);
        } */
        setTagName(tag);
    }

    const handleAddTagRPM = (event) => {
        event.preventDefault();
        if (isRPMSelected) {
            setIsRPMSelected(false);
            setTagName("");
        } else {
            setIsRPMSelected(true);
            setIsCCMSelected(false);
            setIsGeneralSelected(false);
            setTagName("RPM");
        }
    }

    const handleAddTagCCM = (event) => {
        event.preventDefault();
        if (isCCMSelected) {
            setIsCCMSelected(false);
            setTagName("");
        } else {
            setIsRPMSelected(false);
            setIsCCMSelected(true);
            setIsGeneralSelected(false);
            setTagName("CCM");
        }
    }

    const handleAddTagGeneral = (event) => {
        event.preventDefault();
        if (isGeneralSelected) {
            setIsGeneralSelected(false);
            setTagName("");
        } else {
            setIsRPMSelected(false);
            setIsCCMSelected(false);
            setIsGeneralSelected(true);
            setTagName("General");
        }
    }

    const handleCheckBookmark = (event) => {
        event.preventDefault();
        setIsBookmarked(!isBookmarked);
        //console.log("ISBOOKMARK: ", isBookmarked);
    }

    const searchNotes = (event) => {
        setSearched(event.target.value);
        let searchValue = event.target.value;
        let filteredNotes = patientNotes;

        if (!searchValue || searchValue.length === 0) {
            setNotes(filteredNotes);
        } else {
            filteredNotes = patientNotes && patientNotes.filter((note) => (note.note.toLowerCase().includes(searchValue.toLowerCase()) || (note.createdBy ? note.createdBy.toLowerCase().includes(searchValue.toLowerCase()) : false)));
            setNotes(filteredNotes);
        }
    }

    const handleCancleSearch = (event) => {
        event.preventDefault();
        setSearched("");
        setNotes(patientNotes);
    }

    const handleBookmarking = (event, notes) => {
        event.preventDefault();
        updateNote(notes);
    }

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const updateNote = (notes) => {
        const payload = {
            id: notes.id,
            subjectId: notes.subjectId,
            recordId: notes.recordId,
            recordTimestamp: notes.recordTimestamp,
            createdDateTime: new Date(),
            createdBy: notes.createdBy,
            note: notes.note,
            isBookmarked: notes.isBookmarked == "false" ? 1 : 0,
            tags: notes.tags,
            timezoneStr: timeZone,
            userRole: userRole,
        }
        dispatch(updatePatientNote(payload));
    }

    const resetTags = () => {
        setIsRPMSelected(false);
        setIsCCMSelected(false);
        setIsGeneralSelected(false);
        setTagName("");
        setIsBookmarked(false);
    }


    const submitNotes = () => {

        if (newNotes.length <= 0 || newNotes.length > 1000 || ((userRole === globalConstants.USER_ROLE_AIDAR_ADMIN || userRole === globalConstants.USER_ROLE_BILLING_PHYSICIAN) && tagName === "")) {
            return;
        } else {
            const payload = {
                subjectId: selectedSubjectId.selectedSubjectId,
                recordId: selectedRecordId,
                createdBy: userId,
                note: newNotes,
                isBookmarked: isBookmarked,
                tags: tagName,
                createdDateTime: new Date(),
                timezoneStr: timeZone,
                userRole: userRole,
            }
            dispatch(setShowPatientNotesLoader(true));
            dispatch(postPatientNotes(payload));
            setNewNotes("");
            resetTags();
            setIsBookmarked(false);
        }
    }

    function ColorfulTagsGen(props) {

        if (!props.tags || props.tags.length === 0) {
            return (
                <></>
            );
        }
        else {
            return (
                <>
                    {/* props.tags.map(tag => ( */
                        props.tags === "RPM" ?
                            (<div className={styles.selectedTagRPM} key={props.tags}> {props.tags} </div>) :
                            props.tags === "CCM" ?
                                (<div className={styles.selectedTagCCM} key={props.tags}> {props.tags} </div>) :
                                props.tags === "General" ?
                                    (<div className={styles.selectedTagGeneral} key={props.tags}> {props.tags} </div>) :
                                    (<div className={styles.selectedTagOthers} key={props.tags}> General </div>)//))
                    }
                </>
            );
        }
    };

    const applySelectedFilters = (notesList) => {

        let filteredList = notesList.filter((note) => {
            if (selectedProgram === "") {
                return true;
            } else if (note.tags === selectedProgram) {
                return true;
            }
        });

        return filteredList;
    };


    const clearProgramFilter = () => {
        setSelectedProgram("");
    };

    const addToProgramFilter = (event) => {
        event.preventDefault();
        setSelectedProgram(event.target.name);
    };

    const RenderProgramFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedProgram === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedProgram === filter.toString()} onClick={(event) => addToProgramFilter(event)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    const ProgramFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderProgramFilters filterList={programFilters}></RenderProgramFilters>
            </div>
        );
    };

    return (
        <div className={styles.rootDiv}>
            <div className={styles.containerDiv}>
                <div className={styles.datePickerDiv}>
                    <Tooltip title="Choose another date range" classes={{ tooltip: classes.tooltip }}>
                        <CalendarMonthIcon onClick={(event) => handleCalClick(event)}></CalendarMonthIcon>
                    </Tooltip>
                    <div className={styles.dateInputDiv}>
                        <input
                            value={`${format(dateRangeSelected[0].startDate, "MM/dd/yyyy")}  |  ${format(dateRangeSelected[0].endDate, "MM/dd/yyyy")}`}
                            readOnly
                            className={styles.dateInput}
                            onClick={(event) => handleCalClick(event)}
                        />
                    </div>
                    {/* <div>
                        <Button startIcon={<TableChartOutlinedIcon />} onClick={(event) => fetchPatientNotes(event)} className={styles.exportButton}>
                            View
                        </Button>
                    </div> */}
                </div>
                {isCalOpen && (
                    <div className={styles.datePickerWrapper} ref={refOne}>
                        <DateRangePicker
                            onChange={(item) => handleCalChanges(item)}
                            editableDateInputs={false}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={dateRangeSelected}
                            direction="horizontal"
                            maxDate={addDays(new Date(), 1)}
                            rangeColors={["#aa346f"]}
                        />
                        <div className={styles.applyButtonDiv}>
                            <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Cancel </div>
                            <div className={styles.applyButton} onClick={() => fetchPatientNotes()}> Apply </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.notesTopBar}>
                <Input
                    className={styles.searchBar}
                    type="text"
                    placeholder="Search Notes"
                    value={searched}
                    onChange={(event) => setSearched(event.target.value)}
                    onKeyUp={(event) => searchNotes(event)}
                    startAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                size="small"
                            >
                                <SearchIcon className={styles.searchIcon}></SearchIcon>
                            </IconButton>
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            {<IconButton
                                className={styles.searchIcon}
                            >
                                {searched === "" ? <></> : <CloseIcon style={{ color: "#4E253A" }} onClick={(event) => handleCancleSearch(event)}></CloseIcon>}
                            </IconButton>}
                        </InputAdornment>
                    }
                    disableUnderline="true"
                    style={{
                        fontFamily: "GTWalsheimProRegular"
                    }}
                />
                {(userRole === globalConstants.USER_ROLE_AIDAR_ADMIN || userRole === globalConstants.USER_ROLE_BILLING_PHYSICIAN) && (<PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                    {(popupState) => (
                        <div className={selectedProgram != "" ? styles.filterDivSelected : styles.filterDiv} {...bindToggle(popupState)}>
                            <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                            <div className={styles.filterDivLabel} >{selectedProgram != "" ? selectedProgram : 'Program'}</div>
                            <div>
                                {selectedProgram != "" ? (<CloseIcon onClick={() => clearProgramFilter()} style={{ marginTop: '6px', color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>) : (<ExpandMoreIcon style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>)}
                            </div>
                            <Popper {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper className={styles.filterBackdrop}>
                                            <ProgramFilterPanel popupState={popupState} />
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>
                    )}
                </PopupState>)}
            </div>
            <div className={styles.allNotesDiv}>
                <div className={styles.showNotesDiv}>
                    {loader ? (<div className={styles.circularProgressDiv} style={{ backgroundColor: `#FFFFFF`, height: `100%`, width: `100%` }}>
                        <CircularProgress thickness={5.0} style={{ color: `#4E253A` }}></CircularProgress>
                    </div>) : (notes && notes.length > 0) ? (notes.map((note) =>
                        <div className={styles.noteBlock}>
                            <div className={styles.noteHeader}>
                                <div className={styles.createdDiv}>
                                    <div className={styles.createdBy}>
                                        <IconButton
                                            size="small"
                                            onClick={(event) => handleBookmarking(event, note)}
                                        >
                                            {note.isBookmarked === "true" ? (<BookmarkOutlinedIcon className={styles.pinButton}></BookmarkOutlinedIcon>) : (<BookmarkBorderOutlinedIcon className={styles.pinButton}></BookmarkBorderOutlinedIcon>)}
                                        </IconButton>
                                        {note.createdBy}
                                    </div>
                                    <div className={styles.createdTime}>
                                        {dayjs.utc(note.createdDateTime).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
                                    </div>
                                </div>
                                <div className={styles.noteTags}>
                                    <ColorfulTagsGen tags={note.tags} />
                                </div>
                            </div>
                            <div className={styles.noteTextArea}>
                                <Input
                                    className={styles.notesDisplayer}
                                    value={note.note}
                                    multiline={true}
                                    readOnly={true}
                                    disableUnderline="true"
                                />

                                <div className={styles.noteFooter}>
                                    {note.recordTimestamp ? <div className={styles.noteMeasurementTime}>
                                        {dayjs.utc(note.recordTimestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
                                    </div> : <></>
                                    }
                                </div>
                            </div>
                        </div>)) : (
                        <div style={{
                            display: "flex",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            fontFamily: "GTWalsheimProRegular",
                            fontSize: "26px",
                            fontWeight: "bold",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            color: "#4E253A",
                            overflowY: "hidden"
                        }}>
                            No notes available!
                        </div>
                    )}
                </div>
                <div className={styles.addNewNoteDiv}>
                    <div className={styles.dummyWrapper}>
                        <div className={styles.newNotesWrapper}>
                            <Input
                                className={styles.notesEditor}
                                type="text"
                                placeholder="Start typing..."
                                value={newNotes}
                                multiline={true}
                                onChange={(event) => setNewNotes(event.target.value)}
                                disableUnderline="true"
                            />
                            {/* <div className={styles.characterLimitDiv}>Character limit: {<div className={(newNotes.length <= 0 || newNotes.length > 1000) ? styles.characterCountRed : styles.characterCount}>{newNotes.length}</div>}/1000</div> */}
                            <div className={styles.newDumDiv}>
                                <div className={styles.addNoteButtons}>
                                    <IconButton
                                        size="small"
                                        className={styles.addNotesIcon}
                                        onClick={(event) => handleCheckBookmark(event)}
                                    >
                                        {isBookmarked ? (<BookmarkOutlinedIcon style={{ color: "#87668B", height: '20px', width: '20px' }}></BookmarkOutlinedIcon>) : (<BookmarkBorderOutlinedIcon style={{ color: "#87668B", height: '20px', width: '20px' }}></BookmarkBorderOutlinedIcon>)}
                                    </IconButton>
                                </div>
                                <div className={styles.characterLimitDiv}>
                                    {<div className={(newNotes.length <= 0 || newNotes.length > 1000) ? styles.characterCountRed : styles.characterCount}>{newNotes.length}</div>}/1000</div>
                            </div>
                        </div>

                        {(userRole === globalConstants.USER_ROLE_AIDAR_ADMIN || userRole === globalConstants.USER_ROLE_BILLING_PHYSICIAN) && <div className={styles.selectTagsDiv}>
                            <IconButton
                                size="small"
                                onClick={(event) => handleAddTagRPM(event)}
                            >
                                <div className={isRPMSelected ? styles.selectedTagRPM1 : styles.tagNameRPM}>
                                    RPM
                                </div>
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={(event) => handleAddTagCCM(event)}
                            >
                                <div className={isCCMSelected ? styles.selectedTagCCM1 : styles.tagNameCCM}>
                                    CCM
                                </div>
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={(event) => handleAddTagGeneral(event)}
                            >
                                <div className={isGeneralSelected ? styles.selectedTagGeneral1 : styles.tagNameGeneral}>
                                    General
                                </div>
                            </IconButton>
                        </div>}

                        <div disabled={(newNotes.length <= 0 || newNotes.length > 1000 || ((userRole === globalConstants.USER_ROLE_AIDAR_ADMIN || userRole === globalConstants.USER_ROLE_BILLING_PHYSICIAN) && tagName === ""))} onClick={() => submitNotes()} className={(newNotes.length <= 0 || newNotes.length > 1000 || ((userRole === globalConstants.USER_ROLE_AIDAR_ADMIN || userRole === globalConstants.USER_ROLE_BILLING_PHYSICIAN) && tagName === "")) ? styles.submitNotesButtonDisabled : styles.submitNotesButton}>Submit</div>

                    </div>
                </div>
            </div>
        </div >
    );
}