export function getTimerInfoSelector({ timerReducer }) {
    if (timerReducer &&
        timerReducer.timerInfo
    )
        return timerReducer.timerInfo;
    else return {};
}

// export function getLatestMonthTimerInfoSelector({ timerReducer }) {
//     if (timerReducer &&
//         timerReducer.timerInfo &&
//         timerReducer.timerInfo.timerMonthlyInfo &&
//         timerReducer.timerInfo.timerMonthlyInfo.length > 0
//     )
//         return timerReducer.timerInfo.timerMonthlyInfo[0];
//     else return [];
// }

export function getLatestMonthTimerInfoSelector({ timerReducer }) {
    if (timerReducer &&
        timerReducer.latestMonthTimerInfo
    )
        return timerReducer.latestMonthTimerInfo;
    else return {};
}

export function getTimerCreatedUserName({ timerReducer }) {
    if (timerReducer &&
        timerReducer.timerInfo &&
        timerReducer.timerInfo.username !== "null"
    )
        return timerReducer.timerInfo.username;
    else return "";
}

export function getTimerCreatedUserNPI({ timerReducer }) {
    if (timerReducer &&
        timerReducer.timerInfo &&
        timerReducer.timerInfo.userNPI !== "null"
    )
        return timerReducer.timerInfo.userNPI;
    else return "";
}

// export function getPreviousMonthsTimerInfoSelector({ timerReducer }) {
//     if (timerReducer &&
//         timerReducer.timerInfo &&
//         timerReducer.timerInfo.timerMonthlyInfo &&
//         timerReducer.timerInfo.timerMonthlyInfo.length > 1
//     ) {
//         return timerReducer.timerInfo.timerMonthlyInfo.slice(0);
//     }
//     else return [];
// }

export function getPreviousMonthsTimerInfoSelector({ timerReducer }) {
    if (timerReducer &&
        timerReducer.previousMonthTimerInfo
    )
        return timerReducer.previousMonthTimerInfo;
    else return {};
}

export function getIsLastTimerIncompleteSelector({ timerReducer }) {
    if (timerReducer)
        return timerReducer.isLastTimerIncomplete;
    else return false;
}