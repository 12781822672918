import React from "react";
import styles from "./RfmGraph.module.css";
import { Line } from "react-chartjs-2";
import { MdZoomOutMap } from "react-icons/md";
import { FaWaveSquare } from "react-icons/fa";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default function RfmGraph(props) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const timeZoneFromDayjs = dayjs.tz.guess();

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    ctx.canvas.width = 2000;
    ctx.canvas.height = 250;
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(136, 108, 230,1)");
    gradient.addColorStop(0.5, "rgba(136, 108, 230,0.2)");
    gradient.addColorStop(1, "rgba(136, 108, 230,0)");

    //console.log("THIS IS FROM ECG GRAPH SEE THE PROPS", props);

    return {
      labels: props.xValues,
      datasets: [
        {
          label: "RFM",
          fill: false,
          backgroundColor: "#ABC4FF", // Put the gradient here as a fill color
          //borderColor: "#ABC4FF",
          borderColor: "rgba(237, 105, 65)",
          borderWidth: 2,
          pointRadius: 0,
          pointBackgroundColor: "rgba(255, 255, 255)",
          data: props.yValues,
        },
      ],
    };
  };

  var options = {
    responsive: false,
    datasetStrokeWidth: 3,
    pointDotStrokeWidth: 4,
    //scaleLabel: "<%= Number(value).toFixed(0).replace('.', ',') + '°C'%>",

    tooltips: {
      backgroundColor: "rgba(255, 255, 255)",
      bodyFontColor: "rgba(53, 80, 112)",
      titleFontColor: "rgba(53, 80, 112)",
      titleFontSize: 12,
      bodyFontSize: 12,
      bodySpacing: 5,
      bodyAlign: "center",
      titleAlign: "center",

      callbacks: {
        label: function (tooltipItem) {
          return Number(tooltipItem.yLabel);
        },
      },
    },

    scales: {
      xAxes: [
        {
          // type: "time",
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 30,
            beginAtZero: true,
            callback: function (value) { return Math.trunc(value); }
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            //color: "rgba(0, 0, 0, 0)",
            display: true,
            // tickMarkLength: 0,
          },

          ticks: {
            //suggestedMin: -1.2,
            //suggestedMax: 1.2,
            // maxTicksLimit: 11,
            display: false,
          },
        },
      ],
    },

    legend: {
      display: false,
      position: "top",
      labels: {
        fontColor: "#333",
        usePointStyle: true,
      },
    },
  };
  return (
    <div className={styles.ecgCard}>
      <div className={styles.ecgCardTitle}>
        <div className={styles.ecgCardTitleIconStyle}>
          <FaWaveSquare
            className={styles.ecgCardIconStyles}
            style={{ color: `#ED6942` }}
          />
        </div>
        <div
          className={styles.heartRateInformation}
          style={{ color: `#ED6942` }}
        >
          {props.breathingRate} br/min
        </div>
        <div className={styles.zoomEcgCard}>
          {dayjs.utc(props.timestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
          <div className={styles.zoomIconStyle}>
            <MdZoomOutMap />
          </div>
        </div>
      </div>

      <div className={styles.rfmContainer}>
        <Line data={data} options={options}></Line>
      </div>
    </div>
    //<Line data={data} options={options}></Line>
  );
}
