export function getPatientNotesSelector({notesReducer}) {
    if ( notesReducer && 
        notesReducer.patientNotes
    )
    return notesReducer.patientNotes;
    else return [];
}

export function getPatientBookmarkedNotesSelector({notesReducer}) {
    if (
        notesReducer && 
        notesReducer.patientNotes
    ){
        return notesReducer.patientNotes.filter((notes) => notes.isBookmarked === "true");
    }else return [];
}

export function postPatientNotesSelector({notesReducer}) {
    if (
        notesReducer && 
        notesReducer.patientNotes
    )
    return notesReducer.patientNotes;
    else return [];
}

export function getNotesTagsSelector({notesReducer}) {
    if (
        notesReducer && 
        notesReducer.notesTags
    )
    return notesReducer.notesTags;
    else return [];
}

export function getSelectedNotesTimestamp({notesReducer}) {
    if (
        notesReducer && 
        notesReducer.selectedTimestamp
    )
    return notesReducer.selectedTimestamp;
    else return "";
}

export function postPatientNotesSuccessorSelector({notesReducer}) {
    if (
        notesReducer
    )
    return notesReducer.patientNoteAdded;
    else return false;
}

export function getOpenAddNotesPanelSelector({notesReducer}) {
    if (
        notesReducer &&
        notesReducer.openAddNotesPanel
    )
    return notesReducer.openAddNotesPanel;
    else return false;
}

export function selectedRecordIdForNotesSelector({notesReducer}) {
    if (
        notesReducer &&
        notesReducer.selectedRecordIdForNotes
    )
    return notesReducer.selectedRecordIdForNotes;
    else return '';
}