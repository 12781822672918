import React, { useContext, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import styles from "./NDTableBody.module.css";
import { NavLink } from "react-router-dom";
import QuickView from "../../QuickView/QuickView";
import { setCurrentPatientGUID, setShowLoader } from "../../../../../Actions/UtilActions";
import { useDispatch } from "react-redux";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { setSelectedPCCPatient } from "../../../../../Actions";

export default function NDTableBody(props) {

    const first = props.primaryDiagnosis && props.primaryDiagnosis.at(0) ? props.primaryDiagnosis.at(0) : "";
    // Using forwarding ref to pass function to
    //control quick view drawer operations
    const ref = useRef(null);
    const dispatch = useDispatch();

    function handleSettingPatientInfo(event) {
        event.preventDefault();
        dispatch(setCurrentPatientGUID(props.subjectGUID));

        let pccPatientInfo = {
            fullName: props.fullName,
            patientId: props.patientID,
            medicalRecordNumber: props.medicalRecordNumber,
            unitId: props.unitId,
        };

        dispatch(setSelectedPCCPatient(pccPatientInfo));
    }

    function SplitTime(hours) {
        var Days = Math.floor(hours / 24);
        var Remainder = hours % 24;
        var Hours = Math.floor(Remainder);
        var Minutes = Math.floor(60 * (Remainder - Hours));
        return { Days: Days, Hours: Hours, Minutes: Minutes };
    }

    var timeResult = "--";
    if (!isNaN(props.elapsedHours)) {
        parseInt(props.elapsedHours) === -1 ? (timeResult = "--") : (timeResult = SplitTime(props.elapsedHours));
    }

    const DailyAdherence = ({ ma, me, mm }) => {

        return (
            <div className={styles.adhBox}>
                <div className={styles.adhMA}>
                    {ma ? <CheckCircleIcon sx={{ color: "green" }}></CheckCircleIcon> : <CancelIcon sx={{ color: "red" }}></CancelIcon>}
                </div>
                <div className={styles.adhME}>
                    {me ? <CheckCircleIcon sx={{ color: "green" }}></CheckCircleIcon> : <CancelIcon sx={{ color: "red" }}></CancelIcon>}
                </div>
                <div className={styles.adhMM}>
                    {mm ? <CheckCircleIcon sx={{ color: "green" }}></CheckCircleIcon> : <CancelIcon sx={{ color: "red" }}></CancelIcon>}
                </div>
            </div>
        );
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                    <table className={styles.responsiveTable}>
                        <tbody>
                            <tr>
                                <td className={styles.firstColumnContainer} onClick={(event) => handleSettingPatientInfo(event)}>
                                    <div className={styles.subjectIdDiv} data-tip data-for="Hurtz">
                                        <NavLink to={`/pccPatientDetails/${props.patientID}`} className={styles.navlinkStyle}>
                                            {props.fullName}
                                        </NavLink>
                                    </div>
                                </td>
                                <td className={styles.tableCellContainer}>
                                    <div className={styles.mouthLabIdDiv}>{props.medicalRecordNumber}</div>
                                </td>
                                <td className={styles.tableCellContainer}>
                                    <div className={styles.tableCellContents}>{props.room}</div>
                                </td>
                                <th className={styles.tableCellContainerAdh}>
                                    <DailyAdherence ma={props.adh1} me={props.adh2} mm={props.adh3} />
                                </th>
                                <td className={styles.lastColumnContainer}>
                                    <div className={styles.hourElapsedDiv}>
                                        {" "}
                                        {timeResult === "--"
                                            ? "--"
                                            : parseInt(props.elapsedHours) > 24
                                                ? timeResult.Days + " Days " + timeResult.Hours + (timeResult.Hours === 1 ? " Hour" : " Hours")
                                                : timeResult.Hours + (timeResult.Hours === 1 ? " Hour" : " Hours") }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
            </Grid>
            <QuickView ref={ref} />
        </div>
    );
}
