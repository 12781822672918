import { combineReducers } from "redux";
import {dashboardReducer} from './DashboardReducer';
import { patientReducer } from "./PatientReducer";
import { utilReducer } from "./UtilReducer";
import { notesReducer } from "./NotesReducer";
import { nursingDashboardReducer } from "./NursingDashboardReducer";
import { timerReducer } from "./TimerReducer";
import { aidiReducer } from "./AIDIReducer";

const rootReducer = () =>
  combineReducers({
    dashboardReducer: dashboardReducer,
    patientReducer: patientReducer,
    utilReducer: utilReducer,
    notesReducer: notesReducer,
    nursingDashboardReducer: nursingDashboardReducer,
    timerReducer: timerReducer,
    aidiReducer: aidiReducer,
  });

export default rootReducer;

