import React, {
  useEffect, useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatientsList, setProjectIdAndLocationId } from "../../../../Actions";
import { setShowLoader } from "../../../../Actions/UtilActions";
import { getSelectedProjectIdAndLocationId, getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import { getPatientsListSelector } from "../../../../Reducer/selectors/PatientSelector";
import QuickViewMeasurement from "./QuickViewTabs/QuickViewMeasurement";
import QuickViewTabs from "./QuickViewTabs/QuickViewTabs";

export default function SubjectQuickView() {

  const selected = useSelector(getSelectedProjectIdAndLocationId);
  const pList = useSelector(getPatientsListSelector);
  const userProfile = useSelector(getUserProfile);
  const [subjectId, setSubjectId] = useState(userProfile && userProfile.projects[0].id);
  const [guid, setGuid] = useState('');
  const [patientList, setPatientList] = useState(pList && pList.length > 0 ? pList : []);
  const [assignedDevice, setAssignecDevice] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (userProfile && userProfile.projects && userProfile.locations) {
      dispatch(
        setProjectIdAndLocationId({
          projectId: userProfile.projects[0].id,
          locationId: userProfile.locations[0].id,
        })
      );
    }
    dispatch(setShowLoader(true));
    const payload = { 'projectId': userProfile.projects[0].id, 'locationId': userProfile.locations[0].id };
    dispatch(getPatientsList(payload));
  }, [])

  useEffect(() => {

    if (userProfile && userProfile.locations && userProfile.locations.length > 0) {
      // userProfile.locations.filter(loc -> loc.id === );
    }
  }, [userProfile])

  useEffect(() => {
    if (pList && pList.length > 0) {
      const ps = pList.filter(p => userProfile.email.includes(p.deviceId));
      if (ps && ps.length > 0) {
        setSubjectId(ps[0].subjectId);
        setGuid(ps[0].guid);
        setPatientList(ps);
      }

    }

  }, [pList])

  const pList1 = [
    {
      "guid": "c948a5b5-d71e-4e84-9a3b-a4d4bd0f1cfb",
      "subjectId": "731",
      "deviceId": "None",
      "firstName": "",
      "lastName": "",
      "gender": "",
      "dob": "",
      "status": "Registered",
      "registeredBy": "Test Tester",
      "adherence": "0",
      "adherence16": "0",
      "elapsedHours": "1203",
      "diagnoses": [
        ""
      ],
      "email": "",
      "dateOfFirstMeasurement": "None"
    }
  ]

  // let subjectId = pList[0].subjectId;
  // let subjectGUID = pList[0].guid;
  // let patientList = pList;

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      {/* <div>hello</div> */}
      {subjectId && guid && patientList && patientList.length > 0 && <QuickViewMeasurement
        subjectId={subjectId}
        subjectGUID={guid}
        patientList={patientList}
        subjectView={true}
      />}
    </div>

  );
}