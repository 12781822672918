import React from "react";
import styles from "./FacilityReportsWrapper.module.css";
import { getUserProfile } from "../../../Reducer/selectors/DashboardSelector";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import AssessmentIcon from '@mui/icons-material/Assessment';
import Avatar from "@material-ui/core/Avatar";
import ProfileIcon from "../../../Assets/Images/ProfileIcon.png";

export default function FacilityReportsHeader() {

    const userProfile = useSelector(getUserProfile);

    const getUserDisplayName = (firstName, lastName) => {
        return firstName.substring(0, 1) + '. ' + lastName;
    };

    return (
        <div className={styles.accContainer}>
            <Grid container>
                <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.displayContainer}>
                        <div className={styles.pageHeader}>
                            <AssessmentIcon style={{ color: "#4E253A", height: "25px", width: "25px" }}></AssessmentIcon>
                            <h3> Reports </h3>
                        </div>
                        <div className={styles.userGreetings}>
                            <div className={styles.userInfoDiv}>
                                <div>
                                    <Avatar className={styles.avatar2} alt="avatar_patient" src={ProfileIcon}></Avatar>
                                </div>
                                <div className={styles.displayName}>
                                    {userProfile.firstName ? getUserDisplayName(userProfile.firstName, userProfile.lastName) : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}