import apiClient from './ApiClient';
import axios from "axios";

export default class Transport {

  static async genericOperation(url, headersOld, data, type) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const TYPE = type || "POST";
    try {
      //    const response = await axios.post(url, postData, { headers })
      const response = await apiClient({
        method: TYPE,
        headers,
        url,
        data,
      });
      return response.data;
    } catch (e) {
      console.error("ERRORRRR ", e);
      //throw e;
      return null;
    }
  }
}
