import React, { useEffect, useState } from "react";
import {
    makeStyles,
    withStyles,
} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import styles from "./PatientProfileTabs.module.css";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedAIDITabId, getAidiRecordTableSelector } from "../../../../Reducer/selectors/AIDISelector";
import { setSelectedAIDITab, getAidiRecordByRangeTable, getFacilityBPH, setGeneralFormEdit, setPayerFormEdit1, setPayerFormEdit2, setPayerFormEdit3, setFamilyFormEdit1, setFamilyFormEdit2, setFamilyFormEdit3, setFacilityFormEdit, setCareteamFormEdit1, setCareteamFormEdit2, setCareteamFormEdit3 } from "../../../../Actions";
import { getSelectedProjectIdAndLocationId } from "../../../../Reducer/selectors/DashboardSelector";

const AntTabs = withStyles({
    root: {
        boxShadow: "0px 1px 5px 0px #cccaca",
        alignItems: "right",
        marginRight: `20px`,
        marginLeft: `20px`,
        borderBottomRightRadius: `10px`,
        borderBottomLeftRadius: `10px`,
        backgroundColor: `#E3E3E4`,
    },
    indicator: {
        // backgroundColor: "#1890ff",
        // maxWidth: 50,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        fontWeight: "bold",
        backgroundColor: "transparent",
        "& > span": {
            maxWidth: "99%",
            width: "100%",
            backgroundColor: "#4E253A",
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: "none",
        paddingTop: `10px`,
        color: "#7C596A",
        // borderTopLeftRadius : `20px`,
        // borderTopRightRadius : `20px`,
        background: `#e9ecef`,
        minWidth: 72,
        borderRight: "1px solid #B0B0B0",
        fontSize: `15px`,
        fontWeight: "bold",
        fontFamily: ["GTWalsheimProRegular"].join(","),
        "&:last-child": {
            borderRight: `none`,
        },
        "&:hover": {
            //color: '#40a9ff',
            //opacity: 1,
        },
        //selected tab text styles
        "&$selected": {
            color: "#4E253A",
            fontWeight: theme.typography.fontWeightMedium,
        },
        "&:focus": {
            color: "#4E253A",
        },
        //selected tab styles
        "&$selected": {
            color: "#4E253A",
            backgroundColor: "#fff",
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    demo2: {
        backgroundColor: "#2e1534",
        alignItems: "center",
    },
}));

export default function PatientProfileTabs() {
    const classes = useStyles();

    const [selectedTab, setSelectedTab] = useState(0);
    const dispatch = useDispatch();
    const aidiTabId = useSelector(getSelectedAIDITabId);

    const navigate = useNavigate();

    // useEffect(() => {
    //     setSelectedTab(0);
    // }, []);

    useEffect(() => {
        if (selectedTab === 0) {
            navigate('profileGeneral');
        } else if (selectedTab === 1) {
            navigate('profilePayer');
        }
    }, [selectedTab]);

    const projectAndLocation = useSelector(getSelectedProjectIdAndLocationId);

    const handleChange = (event, newValue) => {
        //dispatch(setSelectedAIDITab(newValue));
        dispatch(setGeneralFormEdit(false));
        dispatch(setPayerFormEdit1(false));
        dispatch(setPayerFormEdit2(false));
        dispatch(setPayerFormEdit3(false));
        dispatch(setFamilyFormEdit1(false));
        dispatch(setFamilyFormEdit2(false));
        dispatch(setFamilyFormEdit3(false));
        dispatch(setCareteamFormEdit1(false));
        dispatch(setCareteamFormEdit2(false));
        dispatch(setCareteamFormEdit3(false));
        dispatch(setFacilityFormEdit(false));
        if (newValue === 1) {
            let payload = {
                projectId: projectAndLocation.selectedProjectId,
                locationId: projectAndLocation.selectedLocationId,
            };
            dispatch(getFacilityBPH(payload));
        };
        setSelectedTab(newValue);
    };

    return (
        <div className={classes.root}>
            <>
                <div className={classes.demo1}>
                    <AntTabs
                        value={selectedTab}
                        onChange={handleChange}
                        aria-label="ant example"
                        variant="fullWidth"
                    >
                        <AntTab label="General" component={NavLink} to="profileGeneral" />
                        <AntTab label="Payer & Programs" component={NavLink} to="profilePayer" />
                        <AntTab label="Family Contact" component={NavLink} to="profileFamily" />
                        <AntTab label="Care Team" component={NavLink} to="profileCareteam" />
                        <AntTab label="Facility" component={NavLink} to="profileFacility" />
                        {/* <AntTab label="Info" component={NavLink} to="patientAidiInfo" /> */}
                        {/* <AntTab label="Threshold" component={NavLink} to="baseline" /> */}
                    </AntTabs>
                    <Typography className={classes.padding} />

                    <div style={{ alignItems: "center", textAlign: "-webkit-center" }}>
                        {/* {selectedTab === 0 && <BpCalibration />}
            {selectedTab === 1 && <BaselineWrapper />} */}

                        <Outlet />
                    </div>
                </div>
            </>
        </div>
    );
}
