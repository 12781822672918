import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./QuickViewVitalsPH.module.css";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Input from '@material-ui/core/Input';

import { ReactComponent as TempIcon } from "./../../../../../Assets/icons/01-Temp.svg";
import { ReactComponent as BPIcon } from "./../../../../../Assets/icons/02-BP.svg";
import { ReactComponent as RRIcon } from "./../../../../../Assets/icons/03-RR.svg";
import { ReactComponent as PRIcon } from "./../../../../../Assets/icons/04-PR.svg";
import { ReactComponent as HRIcon } from "./../../../../../Assets/icons/05-HR.svg";
import { ReactComponent as SPOIcon } from "./../../../../../Assets/icons/06-SpO2.svg";
import { ReactComponent as HRVIcon } from "./../../../../../Assets/icons/07-HRV.svg";
import { ReactComponent as PEFIcon } from "./../../../../../Assets/icons/08-PEF.svg";
import { ReactComponent as FEVIcon } from "./../../../../../Assets/icons/09-FEV1.svg";
import { ReactComponent as TempIconRisk } from "./../../../../../Assets/icons/01-Temp-white.svg";
import { ReactComponent as BPIconRisk } from "./../../../../../Assets/icons/02-BP-white.svg";
import { ReactComponent as RRIconRisk } from "./../../../../../Assets/icons/03-RR-white.svg";
import { ReactComponent as PRIconRisk } from "./../../../../../Assets/icons/04-PR-white.svg";
import { ReactComponent as HRIconRisk } from "./../../../../../Assets/icons/05-HR-white.svg";
import { ReactComponent as SPOIconRisk } from "./../../../../../Assets/icons/06-SpO2-white.svg";
import { ReactComponent as HRVIconRisk } from "./../../../../../Assets/icons/07-HRV-white.svg";
import { ReactComponent as PEFIconRisk } from "./../../../../../Assets/icons/08-PEF-white.svg";
import { ReactComponent as FEVIconRisk } from "./../../../../../Assets/icons/09-FEV1-white.svg";
import { ReactComponent as GlucoseIcon } from "./../../../../../Assets/icons/Glcouse-Icon.svg";
import { ReactComponent as WeightIcon } from "./../../../../../Assets/icons/Weight-Icon.svg";
import { useSelector } from "react-redux";
import { getPatientVitalsListNotesSelector } from "../../../../../Reducer/selectors/PatientSelector";


const useStyles = makeStyles({
    highRiskCard: {
        height: 80,
        background: "#FFFF",
        borderColor: "#283593",
        color: "#283593",
    },

    cardContentStyleHigh: {
        color: "#283593",
        textAlign: "right",
    },

    textFont: {
        fontSize: 15,
        textTransform: "uppercase",
    },
});

export default function QuickViewVitalsPH(props) {
    const classes = useStyles();

    dayjs.extend(utc);
    dayjs.extend(timezone);

    const timeZoneFromDayjs = dayjs.tz.guess();

    const [painLevel, setPainLevel] = useState(props.painLevel);
    const latestVitalsNotes = useSelector(getPatientVitalsListNotesSelector);
    const [newNotes, setNewNotes] = useState("");

    return (
        <div className={styles.backColor}>
            <Grid
                container
                spacing={2}
                // justify="center"
                style={{ padding: 10 }}
            >
                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                    {props.tempRisk ?
                        <div className={styles.cardHighRisk}>
                            <div className={styles.iconRiskDivStyles}>
                                <TempIconRisk className={styles.highRiskIcon} size={35} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountHighRisk}>{props.temp}</div>
                                <div className={styles.patientCatagoryHighRisk}>Temp (F)</div>
                            </div>
                        </div>
                        : <div className={styles.cardNoRisk}>
                            <div className={styles.iconDivStyles}>
                                <TempIcon className={styles.noRiskIcon} size={35} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountNormal}>{props.temp}</div>
                                <div className={styles.patientCatagoryNoRisk}>Temp (F)</div>
                            </div>
                        </div>}
                </Grid>

                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                    {props.bpRisk ?
                        <div className={styles.cardHighRisk}>
                            <div className={styles.iconRiskDivStyles}>
                                <BPIconRisk className={styles.highRiskIcon} size={35} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountHighRisk}>
                                    {props.sbp}
                                </div>
                                <div className={styles.patientCatagoryHighRisk}>SBP (mmHg)</div>
                            </div>
                        </div>
                        : <div className={styles.cardNoRisk}>
                            <div className={styles.iconDivStyles}>
                                <BPIcon className={styles.noRiskIcon} size={35} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountNormal}>
                                    {props.sbp}
                                </div>
                                <div className={styles.patientCatagoryNoRisk}>SBP (mmHg)</div>
                            </div>
                        </div>}
                </Grid>


                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                    {props.bpRisk ?
                        <div className={styles.cardHighRisk}>
                            <div className={styles.iconRiskDivStyles}>
                                <BPIconRisk className={styles.highRiskIcon} size={35} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountHighRisk}>
                                    {props.dbp}
                                </div>
                                <div className={styles.patientCatagoryHighRisk}>DBP (mmHg)</div>
                            </div>
                        </div>
                        : <div className={styles.cardNoRisk}>
                            <div className={styles.iconDivStyles}>
                                <BPIcon className={styles.noRiskIcon} size={35} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountNormal}>
                                    {props.dbp}
                                </div>
                                <div className={styles.patientCatagoryNoRisk}>DBP (mmHg)</div>
                            </div>
                        </div>}
                </Grid>

                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                    {props.breathingRateRisk ?
                        <div className={styles.cardHighRisk}>
                            <div className={styles.iconRiskDivStyles}>
                                <RRIconRisk className={styles.highRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountHighRisk}>
                                    {props.breathingRate}
                                </div>
                                <div className={styles.patientCatagoryHighRisk}>
                                    RR (br/min)
                                </div>
                            </div>
                        </div>
                        : <div className={styles.cardNoRisk}>
                            <div className={styles.iconDivStyles}>
                                <RRIcon className={styles.noRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountNormal}>
                                    {props.breathingRate}
                                </div>
                                <div className={styles.patientCatagoryNoRisk}>
                                    RR (br/min)
                                </div>
                            </div>
                        </div>}
                </Grid>

                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                    {props.pulseRateRisk ?
                        <div className={styles.cardHighRisk}>
                            <div className={styles.iconRiskDivStyles}>
                                <PRIconRisk className={styles.highRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountHighRisk}>
                                    {props.pulseRate}
                                </div>
                                <div className={styles.patientCatagoryHighRisk}>PR (bpm)</div>
                            </div>
                        </div>
                        : <div className={styles.cardNoRisk}>
                            <div className={styles.iconDivStyles}>
                                <PRIcon className={styles.noRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountNormal}>
                                    {props.pulseRate}
                                </div>
                                <div className={styles.patientCatagoryNoRisk}>PR (bpm)</div>
                            </div>
                        </div>}
                </Grid>

                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                    {props.heartRateRisk ?
                        <div className={styles.cardHighRisk}>
                            <div className={styles.iconRiskDivStyles}>
                                <HRIconRisk className={styles.highRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountHighRisk}>
                                    {props.heartRate}
                                </div>
                                <div className={styles.patientCatagoryHighRisk}>HR (bpm)</div>
                            </div>
                        </div>
                        : <div className={styles.cardNoRisk}>
                            <div className={styles.iconDivStyles}>
                                <HRIcon className={styles.noRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountNormal}>
                                    {props.heartRate}
                                </div>
                                <div className={styles.patientCatagoryNoRisk}>HR (bpm)</div>
                            </div>
                        </div>}
                </Grid>

                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                    {props.spo2Risk ?
                        <div className={styles.cardHighRisk}>
                            <div className={styles.iconRiskDivStyles}>
                                <SPOIconRisk className={styles.highRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountHighRisk}>{props.spo2}</div>
                                <div className={styles.patientCatagoryHighRisk}>SpO2 (%)</div>
                            </div>
                        </div>
                        : <div className={styles.cardNoRisk}>
                            <div className={styles.iconDivStyles}>
                                <SPOIcon className={styles.noRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountNormal}>{props.spo2}</div>
                                <div className={styles.patientCatagoryNoRisk}>SpO2 (%)</div>
                            </div>
                        </div>}
                </Grid>

                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                    {props.hrvRisk ?
                        <div className={styles.cardHighRisk}>
                            <div className={styles.iconRiskDivStyles}>
                                <HRVIconRisk className={styles.highRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountHighRisk}>{props.hrv}</div>
                                <div className={styles.patientCatagoryHighRisk}>HRV (ms)</div>
                            </div>
                        </div>
                        : <div className={styles.cardNoRisk}>
                            <div className={styles.iconDivStyles}>
                                <HRVIcon className={styles.noRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountNormal}>{props.hrv}</div>
                                <div className={styles.patientCatagoryNoRisk}>HRV (ms)</div>
                            </div>
                        </div>}
                </Grid>

                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                    {props.pefRisk ?
                        <div className={styles.cardHighRisk}>
                            <div className={styles.iconRiskDivStyles}>
                                <PEFIconRisk className={styles.highRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountHighRisk}>{props.pef}</div>
                                <div className={styles.patientCatagoryHighRisk}>
                                    PEF (L/sec)
                                </div>
                            </div>
                        </div>
                        : <div className={styles.cardNoRisk}>
                            <div className={styles.iconDivStyles}>
                                <PEFIcon className={styles.noRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountNormal}>{props.pef}</div>
                                <div className={styles.patientCatagoryNoRisk}>
                                    PEF (L/sec)
                                </div>
                            </div>
                        </div>}
                </Grid>

                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                    {props.fev1Risk ?
                        <div className={styles.cardHighRisk}>
                            <div className={styles.iconRiskDivStyles}>
                                <FEVIconRisk className={styles.highRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountHighRisk}>{props.fev1}</div>
                                <div className={styles.patientCatagoryHighRisk}>FEV1 (L)</div>
                            </div>
                        </div>
                        : <div className={styles.cardNoRisk}>
                            <div className={styles.iconDivStyles}>
                                <FEVIcon className={styles.noRiskIcon} size={40} />
                            </div>
                            <div className={styles.countDivStyles}>
                                <div className={styles.patientCountNormal}>{props.fev1}</div>
                                <div className={styles.patientCatagoryNoRisk}>FEV1 (L)</div>
                            </div>
                        </div>}
                </Grid>

                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                    <div className={styles.cardNoRisk}>
                        <div className={styles.iconDivStyles}>
                            <WeightIcon className={styles.noRiskIcon} size={40} />
                        </div>
                        <div className={styles.countDivStyles}>
                            <div className={styles.patientCountNormal}>{props.weight}</div>
                            <div className={styles.patientCatagoryNoRisk}>Weight (lbs)</div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                    <div className={styles.cardNoRisk}>
                        <div className={styles.iconDivStyles}>
                            <GlucoseIcon className={styles.noRiskIcon} size={40} />
                        </div>
                        <div className={styles.countDivStyles}>
                            <div className={styles.patientCountNormal}>{props.glucose}</div>
                            <div className={styles.patientCatagoryNoRisk}>Glu. (mg/dL)</div>
                        </div>
                    </div>
                </Grid>


                <div className={styles.painDiv}>
                    <div className={styles.painDivDesc}>
                        Pain level on a scale of 0 - 10
                    </div>
                    <div className={styles.painLevelRating}>
                        <div className={painLevel >= 0 ? styles.selectedRatingdiv : styles.ratingdiv}>0</div>
                        <div className={painLevel && painLevel >= 1 ? styles.selectedRatingdiv : styles.ratingdiv}>1</div>
                        <div className={painLevel && painLevel >= 2 ? styles.selectedRatingdiv : styles.ratingdiv}>2</div>
                        <div className={painLevel && painLevel >= 3 ? styles.selectedRatingdiv : styles.ratingdiv}>3</div>
                        <div className={painLevel && painLevel >= 4 ? styles.selectedRatingdiv : styles.ratingdiv}>4</div>
                        <div className={painLevel && painLevel >= 5 ? styles.selectedRatingdiv : styles.ratingdiv}>5</div>
                        <div className={painLevel && painLevel >= 6 ? styles.selectedRatingdiv : styles.ratingdiv}>6</div>
                        <div className={painLevel && painLevel >= 7 ? styles.selectedRatingdiv : styles.ratingdiv}>7</div>
                        <div className={painLevel && painLevel >= 8 ? styles.selectedRatingdiv : styles.ratingdiv}>8</div>
                        <div className={painLevel && painLevel >= 9 ? styles.selectedRatingdiv : styles.ratingdiv}>9</div>
                        <div className={painLevel && painLevel >= 10 ? styles.selectedRatingdiv : styles.ratingdiv}>10</div>
                    </div>
                </div>

                <div className={styles.addNewNoteDiv}>
                    <Input
                        className={styles.notesEditor}
                        type="text"
                        //placeholder="Notes yet to be integrated!"
                        value={latestVitalsNotes}
                        multiline={true}
                        disabled={true}
                        //onChange={(event) => setNewNotes(event.target.value)}
                        disableUnderline="true"
                    />
                </div>



                <div className={styles.timeStampStyles}>
                    <div>{dayjs.utc(props.timestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}</div>
                    <div className={styles.timeStampText} /* style={{ color: "#4E253A", fontSize: "12px" }} */>Most Recent Measurement</div>
                </div>
                {/*
           <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
            <div className={styles.cardNoRisk}>
              <div className={styles.iconDivStyles}>
                <RiTempHotLine className={styles.highRiskIcon} size={50} />
              </div>
              <div className={styles.countDivStyles}>
                <div className={styles.patientCountHighRisk}>{props.fvc}</div>
                <div className={styles.patientCatagoryHighRisk}>FVC (L)</div>
              </div>
            </div>
          </Grid> 
*/}
                {/* 
        <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
          <div className={styles.cardNoRisk}>
            <div className={styles.iconDivStyles}>
              <RiLungsLine className={styles.highRiskIcon} size={50} />
            </div>
            <div className={styles.countDivStyles}>
              <div className={styles.patientCountHighRisk}>
                {props.patientVitals.fev1_fvc}
              </div>
              <div className={styles.patientCatagoryHighRisk}>FEV1/FVC (%)</div>
            </div>
          </div>
        </Grid>
*/}
            </Grid >
        </div >
    );
}