import React, { useState, useContext, useEffect, useRef } from "react";
import { Tabs, Tab, AppBar } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./QuickViewMeasurement.module.css";
import subDays from "date-fns/subDays";
import { addDays, format } from "date-fns";
import { useNavigate } from "react-router-dom";
import QuickViewWrapper from "../QuickViewWrapper/QuickViewWrapper";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from "@mui/material/IconButton";
import { PatientContext } from "../../../../Store/PatientDataProvider";
import { useDispatch, useSelector } from "react-redux";
import { getPatientVitalsList, getSubjectVitalsList } from "../../../../../Actions";
import { getPatientsListLoader, getPatientVitalsListSelector, getSubjectVitalsListSelector } from "../../../../../Reducer/selectors/PatientSelector";
import { Button, CircularProgress } from "@mui/material";
import ProfileIcon from "../../../../../Assets/Images/ProfileIcon.png"
import { DateContext } from "../../../../Store/DateProvider";
import QuickViewVitals from "../QuickViewVitals/QuickViewVitals";
import { Refresh } from "@mui/icons-material";
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import { AccountContext } from "../../../../Store/AuthenticationStore/Account";
import Grid from "@material-ui/core/Grid";
import { ExitToApp } from "@mui/icons-material";

const useStyles = makeStyles({
    textFont: {
        fontSize: 20,
        textTransform: `capitalize;`,
        fontFamily: `GTWalsheimProRegular`
    },
    typoStyle: {
        fontSize: 20,
        textTransform: `capitalize;`,
        fontFamily: `GTWalsheimProRegular`,
        width: 100,
    },
    measurementDiv: {
        fontSize: 15,
        textTransform: `capitalize;`,
        fontFamily: `GTWalsheimProRegular`,
        marginLeft: `auto`,
        marginRight: `10px`,
    },
});

export default function QuickViewMeasurement(props) {

    const fromDate = format(subDays(new Date(), 30), "yyyy-MM-dd");
    const toDate = format(new Date(), "yyyy-MM-dd");

    const qvVital = [
        {
            temperature: {
                value: "--",
                baseline: 0,
                risk: "0",
            },
            spo2: {
                value: "--",
                baseline: 0,
                risk: "0",
            },
            breathingRate: {
                value: "--",
                baseline: 0,
                risk: "0",
            },
            pulseRate: {
                value: "--",
                baseline: 0,
                risk: "0",
            },
            heartRate: {
                value: "--",
                baseline: 0,
                risk: "0",
            },
            hrv: {
                value: "--",
                baseline: 0,
                risk: "0",
            },
            sbp: {
                value: "--",
                baseline: 0,
                risk: "0",
            },
            dbp: {
                value: "--",
                baseline: 0,
                risk: "0",
            },
            fvc: {
                value: "--",
                baseline: 0,
                risk: "0",
            },
            fev1: {
                value: "--",
                baseline: 0,
                risk: "0",
            },
            pef: {
                value: "--",
                baseline: 0,
                risk: "0",
            },
            timestamp: "YYYY-MM-DD HH:MM:SS",
            recordId: false,
        },
    ];

    const [selectedTab, setSelectedTab] = React.useState(0);
    const [selectedVitals, setSelectedVitals] = useState(0);
    const classes = useStyles();

    let patientInfo = useContext(PatientContext);
    const dispatch = useDispatch();
    const patientVitals = useSelector(getSubjectVitalsListSelector);
    const loader = useSelector(getPatientsListLoader);


    const end_date = format(addDays(new Date(), 1), "yyyy-MM-dd");
    const start_date = format(addDays(new Date(), -30), "yyyy-MM-dd");

    // Logout Pop UP States
    const [isLogout, setIsLogout] = useState(false);
    const openLogoutMenu = () => {
        setIsLogout(true);
    };
    const closeLogoutMenu = () => {
        setIsLogout(false);
    };

    let { logout } = useContext(AccountContext);

    useEffect(() => {
        dispatch(getSubjectVitalsList({ subjectId: props.subjectGUID, startDate: start_date, endDate: end_date }));
        // dispatch(getPatientVitalsList(props.subjectGUID));
    }, []);

    const handlePrevMeasurement = () => {
        if (selectedVitals > 0) {
            setSelectedVitals(selectedVitals - 1)
        }
    }

    const handleNextMeasurement = () => {

        if (selectedVitals < patientVitals.length - 1) {
            setSelectedVitals(selectedVitals + 1);
        }
    }
    const refreshPatientVitals = () => {
        dispatch(getSubjectVitalsList({ subjectId: props.subjectGUID, startDate: start_date, endDate: end_date }));
        setSelectedVitals(0);
    }
    return (
        <>
            <div className={styles.qvHeader}>
                <div className={styles.logoutButtonDiv}>
                    <IconButton onClick={openLogoutMenu}>
                        <ExitToApp sx={{ color: "#4E253A" }}></ExitToApp>
                    </IconButton>
                </div>
                <Avatar className={styles.avatar} alt="avatar_patient" src={ProfileIcon}></Avatar>
                <div className={styles.avatarTitleDiv}>
                    {/* {selectedVitals > 0 && <IconButton
                        size="medium"
                        className={styles.iconButtonLeft}
                        onClick={() => handlePrevMeasurement()}
                        disabled={selectedVitals === 0}>
                        <KeyboardArrowLeftIcon style={selectedVitals === 0 ? { color: `#E4DFE9` } : { color: `#FFFFFF` }}></KeyboardArrowLeftIcon>
                    </IconButton>} */}

                    <div className={styles.avatarTitle}><span className={classes.typoStyle}>ADR - {props.subjectId}</span></div>

                    {/* {selectedVitals < patientVitals.length - 1 && <IconButton
                        size="medium"
                        className={styles.iconButtonRight}
                        onClick={() => handleNextMeasurement()}
                        disabled={selectedVitals === patientVitals.length - 1}>
                        <KeyboardArrowRightIcon style={selectedVitals === patientVitals.length - 1 ? { color: `#E4DFE9` } : { color: `#FFFFFF` }}></KeyboardArrowRightIcon>
                    </IconButton>} */}

                </div>
            </div>

            {loader ? (<div className={styles.circularProgressDiv} style={{ backgroundColor: `#F3E8EE`, height: `100vh` }}><CircularProgress thickness={5.0} style={{ color: `#4E253A` }}></CircularProgress></div>)
                :
                <>

                    {patientVitals && patientVitals.length > 0 && <QuickViewVitals
                        key={patientVitals[selectedVitals].recordId}
                        mlId={patientVitals[selectedVitals].deviceId}
                        timestamp={patientVitals[selectedVitals].timestamp}
                        temp={patientVitals[selectedVitals].temperature.value}
                        spo2={patientVitals[selectedVitals].spo2.value}
                        breathingRate={patientVitals[selectedVitals].breathingRate.value}
                        pulseRate={patientVitals[selectedVitals].pulseRate.value}
                        heartRate={patientVitals[selectedVitals].heartRate.value}
                        hrv={patientVitals[selectedVitals].hrv.value}
                        sbp={patientVitals[selectedVitals].sbp.value}
                        dbp={patientVitals[selectedVitals].dbp.value}
                        fvc={patientVitals[selectedVitals].fvc.value}
                        fev1={patientVitals[selectedVitals].fev1.value}
                        pef={patientVitals[selectedVitals].pef.value}
                        tempRisk={patientVitals[selectedVitals].temperature.risk}
                        spo2Risk={patientVitals[selectedVitals].spo2.risk}
                        breathingRateRisk={patientVitals[selectedVitals].breathingRate.risk}
                        pulseRateRisk={patientVitals[selectedVitals].pulseRate.risk}
                        heartRateRisk={patientVitals[selectedVitals].heartRate.risk}
                        hrvRisk={patientVitals[selectedVitals].hrv.risk}
                        sbpRisk={patientVitals[selectedVitals].sbp.risk}
                        dbpRisk={patientVitals[selectedVitals].dbp.risk}
                        fvcRisk={patientVitals[selectedVitals].fvc.risk}
                        fev1Risk={patientVitals[selectedVitals].fev1.risk}
                        pefRisk={patientVitals[selectedVitals].pef.risk}
                        recordId={patientVitals[selectedVitals].recordId}
                    />}
                </>}

            <div className={styles.qvFooter}>
                <div style={{ minWidth: "100px" }}>{selectedVitals > 0 && <div className={styles.prevButton} onClick={() => handlePrevMeasurement()} >Previous</div>}</div>
                <Button onClick={() => refreshPatientVitals()}><Refresh sx={{ color: "#4E253A" }} /></Button>
                <div style={{ minWidth: "72px" }}>{selectedVitals < patientVitals.length - 1 && <div className={styles.nextButton} onClick={() => handleNextMeasurement()} >Next</div>}</div>
                {/* <Avatar className={styles.avatar} alt="avatar_patient" src={ProfileIcon}></Avatar> */}

            </div>

            {/* Logout menu */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={isLogout}
                onClose={closeLogoutMenu}
            >
                <div className={styles.paper}>
                    <div className={styles.menuTitle}>
                        <div className={styles.titleText}>Log Out?</div>
                        <div>
                            <IconButton aria-label="delete">
                                <RiCloseCircleFill size={29} onClick={closeLogoutMenu} style={{ color: "#4E253A" }} />
                            </IconButton>
                        </div>
                    </div>

                    <div className={styles.patientListDiv}>
                        <Grid container>
                            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                                <div className={styles.userQuestionMessage}>Are you sure you would like to log out?</div>
                                <div className={styles.userInfoMessage}>This action will end your session</div>

                                <div /* style={{ textAlign: "center" }} */>
                                    <button type="button" onClick={closeLogoutMenu} className={styles.cancelButton}>
                                        Cancel
                                    </button>
                                    <button type="submit" onClick={logout} className={styles.submitButton}>
                                        Confirm
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Modal>

        </>
    );
}
