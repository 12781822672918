import React from "react";
import styles from "./LfGraph.module.css";
import { Line } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default function LfGraph(props) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const timeZoneFromDayjs = dayjs.tz.guess();

//  console.log("LfGraph: props = ", props);
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    ctx.canvas.width = 1200;
    ctx.canvas.height = 300;
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(4, 222, 113,1)");
    gradient.addColorStop(0.5, "rgba(4, 222, 113,0.2)");
    gradient.addColorStop(1, "rgba(4, 222, 113,0)");

    return {
      labels: props.xValues,
      datasets: [
        {
          label: "Exhalation Curve ",
          fill: "start",
          backgroundColor: gradient, // Put the gradient here as a fill color
          borderColor: "#01DE71",
          borderWidth: 2,
          pointRadius: 0,
          pointBackgroundColor: "rgba(255, 255, 255)",
          data: props.yValues,
        },
      ],
    };
  };

  var options = {
    responsive: false,
    datasetStrokeWidth: 3,
    pointDotStrokeWidth: 4,
    //scaleLabel: "<%= Number(value).toFixed(0).replace('.', ',') + '°C'%>",

    tooltips: {
      backgroundColor: "rgba(255, 255, 255)",
      bodyFontColor: "rgba(53, 80, 112)",
      titleFontColor: "rgba(53, 80, 112)",
      titleFontSize: 12,
      bodyFontSize: 12,
      bodySpacing: 5,
      bodyAlign: "center",
      titleAlign: "center",

      callbacks: {
        label: function (tooltipItem) {
          return Number(tooltipItem.yLabel);
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            //display: false,
          },
          ticks: {
            display: false,
            maxTicksLimit: 24,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            //display:false
          },

          ticks: {
            //display: false,
            maxTicksLimit: 11,
          },
        },
      ],
    },

    legend: {
      display: true,
      position: "top",
      labels: {
        fontColor: "#333",
        usePointStyle: true,
      },
    },
  };
  return (
    <div className={styles.graphContent}>
      <Grid container>
        <Grid xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.lfGraphCard}>
            <div className={styles.headerDiv}>
              <div className={styles.heartRateInformation} >
                PEF {props.pef} L/sec
              </div>
              <div className={styles.timestampLungFunction}>{dayjs.utc(props.timestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}</div>
            </div>

            <div className={styles.lfGraphContent}>
              <Line data={data} options={options} className={styles.graphStyles}></Line>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
