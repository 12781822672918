import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

const PatientAllergiesTab = forwardRef((props, ref) => {

    return (
        <>
            PatientAllergiesTab
        </>
    );
});

export default PatientAllergiesTab;