import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./RTWrapper.module.css";
import Grid from "@material-ui/core/Grid";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { useParams } from "react-router-dom";
import { format, addDays } from "date-fns";
import { DateContext } from "../../../../../../Store/DateProvider";
import Spo2 from "../RTrends/Spo2";
import Fev from "../RTrends/Fev";
import Pef from "../RTrends/Pef";
import RR from "../RTrends/RR";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getSubjectVitalsList } from "../../../../../../../Actions";
import { getSubjectVitalsListSelector } from "../../../../../../../Reducer/selectors/PatientSelector";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    marginTop: 5,
    font: "12px",
    background: "black",
    fontFamily: "GTWalsheimProRegular",
  },
}));

export default function RTWrapper() {
  // Date context defined to get access to context information
  const dateInfo = useContext(DateContext);
  const classes = useStyles();

  // State to manage the Date pickers inputs
  const [dateRange, setDateRange] = useState([
    {
      startDate: dateInfo.fromDate,
      endDate: dateInfo.toDate,
      key: "selection",
    },
  ]);

  var start_date = format(dateRange[0].startDate, "yyyy-MM-dd");
  var end_date = format(addDays(dateRange[0].endDate, 1), "yyyy-MM-dd");

  const [isCalOpen, setIsCalOpen] = useState(false);
  const refOne = useRef(null);
  const dispatch = useDispatch();
  const subjectVitalsData = useSelector(getSubjectVitalsListSelector);

  // fetching subject id from the url params
  let curPatientId = useParams();

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
    dispatch(getSubjectVitalsList({ subjectId: curPatientId.patientId, startDate: start_date, endDate: end_date }));
  }, []);

  const handleCalChanges = (item) => {
    setDateRange([item.selection]);
  };

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setIsCalOpen(false);
    }
  };

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setIsCalOpen(false);
    }
  };

  const handleCalClick = (event) => {
    event.preventDefault();
    if (isCalOpen) {
      setIsCalOpen(false);
    } else {
      setIsCalOpen(true);
    }
  };

  const fetchPatientRTDetials = async (event) => {
    event.preventDefault();
    dateInfo.setFromDate(dateRange[0].startDate);
    dateInfo.setToDate(dateRange[0].endDate);
    dispatch(getSubjectVitalsList({ subjectId: curPatientId.patientId, startDate: start_date, endDate: end_date }));
  };

  return (
    <div>
      <div className={styles.containerDiv}>
        <div className={styles.datePickerDiv}>
          <Tooltip title="Choose another date range" classes={{ tooltip: classes.tooltip }}>
            <CalendarMonthIcon onClick={(event) => handleCalClick(event)}></CalendarMonthIcon>
          </Tooltip>
          <div className={styles.dateInputDiv}>
            <input
              value={`${format(dateRange[0].startDate, "MM/dd/yyyy")}  |  ${format(dateRange[0].endDate, "MM/dd/yyyy")}`}
              readOnly
              className={styles.dateInput}
              onClick={(event) => handleCalClick(event)}
            />
          </div>
          <div>
            <Tooltip title="View data" classes={{ tooltip: classes.tooltip }}>
              <Button startIcon={<TableChartOutlinedIcon />} onClick={(event) => fetchPatientRTDetials(event)} className={styles.exportButton}>
                View
              </Button>
            </Tooltip>
          </div>
        </div>
        {isCalOpen && (
          <div className={styles.datePickerWrapper} ref={refOne}>
            <DateRangePicker
              onChange={(item) => handleCalChanges(item)}
              editableDateInputs={false}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRange}
              direction="horizontal"
              rangeColors={["#4E253A"]}
              maxDate={addDays(new Date(), 1)}
            />
          </div>
        )}
      </div>
      <Grid container style={{ maxHeight: "65vh", overflowY: "scroll", padding: "5px" }}>
        <Grid item xs={6} xl={6} lg={6} md={6} sm={12}>
          <Spo2 patientVitals={subjectVitalsData} />
        </Grid>
        <Grid item xs={6} xl={6} lg={6} md={6} sm={12}>
          <RR patientVitals={subjectVitalsData} />
        </Grid>
        <Grid item xs={6} xl={6} lg={6} md={6} sm={12}>
          <Pef patientVitals={subjectVitalsData} />
        </Grid>
        <Grid item xs={6} xl={6} lg={6} md={6} sm={12}>
          <Fev patientVitals={subjectVitalsData} />
        </Grid>
        {/* <Grid item xs={6} xl={6} lg={6} md={6} sm={12}>
          <Fvc patientVitals={subjectVitalsData} />
        </Grid> */}
      </Grid>
      {/* <TableHeaderVitals patientVitals={subjectVitalsData} /> */}
    </div>
  );
}
