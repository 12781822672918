import React, { useState, useRef, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import StepContent from "@material-ui/core/StepContent";
import Paper from "@material-ui/core/Paper";
import styles from "./BpCalibration.module.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation, useParams } from "react-router-dom";

// Component Imports
import CalibrationForm from "./CalibrationForm/CalibrationForm";

// Calibration Api
import IsCalibrationSuccess from "../../../../API/BpCalibration/IsCalibrationSuccess";

// contexts import
import { PatientContext } from "../../../../Store/PatientDataProvider";
import { useSelector } from "react-redux";
import { getAddSubjectSelector } from "../../../../../Reducer/selectors/PatientSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: `transparent`,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    // marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    <div className={styles.stepTitle}>
      Measure Blood Pressure (BP)<span style={{ color: 'red' }}>*</span> using a BP cuff.
    </div>,
    <div className={styles.stepTitle}>
      Enter reference BP values and click calibrate.
    </div>,
    <div className={styles.stepTitle}>Take a measurement with MouthLab.</div>,
    <div className={styles.stepTitle}>Verifying BP calibration.</div>,
  ];
}

export default function BpCalibration() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <StepOneMsg onNextStep={handleNext} />;
      case 1:
        return <CalibrationForm onNextStep={handleNext} />;
      case 2:
        return <StepThreeMsg onNextStep={handleNext} />;
      case 3:
        return <LastBPStep onReset={handleReset} />;
      default:
        return "Unknown step";
    }
  }

  return (
    <Grid container>
      <div className={styles.calibrationCard}>
        <Grid item xs={12} xl={4} lg={4} md={4} sm={12}>
          <div className={styles.formCard}>
            <div className={styles.stepperStyles}>
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                //style={{ backgroundColor: `#3e1e92`, color: `#fff` }}
                className={styles.stepperStyles}
              >
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent
                    //style={{ backgroundColor: `#3e1e92`, color: `#fffff` }}
                    >
                      <Typography>{getStepContent(index)}</Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper
                  square
                  elevation={0}
                  // className={classes.resetContainer}
                  style={{ backgroundColor: `#3e1e92`, color: `#fff` }}
                >
                  <div className={styles.successMessage}>
                    All steps completed - Calibration Successful
                  </div>

                  <button
                    onClick={handleReset}
                    className={styles.stepperButton}
                  >
                    Recalibrate
                  </button>
                </Paper>
              )}
            </div>
          </div>
        </Grid>

        <Grid item xs={12} xl={8} lg={8} md={8} sm={12}>
          {/* <div className={classes.root}> */}
          <div className={styles.card}>
            <div className={styles.cardContentWrapper}>
              <div className={styles.cardTitleDiv}>
                <iframe
                  width="560"
                  height="315"
                  //src="https://www.youtube.com/embed/HEt2TGYli1g?playlist=HEt2TGYli1g&loop=1&rel=0"
                  src="https://www.youtube.com/embed/pzGuRkBhk58?si=XPmQvoWMPNXo2r80"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ borderRadius: `20px` }}
                ></iframe>
              </div>
              {/* <div className={styles.cardDescription}>
                <div className={styles.titleTrainginCard}>
                  BP Calibration Demo
                </div>
              </div> */}
            </div>
          </div>
          {/* </div> */}
          <div className={styles.bpTitle}>
            <span style={{ color: 'red' }}>*</span> BP is for investigational use only.
          </div>
        </Grid>
      </div>
    </Grid>
  );
}

// STEP ONE MSG FUNCTION
function StepOneMsg(props) {
  let location = useLocation();
  // console.log("This is the location fetched from params", location);
  return (
    <Fragment>
      <div className={styles.msgStyle}>
        Measure the resting blood pressure using a cuff-based BP monitor.
        <br /> Once done, click the 'Next' button.
      </div>
      <div
      // className={classes.actionsContainer}
      >
        <div style={{ textAlign: `left` }}>
          <button
            variant="contained"
            onClick={props.onNextStep}
            className={styles.stepperButton}
          >
            {/* {activeStep === steps.length - 1 ? "Finish" : "Next"} */}
            Next
          </button>
        </div>
      </div>
    </Fragment>
  );
}

// STEP ONE MSG FUNCTION
function StepThreeMsg(props) {
  return (
    <Fragment>
      <div className={styles.msgStyle}>
        Proceed to take a measurement with the MouthLab device. For calibration,
        remove the device from the mouth after the first vibration. Once the
        data transmission is complete (when blue LED turns off), click 'Next'
      </div>
      <div
      // className={classes.actionsContainer}
      >
        <div>
          <button
            variant="contained"
            onClick={props.onNextStep}
            className={styles.stepperButton}
          >
            {/* {activeStep === steps.length - 1 ? "Finish" : "Next"} */}
            Next
          </button>
        </div>
      </div>
    </Fragment>
  );
}

// Function of the last step
// This function will call the isCalibrationSuccessful API
// The API will return the calibration success values - boolean (true/false)
// If the returned values is true then shoe success message
// If the returned value is false display message calibration is not successful and
// Display the recalibrate button
// recalibration button resets the form values and directs user to initial steps

// STEP ONE MSG FUNCTION
function LastBPStep(props) {
  let location = useLocation();
  // console.log("This is the location fetched from params", location);
  // const [bpCaptured, setBpCaptured] = useState([]);
  const [displayControl, setDisplayControl] = useState(0);
  // const [progress, setProgress] = React.useState(0);

  const [isCalSuccess, setIsCalSuccess] = useState([]);

  // Fetching patient info from context - NOT in USE ATM
  let patGUIDFromParams = useParams();
  // console.log("LastBPStep GUID from params: ", patGUIDFromParams);

  // Fetching current subjectId from Local storage
  const addSubjectResponse = useSelector(getAddSubjectSelector);
  const subjectId = addSubjectResponse && addSubjectResponse.id;
  let curSubjectGUID = addSubjectResponse && addSubjectResponse.id ? addSubjectResponse.id : patGUIDFromParams.patientId;

  // console.log("******************************************", curSubjectGUID);

  const fetchCalibrationResults = async () => {
    // TODO : add http request then block to fetch return value from is cal success
    // and set display control state based of the received  value
    IsCalibrationSuccess(curSubjectGUID).then((res) => {
      setIsCalSuccess(res);
      setDisplayControl(1);
      // console.log("I got the response ", res);
    });
  };


  useInterval(() => {
    fetchCalibrationResults();
  }, 60000);

  // console.log("Latest Patient Vitals", isCalSuccess);
  // console.log("Calibration successful", isCalSuccess.isCalibrationSuccessful);
  // console.log("ptt of the record", isCalSuccess.ptt);
  // console.log(
  //   "record calibration timestamp",
  //   isCalSuccess.calibrationTimestamp
  // );
  // console.log("Control State", displayControl);


  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  return (
    <Fragment>
      <div className={styles.msgStyle}>
        Please stay on this page until you receive the final verification
        message.
      </div>
      {displayControl === 0 && <CircularProgress style={{ color: `#4E253A` }} />}

      {displayControl === 1 && (
        // <div>Calibration Result - {isCalSuccess.isCalibrationSuccessful}</div>
        <div>
          {isCalSuccess.isCalibrationSuccessful == 1 ? (
            <div className={styles.msgStyle}>
              Calibration Successful! {isCalSuccess.ptt}
            </div>
          ) : (
            <div>
              <div className={styles.msgStyle}>
                Calibration not Successful {isCalSuccess.ptt}
              </div>
              <div>
                <button
                  variant="contained"
                  onClick={props.onReset}
                  className={styles.stepperButton}
                >
                  Recalibrate
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}
