import { createAction } from "@reduxjs/toolkit";
import { nursingDashboardConstants } from "../Constants/NursingDashboardConstants";

export const getPCCPatientsList = createAction(
    nursingDashboardConstants.GET_PCC_PATIENTS_LIST,
    (payload) => ({ payload })
);

export const getPCCPatientsListSuccess = createAction(
    nursingDashboardConstants.GET_PCC_PATIENTS_LIST_SUCCESS,
    (payload) => ({ payload })
);

export const getPCCPatientsListFailure = createAction(nursingDashboardConstants.GET_PCC_PATIENTS_LIST_FAILURE);

export const getPCCUserProfile = createAction(
    nursingDashboardConstants.GET_PCC_USER_PROFILE_REQUEST,
    (payload) => ({ payload })
);

export const getPCCUserProfileSuccess = createAction(
    nursingDashboardConstants.GET_PCC_USER_PROFILE_SUCCESS,
    (payload) => ({ payload })
);

export const getPCCUserProfileFailure = createAction(nursingDashboardConstants.GET_PCC_USER_PROFILE_FAILURE);

export const setPrevMeasurement = createAction(
    nursingDashboardConstants.SET_PREV_MEASUREMENT,
    (payload) => ({ payload })
);

export const setLatestMeasurement = createAction(
    nursingDashboardConstants.SET_LATEST_MEASUREMENT,
    (payload) => ({ payload })
);

export const updateExtraMeasurementParams = createAction(
    nursingDashboardConstants.UPDATE_EXTRA_MEASUREMENT_PARAMS,
    (payload) => ({ payload })
);

export const updateExtraMeasurementParamsSuccess = createAction(
    nursingDashboardConstants.UPDATE_EXTRA_MEASUREMENT_PARAMS_SUCCESS,
    (payload) => ({ payload })
);

export const updateExtraMeasurementParamsFailure = createAction(nursingDashboardConstants.UPDATE_EXTRA_MEASUREMENT_PARAMS_FAILURE);

export const uploadManualEntry = createAction(
    nursingDashboardConstants.UPLOAD_MANUAL_ENTRY,
    (payload) => ({ payload })
);

export const uploadManualEntrySuccess = createAction(
    nursingDashboardConstants.UPLOAD_MANUAL_ENTRY_SUCCESS,
    (payload) => ({ payload })
);

export const uploadManualEntryFailure = createAction(nursingDashboardConstants.UPLOAD_MANUAL_ENTRY_FAILURE);

export const setUploadManualEntryStatus = createAction(
    nursingDashboardConstants.SET_UPLOAD_MANUAL_ENTRY_STATUS,
    (payload) => ({ payload })
);

export const setCalibrationStatus = createAction(
    nursingDashboardConstants.SET_CALIBRATION_STATUS,
    (payload) => ({ payload })
);

export const setPCCPatientListPageNumber = createAction(
    nursingDashboardConstants.SET_PCC_PAGE_NUMBER,
    (payload) => ({ payload })
);

export const setPCCPatientListPageSize = createAction(
    nursingDashboardConstants.SET_PCC_PAGE_SIZE,
    (payload) => ({ payload })
);

export const searchPccPatient = createAction(
    nursingDashboardConstants.SEARCH_PCC_PATIENT,
    (payload) => ({ payload })
);

export const searchPccPatientSuccess = createAction(
    nursingDashboardConstants.SEARCH_PCC_PATIENT_SUCCESS,
    (payload) => ({ payload })
);

export const searchPccPatientFailure = createAction(nursingDashboardConstants.SEARCH_PCC_PATIENT_FAILURE);

export const getSubjectHasMl = createAction(
    nursingDashboardConstants.GET_SUBJECT_HAS_ML,
    (payload) => ({ payload })
);

export const getSubjectHasMlSuccess = createAction(
    nursingDashboardConstants.GET_SUBJECT_HAS_ML_SUCCESS,
    (payload) => ({ payload })
);

export const getSubjectHasMlFailure = createAction(nursingDashboardConstants.GET_SUBJECT_HAS_ML_FAILURE);
