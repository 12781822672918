import React, { useState, useContext } from "react";
import axios from "axios";
import domainCF from "../domainCF.js";

export default function GetSubjectBaseline(subjectId) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    //Authorization: `"Bearer ${token}"`,
    Authorization: "Bearer " + token,
  };
  const GET_SUBJECT_BASELINE =
    domainCF + "/api/GetBaseline/" + subjectId;

  return axios.get(GET_SUBJECT_BASELINE, { headers }).then(function (response) {
    //console.log("THIS IS baseline", response.data);

    return response.data;
  });
}
