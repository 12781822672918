import React, { useContext, useState, useEffect } from "react";

import styles from "./PatientsList.module.css";
import Grid from "@material-ui/core/Grid";
import PatientAccordion from "../PatientAccordion/PatientAccordion";
import NDPatientAccordion from "../PatientAccordion/NDPatientAccordion";
import { UserContext } from "../../../../Store/UserContext";
import { getAccRenderInfo } from "../../../../API/data";
import CircularProgress from '@mui/material/CircularProgress';
import Header from "../../Header/Header";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLocationName, getProjectName, getUserProfile } from "../../../../../Reducer/selectors/DashboardSelector";
import { setMLVitalsWrapperId, setRulesWrapperId, setSelectedPageName } from "../../../../../Actions";
import { globalConstants } from "../../../../../Constants/GlobalConstants";
import NAHeader from "../../Header/NAHeader";
import { getSelectedMlIdSelector } from "../../../../../Reducer/selectors/DashboardSelector";
import HubIcon from '@mui/icons-material/Hub';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { setSelectedMlId } from "../../../../../Actions";
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import IconButton from "@material-ui/core/IconButton";
import UnselectML from "../../DeviceLog/MouthLabLogs/MLActions/UnselectML";
import Backdrop from '@mui/material/Backdrop';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

export default function PatientsList(props) {
  const userContext = useContext(UserContext);
  const profile = useSelector(getUserProfile);

  const [userEmail] = useState(profile.email);
  const [firstName] = useState(profile.firstName);
  const [lastName] = useState(profile.lastName);

  const { projectId, locationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const projectName = useSelector(getProjectName);
  const locationName = useSelector(getLocationName);

  const selectedML = useSelector(getSelectedMlIdSelector);

  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    dispatch(setMLVitalsWrapperId(0));
    dispatch(setRulesWrapperId(0));

    dispatch(setSelectedPageName('patientslist'));
  }, []);

  useEffect(() => {
    if (projectId && locationId) {
      userContext.setCurPlSelections({
        projectId,
        locationId,
      });
    }
  }, [projectId, locationId]);

  const indicatorSize = 80;

  if (!userEmail || !projectId || !locationId) {
    return (

      <CircularProgress thickness={5.0} style={{ color: `#4E253A` }} />
    );
  }
  const userName = firstName + " " + lastName;
  const accs = getAccRenderInfo(); //TODO #YI# Refactor this dummy data

  const renderAccordions = accs.map((acc) => (
    <PatientAccordion
      key={acc.accID}
      accId={acc.accID}
      /* label={acc.accLabel} */
      label={'All Patients'}
      type={acc.accType}
      priorityId={acc.priorityID}
      risk={acc.priority}
      email={userEmail}
      projectId={projectId}
      locationId={locationId}
    />
  ));

  const renderNDAccordions = accs.map((acc) => (
    <NDPatientAccordion
      key={acc.accID}
      accId={acc.accID}
      /* label={acc.accLabel} */
      label={'All Patients'}
      type={acc.accType}
      priorityId={acc.priorityID}
      risk={acc.priority}
      email={userEmail}
      projectId={projectId}
      locationId={locationId}
    />
  ));

  let today = new Date();
  var hour = today.getHours();

  const unpairML = () => {
    setShowDialog(true);
    // PostUnassignMouthLabFromSubject({ deviceId: selectedML }).then((res) => {

    // });
    // navigate(`/deviceAssignments`);
  }

  function closeDialogMenu(event, reason) {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      // Set 'open' to false, however you would do that with your particular code.
      setShowDialog(false);
    }
  };

  if (profile.role === globalConstants.USER_ROLE_NURSE_AID) {
    return (
      <div className={styles.accessDeniedDiv}>
        Sorry, you don't have access to this page!
      </div>
    );
  } else {
    return (
      <div className={styles.accContainer}>
        <Header userEmail={userEmail} />
        <Grid container>
          <div className={styles.allPatientsHeader}>
            <div className={styles.dummyDiv}></div>
            <div className={styles.projectNameDiv}>{projectName}</div>
            <div className={styles.locationDiv}><div className={styles.locIconDiv}><LocationOnOutlinedIcon style={{ color: "#DF5927", height: "16px", width: "16px" }}></LocationOnOutlinedIcon></div>{locationName}</div>
          </div>
          <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
            <div>{renderAccordions}</div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
