import React from "react";
import styles from "./VitalsTable.module.css";
import Grid from "@material-ui/core/Grid";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import DescriptionIcon from '@mui/icons-material/Description';
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { getPatientNotes, setShowNotesPanel, setShowNotesDrawer, setOpenAddNotesPanel, addNotesToRecordFromTable, setSelectedTimestamp } from "../../../../../../Actions";
import { getSelectedSubjectId, getUserRole } from "../../../../../../Reducer/selectors/DashboardSelector.js";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default function VitalsTable(props) {
  //console.log("VitalsTable");
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const dispatch = useDispatch();
  const sId = useSelector(getSelectedSubjectId);
  const timeZoneFromDayjs = dayjs.tz.guess();
  const userRole = useSelector(getUserRole);

  //console.log("props: ", props);

  const handleAddNotes = (event) => {
    event.preventDefault();
    dispatch(setShowNotesPanel(true));
    dispatch(setShowNotesDrawer(true));
    dispatch(setOpenAddNotesPanel(true));
    dispatch(setSelectedTimestamp(props.timestamp));
    const payload2 = {
      subjectId: sId.selectedSubjectId,
      recordId: props.recordId,
      userRole: userRole,
    };
    dispatch(getPatientNotes(payload2));
    const payload = {
      timeStamp: props.timestamp,
      recordId: props.recordId,
    };
    dispatch(addNotesToRecordFromTable(payload));

  };

  const handleDescriptionNotes = (event) => {
    event.preventDefault();
    const payload = {
      timeStamp: props.timestamp,
      recordId: props.recordId,
    };
    dispatch(addNotesToRecordFromTable(payload));
    const payload2 = {
      subjectId: sId.selectedSubjectId,
      recordId: props.recordId,
      userRole: userRole,
    };
    dispatch(getPatientNotes(payload2));
  };

  return (
    <div className={styles.vitalsTile}>
      <Grid container>
        <Grid item xs={12} xl={12} lg={12} md={10} sm={12}>
          <div className={styles.responsive}>
            <table className={styles.rows}>
              <tbody className={styles.tableBody}>
                <tr className={styles.content}>
                  <td className={styles.timeStamp}>{dayjs.utc(props.timestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}</td>
                  <td className={`${styles["id"]} ${props.mlId == 99 ? styles.manualMouthLabId : styles.mouthLabId}`}>{props.mlId == 99 ? "M" : props.mlId}</td>

                  <td className={`${styles["temperature"]} ${props.tempRisk == 1 ? styles.riskOne : styles.riskZero}`}>{props.temp}</td>
                  <td className={`${styles["bp"]} ${props.bpRisk == 1 ? styles.riskOne : styles.riskZero}`}>{props.sbp + "/" + props.dbp}</td>
                  <td className={`${styles["pr"]} ${props.pulseRateRisk == 1 ? styles.riskOne : styles.riskZero}`}> {props.pulseRate}</td>
                  <td className={`${styles["spo2"]} ${props.spo2Risk == 1 ? styles.riskOne : styles.riskZero}`}>{props.spo2}</td>
                  <td className={`${styles["hr"]} ${props.heartRateRisk == 1 ? styles.riskOne : styles.riskZero}`}>{props.heartRate} </td>
                  <td className={`${styles["hrv"]} ${props.hrvRisk == 1 ? styles.riskOne : styles.riskZero}`}>{props.hrv}</td>
                  <td className={`${styles["rr"]} ${props.breathingRateRisk == 1 ? styles.riskOne : styles.riskZero}`}>{props.breathingRate}</td>
                  <td className={`${styles["fev1"]} ${props.fev1Risk == 1 ? styles.riskOne : styles.riskZero}`}>{props.fev1}</td>
                  <td className={`${styles["pef"]} ${props.pefRisk == 1 ? styles.riskOne : styles.riskZero}`}>{props.pef}</td>
                  <td className={`${styles["notes"]} ${styles.riskZero}`}>
                    {!props.hasNotes ? (
                      <IconButton size="small" className={styles.allNotesButtonIcon} onClick={(event) => handleAddNotes(event)}>
                        <NoteAddOutlinedIcon style={{ color: `#4E253A` }}></NoteAddOutlinedIcon>
                      </IconButton>
                    ) : (
                      <IconButton size="small" className={styles.allNotesButtonIcon} onClick={(event) => handleDescriptionNotes(event)}>
                        <DescriptionIcon style={{ color: `#4E253A` }}></DescriptionIcon>
                      </IconButton>
                    )}
                  </td>
                  {/* Currently it is not being calculated and hence need not be shown.
                  <td
                    className={`${styles["fvc"]} ${
                      props.fvcRisk == 1 ? styles.riskOne : styles.riskZero
                    }`}
                  >
                    {props.fvc}
                  </td>
                  <td
                    className={`${styles["fev1fvc"]} ${
                      props.feV1_FVCRisk == 1 ? styles.riskOne : styles.riskZero
                    }`}
                  >
                    {props.feV1_FVC}
                  </td>
 */}
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
