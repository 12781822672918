import React, { useState, createContext } from "react";

export const PatientContext = createContext();

export const PatientDataProvider = ({ children }) => {
  const [subjectId, setSubjectId] = useState("10");
  const [subjectGUID, setSubjectGUID] = useState("4534trf4");
  const [currentMouthLabId, setCurrentMouthLabId] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  const [patientList, setPatientList] = useState([]);
/* 
  console.log("from patient data provider ", subjectId);
  console.log("DEVICE ID CHANGED", currentMouthLabId);
 */
  return (
    <PatientContext.Provider
      value={{
        subjectId,
        setSubjectId,
        subjectGUID,
        setSubjectGUID,
        currentMouthLabId,
        setCurrentMouthLabId,
        isRefresh,
        setIsRefresh,
        patientList,
        setPatientList
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};
