import React from "react";
import styles from "./PatientAIDITableData.module.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const getColorClass = (value) => {
  if (value != null) {
    if (value < 0.3) {
      return styles.AIDIlowValue;
    } else if (value >= 0.3 && value <= 0.6) {
      return styles.AIDImediumValue;
    } else {
      return styles.AIDIhighValue;
    }
  } else {
    return styles.AIDI;
  }
};

const PatientAIDITableData = ({ data }) => {
  //console.log("data: ", data);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const timeZoneFromDayjs = dayjs.tz.guess();

  return (
    <tbody className={styles.tableBody}>
      {data.map((item, index) => (
        <tr key={item.recordTs || index} className={styles.content}>
          <td className={styles.timeStamp}>{dayjs.utc(item.recordTs).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}</td>
          <td className={styles.deviceId}>{item.deviceId}</td>
          <td className={`${styles.AIDI} ${getColorClass(item.aidi)}`}>{item.aidi != null ? item.aidi : "NA"}</td>
          <td className={styles.averageHR}>{item.avgHr}</td>
          <td className={styles.averageRR}>{item.avgBr}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default PatientAIDITableData;
