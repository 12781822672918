import styles from "./FamilyInfoReview.module.css";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Select from 'react-dropdown-select';
import { setPRFamilyInfo, setRegistrationStatus } from "../../../../Actions/UtilActions";
import { useDispatch, useSelector } from "react-redux";
import { getPRFamilyInfo, getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { getSelectedProjectIdAndLocationId } from "../../../../Reducer/selectors/DashboardSelector";
import Backdrop from '@mui/material/Backdrop';
import Modal from "@material-ui/core/Modal";
import logoutImg from '../../../../Assets/Images/Logout.png';
import Grid from "@material-ui/core/Grid";

const FamilyInfoReview = forwardRef((props, ref) => {

    const handleFocus = (event) => event.target.select();
    const dispatch = useDispatch();

    const curRegStatus = useSelector(getRegistrationStatus);

    const familyInfo = useSelector(getPRFamilyInfo);
    console.log("familyInfo: ", familyInfo);

    const [contactsCount, setContactsCount] = useState(familyInfo.firstNameC3 !== '' ? 3 : familyInfo.firstNameC2 !== '' ? 2 : 1);
    console.log("contacts count: ", contactsCount);
    const [showSecContact, setShowSecContact] = useState(contactsCount >= 2);
    const [showTerContact, setShowTerContact] = useState(contactsCount >= 3);

    const [prefixC1, setPrefixC1] = useState([{ value: familyInfo.prefixC1, label: familyInfo.prefixC1 }]);
    const [firstNameC1, setFirstNameC1] = useState(familyInfo.firstNameC1);
    const [suffixC1, setSuffixC1] = useState([{ value: familyInfo.suffixC1, label: familyInfo.suffixC1 }]);
    const [lastNameC1, setLastNameC1] = useState(familyInfo.lastNameC1);
    const [relC1, setRelC1] = useState(familyInfo.relC1);
    const [bioSexC1, setBioSexC1] = useState([{ value: familyInfo.bioSexC1, label: familyInfo.bioSexC1 }]);
    const [emailC1, setEmailC1] = useState(familyInfo.emailC1);
    const [phNumC1, setPhNumC1] = useState(familyInfo.phNumC1);
    const [conTypeC1, setConTypeC1] = useState([{ value: familyInfo.conTypeC1, label: familyInfo.conTypeC1 }]);
    const [comModeC1, setComModeC1] = useState([{ value: familyInfo.comModeC1, label: familyInfo.comModeC1 }]);

    const [prefixC2, setPrefixC2] = useState([{ value: familyInfo.prefixC2, label: familyInfo.prefixC2 }]);
    const [firstNameC2, setFirstNameC2] = useState(familyInfo.firstNameC2);
    const [suffixC2, setSuffixC2] = useState([{ value: familyInfo.suffixC2, label: familyInfo.suffixC2 }]);
    const [lastNameC2, setLastNameC2] = useState(familyInfo.lastNameC2);
    const [relC2, setRelC2] = useState(familyInfo.relC2);
    const [bioSexC2, setBioSexC2] = useState([{ value: familyInfo.bioSexC2, label: familyInfo.bioSexC2 }]);
    const [emailC2, setEmailC2] = useState(familyInfo.emailC2);
    const [phNumC2, setPhNumC2] = useState(familyInfo.phNumC2);
    const [conTypeC2, setConTypeC2] = useState([{ value: familyInfo.conTypeC2, label: familyInfo.conTypeC2 }]);
    const [comModeC2, setComModeC2] = useState([{ value: familyInfo.comModeC2, label: familyInfo.comModeC2 }]);

    const [prefixC3, setPrefixC3] = useState([{ value: familyInfo.prefixC3, label: familyInfo.prefixC3 }]);
    const [firstNameC3, setFirstNameC3] = useState(familyInfo.firstNameC3);
    const [suffixC3, setSuffixC3] = useState([{ value: familyInfo.suffixC3, label: familyInfo.suffixC3 }]);
    const [lastNameC3, setLastNameC3] = useState(familyInfo.lastNameC3);
    const [relC3, setRelC3] = useState(familyInfo.relC3);
    const [bioSexC3, setBioSexC3] = useState([{ value: familyInfo.bioSexC3, label: familyInfo.bioSexC3 }]);
    const [emailC3, setEmailC3] = useState(familyInfo.emailC3);
    const [phNumC3, setPhNumC3] = useState(familyInfo.phNumC3);
    const [conTypeC3, setConTypeC3] = useState([{ value: familyInfo.conTypeC3, label: familyInfo.conTypeC3 }]);
    const [comModeC3, setComModeC3] = useState([{ value: familyInfo.comModeC3, label: familyInfo.comModeC3 }]);

    const [consentSel1, setConsentSel1] = useState(familyInfo.consentSel1);
    const [consentSel2, setConsentSel2] = useState(familyInfo.consentSel2);
    const [consentSel3, setConsentSel3] = useState(familyInfo.consentSel3);

    const [showReqWarn1, setShowReqWarn1] = useState(false);
    const [showReqWarn2, setShowReqWarn2] = useState(false);
    const [showReqWarn3, setShowReqWarn3] = useState(false);

    const [isFirstNameC1Fill, setIsFirstNameC1Fill] = useState(true);
    const [isLastNameC1Fill, setIsLastNameC1Fill] = useState(true);
    const [isBioSexC1Fill, setIsBioSexC1Fill] = useState(true);
    const [isPhNumC1Fill, setIsPhNumC1Fill] = useState(true);
    const [isEmailC1Fill, setIsEmailC1Fill] = useState(true);
    const [isRelC1Fill, setIsRelC1Fill] = useState(true);
    const [isConTypeC1Fill, setIsConTypeC1Fill] = useState(true);
    const [isComModeC1Fill, setIsComModeC1Fill] = useState(true);

    const [isFirstNameC2Fill, setIsFirstNameC2Fill] = useState(true);
    const [isLastNameC2Fill, setIsLastNameC2Fill] = useState(true);
    const [isBioSexC2Fill, setIsBioSexC2Fill] = useState(true);
    const [isPhNumC2Fill, setIsPhNumC2Fill] = useState(true);
    const [isEmailC2Fill, setIsEmailC2Fill] = useState(true);
    const [isRelC2Fill, setIsRelC2Fill] = useState(true);
    const [isConTypeC2Fill, setIsConTypeC2Fill] = useState(true);
    const [isComModeC2Fill, setIsComModeC2Fill] = useState(true);

    const [isFirstNameC3Fill, setIsFirstNameC3Fill] = useState(true);
    const [isLastNameC3Fill, setIsLastNameC3Fill] = useState(true);
    const [isBioSexC3Fill, setIsBioSexC3Fill] = useState(true);
    const [isPhNumC3Fill, setIsPhNumC3Fill] = useState(true);
    const [isEmailC3Fill, setIsEmailC3Fill] = useState(true);
    const [isRelC3Fill, setIsRelC3Fill] = useState(true);
    const [isConTypeC3Fill, setIsConTypeC3Fill] = useState(true);
    const [isComModeC3Fill, setIsComModeC3Fill] = useState(true);

    const [cancelRegistration, setCancelRegistration] = useState(false);

    const [isFamilyFormEditable1, setIsFamilyFormEditable1] = useState(false);
    const [isFamilyFormEditable2, setIsFamilyFormEditable2] = useState(false);
    const [isFamilyFormEditable3, setIsFamilyFormEditable3] = useState(false);

    const clearC1Fields = () => {
        setPrefixC1([]);
        setFirstNameC1('');
        setSuffixC1([]);
        setLastNameC1('');
        setRelC1('');
        setBioSexC1([]);
        setEmailC1('');
        setPhNumC1('');
        setConTypeC1([]);
        setComModeC1([]);
    };

    const clearC2Fields = () => {
        setPrefixC2([]);
        setFirstNameC2('');
        setSuffixC2([]);
        setLastNameC2('');
        setRelC2('');
        setBioSexC2([]);
        setEmailC2('');
        setPhNumC2('');
        setConTypeC2([]);
        setComModeC2([]);
    };

    const clearC3Fields = () => {
        setPrefixC3([]);
        setFirstNameC3('');
        setSuffixC3([]);
        setLastNameC3('');
        setRelC3('');
        setBioSexC3([]);
        setEmailC3('');
        setPhNumC3('');
        setConTypeC3([]);
        setComModeC3([]);
    };

    const clearAllFamilyFields = () => {
        clearC1Fields();
        clearC2Fields();
        clearC3Fields();
        setConsentSel1("No");
        setConsentSel2("No");
        setConsentSel3("No");
        resetReqFills();
    };

    const resetC1ReqFills = () => {
        setIsFirstNameC1Fill(true);
        setIsLastNameC1Fill(true);
        setIsBioSexC1Fill(true);
        setIsPhNumC1Fill(true);
        setIsEmailC1Fill(true);
        setIsRelC1Fill(true);
        setIsConTypeC1Fill(true);
        setIsComModeC1Fill(true);
    };

    const resetC2ReqFills = () => {
        setIsFirstNameC2Fill(true);
        setIsLastNameC2Fill(true);
        setIsBioSexC2Fill(true);
        setIsPhNumC2Fill(true);
        setIsEmailC2Fill(true);
        setIsRelC2Fill(true);
        setIsConTypeC2Fill(true);
        setIsComModeC2Fill(true);
    };

    const resetC3ReqFills = () => {
        setIsFirstNameC3Fill(true);
        setIsLastNameC3Fill(true);
        setIsBioSexC3Fill(true);
        setIsPhNumC3Fill(true);
        setIsEmailC3Fill(true);
        setIsRelC3Fill(true);
        setIsConTypeC3Fill(true);
        setIsComModeC3Fill(true);
    };

    const resetReqFills = () => {
        resetC1ReqFills();
        resetC2ReqFills();
        resetC3ReqFills();
    };

    useImperativeHandle(ref, () => {
        return {
            clearAllFamilyFields: clearAllFamilyFields,
        };
    });

    const optionsPrefix = [
        { value: 'Dr.', label: 'Dr.' },
        { value: 'Miss.', label: 'Miss.' },
        { value: 'Mr.', label: 'Mr.' },
        { value: 'Mrs.', label: 'Mrs.' },
        { value: 'Ms.', label: 'Ms.' },
    ];

    const optionsSuffix = [
        { value: 'II', label: 'II' },
        { value: 'III', label: 'III' },
        { value: 'IV', label: 'IV' },
        { value: 'Jr.', label: 'Jr.' },
        { value: 'Sr.', label: 'Sr.' },
    ];

    const optionsBioSex = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Unknown', label: 'Unknown' },
    ];

    const optionsConType = [
        { value: 'Care Conference Person', label: 'Care Conference Person' },
        { value: 'Guardian of Person', label: 'Guardian of Person' },
        { value: 'Guardian of Assets', label: 'Guardian of Assets' },
        { value: 'Health Care Surrogate', label: 'Health Care Surrogate' },
        { value: 'POA-Financial', label: 'POA-Financial' },
        { value: 'POA-Care', label: 'POA-Care' },
        { value: 'This contact receives satisfaction survey', label: 'This contact receives satisfaction survey' },
        { value: 'Resident makes health care decisions', label: 'Resident makes health care decisions' },
        { value: 'Financial Responsible Party', label: 'Financial Responsible Party' },
    ];

    const optionsComModeC1 = [
        { value: 'Email', label: 'Email' },
        { value: 'Phone', label: 'Phone' },
        { value: 'Both', label: 'Both' },
    ];

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const onAddSecContact = (event) => {
        event.preventDefault();
        if (checkIfContact1IsDone(false)) {
            setShowSecContact(true);
            setContactsCount(2);
            setShowReqWarn1(false);
            resetC1ReqFills();
            setIsFamilyFormEditable1(false);
            setIsFamilyFormEditable2(true);
        }
    };

    const onAddTerContact = (event) => {
        event.preventDefault();
        if (checkIfContact2IsDone(false)) {
            setShowTerContact(true);
            setContactsCount(3);
            setShowReqWarn2(false);
            resetC2ReqFills();
            setIsFamilyFormEditable2(false);
            setIsFamilyFormEditable3(true);
        }
    };

    const deleteSecContact = (event) => {
        event.preventDefault();
        setShowSecContact(false);
        clearC2Fields();
        resetC2ReqFills();
        setContactsCount(1);
    };

    const deleteTerContact = (event) => {
        event.preventDefault();
        setShowTerContact(false);
        clearC3Fields();
        resetC3ReqFills();
        setContactsCount(2);
    };

    const getStringFromSelectArray = (arr) => {
        const len = arr.length;
        let res = "";
        for (let i = 0; i < len; i++) {
            if (res.length > 0) {
                res = res + ", " + arr[i].value;
            } else {
                res = res + arr[i].value;
            }
        }
        return res;
    };

    const checkIfContact1IsDone = (flag) => {
        if ((firstNameC1 !== '')) {
            if (!comModeC1[0]) {
                return true;
            } else if (comModeC1[0].value === 'Email') {
                if (emailC1 != '') {
                    return true;
                } else {
                    setShowReqWarn1(true);
                    setIsEmailC1Fill(false);
                    return false;
                }
            } else if (comModeC1[0].value === 'Phone') {
                if (phNumC1 != '') {
                    return true;
                } else {
                    setShowReqWarn1(true);
                    setIsPhNumC1Fill(false);
                    return false;
                }
            } else if (comModeC1[0].value === 'Both') {
                if (emailC1 !== '' && phNumC1 !== '') {
                    return true;
                } else {
                    setShowReqWarn1(true);
                    if (emailC1 === '') {
                        setIsEmailC1Fill(false);
                    }
                    if (phNumC1 === '') {
                        setIsPhNumC1Fill(false);
                    }
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true && flag;
        }
    };

    const checkIfContact2IsDone = (flag) => {
        if ((firstNameC2 !== '')) {
            if (!comModeC2[0]) {
                return true;
            } else if (comModeC2[0].value === 'Email') {
                if (emailC2 != '') {
                    return true;
                } else {
                    setShowReqWarn2(true);
                    setIsEmailC2Fill(false);
                    return false;
                }
            } else if (comModeC2[0].value === 'Phone') {
                if (phNumC2 != '') {
                    return true;
                } else {
                    setShowReqWarn2(true);
                    setIsPhNumC2Fill(false);
                    return false;
                }
            } else if (comModeC2[0].value === 'Both') {
                if (emailC2 !== '' && phNumC2 !== '') {
                    return true;
                } else {
                    setShowReqWarn2(true);
                    if (emailC2 === '') {
                        setIsEmailC2Fill(false);
                    }
                    if (phNumC2 === '') {
                        setIsPhNumC2Fill(false);
                    }
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true && flag;
        }
    };

    const checkIfContact3IsDone = (flag) => {
        if ((firstNameC3 !== '')) {
            if (!comModeC3[0]) {
                return true;
            } else if (comModeC3[0].value === 'Email') {
                if (emailC3 != '') {
                    return true;
                } else {
                    setShowReqWarn3(true);
                    setIsEmailC3Fill(false);
                    return false;
                }
            } else if (comModeC3[0].value === 'Phone') {
                if (phNumC3 != '') {
                    return true;
                } else {
                    setShowReqWarn3(true);
                    setIsPhNumC3Fill(false);
                    return false;
                }
            } else if (comModeC3[0].value === 'Both') {
                if (emailC3 !== '' && phNumC3 !== '') {
                    return true;
                } else {
                    setShowReqWarn3(true);
                    if (emailC3 === '') {
                        setIsEmailC3Fill(false);
                    }
                    if (phNumC3 === '') {
                        setIsPhNumC3Fill(false);
                    }
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true && flag;
        }
    };

    const markStepCompleted = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: curRegStatus.step2,
            step3: true,
            step4: curRegStatus.step4,
            step5: curRegStatus.step5,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
    };

    const setTabInfo = () => {

        const payload = {
            prefixC1: prefixC1[0] ? prefixC1[0].value : '',
            firstNameC1: firstNameC1,
            suffixC1: suffixC1[0] ? suffixC1[0].value : '',
            lastNameC1: lastNameC1,
            relC1: relC1,
            bioSexC1: bioSexC1[0] ? bioSexC1[0].value : '',
            emailC1: emailC1,
            phNumC1: phNumC1,
            conTypeC1: getStringFromSelectArray(conTypeC1),
            comModeC1: getStringFromSelectArray(comModeC1),

            prefixC2: prefixC2[0] ? prefixC2[0].value : '',
            firstNameC2: firstNameC2,
            suffixC2: suffixC2[0] ? suffixC2[0].value : '',
            lastNameC2: lastNameC2,
            relC2: relC2,
            bioSexC2: bioSexC2[0] ? bioSexC2[0].value : '',
            emailC2: emailC2,
            phNumC2: phNumC2,
            conTypeC2: getStringFromSelectArray(conTypeC2),
            comModeC2: getStringFromSelectArray(comModeC2),

            prefixC3: prefixC3[0] ? prefixC3[0].value : '',
            firstNameC3: firstNameC3,
            suffixC3: suffixC3[0] ? suffixC3[0].value : '',
            lastNameC3: lastNameC3,
            relC3: relC3,
            bioSexC3: bioSexC3[0] ? bioSexC3[0].value : '',
            emailC3: emailC3,
            phNumC3: phNumC3,
            conTypeC3: getStringFromSelectArray(conTypeC3),
            comModeC3: getStringFromSelectArray(comModeC3),

            consentSel1: consentSel1,
            consentSel2: consentSel2,
            consentSel3: consentSel3,
        };
        console.log("familyInfoTab payload: ", payload);
        dispatch(setPRFamilyInfo(payload));
    };

    const checkIfPageConditionsMet = () => {
        if (checkIfContact1IsDone(true) && checkIfContact2IsDone(true) && checkIfContact3IsDone(true)) {
            console.log('conditions met!');
            resetC1ReqFills();
            resetC2ReqFills();
            resetC3ReqFills();
            return true;
        } else {
            console.log('conditions not met!');
            return false;
        }
    };

    const handleSaveAndContinue = () => {
        if (checkIfPageConditionsMet()) {
            props.tabChange("4");
            setTabInfo();
            markStepCompleted();
        }
    };

    const handleSave1 = () => {
        if (checkIfContact1IsDone(true)) {
            setTabInfo();
            setIsFamilyFormEditable1(false);
        }
    };

    const handleSave2 = () => {
        if (checkIfContact2IsDone(true)) {
            setTabInfo();
            setIsFamilyFormEditable2(false);
        }
    };

    const handleSave3 = () => {
        if (checkIfContact3IsDone(true)) {
            setTabInfo();
            setIsFamilyFormEditable3(false);
        }
    };

    const navigate = useNavigate();
    const projLoc = useSelector(getSelectedProjectIdAndLocationId);
    const selectedProjectId = projLoc.selectedProjectId;
    const selectedLocationId = projLoc.selectedLocationId;

    const onValueChange1 = (event) => {
        setConsentSel1(event.target.value);
    };

    const onValueChange2 = (event) => {
        setConsentSel2(event.target.value);
    };

    const onValueChange3 = (event) => {
        setConsentSel3(event.target.value);
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    const closeCancelRegistration = () => {
        setCancelRegistration(false);
    };

    const handleCancelRegistration = () => {
        setCancelRegistration(true);
    };

    const handleCancelRegistrationSuccess = () => {
        const payload = {
            step1: false,
            step2: false,
            step3: false,
            step4: false,
            step5: false,
            review: false,
        }
        dispatch(setRegistrationStatus(payload));
        clearAllFamilyFields();
        navigate('/subjects/' + selectedProjectId + '/' + selectedLocationId);
    };

    const editFamilyForm1 = (event) => {
        event.preventDefault();
        setIsFamilyFormEditable1(true);
    };

    const handleCancel1 = () => {
        setIsFamilyFormEditable1(false);
    };

    const editFamilyForm2 = (event) => {
        event.preventDefault();
        setIsFamilyFormEditable2(true);
    };

    const handleCancel2 = () => {
        if (firstNameC2 === "") {
            setShowSecContact(false);
            clearC2Fields();
            resetC2ReqFills();
            setContactsCount(1);
        }
        setIsFamilyFormEditable2(false);
    };

    const editFamilyForm3 = (event) => {
        event.preventDefault();
        setIsFamilyFormEditable3(true);
    };

    const handleCancel3 = () => {
        if (firstNameC3 === "") {
            setShowTerContact(false);
            clearC3Fields();
            resetC3ReqFills();
            setContactsCount(2);
        }
        setIsFamilyFormEditable3(false);
    };

    return (
        <div className={styles.rootDiv}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={cancelRegistration}
            ></Backdrop>
            <div className={styles.containerDiv}>
                <div className={styles.dumDiv}>

                    {contactsCount >= 1 && (<>
                        <div className={styles.headerDiv}>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.headerTitle}> Primary </div>
                            <div className={styles.dummyDiv} > <div className={!isFamilyFormEditable1 ? styles.editButton : styles.editButtonClicked} onClick={event => editFamilyForm1(event)}>Edit</div></div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={prefixC1}
                                                onChange={setPrefixC1}
                                                options={optionsPrefix}
                                                color="#4E253A"
                                                disabled={!isFamilyFormEditable1}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstNameC1} disabled={!isFamilyFormEditable1} onChange={(e) => setFirstNameC1(e.currentTarget.value)} className={isFamilyFormEditable1 ? isFirstNameC1Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastNameC1} disabled={!isFamilyFormEditable1} onChange={(e) => setLastNameC1(e.currentTarget.value)} className={isFamilyFormEditable1 ? isLastNameC1Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={suffixC1}
                                                onChange={setSuffixC1}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                                disabled={!isFamilyFormEditable1}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Sex at Birth
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isBioSexC1Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={bioSexC1}
                                            onChange={setBioSexC1}
                                            options={optionsBioSex}
                                            color="#4E253A"
                                            disabled={!isFamilyFormEditable1}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Relationship to Patient
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={relC1} disabled={!isFamilyFormEditable1} onChange={(e) => setRelC1(e.currentTarget.value)} className={isFamilyFormEditable1 ? isRelC1Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={emailC1} disabled={!isFamilyFormEditable1} onChange={(e) => setEmailC1(e.currentTarget.value)} className={isFamilyFormEditable1 ? isEmailC1Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNumC1} disabled={!isFamilyFormEditable1} onChange={(e) => setPhNumC1(e.currentTarget.value)} className={isFamilyFormEditable1 ? isPhNumC1Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Contact Type
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isConTypeC1Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={conTypeC1}
                                            onChange={setConTypeC1}
                                            options={optionsConType}
                                            color="#4E253A"
                                            disabled={!isFamilyFormEditable1}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of communication
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isComModeC1Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={comModeC1}
                                            onChange={setComModeC1}
                                            options={optionsComModeC1}
                                            color="#4E253A"
                                            disabled={!isFamilyFormEditable1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.divFooter}>
                            <div className={styles.consentDiv}>
                                <div className={styles.consentLabel}>Consent<span style={{ color: 'red', paddingLeft: "2px" }}>*</span></div>
                                <div className={styles.consentContent}>
                                    By providing the contact details of the designated family member, you consent to their inclusion as a point of contact for any issues related to your care and well-being, and for the collection of relevant information to ensure comprehensive care coordination.
                                </div>
                                <div className={styles.consentButtons}>
                                    <label>
                                        <input
                                            type="radio"
                                            value="No"
                                            checked={consentSel1 === "No"}
                                            onChange={onValueChange1}
                                            style={{ accentColor: '#AA346F' }}
                                            disabled={!isFamilyFormEditable1}
                                        />
                                        No
                                    </label>

                                    <label>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            checked={consentSel1 === "Yes"}
                                            onChange={onValueChange1}
                                            style={{ accentColor: '#AA346F' }}
                                            disabled={!isFamilyFormEditable1}
                                        />
                                        Yes
                                    </label>
                                </div>
                            </div>
                            {showReqWarn1 && <div className={styles.consentWarningDiv}>
                                Please fill out the required fields.
                            </div>}
                            {(isFamilyFormEditable1) && (<>
                                <div className={styles.pageButtonsDiv}>
                                    {/* <div className={styles.cancelButton} onClick={handleCancel1}>Cancel</div> */}
                                    <div className={styles.saveButton} onClick={handleSave1}>Save</div>
                                </div>
                                {(contactsCount < 2) && (<div className={styles.addNewDiv}>
                                    <div className={styles.addNewContButton} onClick={(event) => onAddSecContact(event)}>
                                        + Add Another Contact
                                    </div>
                                </div>)}
                            </>)}
                        </div>
                    </>)}


                    {showSecContact && (<>
                        <div className={styles.headerDiv}>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.headerTitle}> Secondary </div>
                            <div>
                                {(!isFamilyFormEditable2) && (<div className={!isFamilyFormEditable2 ? styles.editButton : styles.editButtonClicked} onClick={event => editFamilyForm2(event)}>Edit</div>)}
                                {(isFamilyFormEditable2) && (<div className={styles.deleteButton1}><CloseIcon onClick={(event) => deleteSecContact(event)} style={{ height: '20px', width: '20px', fill: "#A040AB" }} /> </div>)} </div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={prefixC2}
                                                onChange={setPrefixC2}
                                                options={optionsPrefix}
                                                color="#4E253A"
                                                disabled={!isFamilyFormEditable2}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstNameC2} disabled={!isFamilyFormEditable2} onChange={(e) => setFirstNameC2(e.currentTarget.value)} className={isFamilyFormEditable2 ? isFirstNameC2Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastNameC2} disabled={!isFamilyFormEditable2} onChange={(e) => setLastNameC2(e.currentTarget.value)} className={isFamilyFormEditable2 ? isLastNameC2Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={suffixC2}
                                                onChange={setSuffixC2}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                                disabled={!isFamilyFormEditable2}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Sex at Birth
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isBioSexC2Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={bioSexC2}
                                            onChange={setBioSexC2}
                                            options={optionsBioSex}
                                            color="#4E253A"
                                            disabled={!isFamilyFormEditable2}
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Relationship to Patient
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={relC2} disabled={!isFamilyFormEditable2} onChange={(e) => setRelC2(e.currentTarget.value)} className={isFamilyFormEditable2 ? isRelC2Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={emailC2} disabled={!isFamilyFormEditable2} onChange={(e) => setEmailC2(e.currentTarget.value)} className={isFamilyFormEditable2 ? isEmailC2Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNumC2} disabled={!isFamilyFormEditable2} onChange={(e) => setPhNumC2(e.currentTarget.value)} className={isFamilyFormEditable2 ? isPhNumC2Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Contact Type
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isConTypeC2Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={conTypeC2}
                                            onChange={setConTypeC2}
                                            options={optionsConType}
                                            color="#4E253A"
                                            disabled={!isFamilyFormEditable2}
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of communication
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isComModeC2Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={comModeC2}
                                            onChange={setComModeC2}
                                            options={optionsComModeC1}
                                            color="#4E253A"
                                            disabled={!isFamilyFormEditable2}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.divFooter}>

                            <div className={styles.consentDiv}>
                                <div className={styles.consentLabel}>Consent<span style={{ color: 'red', paddingLeft: "2px" }}>*</span></div>
                                <div className={styles.consentContent}>
                                    By providing the contact details of the designated family member, you consent to their inclusion as a point of contact for any issues related to your care and well-being, and for the collection of relevant information to ensure comprehensive care coordination.
                                </div>
                                <div className={styles.consentButtons}>
                                    <label>
                                        <input
                                            type="radio"
                                            value="No"
                                            checked={consentSel2 === "No"}
                                            onChange={onValueChange2}
                                            style={{ accentColor: '#AA346F' }}
                                            disabled={!isFamilyFormEditable2}
                                        />
                                        No
                                    </label>

                                    <label>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            checked={consentSel2 === "Yes"}
                                            onChange={onValueChange2}
                                            style={{ accentColor: '#AA346F' }}
                                            disabled={!isFamilyFormEditable2}
                                        />
                                        Yes
                                    </label>
                                </div>
                            </div>
                            {showReqWarn2 && <div className={styles.consentWarningDiv}>
                                Please fill out the required fields.
                            </div>}
                            {(isFamilyFormEditable2) && (<><div className={styles.pageButtonsDiv}>
                                {/* <div className={styles.cancelButton} onClick={handleCancel2}>Cancel</div> */}
                                <div className={styles.saveButton} onClick={handleSave2}>Save</div>
                            </div>
                                {(contactsCount < 3) && (<div className={styles.addNewDiv}>
                                    <div className={styles.addNewContButton} onClick={(event) => onAddTerContact(event)}>
                                        + Add Another Contact
                                    </div>
                                </div>)}
                            </>)}
                        </div>
                    </>)}


                    {showTerContact && (<>
                        <div className={styles.headerDiv}>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.headerTitle}> Tertiary </div>
                            <div>
                                {(!isFamilyFormEditable3) && (<div className={!isFamilyFormEditable3 ? styles.editButton : styles.editButtonClicked} onClick={event => editFamilyForm3(event)}>Edit</div>)}
                                {(isFamilyFormEditable3) && (<div className={styles.deleteButton1}> <CloseIcon onClick={(event) => deleteTerContact(event)} style={{ height: '20px', width: '20px', fill: "#A040AB" }} /></div>)} </div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={prefixC3}
                                                onChange={setPrefixC3}
                                                options={optionsPrefix}
                                                color="#4E253A"
                                                disabled={!isFamilyFormEditable3}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstNameC3} disabled={!isFamilyFormEditable3} onChange={(e) => setFirstNameC3(e.currentTarget.value)} className={isFamilyFormEditable3 ? isFirstNameC3Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastNameC3} disabled={!isFamilyFormEditable3} onChange={(e) => setLastNameC3(e.currentTarget.value)} className={isFamilyFormEditable3 ? isLastNameC3Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2 : styles.inputFieldSelcted2UE} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={suffixC3}
                                                onChange={setSuffixC3}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                                disabled={!isFamilyFormEditable3}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Sex at Birth
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isBioSexC3Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={bioSexC3}
                                            onChange={setBioSexC3}
                                            options={optionsBioSex}
                                            color="#4E253A"
                                            disabled={!isFamilyFormEditable3}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Relationship to Patient
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={relC3} disabled={!isFamilyFormEditable3} onChange={(e) => setRelC3(e.currentTarget.value)} className={isFamilyFormEditable3 ? isRelC3Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={emailC3} disabled={!isFamilyFormEditable3} onChange={(e) => setEmailC3(e.currentTarget.value)} className={isFamilyFormEditable3 ? isEmailC3Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNumC3} disabled={!isFamilyFormEditable3} onChange={(e) => setPhNumC3(e.currentTarget.value)} className={isFamilyFormEditable3 ? isPhNumC3Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Contact Type
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isConTypeC3Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={conTypeC3}
                                            onChange={setConTypeC3}
                                            options={optionsConType}
                                            color="#4E253A"
                                            disabled={!isFamilyFormEditable3}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of communication
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isComModeC3Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={comModeC3}
                                            onChange={setComModeC3}
                                            options={optionsComModeC1}
                                            color="#4E253A"
                                            disabled={!isFamilyFormEditable3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.divFooter}>
                            {/* <div className={styles.addNewDiv}>
                                    <div className={styles.addNewContButton} onClick={onAddNewContact}>
                                        + Add Another Contact
                                    </div>
                                </div> */}
                            <div className={styles.consentDiv}>
                                <div className={styles.consentLabel}>Consent<span style={{ color: 'red', paddingLeft: "2px" }}>*</span></div>
                                <div className={styles.consentContent}>
                                    By providing the contact details of the designated family member, you consent to their inclusion as a point of contact for any issues related to your care and well-being, and for the collection of relevant information to ensure comprehensive care coordination.
                                </div>
                                <div className={styles.consentButtons}>
                                    <label>
                                        <input
                                            type="radio"
                                            value="No"
                                            checked={consentSel3 === "No"}
                                            onChange={onValueChange3}
                                            style={{ accentColor: '#AA346F' }}
                                            disabled={!isFamilyFormEditable3}
                                        />
                                        No
                                    </label>

                                    <label>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            checked={consentSel3 === "Yes"}
                                            onChange={onValueChange3}
                                            style={{ accentColor: '#AA346F' }}
                                            disabled={!isFamilyFormEditable3}
                                        />
                                        Yes
                                    </label>
                                </div>
                            </div>
                            {showReqWarn3 && <div className={styles.consentWarningDiv}>
                                Please fill out the required fields.
                            </div>}
                            {(isFamilyFormEditable3) && (<><div className={styles.pageButtonsDiv}>
                                {/* <div className={styles.cancelButton} onClick={handleCancel3}>Cancel</div> */}
                                <div className={styles.saveButton} onClick={handleSave3}>Save</div>
                            </div>
                            </>)}
                        </div>
                    </>)}

                </div>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={cancelRegistration}
                onClose={closeCancelRegistration}
            >
                <div className={styles.paper}>
                    <div className={styles.menuTitle}>Cancel Registration</div>

                    <div className={styles.patientListDiv}>
                        <Grid container>
                            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                                <div className={styles.userQuestionMessage}>All saved changes will be lost. Are you sure you want to exit?</div>

                                <div className={styles.mouthLabImgDiv}>
                                    <img src={logoutImg} loading="lazy" className={styles.mlImg} />
                                </div>

                                <div className={styles.buttonContainer}>
                                    <button type="button" onClick={closeCancelRegistration} className={styles.cancelButtonModal}>
                                        No
                                    </button>
                                    <button type="submit" onClick={handleCancelRegistrationSuccess} className={styles.submitButtonModal}>
                                        Yes
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Modal>

        </div>
    );
});

export default FamilyInfoReview;