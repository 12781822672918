import 'c3/c3.css';
import c3 from "c3";
import 'c3/c3.min.css';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDbpPayloadSelector, getSbpPayloadSelector } from '../../../../../../../Reducer/selectors/PatientSelector';

export default function RenderBP() {

    const dbpPayload = useSelector(getDbpPayloadSelector);
    // console.log("dbpPayload: ", dbpPayload);

    // let dbpColumnPayload = [
    //     ["daily_avg", 142, 143, 146, 149, 150, 149, 142, 143, 145, 143, 145, 143, 148, 147, 142, 139, 143, 142, 141],
    //     ["daily_avg_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
    //     ["measurement_1", 140, 145, 141, 142, 152, 145, 145, 146, 145, 145, 146, 146, 144, 145, 143, 135, 145, 146, 145],
    //     ["measurement_1_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],
    //     ['measurement_2_x'],
    //     ['measurement_2'],
    //     ['measurement_3_x'],
    //     ['measurement_3'],
    //     ['measurement_4_x'],
    //     ['measurement_4'],
    //     ['measurement_5_x'],
    //     ['measurement_5'],
    //     ['measurement_6_x'],
    //     ['measurement_6'],
    //     ['measurement_7_x'],
    //     ['measurement_7'],
    //     ['measurement_8_x'],
    //     ['measurement_8'],
    // ];

    let dbpColumnPayload = dbpPayload;

    let chart_dbp_bindto = "#chart-dbp";

    let chart_dbp_padding = {
        right: 40,
    };

    let chart_dbp_zoom = {
        enabled: false,
    };

    let prevXLabel = '';

    let chart_dbp_axis = {
        x: {
            show: true,
            type: 'timeseries',
            tick: {
                rotate: 0,
                fit: false,
                format: "%e %b",
                // format: function (d) {
                //     let curXLabel = format(d, "do MMM");
                //     if (curXLabel === prevXLabel) {
                //         return '';
                //     } else {
                //         prevXLabel = curXLabel;
                //         return curXLabel;
                //     }
                // },
                centered: true,
            }
        },
        y: {
            tick: {
                format: function (d) {
                    let temp = d.toFixed(2);
                    return (parseInt(temp) == temp) ? parseInt(temp) : null;
                }
            },
            label: {
                text: 'Diastolic Blood Pressure',
                position: 'outer middle',
            }
        }
    };

    let chart_dbp_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id) {
                return value + " mmHg";
            }
        }
    };

    let chart_dbp_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_avg: 'daily_avg_x',
            measurement_1: 'measurement_1_x',
            measurement_2: 'measurement_2_x',
            measurement_3: 'measurement_3_x',
            measurement_4: 'measurement_4_x',
            measurement_5: 'measurement_5_x',
            measurement_6: 'measurement_6_x',
            measurement_7: 'measurement_7_x',
            measurement_8: 'measurement_8_x',
        },
        columns: dbpColumnPayload,
        type: "line",
        types: {
            measurement_1: 'scatter',
            measurement_2: 'scatter',
            measurement_3: 'scatter',
            measurement_4: 'scatter',
            measurement_5: 'scatter',
            measurement_6: 'scatter',
            measurement_7: 'scatter',
            measurement_8: 'scatter',
        },
    };

    let chart_dbp_color = {
        pattern: ['#694FC0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0', '#9981f0']
    };

    let chart_dbp_size = {
        height: 200,
    };

    let chart_dbp_point = {
        show: true,
        r: 3,
        focus: {
            enabled: true,
            r: 4
        },
        select: {
            r: 5
        }
    };

    let chart_dbp_legend = {
        show: false
    };

    const regenerateDBPGraph = () => {
        dbpGraph = c3.generate({
            bindto: chart_dbp_bindto,
            padding: chart_dbp_padding,
            data: chart_dbp_data,
            zoom: chart_dbp_zoom,
            axis: chart_dbp_axis,
            legend: chart_dbp_legend,
            tooltip: chart_dbp_tooltip,
            color: chart_dbp_color,
            size: chart_dbp_size,
            point: chart_dbp_point,
        });
    }

    var dbpGraph = c3.generate({
        bindto: chart_dbp_bindto,
        padding: chart_dbp_padding,
        data: chart_dbp_data,
        zoom: chart_dbp_zoom,
        axis: chart_dbp_axis,
        legend: chart_dbp_legend,
        tooltip: chart_dbp_tooltip,
        color: chart_dbp_color,
        size: chart_dbp_size,
        point: chart_dbp_point,
    });

    useEffect(() => {
        regenerateDBPGraph();
    }, []);

    const sbpPayload = useSelector(getSbpPayloadSelector);
    // console.log("sbpPayload: ", sbpPayload);

    // let sbpColumnPayload = [
    //     ["daily_avg", 91, 91, 92, 92, 93, 92, 91, 91, 92, 91, 92, 92, 91, 90, 91, 91, 91, 92, 93],
    //     ["daily_avg_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],['measurement_2_x'],
    //     ["measurement_1", 93, 94, 90, 89, 95, 95, 90, 91, 90, 93, 95, 92, 93, 95, 94, 87, 89, 95, 95],
    //     ["measurement_1_x", "2023-12-05", "2023-12-06", "2023-12-07", "2023-12-08", "2023-12-09", "2023-12-10", "2023-12-11", "2023-12-12", "2023-12-13", "2023-12-14", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18", "2023-12-19", "2023-12-20", "2023-12-21"],['measurement_2_x'],
    //     ['measurement_2'],
    //     ['measurement_3_x'],
    //     ['measurement_3'],
    //     ['measurement_4_x'],
    //     ['measurement_4'],
    //     ['measurement_5_x'],
    //     ['measurement_5'],
    //     ['measurement_6_x'],
    //     ['measurement_6'],
    //     ['measurement_7_x'],
    //     ['measurement_7'],
    //     ['measurement_8_x'],
    //     ['measurement_8'],
    // ];

    let sbpColumnPayload = sbpPayload;

    let chart_sbp_bindto = "#chart-sbp";

    let chart_sbp_padding = {
        right: 40,
    };

    let chart_sbp_zoom = {
        enabled: false,
    };

    let chart_sbp_axis = {
        x: {
            show: false,
            type: 'timeseries',
            tick: {
                rotate: 0,
                fit: false,
                format: "%e %b",
                centered: true,
            }
        },
        y: {
            tick: {
                format: function (d) {
                    return (parseInt(d) == d) ? d : null;
                }
            },
            label: {
                text: 'Systolic Blood Pressure',
                position: 'outer middle',
            }
        }
    };

    let chart_sbp_tooltip = {
        grouped: false, // Default true
        format: {
            title: function (d) {
                return format(d, "yyyy-MM-dd");
            },
            value: function (value, ratio, id) {
                return value + " mmHg";
            }
        }
    };

    let chart_sbp_data = {
        selection: {
            enabled: false,
        },
        xs: {
            daily_avg: 'daily_avg_x',
            measurement_1: 'measurement_1_x',
            measurement_2: 'measurement_2_x',
            measurement_3: 'measurement_3_x',
            measurement_4: 'measurement_4_x',
            measurement_5: 'measurement_5_x',
            measurement_6: 'measurement_6_x',
            measurement_7: 'measurement_7_x',
            measurement_8: 'measurement_8_x',
        },
        columns: sbpColumnPayload,
        type: "line",
        types: {
            measurement_1: 'scatter',
            measurement_2: 'scatter',
            measurement_3: 'scatter',
            measurement_4: 'scatter',
            measurement_5: 'scatter',
            measurement_6: 'scatter',
            measurement_7: 'scatter',
            measurement_8: 'scatter',
        }
    };

    let chart_sbp_color = {
        pattern: ['#F14C00', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c', '#fa8f5c']
    };

    let chart_sbp_size = {
        height: 200,
    };

    let chart_sbp_point = {
        show: true,
        r: 3,
        focus: {
            enabled: true,
            r: 4
        },
        select: {
            r: 5
        }
    };

    let chart_sbp_legend = {
        show: false
    };

    const regenerateSBPGraph = () => {
        sbpGraph = c3.generate({
            bindto: chart_sbp_bindto,
            padding: chart_sbp_padding,
            data: chart_sbp_data,
            zoom: chart_sbp_zoom,
            axis: chart_sbp_axis,
            legend: chart_sbp_legend,
            tooltip: chart_sbp_tooltip,
            color: chart_sbp_color,
            size: chart_sbp_size,
            point: chart_sbp_point,
        });
    }

    var sbpGraph = c3.generate({
        bindto: chart_sbp_bindto,
        padding: chart_sbp_padding,
        data: chart_sbp_data,
        zoom: chart_sbp_zoom,
        axis: chart_sbp_axis,
        legend: chart_sbp_legend,
        tooltip: chart_sbp_tooltip,
        color: chart_sbp_color,
        size: chart_sbp_size,
        point: chart_sbp_point,
    });

    useEffect(() => {
        regenerateSBPGraph();
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <div id='chart-sbp' style={{ marginTop: "10px", marginLeft: "10px" }} />
            <div id='chart-dbp' style={{ marginLeft: "10px" }} />
        </div>
    );
}