import React from "react";
import { Field, ErrorMessage } from "formik";
import styles from "./SmallIp.module.css";
import InputErrorMessage from "../../InputError/InputErrorMessage";

export default function SmallIP(props) {
  const { label, name, ...rest } = props;
  return (
    <div>
      <div>
        {/* <div style={{ display: `flex` }}> */}
        <label htmlFor={name} className={styles.labelDivStyles}>
          <div className={styles.labelStyles}>{label}</div>
        </label>
        <Field id={name} name={name} {...rest} className={styles.inputStyle} />
        {/* </div> */}
        <ErrorMessage name={name} component={InputErrorMessage} />
      </div>
    </div>
  );
}
