import { put, take, takeLatest } from "redux-saga/effects";
import Transport from "../Transport/AidarTransport";
import domainCF from "../Components/API/domainCF";
import { getAidiHrPayload, getAidiHrPayloadFailure, getAidiHrPayloadSuccess, getAidiBrPayload, getAidiBrPayloadFailure, getAidiBrPayloadSuccess } from "../Actions";
import { setShowAidiValidationLoader, setShowPatientAidiLoader } from "../Actions/UtilActions";
import * as aidiActions from "../Actions/AIDIActions";
import { useSelector } from "react-redux";
import { getSelectedPatientBaselinePayload } from "../Reducer/selectors/AIDISelector";

function* getAidiHrPayloadSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const GET_AIDI_HR_PAYLOAD_URL = `${domainCF}/getHRAidiRecordBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

    try {
        const response = yield Transport.genericOperation(GET_AIDI_HR_PAYLOAD_URL, headers, {}, "GET");
        console.log("GET_AIDI_HR_PAYLOAD Saga response: ", response);
        if (!response) {
            yield put(getAidiHrPayloadFailure());
        } else {
            yield put(getAidiHrPayloadSuccess(response.ar8arrays));
        }
    } catch (e) {
        console.error("GET_AIDI_HR_PAYLOAD Saga ERROR ", e);
        throw e;
    }
};

function* getAidiBrPayloadSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const GET_AIDI_BR_PAYLOAD_URL = `${domainCF}/getBRAidiRecordBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

    try {

        const response = yield Transport.genericOperation(GET_AIDI_BR_PAYLOAD_URL, headers, {}, "GET");
        console.log("GET_AIDI_BR_PAYLOAD Saga response: ", response);
        if (!response) {
            yield put(getAidiBrPayloadFailure());
        } else {
            yield put(getAidiBrPayloadSuccess(response.ar8arrays));
        }
    } catch (e) {
        console.error("GET_AIDI_BR_PAYLOAD Saga ERROR ", e);
        throw e;
    }
};

function* getAidiBaselinePayloadSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const GET_AIDI_BASELINE_PAYLOAD_URL = `${domainCF}/api/getAllBaselinesByRange?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

    try {

        const response = yield Transport.genericOperation(GET_AIDI_BASELINE_PAYLOAD_URL, headers, {}, "GET");
        console.log("GET_AIDI_BASELINE_PAYLOAD Saga response: ", response);

        if (!response) {
            yield put(aidiActions.getAidiBaselinePayloadFailure());
        } else {
            let baselineLen = response.aidiAllBaselineArray.baselinePayload.length;
            let labels = [];
            let colors = [];
            let colors1 = [];
            // const pallete = ["#cecbd0", "#cfd4d8", "#ddcad9", "#cfcdd8", "#dec7d0", "#d3cbd1"];
            const pallete = ["#cdb4db", "#ffafcc", "#f0c39d", "#758bc5", "#69b6c3", "#d272a3"];
            for (let i = 0; i < baselineLen; i++) {
                labels.push(response.aidiAllBaselineArray.baselinePayload[i].label);
                let idx = parseInt(response.aidiAllBaselineArray.baselinePayload[i].class.toString().slice(-1));
                colors.push(pallete[idx - 1]);
                colors1.push(pallete[idx - 1]);
            };
            yield put(aidiActions.setBaselineColors(colors));
            yield put(aidiActions.setAidiColors(colors1));
            yield put(aidiActions.setBaselineLabels(labels));
            yield put(aidiActions.getAidiBaselinePayloadSuccess(response.aidiAllBaselineArray.baselinePayload));
            yield put(aidiActions.setIsThereNewBaselineRec(response.aidiAllBaselineArray.isThereANewRecommendation));
        }
        yield put(setShowPatientAidiLoader(false));
    } catch (e) {
        yield put(setShowPatientAidiLoader(false));
        console.error("GET_AIDI_BASELINE_PAYLOAD Saga ERROR ", e);
        throw e;
    }
};


function* getAidiGraphPayloadSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const GET_AIDI_GRAPH_PAYLOAD_URL = `${domainCF}/getAidiGraphPayloadByRange?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

    try {

        const response = yield Transport.genericOperation(GET_AIDI_GRAPH_PAYLOAD_URL, headers, {}, "GET");
        console.log("GET_AIDI_GRAPH_PAYLOAD Saga response: ", response);

        if (!response) {
            yield put(aidiActions.getAidiGraphPayloadFailure());
        } else {
            yield put(aidiActions.getAidiGraphPayloadSuccess(response.aidiRecordsGraph));
            if (response.aidiRecordsGraph.daily_aidi.length > 1) {
                let daily_vals = response.aidiRecordsGraph.daily_aidi;
                for (var i = 1; i < daily_vals.length; i++) {
                    if (daily_vals[i] != "") {
                        yield put(aidiActions.setIsAidiComputed(true));
                    }
                }
            } else {
                yield put(aidiActions.setIsAidiComputed(false));
            }
        }
        yield put(setShowPatientAidiLoader(false));
    } catch (e) {
        yield put(setShowPatientAidiLoader(false));
        console.error("GET_AIDI_GRAPH_PAYLOAD Saga ERROR ", e);
        throw e;
    }
};

function* getAllAidiBaselinesSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const GET_ALL_AIDI_BASELINE_URL = `${domainCF}/api/getAidiBaselinesBySubjectId/${action.payload.subjectId}`;

    try {

        const response = yield Transport.genericOperation(GET_ALL_AIDI_BASELINE_URL, headers, {}, "GET");
        console.log("GET_ALL_AIDI_BASELINE_URL Saga response: ", response);
        if (!response) {
            yield put(aidiActions.getAllAidiBaselinesFailure());
        } else {
            yield put(aidiActions.getAllAidiBaselinesSuccess(response));
        }
    } catch (e) {
        console.error("GET_ALL_AIDI_BASELINE_URL Saga ERROR ", e);
        throw e;
    }
}

function* postAidiBaselineSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const POST_AIDI_BASELINE = `${domainCF}/api/addAidiBaseline`;
    console.log("POST BASELINE PAYLOAD: ", action.payload);

    try {
        const response = yield Transport.genericOperation(POST_AIDI_BASELINE, headers, action.payload, "POST");
        console.log("POST BASELINE RESPONSE: ", response);

        if (!response) {
            yield put(aidiActions.postAidiBaselineFailure());
        } else {
            yield put(aidiActions.postAidiBaselineSuccess(response));
        }
    } catch (e) {
        console.error("POST_AIDI_BASELINE Saga ERROR ", e);
        throw e;
    }
}

function* updateAidiBaselineSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const UPDATE_AIDI_BASELINE = `${domainCF}/api/updateAidiBaseline`;

    try {
        const response = yield Transport.genericOperation(UPDATE_AIDI_BASELINE, headers, action.payload, "POST");

        if (!response) {
            yield put(aidiActions.updateAidiBaselineFailure());
        } else {
            yield put(aidiActions.updateAidiBaselineSuccess(response));
        }
    } catch (e) {
        console.error("UPDATE_AIDI_BASELINE Saga ERROR ", e);
        throw e;
    }
}

function* updateAidiBaselineStatusSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const UPDATE_AIDI_BASELINE_STATUS_URL = `${domainCF}/api/updateAidiBaselineStatus?subjectId=${action.payload.subjectId}&userId=${action.payload.userId}&accepted=${action.payload.accepted}`;

    try {
        const response = yield Transport.genericOperation(UPDATE_AIDI_BASELINE_STATUS_URL, headers, {}, "POST");
        console.log("UPDATE_AIDI_BASELINE_STATUS response: ", response);
        if (!response) {
            yield put(aidiActions.updateAidiBaselineStatusFailure());
        } else {
            yield put(aidiActions.updateAidiBaselineSuccess(response));
        }
    } catch (e) {
        console.error("UPDATE_AIDI_BASELINE_STATUS Saga ERROR ", e);
        throw e;
    }
}

function* validateAidiBaselineSaga(action) {
    let baselines = action.payload.baselines;
    let baselineColors = action.payload.baselineColors;
    let baselineLabels = action.payload.baselineLabels;

    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const VALIDATE_AIDI_BASELINE_URL = `${domainCF}/api/validateAidiBaseline?subjectId=${action.payload.subjectId}&userId=${action.payload.userId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

    try {
        const response = yield Transport.genericOperation(VALIDATE_AIDI_BASELINE_URL, headers, {}, "POST");
        console.log("VALIDATE_AIDI_BASELINE response: ", response);
        if (!response) {

        } else {
            if (response.success) {
                yield put(aidiActions.validateAidiBaselineSuccess("Success!"));
                yield put(aidiActions.setIsAidiValidating(false));
                yield put(aidiActions.setIsAddBaselineModeDone(false));
                yield put(aidiActions.setIsAddBaselineMode(false));
            } else {
                baselines.pop();
                baselineColors.pop();
                baselineLabels.pop();
                yield put(aidiActions.getAidiBaselinePayloadSuccess(baselines));
                yield put(aidiActions.setBaselineColors(baselineColors));
                yield put(aidiActions.setBaselineLabels(baselineLabels));
                yield put(aidiActions.validateAidiBaselineFailure(response.message));
            }
        }
        yield put(setShowAidiValidationLoader(false));
    } catch (e) {
        yield put(setShowAidiValidationLoader(false));
        console.error("UPDATE_AIDI_BASELINE_STATUS Saga ERROR ", e);
        throw e;
    }
}

function* getAidiRecordTableSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const GET_AIDI_RECORD_TABLE_PAYLOAD_URL = `${domainCF}/api/getAidiRecordBySubjectIdWithRange?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

    try {
        const response = yield Transport.genericOperation(GET_AIDI_RECORD_TABLE_PAYLOAD_URL, headers, {}, "GET");
        console.log("GET_AIDI_RECORD_TABLE_PAYLOAD Saga response: ", response);
        if (!response) {
            yield put(aidiActions.getAidiRecordByRangeTableFailure());
        } else {
            yield put(aidiActions.getAidiRecordByRangeTableSuccess(response.aidiRecordTablearrays));
        }
    } catch (e) {
        console.error("GET_AIDI_RECORD_TABLE_PAYLOAD Saga ERROR ", e);
        throw e;
    }
};


export default function* AIDISaga() {

    try {
        yield takeLatest(getAidiHrPayload, getAidiHrPayloadSaga);
        yield takeLatest(getAidiBrPayload, getAidiBrPayloadSaga);
        yield takeLatest(aidiActions.getAllAidiBaselines, getAllAidiBaselinesSaga);
        yield takeLatest(aidiActions.postAidiBaseline, postAidiBaselineSaga);
        yield takeLatest(aidiActions.updateAidiBaseline, updateAidiBaselineSaga);
        yield takeLatest(aidiActions.getAidiBaselinePayload, getAidiBaselinePayloadSaga);
        yield takeLatest(aidiActions.updateAidiBaselineStatus, updateAidiBaselineStatusSaga);
        yield takeLatest(aidiActions.validateAidiBaseline, validateAidiBaselineSaga);
        yield takeLatest(aidiActions.getAidiRecordByRangeTable, getAidiRecordTableSaga);
        yield takeLatest(aidiActions.getAidiGraphPayload, getAidiGraphPayloadSaga);
    } catch {

    }
}