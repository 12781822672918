import React from "react";
import styles from "./BaselineRanges.module.css";

export default function BaselineRange(props) {
  // console.log("||||||||||||||||||||||||||", props);
  // console.log("|||||||||||||||||||||||||| High MAx", props.ranges.highMax);
  return (
    <div className={styles.rangeCard}>
      {/* High Risk Range */}
      <div className={styles.rangeContainer}>
        <div className={styles.rangeTitleHigh}>High Risk</div>
        <div className={styles.rangeWrapper}>
          <div className={styles.highRangeMIN}>
            {props.ranges.highMin.toFixed(2)}
          </div>
          <div className={styles.toIndicator}>-</div>
          <div className={styles.highRangeMAX}>
            {props.ranges.highMax.toFixed(2)}
          </div>
        </div>
      </div>

      {/* Moderate Risk Range */}
      <div className={styles.rangeContainer}>
        <div className={styles.rangeTitleMod}>Mod Risk</div>
        <div className={styles.rangeWrapper}>
          <div className={styles.modRangeMIN}>
            {props.ranges.moderateRisk.lower.minimum.toFixed(2)}
          </div>
          <div className={styles.toIndicator}>-</div>
          <div className={styles.modRangeMAX}>
            {props.ranges.moderateRisk.upper.maximum.toFixed(2)}
          </div>
        </div>
      </div>

      {/* Normal Risk Range */}
      <div className={styles.rangeContainer}>
        <div className={styles.rangeTitleNormal}>Normal Risk</div>
        <div className={styles.rangeWrapper}>
          <div className={styles.normalRangeMIN}>
            {props.ranges.normalMin.toFixed(2)}
          </div>
          <div className={styles.toIndicator}>-</div>
          <div className={styles.normalRangeMAX}>
            {props.ranges.normalMax.toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
}
