import React from "react";
import QuickViewVitals from "../QuickViewVitals/QuickViewVitals";
import { BsExclamationCircle } from "react-icons/bs";
import Grid from "@material-ui/core/Grid";
import styles from "../QuickViewVitals/QuickViewVitals.module.css";
import { useSelector } from "react-redux";
import { getUserRole } from "../../../../../Reducer/selectors/DashboardSelector";
import { globalConstants } from "../../../../../Constants/GlobalConstants";
import QuickViewVitalsPH from "../QuickViewVitalsPhysicianView/QuickViewVitalsPH";

export default function QuickViewWrapper(props) {
  // console.log(
  //   "=======================================",
  //   props.vitals
  // );
  // console.log(
  //   "=======================================",
  //   props.thresholds
  // );

  const isVitals = (props.vitals.length > 0) ? true : false;
  const isRecordId = isVitals ? props.vitals[0].recordId : false;
  const userRole = useSelector(getUserRole);

  const patientVitalsTable = props.vitals.map((allVitals) => (
    <QuickViewVitals
      key={allVitals.recordId}
      mlId={allVitals.deviceId}
      timestamp={allVitals.timestamp}
      temp={allVitals.temperature.value}
      spo2={allVitals.spo2.value}
      breathingRate={allVitals.breathingRate.value}
      pulseRate={allVitals.pulseRate.value}
      heartRate={allVitals.heartRate.value}
      hrv={allVitals.hrv.value}
      sbp={allVitals.sbp.value}
      dbp={allVitals.dbp.value}
      fvc={allVitals.fvc.value}
      fev1={allVitals.fev1.value}
      pef={allVitals.pef.value}
      weight={allVitals.weight}
      glucose={allVitals.glucose}
      //   tempRisk={1}
      //   spo2Risk={spo2Risk}
      //   breathingRateRisk={breathingRateRisk}
      //   pulseRateRisk={pulseRateRisk}
      //   heartRateRisk={heartRateRisk}
      //   hrvRisk={hrvRisk}
      //   bpRisk={bpRisk}
      //   fvcRisk={fvcRisk}
      //   fev1Risk={fev1Risk}
      //   pefRisk={pefRisk}
      recordId={allVitals.recordId}
    />
  ));

  const patientVitalsTablePH = props.vitals.map((allVitals) => {

    const tempRisk = ((props.thresholds && props.thresholds.tempHigh && (allVitals.temperature.value > props.thresholds.tempHigh)) ||
      (props.thresholds && props.thresholds.tempLow && (allVitals.temperature.value < props.thresholds.tempLow))) ? 1 : 0;
    const spo2Risk = ((props.thresholds && props.thresholds.spo2High && (allVitals.spo2.value > props.thresholds.spo2High)) ||
      (props.thresholds && props.thresholds.spo2Low && (allVitals.spo2.value < props.thresholds.spo2Low))) ? 1 : 0;
    const breathingRateRisk = ((props.thresholds && props.thresholds.breathingRateHigh && (allVitals.breathingRate.value > props.thresholds.breathingRateHigh)) ||
      (props.thresholds && props.thresholds.breathingRateLow && (allVitals.breathingRate.value < props.thresholds.breathingRateLow))) ? 1 : 0;
    const pulseRateRisk = ((props.thresholds && props.thresholds.pulseRateHigh && (allVitals.pulseRate.value > props.thresholds.pulseRateHigh)) ||
      (props.thresholds && props.thresholds.pulseRateLow && (allVitals.pulseRate.value < props.thresholds.pulseRateLow))) ? 1 : 0;
    const heartRateRisk = ((props.thresholds && props.thresholds.heartRateHigh && (allVitals.heartRate.value > props.thresholds.heartRateHigh)) ||
      (props.thresholds && props.thresholds.heartRateLow && (allVitals.heartRate.value < props.thresholds.heartRateLow))) ? 1 : 0;
    const hrvRisk = ((props.thresholds && props.thresholds.hrvHigh && (allVitals.hrv.value > props.thresholds.hrvHigh)) ||
      (props.thresholds && props.thresholds.hrvLow && (allVitals.hrv.value < props.thresholds.hrvLow))) ? 1 : 0;
    const sbpRisk = ((props.thresholds && props.thresholds.sbpHigh && (allVitals.sbp.value > props.thresholds.sbpHigh)) ||
      (props.thresholds && props.thresholds.sbpLow && (allVitals.sbp.value < props.thresholds.sbpLow))) ? 1 : 0;
    const dbpRisk = ((props.thresholds && props.thresholds.dbpHigh && (allVitals.dbp.value > props.thresholds.dbpHigh)) ||
      (props.thresholds && props.thresholds.dbpLow && (allVitals.dbp.value < props.thresholds.dbpLow))) ? 1 : 0;
    // const fvcRisk = ((props.thresholds.fvcHigh && (allVitals.fvc.value > props.thresholds.fvcHigh)) || 
    // (props.thresholds.fvcLow && (allVitals.fvc.value < props.thresholds.fvcLow))) ? 1 : 0;
    const fev1Risk = ((props.thresholds && props.thresholds.fev1High && (allVitals.fev1.value > props.thresholds.fev1High)) ||
      (props.thresholds && props.thresholds.fev1Low && (allVitals.fev1.value < props.thresholds.fev1Low))) ? 1 : 0;
    const pefRisk = ((props.thresholds && props.thresholds.pefHigh && (allVitals.pef.value > props.thresholds.pefHigh)) ||
      (props.thresholds && props.thresholds.pefLow && (allVitals.pef.value < props.thresholds.pefLow))) ? 1 : 0;
    const bpRisk = sbpRisk || dbpRisk ? 1 : 0;

    return (<QuickViewVitalsPH
      key={allVitals.recordId}
      mlId={allVitals.deviceId}
      timestamp={allVitals.timestamp}
      temp={allVitals.temperature.value}
      spo2={allVitals.spo2.value}
      breathingRate={allVitals.breathingRate.value}
      pulseRate={allVitals.pulseRate.value}
      heartRate={allVitals.heartRate.value}
      hrv={allVitals.hrv.value}
      sbp={allVitals.sbp.value}
      dbp={allVitals.dbp.value}
      fvc={allVitals.fvc.value}
      fev1={allVitals.fev1.value}
      pef={allVitals.pef.value}
      tempRisk={tempRisk}
      spo2Risk={spo2Risk}
      breathingRateRisk={breathingRateRisk}
      pulseRateRisk={pulseRateRisk}
      heartRateRisk={heartRateRisk}
      hrvRisk={hrvRisk}
      bpRisk={bpRisk}
      //  dbpRisk={allVitals.dbp.risk}
      //  fvcRisk={allVitals.fvc.risk}
      fev1Risk={fev1Risk}
      pefRisk={pefRisk}
      weight={allVitals.weight}
      glucose={allVitals.glucose}
      painLevel={allVitals.painLevel}
      recordId={allVitals.recordId}
    />
    )
  });

  return (
    <div style={{ backgroundColor: `#F3E8EE`, height: `100%` }}>
      {isRecordId ? patientVitalsTablePH : (
        <Grid
          container
          spacing={4}
          // justify="center"
          style={{ padding: 20 }}
        >
          <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
            <div className={styles.cardHighRisk}>
              <div className={styles.iconDivStyles}>
                <BsExclamationCircle
                  className={styles.highRiskIcon}
                  size={50}
                />
              </div>
              <div className={styles.countDivStyles}>
                <div className={styles.patientCountHighRisk}>
                  No measurements found
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
