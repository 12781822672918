import React from "react";
import { Field, ErrorMessage } from "formik";

import styles from "./TextArea.module.css";
import InputErrorMessage from "../InputError/InputErrorMessage";

export default function TextArea(props) {
  const { label, name, ...rest } = props;
  return (
    <div>
      <label htmlFor={name} className={styles.labelDivStyles}>
        <div className={styles.labelStyles}>{label}</div>
      </label>
      <Field
        as="textarea"
        id={name}
        name={name}
        {...rest}
        className={styles.inputStyle}
      />
      <ErrorMessage name={name} component={InputErrorMessage} />
    </div>
  );
}
