import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AccountContext } from "../Components/Store/AuthenticationStore/Account";

export default function ProtectedRoutes({ children, ...rest }) {
  const { isUserAuthenticated } = useContext(AccountContext);

  let authToken = sessionStorage.getItem("tokenAccessJWT");

  if (!authToken) {
    return <Navigate to="/authentication" replace />;
  }
  return children;
}
