import styles from "./FacilityInfoReview.module.css";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Select from 'react-dropdown-select';
import { Calendar } from "react-date-range";
import { addDays, format } from "date-fns";
import { setPRFacilityInfo, setRegistrationStatus } from "../../../../Actions/UtilActions";
import { useDispatch, useSelector } from "react-redux";
import { getPRFacilityInfo, getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import { getFacilityInfoSelector } from "../../../../Reducer/selectors/PatientSelector";
import { useNavigate } from "react-router-dom";
import { getSelectedProjectIdAndLocationId } from "../../../../Reducer/selectors/DashboardSelector";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Input } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import IconButton from "@mui/material/IconButton";
import Backdrop from '@mui/material/Backdrop';
import Modal from "@material-ui/core/Modal";
import logoutImg from '../../../../Assets/Images/Logout.png';
import Grid from "@material-ui/core/Grid";

const FacilityInfoReview = forwardRef((props, ref) => {

    const handleFocus = (event) => event.target.select();

    const dispatch = useDispatch();

    const curRegStatus = useSelector(getRegistrationStatus);

    const facilityInfo = useSelector(getFacilityInfoSelector);

    const facilityTabInfo = useSelector(getPRFacilityInfo);

    const facilityName = facilityInfo.name ? facilityInfo.name : 'Static Text';
    const facilityNum = facilityInfo.number ? facilityInfo.number : 'Static Text';
    const facilityType = facilityInfo.type ? facilityInfo.type : 'Static Text';

    const [bedNum, setBedNum] = useState(facilityTabInfo.bedNum);
    const [actCode, setActCode] = useState([{ value: facilityTabInfo.actCode, label: facilityTabInfo.actCode }]);
    const [unit, setUnit] = useState(facilityTabInfo.unit);
    const [floor, setFloor] = useState(facilityTabInfo.floor);
    const [roomNum, setRoomNum] = useState(facilityTabInfo.roomNum);
    const [admDate, setAdmDate] = useState(new Date(facilityTabInfo.admDateObj));
    const [barcode, setBarcode] = useState(facilityTabInfo.barcode);

    const [isCalOpen, setIsCalOpen] = useState(false);

    const refOne = useRef(null);

    const [showReqWarn, setShowReqWarn] = useState(false);

    const [isActCodeFill, setIsActCodeFill] = useState(true);
    const [isFloorFill, setIsFloorFill] = useState(true);
    const [isRoomNumFill, setIsRoomNumFill] = useState(true);
    const [isUnitFill, setIsUnitFill] = useState(true);
    const [isBedNumFill, setIsBedNumFill] = useState(true);
    const [isAdmDateFill, setIsAdmDateFill] = useState(true);

    const [cancelRegistration, setCancelRegistration] = useState(false);

    const [isFacilityFormEditable, setIsFacilityFormEditable] = useState(false);

    const editFacilityForm = (event) => {
        event.preventDefault();
        setIsFacilityFormEditable(true);
    };

    const resetReqFills = () => {
        setIsActCodeFill(true);
        setIsFloorFill(true);
        setIsRoomNumFill(true);
        setIsUnitFill(true);
        setIsBedNumFill(true);
        setIsAdmDateFill(true);
    };

    const clearAllFacilityFields = () => {
        setBedNum('');
        setActCode([]);
        setUnit([]);
        setFloor('');
        setRoomNum('');
        setAdmDate('');
        resetReqFills();
    };

    useImperativeHandle(ref, () => {
        return {
            clearAllFacilityFields: clearAllFacilityFields,
        };
    });

    const optionsActCode = [
        { value: 'Initial Admission (IA)', label: 'Initial Admission (IA)' },
        { value: 'Readmission (RA)', label: 'Readmission (RA)' },
        { value: 'Respite - Initial Admission (RIA)', label: 'Respite - Initial Admission (RIA)' },
        { value: 'Respite - ReAdmission (RRA)', label: 'Respite - ReAdmission (RRA)' },
    ];

    const optionsUnit = [
        { value: 'North', label: 'North' },
        { value: 'South', label: 'South' },
        { value: 'East', label: 'East' },
        { value: 'West', label: 'West' },
    ];

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);
    }, []);

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            handleCalClick(e);
        }
    };

    // Hide dropdown on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            handleCalClick(e);
        }
    };

    const handleCalClick = (event) => {
        if (isCalOpen) {
            setIsCalOpen(false);
        } else {
            setIsCalOpen(true);
        }
    };

    const handleCalClickCancel = (event) => {
        setIsCalOpen(false);
    };

    const markStepCompleted = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: curRegStatus.step2,
            step3: curRegStatus.step3,
            step4: curRegStatus.step4,
            step5: true,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
    };

    const setTabInfo = () => {
        const payload = {
            facilityName: facilityName,
            facilityNum: facilityNum,
            facilityType: facilityType,
            bedNum: bedNum,
            actCode: actCode[0] ? actCode[0].value : '',
            unit: unit,
            floor: floor,
            roomNum: roomNum,
            admDate: format(admDate, "yyyy-MM-dd"),
            barcode: barcode,
            admDateObj: admDate,
        };
        console.log("facilityInfo payload: ", payload);
        dispatch(setPRFacilityInfo(payload));
    };

    const checkIfPageConditionsMet = () => {
        if ((floor !== '') && (roomNum !== '') && (unit !== '') && (bedNum !== '') && (admDate !== '') && (actCode[0] && actCode[0].value !== '')) {
            console.log('conditions met!');
            resetReqFills();
            setShowReqWarn(false);
            return true;
        } else {
            console.log('conditions not met!');
            resetReqFills();
            if (floor === '') {
                setIsFloorFill(false);
            };
            if (roomNum === '') {
                setIsRoomNumFill(false);
            };
            if (unit === '') {
                setIsUnitFill(false);
            };
            if (bedNum === '') {
                setIsBedNumFill(false);
            };
            if (!actCode[0] || actCode[0].value === '') {
                setIsActCodeFill(false);
            };
            if (admDate === '') {
                setIsAdmDateFill(false);
            }
            setShowReqWarn(true);
            return false;
        }
    };

    const handleSave = () => {
        if (checkIfPageConditionsMet()) {
            setTabInfo();
            setIsFacilityFormEditable(false);
        }
    };

    const navigate = useNavigate();
    const projLoc = useSelector(getSelectedProjectIdAndLocationId);
    const selectedProjectId = projLoc.selectedProjectId;
    const selectedLocationId = projLoc.selectedLocationId;

    const handleBackCLick = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: curRegStatus.step2,
            step3: curRegStatus.step3,
            step4: false,
            step5: false,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
        props.tabChange("4");

        //navigate('/subjects/' + selectedProjectId + '/' + selectedLocationId);
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    const closeCancelRegistration = () => {
        setCancelRegistration(false);
    };

    const handleCancelRegistration = () => {
        setCancelRegistration(true);
    };

    const handleCancelRegistrationSuccess = () => {
        const payload = {
            step1: false,
            step2: false,
            step3: false,
            step4: false,
            step5: false,
            review: false,
        }
        dispatch(setRegistrationStatus(payload));
        clearAllFacilityFields();
        navigate('/subjects/' + selectedProjectId + '/' + selectedLocationId);
    };

    const handleCancel = () => {
        setIsFacilityFormEditable(false);
    };

    return (
        <div className={styles.rootDiv}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={cancelRegistration}
            ></Backdrop>
            <div className={styles.containerDiv}>
                <div className={styles.dumDiv}>
                    <div className={styles.headerDiv}>
                        <div></div>
                        <div>Facility Information</div>
                        <div className={styles.editButtonDiv}>
                            <div className={!isFacilityFormEditable ? styles.editButton : styles.editButtonClicked} onClick={event => editFacilityForm(event)}>Edit</div>
                        </div>
                    </div>
                    <div className={styles.contentDiv}>
                        <div className={styles.column1}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Facility Name
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={facilityName} disabled className={styles.inputFieldSelcted1} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Facility Number
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="number" value={facilityNum} disabled className={styles.inputFieldSelcted1} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Facility Type
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={facilityType} disabled className={styles.inputFieldSelcted1} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column2}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Action Code <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={isActCodeFill ? styles.dropdown2 : styles.dropdownReq2}
                                        values={actCode}
                                        onChange={setActCode}
                                        options={optionsActCode}
                                        color="#4E253A"
                                        disabled={!isFacilityFormEditable}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Admission Date (YYYY-MM-DD) <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <Input
                                        value={admDate === '' ? `` : `${format(admDate, "yyyy-MM-dd")}`}
                                        readOnly
                                        className={isFacilityFormEditable ? isAdmDateFill ? styles.inputFieldSelctedDOB : styles.inputFieldSelctedDOBReq : styles.inputFieldSelctedDOBUE}
                                        onClick={(event) => handleCalClick(event)}
                                        disableUnderline={true}
                                        disabled={!isFacilityFormEditable}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(event) => handleCalClick(event)}
                                                >
                                                    <CalendarTodayIcon sx={{ color: "#4E253A", height: "20px !important", weight: "20px !important" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {isCalOpen && (
                                        <div className={styles.datePickerWrapper} ref={refOne}>
                                            <Calendar
                                                onChange={item => setAdmDate(item)}
                                                editableDateInputs={false}
                                                showSelectionPreview={true}
                                                months={1}
                                                date={admDate}
                                                direction="horizontal"
                                                maxDate={addDays(new Date(), 1)}
                                                color={["#aa346f"]}
                                                disabled={!isFacilityFormEditable}
                                            />
                                            <div className={styles.applyButtonDiv}>
                                                <div className={styles.cancelButton} onClick={(event) => handleCalClickCancel(event)}> Cancel </div>
                                                <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Confirm </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Barcode
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" disabled={!isFacilityFormEditable} value={barcode} onChange={(e) => setBarcode(e.currentTarget.value)} className={isFacilityFormEditable ? styles.inputFieldSelcted : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column3}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Floor <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" disabled={!isFacilityFormEditable} value={floor} onChange={(e) => setFloor(e.currentTarget.value)} className={isFacilityFormEditable ? isFloorFill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Room Number <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" disabled={!isFacilityFormEditable} value={roomNum} onChange={(e) => setRoomNum(e.currentTarget.value)} className={isFacilityFormEditable ? isRoomNumFill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column4}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Unit/Wing/Zone <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" disabled={!isFacilityFormEditable} value={unit} onChange={(e) => setUnit(e.currentTarget.value)} className={isFacilityFormEditable ? isUnitFill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Bed Number <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" disabled={!isFacilityFormEditable} value={bedNum} onChange={(e) => setBedNum(e.currentTarget.value)} className={isFacilityFormEditable ? isBedNumFill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq : styles.inputFieldSelctedUE} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.divFooter}>
                        {showReqWarn && <div className={styles.consentWarningDiv}>
                            Please fill out the required fields marked with an *
                        </div>}
                        {isFacilityFormEditable && (<div className={styles.pageButtonsDiv}>
                            {/* <div className={styles.cancelButton1} onClick={handleCancel}>Cancel</div> */}
                            <div className={styles.saveButton} onClick={handleSave}>Save</div>
                        </div>)}
                    </div>
                </div>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={cancelRegistration}
                onClose={closeCancelRegistration}
            >
                <div className={styles.paper}>
                    <div className={styles.menuTitle}>Cancel Registration</div>

                    <div className={styles.patientListDiv}>
                        <Grid container>
                            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                                <div className={styles.userQuestionMessage}>All saved changes will be lost. Are you sure you want to exit?</div>

                                <div className={styles.mouthLabImgDiv}>
                                    <img src={logoutImg} loading="lazy" className={styles.mlImg} />
                                </div>

                                <div className={styles.buttonContainer}>
                                    <button type="button" onClick={closeCancelRegistration} className={styles.cancelButtonModal}>
                                        No
                                    </button>
                                    <button type="submit" onClick={handleCancelRegistrationSuccess} className={styles.submitButtonModal}>
                                        Yes
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Modal>

        </div>
    );
});

export default FacilityInfoReview;