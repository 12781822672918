import { createAction } from "@reduxjs/toolkit";
import { aidiConstants } from "../Constants/AIDIConstants";

export const getAidiHrPayload = createAction(
    aidiConstants.GET_AIDI_HR_PAYLOAD,
    (payload) => ({ payload })
);

export const getAidiHrPayloadSuccess = createAction(
    aidiConstants.GET_AIDI_HR_PAYLOAD_SUCCESS,
    (payload) => ({ payload })
);

export const getAidiHrPayloadFailure = createAction(aidiConstants.GET_AIDI_HR_PAYLOAD_FAILURE);

export const getAidiBrPayload = createAction(
    aidiConstants.GET_AIDI_BR_PAYLOAD,
    (payload) => ({ payload })
);

export const getAidiBrPayloadSuccess = createAction(
    aidiConstants.GET_AIDI_BR_PAYLOAD_SUCCESS,
    (payload) => ({ payload })
);

export const getAidiBrPayloadFailure = createAction(aidiConstants.GET_AIDI_BR_PAYLOAD_FAILURE);

export const getAidiBaselinePayload = createAction(
    aidiConstants.GET_AIDI_BASELINE_PAYLOAD,
    (payload) => ({ payload })
);

export const getAidiBaselinePayloadSuccess = createAction(
    aidiConstants.GET_AIDI_BASELINE_PAYLOAD_SUCCESS,
    (payload) => ({ payload })
);

export const getAidiBaselinePayloadFailure = createAction(aidiConstants.GET_AIDI_BASELINE_PAYLOAD_FAILURE);

export const setNewBaselineSD = createAction(
    aidiConstants.SET_NEW_BASELINE_SD,
    (payload) => ({ payload })
);

export const setNewBaselineED = createAction(
    aidiConstants.SET_NEW_BASELINE_ED,
    (payload) => ({ payload })
);

export const setIsAddBaselineMode = createAction(
    aidiConstants.SET_IS_ADD_BASELINE_MODE,
    (payload) => ({ payload })
);

export const setIsAddBaselineModeDone = createAction(
    aidiConstants.SET_IS_ADD_BASELINE_MODE_DONE,
    (payload) => ({ payload })
);

// Aidi Baselines
export const getAllAidiBaselines = createAction(
    aidiConstants.GET_ALL_AIDI_BASELINES,
    (payload) => ({ payload })
);

export const getAllAidiBaselinesSuccess = createAction(
    aidiConstants.GET_ALL_AIDI_BASELINES_SUCCESS,
    (payload) => ({ payload })
);

export const postAidiBaseline = createAction(
    aidiConstants.POST_AIDI_BASELINE,
    (payload) => ({ payload })
);

export const postAidiBaselineSuccess = createAction(
    aidiConstants.POST_AIDI_BASELINE_SUCCESS,
    (payload) => ({ payload })
);

export const updateAidiBaseline = createAction(
    aidiConstants.UPDATE_AIDI_BASELINE,
    (payload) => ({ payload })
);

export const updateAidiBaselineSuccess = createAction(
    aidiConstants.UPDATE_AIDI_BASELINE_SUCCESS,
    (payload) => ({ payload })
);

export const getAllAidiBaselinesFailure = createAction(aidiConstants.GET_ALL_AIDI_BASELINES_FAILURE);
export const postAidiBaselineFailure = createAction(aidiConstants.POST_AIDI_BASELINE_FAILURE);
export const updateAidiBaselineFailure = createAction(aidiConstants.UPDATE_AIDI_BASELINE);

export const setSelectedAIDITab = createAction(
    aidiConstants.SET_SELECTED_AIDI_TAB_ID,
    (payload) => ({ payload })
);

export const setIsThereNewBaselineRec = createAction(
    aidiConstants.SET_IS_THERE_NEW_BASELINE_REC,
    (payload) => ({ payload })
);

export const updateAidiBaselineStatus = createAction(
    aidiConstants.UPDATE_AIDI_BASELINE_STATUS,
    (payload) => ({ payload })
);

export const updateAidiBaselineStatusSuccess = createAction(
    aidiConstants.UPDATE_AIDI_BASELINE_STATUS_SUCCESS,
    (payload) => ({ payload })
);

export const updateAidiBaselineStatusFailure = createAction(
    aidiConstants.UPDATE_AIDI_BASELINE_STATUS_FAILURE
);

export const setIsAidiValidating = createAction(
    aidiConstants.SET_IS_AIDI_VALIDATING,
    (payload) => ({ payload })
);

export const validateAidiBaseline = createAction(
    aidiConstants.VALIDATE_AIDI_BASELINE,
    (payload) => ({ payload })
);

export const validateAidiBaselineSuccess = createAction(
    aidiConstants.VALIDATE_AIDI_BASELINE_SUCCESS,
    (payload) => ({ payload })
);

export const validateAidiBaselineFailure = createAction(
    aidiConstants.VALIDATE_AIDI_BASELINE_FAILURE
);

export const setValidationMessage = createAction(
    aidiConstants.SET_VALIDATION_MESSAGE,
    (payload) => ({ payload })
);

// Aidi Record Table
export const getAidiRecordByRangeTable = createAction(
    aidiConstants.GET_AIDI_RECORD_TABLE,
    (payload) => ({ payload })
);

export const getAidiRecordByRangeTableSuccess = createAction(
    aidiConstants.GET_AIDI_RECORD_TABLE_SUCCESS,
    (payload) => ({ payload })
);

export const getAidiRecordByRangeTableFailure = createAction(aidiConstants.GET_AIDI_RECORD_TABLE_FAILURE);

export const getAidiGraphPayload = createAction(
    aidiConstants.GET_AIDI_GRAPH_PAYLOAD,
    (payload) => ({ payload })
);

export const getAidiGraphPayloadSuccess = createAction(
    aidiConstants.GET_AIDI_GRAPH_PAYLOAD_SUCCESS,
    (payload) => ({ payload })
);

export const getAidiGraphPayloadFailure = createAction(aidiConstants.GET_AIDI_GRAPH_PAYLOAD_FAILURE);

export const setIsAidiComputed = createAction(
    aidiConstants.SET_IS_AIDI_COMPUTED,
    (payload) => ({ payload })
);

export const setBaselineLabels = createAction(
    aidiConstants.SET_BASELINE_LABELS,
    (payload) => ({ payload })
);

export const setBaselineColors = createAction(
    aidiConstants.SET_BASELINE_COLORS,
    (payload) => ({ payload })
);

export const setAidiColors = createAction(
    aidiConstants.SET_AIDI_COLORS,
    (payload) => ({ payload })
);

export const setAidiDateRange = createAction(
    aidiConstants.SET_AIDI_DATE_RANGE,
    (payload) => ({ payload })
);