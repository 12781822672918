import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getAidiColorsSelector, getBaselineLabelsSelector, getIsAidiComputedSelector, getIsThereNewBaselineRec } from "../../../../Reducer/selectors/AIDISelector";
import styles from "./AIDIGraphWrapper.module.css";
import RenderAIDIGraph from "./Graphs/RenderAIDIGraph";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from "@material-ui/core";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import RenderAIDIGraphCollapsed from "./Graphs/RenderAIDIGraphCollapsed";

export default function AIDIGraphWrapper() {

    const [isExpandedView, setIsExpandedView] = useState(true);

    const isAidiComputed = useSelector(getIsAidiComputedSelector);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    const aidiColors = useSelector(getAidiColorsSelector);
    const aidiLabels = useSelector(getBaselineLabelsSelector);

    const isThereANewBaselineRec = useSelector(getIsThereNewBaselineRec);

    const generateAidiLegends = () => {

        const baselineColorsLen = aidiColors.length;
        const baselineLabelsLen = aidiLabels.length;

        if (!isThereANewBaselineRec) {
            return (
                <>
                    {(baselineColorsLen > 0 && baselineLabelsLen > 0) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: aidiColors[0] }}>{"B" + aidiLabels[0].slice(9)}</div>{aidiLabels[0]}
                    </div>)}

                    {(baselineColorsLen > 1 && baselineLabelsLen > 1) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: aidiColors[1] }}>{"B" + aidiLabels[1].slice(9)}</div>{aidiLabels[1]}
                    </div>)}

                    {(baselineColorsLen > 2 && baselineLabelsLen > 2) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: aidiColors[2] }}>{"B" + aidiLabels[2].slice(9)}</div>{aidiLabels[2]}
                    </div>)}

                    {(baselineColorsLen > 3 && baselineLabelsLen > 3) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: aidiColors[3] }}>{"B" + aidiLabels[3].slice(9)}</div>{aidiLabels[3]}
                    </div>)}

                    {(baselineColorsLen > 4 && baselineLabelsLen > 4) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: aidiColors[4] }}>{"B" + aidiLabels[4].slice(9)}</div>{aidiLabels[4]}
                    </div>)}

                    {(baselineColorsLen > 5 && baselineLabelsLen > 5) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: aidiColors[5] }}>{"B" + aidiLabels[5].slice(9)}</div>{aidiLabels[5]}
                    </div>)}
                </>
            )
        } else {
            return (
                <>
                    {(baselineColorsLen > 1 && baselineLabelsLen > 1) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: aidiColors[0] }}>{"B" + aidiLabels[0].slice(9)}</div>{aidiLabels[0]}
                    </div>)}

                    {(baselineColorsLen > 2 && baselineLabelsLen > 2) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: aidiColors[1] }}>{"B" + aidiLabels[1].slice(9)}</div>{aidiLabels[1]}
                    </div>)}

                    {(baselineColorsLen > 3 && baselineLabelsLen > 3) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: aidiColors[2] }}>{"B" + aidiLabels[2].slice(9)}</div>{aidiLabels[2]}
                    </div>)}

                    {(baselineColorsLen > 4 && baselineLabelsLen > 4) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: aidiColors[3] }}>{"B" + aidiLabels[3].slice(9)}</div>{aidiLabels[3]}
                    </div>)}

                    {(baselineColorsLen > 5 && baselineLabelsLen > 5) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: aidiColors[4] }}>{"B" + aidiLabels[4].slice(9)}</div>{aidiLabels[4]}
                    </div>)}

                    {(baselineColorsLen > 6 && baselineLabelsLen > 6) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: aidiColors[5] }}>{"B" + aidiLabels[5].slice(9)}</div>{aidiLabels[5]}
                    </div>)}
                </>
            )
        }

    };

    return (
        <>
            <div className={styles.aidiGraphWrapper}>
                <div className={styles.containerHeader}>
                    <div className={styles.aidiGraphHeadingDiv}>
                        <div className={styles.aidiIconWrapper}>
                            <GraphicEqIcon style={{ color: "#4E253A", height: "18px", width: "18px" }} />
                        </div>
                        Aidar Index (AIDI) <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <div className={styles.tootlTipHeading}>Aidar Index</div>
                                    <div className={styles.circlesList}>
                                        <div className={styles.circlesListItem}><div className={styles.greenCircle}></div> 0.00 - 0.30</div>
                                        <div className={styles.circlesListItem}><div className={styles.orangeCircle}></div> 0.30 - 0.45</div>
                                        <div className={styles.circlesListItem}><div className={styles.redCircle}></div> 0.45 - 1.00</div>
                                    </div>
                                </React.Fragment>
                            }
                        >
                            <InfoOutlinedIcon color="#ffffff" style={{ height: '20px', width: '20px', fill: "#ffffff", marginRight: "5px", marginLeft: "10px" }} />
                        </HtmlTooltip>
                    </div>
                    <div className={styles.expandAidiButton}>
                        <IconButton style={{ padding: "8px" }} KeyboardArrowUpIcon onClick={() => setIsExpandedView(!isExpandedView)}> <div style={{ height: '20px', width: '20px', backgroundColor: "#ffffff", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>{isExpandedView ? (<KeyboardArrowUpIcon style={{ height: '25px', width: '25px', fill: "#4E253A" }}></KeyboardArrowUpIcon>) : (<KeyboardArrowDownIcon style={{ height: '25px', width: '25px', fill: "#4E253A" }}></KeyboardArrowDownIcon>)}</div></IconButton>
                    </div>
                </div>
                {/* <div className={styles.legendDiv}>
                    {generateAidiLegends()}
                </div> */}
                <div className={styles.graphDiv}>
                    {(isAidiComputed && isExpandedView) && <>
                        <div className={styles.colorBar}>
                            <div className={styles.redBar}>
                            </div>
                            <div className={styles.yellowBar}>
                            </div>
                            <div className={styles.greenBar}>
                            </div>
                        </div>
                    </>}
                    {isAidiComputed ? (isExpandedView ? (<div style={{ width: "100%" }}><RenderAIDIGraph /></div>) : (<div style={{ width: "100%" }}><RenderAIDIGraphCollapsed /></div>)) : (
                        <div className={styles.noDataWrapper}> 
                            <div style={{ paddingTop: "20px", paddingBottom: "20px", fontFamily: "GTWalsheimProRegular", fontSize: "22px", fontWeight: "bold" }}>
                                AIDI is not available for the selected dates & measurements
                            </div>
                            {/* <div className={styles.subMessage}>
                            NOTE: AIDI will be calculated and displayed once the patient has a minimum of 10 valid HR & RR measurements within 14 days.
                        </div> */}
                        </div>
                    )}
                </div>
            </div>
        </>

    );
}