import React from "react";
import { Field, ErrorMessage } from "formik";
import styles from "./RadioButton.module.css";
import InputErrorMessage from "../InputError/InputErrorMessage";

export default function RadioButton(props) {
  const { label, subtext, name, options, ...rest } = props;

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
        return false;
    }
  }

  return (
    <div className={styles.radioButtonContainer}>
      <label htmlFor={name} className={styles.labelDivStyle}>
        {label}
      </label>

      {/* <span htmlFor={name}>{subtext}</span> */}
      <div className={styles.wrapper}>
        <Field name={name} {...rest}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <div key={option.key} className={styles.radioWrapper}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id={option.value}
                    {...field}
                    value={option.value}
                    checked={field.value === option.value}
                    onKeyPress={handleKeyPress}
                  ></input>
                  <label
                    htmlFor={option.value}
                    className={styles.radioLabel}
                    style={{ fontSize: `18px` }}
                  >
                    {option.value}
                  </label>
                </div>
              );
            });
          }}
        </Field>
      </div>
      <ErrorMessage name={name} component={InputErrorMessage} />
    </div>
  );
}
