import GeneralInfoReview from "./GeneralInfoReview";
import PayerAndConditionReview from "./PayerAndConditionInfoReview";
import { addDays, format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ReviewAllTab.module.css";
import Select from 'react-dropdown-select';
import { Calendar } from "react-date-range";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Input } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import IconButton from "@mui/material/IconButton";
import { setRegistrationStatus, setShowPRLoader } from "../../../../Actions/UtilActions";
import { useNavigate } from "react-router-dom";
import { getSelectedProjectIdAndLocationId, getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import Backdrop from '@mui/material/Backdrop';
import Modal from "@material-ui/core/Modal";
import logoutImg from '../../../../Assets/Images/Logout.png';
import Grid from "@material-ui/core/Grid";
import { getPRCareTeamInfo, getPRFacilityInfo, getPRFamilyInfo, getPRGeneralInfo, getPRLoader, getPRMessage, getPRPayerInfo, getPRSuccess } from "../../../../Reducer/selectors/UtilSelector";
import { registerNewPatient } from "../../../../Actions";
import { RiCloseCircleFill } from "react-icons/ri";
import { CircularProgress } from "@material-ui/core";
import FacilityInfoReview from "./FacilityInfoReview";
import FamilyInfoReview from "./FamilyInfoReview";
import CareTeamInfoReview from "./CareTeamInfoReview";

export default function ReviewAllTab(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const projLoc = useSelector(getSelectedProjectIdAndLocationId);
    const selectedProjectId = projLoc.selectedProjectId;
    const selectedLocationId = projLoc.selectedLocationId;

    const generalInfo = useSelector(getPRGeneralInfo);
    const payerInfo = useSelector(getPRPayerInfo);
    const familyInfo = useSelector(getPRFamilyInfo);
    const careTeamInfo = useSelector(getPRCareTeamInfo);
    const facilityInfo = useSelector(getPRFacilityInfo);

    const profile = useSelector(getUserProfile);
    const [firstName] = useState(profile.firstName);
    const [lastName] = useState(profile.lastName);

    let username = firstName + " " + lastName;

    const [consentDate, setConsentDate] = useState('');
    const [consentProvider, setConsentProvider] = useState('');
    const [providerOfConsent, setProviderOfConsent] = useState([]);
    const [relToP, setRelToP] = useState('');
    const [isCalOpen, setIsCalOpen] = useState(false);

    const [isProviderOfConsentFill, setIsProviderOfConsentFill] = useState(true);
    const [isConsentDateFill, setIsConsentDateFill] = useState(true);

    const [cancelRegistration, setCancelRegistration] = useState(false);
    const [showRegisterNewPatientPopUp, setShowRegisterNewPatientPopUp] = useState(false);

    const prLoader = useSelector(getPRLoader);
    const prSuccess = useSelector(getPRSuccess);
    const prMessage = useSelector(getPRMessage);

    const resetReqFills = () => {
        setIsProviderOfConsentFill(true);
    };

    const refOne = useRef(null);

    const [consentSel, setConsentSel] = useState("No");

    const [showConsentWarn, setShowConsentWarn] = useState(false);

    const handleFocus = (event) => event.target.select();

    const onValueChange = (event) => {
        // Updating the state with the selected radio button's value
        setConsentSel(event.target.value);
    };

    const redirectToPatientList = () => {
        setShowRegisterNewPatientPopUp(false);
        navigate('/subjects/' + selectedProjectId + '/' + selectedLocationId);
    };

    const handleCalClick = (event) => {
        if (isCalOpen) {
            setIsCalOpen(false);
        } else {
            setIsCalOpen(true);
        }
    };

    const handleCalClickCancel = (event) => {
        setIsCalOpen(false);
    };

    const optionsProviderOfConsent = [
        { value: 'Patient', label: 'Patient' },
        { value: 'Substitute Decision Maker', label: 'Substitute Decision Maker' },
    ];

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const closeCancelRegistration = () => {
        setCancelRegistration(false);
    };

    const handleCancelRegistration = () => {
        setCancelRegistration(true);
    };

    const handleCancelRegistrationSuccess = () => {
        const payload = {
            step1: false,
            step2: false,
            step3: false,
            step4: false,
            step5: false,
            review: false,
        }
        dispatch(setRegistrationStatus(payload));
        //clearAllReviewFields();
        navigate('/subjects/' + selectedProjectId + '/' + selectedLocationId);
    };

    const getFamilyContactList = () => {
        if (familyInfo.firstNameC1 !== '' && familyInfo.firstNameC2 !== '' && familyInfo.firstNameC3 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC1 === '' ? null : familyInfo.firstNameC1,
                        lastName: familyInfo.lastNameC1 === '' ? null : familyInfo.lastNameC1,
                        prefix: familyInfo.prefixC1 === '' ? null : familyInfo.prefixC1,
                        suffix: familyInfo.suffixC1 === '' ? null : familyInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC1 === '' ? null : familyInfo.bioSexC1,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC1 === '' ? null : familyInfo.phNumC1,
                        prefCommMode: familyInfo.comModeC1 === '' ? null : familyInfo.comModeC1,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC1 === '' ? null : familyInfo.emailC1,
                    },
                    relationship: familyInfo.relC1 === '' ? null : familyInfo.relC1,
                    contactType: familyInfo.conTypeC1 === '' ? null : familyInfo.conTypeC1,
                    consent: familyInfo.consentSel1 === 'Yes' ? true : false,
                    rank: "Primary",
                },
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC2 === '' ? null : familyInfo.firstNameC2,
                        lastName: familyInfo.lastNameC2 === '' ? null : familyInfo.lastNameC2,
                        prefix: familyInfo.prefixC2 === '' ? null : familyInfo.prefixC2,
                        suffix: familyInfo.suffixC2 === '' ? null : familyInfo.suffixC2,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC2 === '' ? null : familyInfo.bioSexC2,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC2 === '' ? null : familyInfo.phNumC2,
                        prefCommMode: familyInfo.comModeC2 === '' ? null : familyInfo.comModeC2,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC2 === '' ? null : familyInfo.emailC2,
                    },
                    relationship: familyInfo.relC2 === '' ? null : familyInfo.relC2,
                    contactType: familyInfo.conTypeC2 === '' ? null : familyInfo.conTypeC2,
                    consent: familyInfo.consentSel2 === 'Yes' ? true : false,
                    rank: "Secondary",
                },
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC3 === '' ? null : familyInfo.firstNameC3,
                        lastName: familyInfo.lastNameC3 === '' ? null : familyInfo.lastNameC3,
                        prefix: familyInfo.prefixC3 === '' ? null : familyInfo.prefixC3,
                        suffix: familyInfo.suffixC3 === '' ? null : familyInfo.suffixC3,
                        middle: '',
                        prefName: '',
                        dob: '',
                        birthPlace: '',
                        gender: familyInfo.bioSexC3 === '' ? null : familyInfo.bioSexC3,
                        identity: '',
                        sexOr: '',
                        phone: familyInfo.phNumC3 === '' ? null : familyInfo.phNumC3,
                        prefCommMode: familyInfo.comModeC3 === '' ? null : familyInfo.comModeC3,
                        npi: '',
                        ssn: '',
                        email: familyInfo.emailC3 === '' ? null : familyInfo.emailC3,
                    },
                    relationship: familyInfo.relC3 === '' ? null : familyInfo.relC3,
                    contactType: familyInfo.conTypeC3 === '' ? null : familyInfo.conTypeC3,
                    consent: familyInfo.consentSel3 === 'Yes' ? true : false,
                    rank: "Tertiary",
                },
            ]);
        } else if (familyInfo.firstNameC1 !== '' && familyInfo.firstNameC2 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC1 === '' ? null : familyInfo.firstNameC1,
                        lastName: familyInfo.lastNameC1 === '' ? null : familyInfo.lastNameC1,
                        prefix: familyInfo.prefixC1 === '' ? null : familyInfo.prefixC1,
                        suffix: familyInfo.suffixC1 === '' ? null : familyInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC1 === '' ? null : familyInfo.bioSexC1,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC1 === '' ? null : familyInfo.phNumC1,
                        prefCommMode: familyInfo.comModeC1 === '' ? null : familyInfo.comModeC1,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC1 === '' ? null : familyInfo.emailC1,
                    },
                    relationship: familyInfo.relC1 === '' ? null : familyInfo.relC1,
                    contactType: familyInfo.conTypeC1 === '' ? null : familyInfo.conTypeC1,
                    consent: familyInfo.consentSel1 === 'Yes' ? true : false,
                    rank: "Primary",
                },
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC2 === '' ? null : familyInfo.firstNameC2,
                        lastName: familyInfo.lastNameC2 === '' ? null : familyInfo.lastNameC2,
                        prefix: familyInfo.prefixC2 === '' ? null : familyInfo.prefixC2,
                        suffix: familyInfo.suffixC2 === '' ? null : familyInfo.suffixC2,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC2 === '' ? null : familyInfo.bioSexC2,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC2 === '' ? null : familyInfo.phNumC2,
                        prefCommMode: familyInfo.comModeC2 === '' ? null : familyInfo.comModeC2,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC2 === '' ? null : familyInfo.emailC2,
                    },
                    relationship: familyInfo.relC2 === '' ? null : familyInfo.relC2,
                    contactType: familyInfo.conTypeC2 === '' ? null : familyInfo.conTypeC2,
                    consent: familyInfo.consentSel2 === 'Yes' ? true : false,
                    rank: "Secondary",
                },
            ]);
        } else if (familyInfo.firstNameC1 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC1 === '' ? null : familyInfo.firstNameC1,
                        lastName: familyInfo.lastNameC1 === '' ? null : familyInfo.lastNameC1,
                        prefix: familyInfo.prefixC1 === '' ? null : familyInfo.prefixC1,
                        suffix: familyInfo.suffixC1 === '' ? null : familyInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC1 === '' ? null : familyInfo.bioSexC1,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC1 === '' ? null : familyInfo.phNumC1,
                        prefCommMode: familyInfo.comModeC1 === '' ? null : familyInfo.comModeC1,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC1 === '' ? null : familyInfo.emailC1,
                    },
                    relationship: familyInfo.relC1 === '' ? null : familyInfo.relC1,
                    contactType: familyInfo.conTypeC1 === '' ? null : familyInfo.conTypeC1,
                    consent: familyInfo.consentSel1 === 'Yes' ? true : false,
                    rank: "Primary",
                },
            ]);
        } else if (familyInfo.firstNameC1 !== '' && familyInfo.firstNameC3 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC1 === '' ? null : familyInfo.firstNameC1,
                        lastName: familyInfo.lastNameC1 === '' ? null : familyInfo.lastNameC1,
                        prefix: familyInfo.prefixC1 === '' ? null : familyInfo.prefixC1,
                        suffix: familyInfo.suffixC1 === '' ? null : familyInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC1 === '' ? null : familyInfo.bioSexC1,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC1 === '' ? null : familyInfo.phNumC1,
                        prefCommMode: familyInfo.comModeC1 === '' ? null : familyInfo.comModeC1,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC1 === '' ? null : familyInfo.emailC1,
                    },
                    relationship: familyInfo.relC1 === '' ? null : familyInfo.relC1,
                    contactType: familyInfo.conTypeC1 === '' ? null : familyInfo.conTypeC1,
                    consent: familyInfo.consentSel1 === 'Yes' ? true : false,
                    rank: "Primary",
                },
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC3 === '' ? null : familyInfo.firstNameC3,
                        lastName: familyInfo.lastNameC3 === '' ? null : familyInfo.lastNameC3,
                        prefix: familyInfo.prefixC3 === '' ? null : familyInfo.prefixC3,
                        suffix: familyInfo.suffixC3 === '' ? null : familyInfo.suffixC3,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC3 === '' ? null : familyInfo.bioSexC3,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC3 === '' ? null : familyInfo.phNumC3,
                        prefCommMode: familyInfo.comModeC3 === '' ? null : familyInfo.comModeC3,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC3 === '' ? null : familyInfo.emailC3,
                    },
                    relationship: familyInfo.relC3 === '' ? null : familyInfo.relC3,
                    contactType: familyInfo.conTypeC3 === '' ? null : familyInfo.conTypeC3,
                    consent: familyInfo.consentSel3 === 'Yes' ? true : false,
                    rank: "Tertiary",
                },
            ]);
        } else {
            return ([]);
        }
    };

    const getCareTeamList = () => {
        if (careTeamInfo.firstNameC1 !== '' && careTeamInfo.firstNameC2 !== '' && careTeamInfo.firstNameC3 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC1 === '' ? null : careTeamInfo.firstNameC1,
                        lastName: careTeamInfo.lastNameC1 === '' ? null : careTeamInfo.lastNameC1,
                        prefix: careTeamInfo.prefixC1 === '' ? null : careTeamInfo.prefixC1,
                        suffix: careTeamInfo.suffixC1 === '' ? null : careTeamInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC1 === '' ? null : careTeamInfo.phNumC1,
                        prefCommMode: careTeamInfo.comModeC1 === '' ? null : careTeamInfo.comModeC1,
                        npi: careTeamInfo.npiC1 === '' ? null : careTeamInfo.npiC1,
                        ssn: null,
                        email: careTeamInfo.emailC1 === '' ? null : careTeamInfo.emailC1,
                    },
                    profession: careTeamInfo.profC1 === '' ? null : careTeamInfo.profC1,
                    speciality: careTeamInfo.specC1 === '' ? null : careTeamInfo.specC1,
                    type: 'Primary',
                },
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC2 === '' ? null : careTeamInfo.firstNameC2,
                        lastName: careTeamInfo.lastNameC2 === '' ? null : careTeamInfo.lastNameC2,
                        prefix: careTeamInfo.prefixC2 === '' ? null : careTeamInfo.prefixC2,
                        suffix: careTeamInfo.suffixC2 === '' ? null : careTeamInfo.suffixC2,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC2 === '' ? null : careTeamInfo.phNumC2,
                        prefCommMode: careTeamInfo.comModeC2 === '' ? null : careTeamInfo.comModeC2,
                        npi: careTeamInfo.npiC2 === '' ? null : careTeamInfo.npiC2,
                        ssn: null,
                        email: careTeamInfo.emailC2 === '' ? null : careTeamInfo.emailC2,
                    },
                    profession: careTeamInfo.profC2 === '' ? null : careTeamInfo.profC2,
                    speciality: careTeamInfo.specC2 === '' ? null : careTeamInfo.specC2,
                    type: 'Secondary',
                },
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC3 === '' ? null : careTeamInfo.firstNameC3,
                        lastName: careTeamInfo.lastNameC3 === '' ? null : careTeamInfo.lastNameC3,
                        prefix: careTeamInfo.prefixC3 === '' ? null : careTeamInfo.prefixC3,
                        suffix: careTeamInfo.suffixC3 === '' ? null : careTeamInfo.suffixC3,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC3 === '' ? null : careTeamInfo.phNumC3,
                        prefCommMode: careTeamInfo.comModeC3 === '' ? null : careTeamInfo.comModeC3,
                        npi: careTeamInfo.npiC3 === '' ? null : careTeamInfo.npiC3,
                        ssn: null,
                        email: careTeamInfo.emailC3 === '' ? null : careTeamInfo.emailC3,
                    },
                    profession: careTeamInfo.profC3 === '' ? null : careTeamInfo.profC3,
                    speciality: careTeamInfo.specC3 === '' ? null : careTeamInfo.specC3,
                    type: 'Tertiary',
                },
            ]);
        } else if (careTeamInfo.firstNameC1 !== '' && careTeamInfo.firstNameC2 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC1 === '' ? null : careTeamInfo.firstNameC1,
                        lastName: careTeamInfo.lastNameC1 === '' ? null : careTeamInfo.lastNameC1,
                        prefix: careTeamInfo.prefixC1 === '' ? null : careTeamInfo.prefixC1,
                        suffix: careTeamInfo.suffixC1 === '' ? null : careTeamInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC1 === '' ? null : careTeamInfo.phNumC1,
                        prefCommMode: careTeamInfo.comModeC1 === '' ? null : careTeamInfo.comModeC1,
                        npi: careTeamInfo.npiC1 === '' ? null : careTeamInfo.npiC1,
                        ssn: null,
                        email: careTeamInfo.emailC1 === '' ? null : careTeamInfo.emailC1,
                    },
                    profession: careTeamInfo.profC1 === '' ? null : careTeamInfo.profC1,
                    speciality: careTeamInfo.specC1 === '' ? null : careTeamInfo.specC1,
                    type: 'Primary',
                },
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC2 === '' ? null : careTeamInfo.firstNameC2,
                        lastName: careTeamInfo.lastNameC2 === '' ? null : careTeamInfo.lastNameC2,
                        prefix: careTeamInfo.prefixC2 === '' ? null : careTeamInfo.prefixC2,
                        suffix: careTeamInfo.suffixC2 === '' ? null : careTeamInfo.suffixC2,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC2 === '' ? null : careTeamInfo.phNumC2,
                        prefCommMode: careTeamInfo.comModeC2 === '' ? null : careTeamInfo.comModeC2,
                        npi: careTeamInfo.npiC2 === '' ? null : careTeamInfo.npiC2,
                        ssn: null,
                        email: careTeamInfo.emailC2 === '' ? null : careTeamInfo.emailC2,
                    },
                    profession: careTeamInfo.profC2 === '' ? null : careTeamInfo.profC2,
                    speciality: careTeamInfo.specC2 === '' ? null : careTeamInfo.specC2,
                    type: 'Secondary',
                },
            ]);
        } else if (careTeamInfo.firstNameC1 !== '' && careTeamInfo.firstNameC3 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC1 === '' ? null : careTeamInfo.firstNameC1,
                        lastName: careTeamInfo.lastNameC1 === '' ? null : careTeamInfo.lastNameC1,
                        prefix: careTeamInfo.prefixC1 === '' ? null : careTeamInfo.prefixC1,
                        suffix: careTeamInfo.suffixC1 === '' ? null : careTeamInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC1 === '' ? null : careTeamInfo.phNumC1,
                        prefCommMode: careTeamInfo.comModeC1 === '' ? null : careTeamInfo.comModeC1,
                        npi: careTeamInfo.npiC1 === '' ? null : careTeamInfo.npiC1,
                        ssn: null,
                        email: careTeamInfo.emailC1 === '' ? null : careTeamInfo.emailC1,
                    },
                    profession: careTeamInfo.profC1 === '' ? null : careTeamInfo.profC1,
                    speciality: careTeamInfo.specC1 === '' ? null : careTeamInfo.specC1,
                    type: 'Primary',
                },
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC3 === '' ? null : careTeamInfo.firstNameC3,
                        lastName: careTeamInfo.lastNameC3 === '' ? null : careTeamInfo.lastNameC3,
                        prefix: careTeamInfo.prefixC3 === '' ? null : careTeamInfo.prefixC3,
                        suffix: careTeamInfo.suffixC3 === '' ? null : careTeamInfo.suffixC3,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC3 === '' ? null : careTeamInfo.phNumC3,
                        prefCommMode: careTeamInfo.comModeC3 === '' ? null : careTeamInfo.comModeC3,
                        npi: careTeamInfo.npiC3 === '' ? null : careTeamInfo.npiC3,
                        ssn: null,
                        email: careTeamInfo.emailC3 === '' ? null : careTeamInfo.emailC3,
                    },
                    profession: careTeamInfo.profC3 === '' ? null : careTeamInfo.profC3,
                    speciality: careTeamInfo.specC3 === '' ? null : careTeamInfo.specC3,
                    type: 'Tertiary',
                },
            ]);
        } else if (careTeamInfo.firstNameC1 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC1 === '' ? null : careTeamInfo.firstNameC1,
                        lastName: careTeamInfo.lastNameC1 === '' ? null : careTeamInfo.lastNameC1,
                        prefix: careTeamInfo.prefixC1 === '' ? null : careTeamInfo.prefixC1,
                        suffix: careTeamInfo.suffixC1 === '' ? null : careTeamInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC1 === '' ? null : careTeamInfo.phNumC1,
                        prefCommMode: careTeamInfo.comModeC1 === '' ? null : careTeamInfo.comModeC1,
                        npi: careTeamInfo.npiC1 === '' ? null : careTeamInfo.npiC1,
                        ssn: null,
                        email: careTeamInfo.emailC1 === '' ? null : careTeamInfo.emailC1,
                    },
                    profession: careTeamInfo.profC1 === '' ? null : careTeamInfo.profC1,
                    speciality: careTeamInfo.specC1 === '' ? null : careTeamInfo.specC1,
                    type: 'Primary',
                },
            ]);
        } else {
            return ([]);
        }
    };

    const handleSubmitPR = () => {
        let finalPayload = {
            subject: {
                guid: null,
                ehr: generalInfo.ehrId === '' ? null : generalInfo.ehrId,
                registeredBy: username === '' ? null : username,
                status: null,
                projectLocation: {
                    project: {
                        id: selectedProjectId === '' ? null : selectedProjectId,
                    },
                    location: {
                        id: selectedLocationId === '' ? null : selectedLocationId,
                    },
                },
                contactInfo: {
                    firstName: generalInfo.firstName === '' ? null : generalInfo.firstName,
                    lastName: generalInfo.lastName === '' ? null : generalInfo.lastName,
                    prefix: generalInfo.prefix === '' ? null : generalInfo.prefix,
                    suffix: generalInfo.suffix === '' ? null : generalInfo.suffix,
                    middle: generalInfo.middleName === '' ? null : generalInfo.middleName,
                    prefName: generalInfo.prevName === '' ? null : generalInfo.prevName,
                    dob: generalInfo.dob === '' ? null : generalInfo.dob,
                    birthPlace: null,
                    gender: generalInfo.bioSex === '' ? null : generalInfo.bioSex,
                    identity: generalInfo.genderIdentity === '' ? null : generalInfo.genderIdentity,
                    sexOr: generalInfo.sexOrientation === '' ? null : generalInfo.sexOrientation,
                    phone: generalInfo.phNum === '' ? null : generalInfo.phNum,
                    prefCommMode: null,
                    npi: null,
                    ssn: generalInfo.ssn === '' ? null : generalInfo.ssn,
                    email: generalInfo.email === '' ? null : generalInfo.email,
                },
                address: {
                    addrLine1: generalInfo.address === '' ? null : generalInfo.address,
                    addrLine2: generalInfo.address2 === '' ? null : generalInfo.address2,
                    city: generalInfo.city === '' ? null : generalInfo.city,
                    state: generalInfo.state === '' ? null : generalInfo.state,
                    zip: generalInfo.zip === '' ? null : generalInfo.zip,
                    country: generalInfo.country === '' ? null : generalInfo.country,
                },
                subjectMore: {
                    insurance: {
                        payerType: payerInfo.payerType === '' ? null : payerInfo.payerType,
                        ifOther: payerInfo.payerTypeOther === '' ? null : payerInfo.payerTypeOther,
                        payerName: payerInfo.priPayerName === '' ? null : payerInfo.priPayerName,
                        payerId: payerInfo.payerRel === '' ? null : payerInfo.payerRel,
                        medicareId: payerInfo.medicareBeneId === '' ? null : payerInfo.medicareBeneId,
                        medicaidId: payerInfo.medicaidBeneId === '' ? null : payerInfo.medicaidBeneId,
                        careId: payerInfo.managedCareId === '' ? null : payerInfo.managedCareId,
                        phone: payerInfo.priPayerPh === '' ? null : payerInfo.priPayerPh,
                    },
                    familyConsent: familyInfo.consentSel === 'Yes',
                    education: payerInfo.edu === '' ? null : payerInfo.edu,
                    occupation: payerInfo.occupation === '' ? null : payerInfo.occupation,
                    race: payerInfo.race === '' ? null : payerInfo.race,
                    ethnicity: payerInfo.ethnicity === '' ? null : payerInfo.ethnicity,
                    employment: payerInfo.empStatus === '' ? null : payerInfo.empStatus,
                    religion: payerInfo.religion === '' ? null : payerInfo.religion,
                    facility: {
                        code: facilityInfo.actCode === '' ? null : facilityInfo.actCode,
                        entryDate: facilityInfo.admDate === '' ? null : facilityInfo.admDate,
                        floor: facilityInfo.floor === '' ? null : facilityInfo.floor,
                        unit: facilityInfo.unit === '' ? null : facilityInfo.unit,
                        room: facilityInfo.roomNum === '' ? null : facilityInfo.roomNum,
                        bed: facilityInfo.bedNum === '' ? null : facilityInfo.bedNum,
                        barcode: facilityInfo.barcode === '' ? null : facilityInfo.barcode,
                    },
                    marital: payerInfo.marStatus === '' ? null : payerInfo.marStatus,
                    smoking: payerInfo.smokeStatus === '' ? null : payerInfo.smokeStatus,
                    language: payerInfo.primLang === '' ? null : payerInfo.primLang,
                    ipNeeded: payerInfo.interpNeed === '' ? null : payerInfo.interpNeed,
                    height: payerInfo.height === '' ? null : payerInfo.height,
                    weight: payerInfo.weight === '' ? null : payerInfo.weight,
                    program1: payerInfo.primProg === '' ? null : payerInfo.primProg,
                    program2: payerInfo.secProg === '' ? null : payerInfo.secProg,
                    citizenship: null,//BUG
                },
                consent: {
                    date: consentDate !== '' ? format(consentDate, "yyyy-MM-dd") : '',
                    provider: providerOfConsent[0] ? providerOfConsent[0].value : null,
                    name: consentProvider === '' ? null : consentProvider,
                    relationship: relToP === '' ? null : relToP,
                },
            },
            familyContactList: getFamilyContactList(),
            caregiverList: getCareTeamList(),
            billPhyId: facilityInfo.billPhyId === '' ? null : payerInfo.billPhyId,
            //diagnosesList: getDiagnosisList(),
        };
        console.log("THE FINAL PAYLOAD IS: ", finalPayload);

        if (consentSel === "Yes" && (providerOfConsent[0] && providerOfConsent[0].value !== '')) {
            resetReqFills();
            setShowConsentWarn(false);
            setShowRegisterNewPatientPopUp(true);
            dispatch(setShowPRLoader(true));
            dispatch(registerNewPatient(finalPayload));
        } else {
            resetReqFills();
            setShowConsentWarn(true);
            if (!providerOfConsent[0] || providerOfConsent[0].value === '') {
                setIsProviderOfConsentFill(false);
            }
            if (consentDate === '') {
                setIsConsentDateFill(false);
            }
        }
    };

    return (
        <div className={styles.rootDiv}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={cancelRegistration || showRegisterNewPatientPopUp}
            ></Backdrop>

            <GeneralInfoReview></GeneralInfoReview>
            <PayerAndConditionReview></PayerAndConditionReview>
            <FamilyInfoReview></FamilyInfoReview>
            <CareTeamInfoReview></CareTeamInfoReview>
            <FacilityInfoReview></FacilityInfoReview>

            <div className={styles.containerDiv}>
                <div className={styles.dumDiv}>
                    <div className={styles.headerDiv}>
                        <div className={styles.dummyDiv}></div>
                        <div className={styles.headerTitle}> Consent </div>
                        <div className={styles.dummyDiv} > </div>
                    </div>
                    <div className={styles.contentDiv}>
                        <div className={styles.column1}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Consent Date (YYYY-MM-DD) <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <Input
                                        value={consentDate === '' ? `` : `${format(consentDate, "yyyy-MM-dd")}`}
                                        readOnly
                                        className={isConsentDateFill ? styles.inputFieldSelctedDOB : styles.inputFieldSelctedDOBReq}
                                        onClick={(event) => handleCalClick(event)}
                                        disableUnderline={true}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(event) => handleCalClick(event)}
                                                >
                                                    <CalendarTodayIcon sx={{ color: "#4E253A", height: "20px !important", weight: "20px !important" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {isCalOpen && (
                                        <div className={styles.datePickerWrapper} ref={refOne}>
                                            <Calendar
                                                onChange={item => setConsentDate(item)}
                                                editableDateInputs={false}
                                                showSelectionPreview={true}
                                                months={1}
                                                date={consentDate}
                                                direction="horizontal"
                                                maxDate={addDays(new Date(), 1)}
                                                color={["#aa346f"]}
                                            />
                                            <div className={styles.applyButtonDiv}>
                                                <div className={styles.cancelButton} onClick={(event) => handleCalClickCancel(event)}> Cancel </div>
                                                <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Confirm </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.column2}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Full Name of Consent Provider
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={consentProvider} onChange={(e) => setConsentProvider(e.currentTarget.value)} className={styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column3}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Provider of Consent <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        placeholder=""
                                        className={isProviderOfConsentFill ? styles.dropdown2 : styles.dropdownReq2}
                                        values={providerOfConsent}
                                        onChange={setProviderOfConsent}
                                        options={optionsProviderOfConsent}
                                        color="#4E253A"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column4}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Relation to Patient
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={relToP} onChange={(e) => setRelToP(e.currentTarget.value)} className={styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.divFooter}>
                        <div className={styles.consentDiv}>
                            <div className={styles.consentLabel}>Consent<span style={{ color: 'red', paddingLeft: "2px" }}>*</span></div>
                            <div className={styles.consentContent}>
                                By consenting on this application, you consent to the collection, storage, and usage of your personal health information as described in the consent form. You also consent to the sharing of your personal health information with Aidar Health.
                            </div>
                            <div className={styles.consentButtons}>
                                <label>
                                    <input
                                        type="radio"
                                        value="No"
                                        checked={consentSel === "No"}
                                        onChange={onValueChange}
                                        style={{ accentColor: '#AA346F' }}
                                    />
                                    No
                                </label>

                                <label>
                                    <input
                                        type="radio"
                                        value="Yes"
                                        checked={consentSel === "Yes"}
                                        onChange={onValueChange}
                                        style={{ accentColor: '#AA346F' }}
                                    />
                                    Yes
                                </label>
                            </div>
                        </div>

                        {showConsentWarn && <div className={styles.consentWarningDiv}>
                            Please fill out the required fields marked with an *
                        </div>}
                        {(<>
                            <div className={styles.pageButtonsDiv}>
                                <div className={styles.cancelButton1} onClick={handleCancelRegistration}>Cancel</div>
                                <div className={styles.saveButton} onClick={handleSubmitPR}>Submit</div>
                            </div>
                        </>)}
                    </div>
                </div>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={cancelRegistration}
                onClose={closeCancelRegistration}
            >
                <div className={styles.paper}>
                    <div className={styles.menuTitle}>Cancel Registration</div>

                    <div>
                        <Grid container>
                            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                                <div className={styles.userQuestionMessage}>All saved changes will be lost. Are you sure you want to exit?</div>

                                <div className={styles.mouthLabImgDiv}>
                                    <img src={logoutImg} loading="lazy" className={styles.mlImg} />
                                </div>

                                <div className={styles.buttonContainer}>
                                    <button type="button" onClick={closeCancelRegistration} className={styles.cancelButtonModal}>
                                        No
                                    </button>
                                    <button type="submit" onClick={handleCancelRegistrationSuccess} className={styles.submitButtonModal}>
                                        Yes
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={showRegisterNewPatientPopUp}
                onClose={(event, reason) => setShowRegisterNewPatientPopUp(true)}
                hideBackdrop={true}
            >
                {prLoader ? (<><div className={styles.circularProgress1}><CircularProgress thickness={5.0} /></div></>) : prSuccess ?
                    (<>
                        <div className={styles.paperUnassignML}>
                            <div className={styles.menuTitle1}>
                                <div className={styles.titleText}>Success!</div>
                                <div>
                                </div>
                            </div>
                            <div className={styles.patientListDiv}>
                                Congrats, you have successfully registered:

                                <div className={styles.subWarningDiv}>
                                    {prMessage}
                                </div>
                            </div>

                            <div className={styles.menuButtons2}>
                                <div className={styles.abortButton} onClick={() => redirectToPatientList()}>
                                    Go to Patients List
                                </div>
                            </div>
                        </div>
                    </>) :
                    (<>
                        <div className={styles.paperUnassignML}>
                            <div className={styles.menuTitle1}>
                                <div className={styles.titleText}>Warning</div>
                                <div>
                                    <IconButton aria-label="delete">
                                        <RiCloseCircleFill size={29} onClick={() => setShowRegisterNewPatientPopUp(false)} style={{ color: "#4E253A" }} />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={styles.patientListDiv}>
                                {"Patient registration failed. Please try again."}
                                <div className={styles.subWarningDiv}>
                                </div>
                            </div>

                            <div className={styles.menuButtons2}>
                                <div className={styles.abortButton} onClick={() => setShowRegisterNewPatientPopUp(false)}>
                                    Retry
                                </div>
                            </div>
                        </div>
                    </>)}

            </Modal>

        </div>
    );
}