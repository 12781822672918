import styles from "./PatientFacility.module.css";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Select from 'react-dropdown-select';
import { Calendar } from "react-date-range";
import { addDays, format } from "date-fns";
import { setPRFacilityInfo, setRegistrationStatus, setShowFacilityInfoLoader } from "../../../../Actions/UtilActions";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPatientGUID, getFacilityInfoLoader, getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import { getFacilityFormEdit, getFacilityInfoSelector, getPatientInfoSelector } from "../../../../Reducer/selectors/PatientSelector";
import { getPatientInfo, setFacilityFormEdit, updateFacilityInfo } from "../../../../Actions";
import { getSelectedSubjectId } from "../../../../Reducer/selectors/DashboardSelector";
import CircularProgress from "@material-ui/core/CircularProgress";

const PatientFacility = forwardRef((props, ref) => {

    const handleFocus = (event) => event.target.select();

    const dispatch = useDispatch();

    const curRegStatus = useSelector(getRegistrationStatus);

    const facilityInfo = useSelector(getFacilityInfoSelector);

    const patientInfoObj = useSelector(getPatientInfoSelector);
    //console.log('patientInfo: ', patientInfoObj);

    const facilityName = patientInfoObj.facilityName ? patientInfoObj.facilityName : '';
    const facilityNum = patientInfoObj.facilityKey ? patientInfoObj.facilityKey : '';
    const facilityType = patientInfoObj.facilityType ? patientInfoObj.facilityType : '';

    const [bedNum, setBedNum] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.bed ? patientInfoObj.subjectMore.facility.bed : '' : '' : '');
    const [actCode, setActCode] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.code ? patientInfoObj.subjectMore.facility.code : '' : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.code ? patientInfoObj.subjectMore.facility.code : '' : '' : '' }]);
    const [unit, setUnit] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.unit ? patientInfoObj.subjectMore.facility.unit : '' : '' : '');
    const [floor, setFloor] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.floor ? patientInfoObj.subjectMore.facility.floor : '' : '' : '');
    const [roomNum, setRoomNum] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.room ? patientInfoObj.subjectMore.facility.room : '' : '' : '');
    const [admDate, setAdmDate] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.entryDate ? new Date(patientInfoObj.subjectMore.facility.entryDate).toISOString().split('T')[0] : '' : '' : '');
    const [barcode, setBarcode] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.barcode ? patientInfoObj.subjectMore.facility.barcode : '' : '' : '');

    const [isCalOpen, setIsCalOpen] = useState(false);

    const refOne = useRef(null);

    const [showReqWarn, setShowReqWarn] = useState(false);

    const [isFloorFill, setIsFloorFill] = useState(true);
    const [isRoomNumFill, setIsRoomNumFill] = useState(true);
    const [isUnitFill, setIsUnitFill] = useState(true);
    const [isBedFill, setIsBedFill] = useState(true);

    const selectedSubjectId = useSelector(getSelectedSubjectId);
    const currSubjectGUID = useSelector(getCurrentPatientGUID);

    const isFacilityFormEditable = useSelector(getFacilityFormEdit);
    const facilityInfoLoader = useSelector(getFacilityInfoLoader);

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const setAllFields = () => {
        setBedNum(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.bed ? patientInfoObj.subjectMore.facility.bed : '' : '' : '');
        setUnit(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.unit ? patientInfoObj.subjectMore.facility.unit : '' : '' : '');
        setFloor(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.floor ? patientInfoObj.subjectMore.facility.floor : '' : '' : '');
        setRoomNum(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.room ? patientInfoObj.subjectMore.facility.room : '' : '' : '');
        setBarcode(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.barcode ? patientInfoObj.subjectMore.facility.barcode : '' : '' : '');
    };

    const resetReqFills = () => {
        setIsBedFill(true);
        setIsFloorFill(true);
        setIsRoomNumFill(true);
        setIsUnitFill(true);
    };

    const optionsActCode = [
        { value: 'Initial Admission (IA)', label: 'Initial Admission (IA)' },
        { value: 'Readmission (RA)', label: 'Readmission (RA)' },
        { value: 'Respite - Initial Admission (RIA)', label: 'Respite - Initial Admission (RIA)' },
        { value: 'Respite - ReAdmission (RRA)', label: 'Respite - ReAdmission (RRA)' },
    ];

    const optionsUnit = [
        { value: 'North', label: 'North' },
        { value: 'South', label: 'South' },
        { value: 'East', label: 'East' },
        { value: 'West', label: 'West' },
    ];

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label && state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);
    }, []);

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            handleCalClick(e);
        }
    };

    // Hide dropdown on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            handleCalClick(e);
        }
    };

    const handleCalClick = (event) => {
        if (isCalOpen) {
            setIsCalOpen(false);
        } else {
            setIsCalOpen(true);
        }
    };

    const setTabInfo = async () => {
        const payload = {
            id: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.id ? patientInfoObj.subjectMore.facility.id : '' : '' : '',
            subjectId: selectedSubjectId.selectedSubjectId,
            bed: bedNum,
            unit: unit,
            floor: floor,
            room: roomNum,
            barcode: barcode,
        };
        console.log("update facilityInfo payload: ", payload);
        dispatch(setShowFacilityInfoLoader(true));
        dispatch(updateFacilityInfo(payload));
        dispatch(setFacilityFormEdit(false));
        setShowReqWarn(false);
        await sleep(3000);
        dispatch(getPatientInfo(currSubjectGUID));
    };

    const checkIfPageConditionsMet = () => {
        if ((floor !== '') && (unit !== '') && (roomNum !== '') && (bedNum !== '')) {
            //console.log('conditions met!');
            resetReqFills();
            setShowReqWarn(false);
            return true;
        } else {
            //console.log('conditions not met!');
            resetReqFills();
            if (floor === '') {
                setIsFloorFill(false);
            };
            if (roomNum === '') {
                setIsRoomNumFill(false);
            };
            if (bedNum === '') {
                setIsBedFill(false);
            };
            if (unit === '') {
                setIsUnitFill(false);
            };
            setShowReqWarn(true);
            return false;
        }
    };

    const handleSaveAndContinue = () => {
        if (checkIfPageConditionsMet()) {
            //console.log('conditions met!');
            setTabInfo();
        }
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    const editFacilityForm = (event) => {
        event.preventDefault();
        dispatch(setFacilityFormEdit(true));
    };

    const cancelEdit = () => {
        setAllFields();
        setShowReqWarn(false);
        dispatch(setFacilityFormEdit(false));
    };

    const handleSaveFacility = () => {
        handleSaveAndContinue();
    };

    return (
        <>
            {facilityInfoLoader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) : (<>
                <div className={styles.containerDiv}>
                    <div className={styles.dumDiv}>
                        <div className={styles.headerDiv}>
                            <div></div>
                            <div>Facility Information</div>
                            <div className={styles.editButtonDiv}>
                                <div className={!isFacilityFormEditable ? styles.editButton : styles.editButtonClicked} onClick={event => editFacilityForm(event)}>Edit</div>
                            </div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Facility Name
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={facilityName} disabled={true} className={styles.inputFieldDisabled} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Facility Number
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="number" value={facilityNum} disabled={true} className={styles.inputFieldDisabled} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Facility Type
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={facilityType} disabled={true} className={styles.inputFieldDisabled} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Action Code <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={styles.dropdownDisabled2}
                                            values={actCode}
                                            onChange={setActCode}
                                            options={optionsActCode}
                                            color="#4E253A"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Admission Date (YYYY-MM-DD) <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                    </div>
                                    <div className={styles.fieldInput}>
                                        {/* <input
                                        value={`${format(admDate, "yyyy/MM/dd")}`}
                                        readOnly
                                        className={styles.inputFieldSelctedDOB}
                                        onClick={(event) => handleCalClick(event)}
                                    />
                                    {isCalOpen && (
                                        <div className={styles.datePickerWrapper} ref={refOne}>
                                            <Calendar
                                                onChange={item => setAdmDate(item)}
                                                editableDateInputs={false}
                                                showSelectionPreview={true}
                                                months={1}
                                                date={admDate}
                                                direction="horizontal"
                                                maxDate={addDays(new Date(), 1)}
                                                color={["#4E253A"]}
                                            />
                                            <div className={styles.applyButtonDiv}>
                                                <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Confirm </div>
                                            </div>
                                        </div>
                                    )} */}
                                        <div className={styles.fieldInput}>
                                            <input type="text" disabled={true} className={styles.inputFieldDisabled} value={admDate} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Barcode
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={barcode} onChange={(e) => setBarcode(e.currentTarget.value)} disabled={!isFacilityFormEditable} className={isFacilityFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Floor <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={floor} onChange={(e) => setFloor(e.currentTarget.value)} disabled={!isFacilityFormEditable} className={isFacilityFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Room Number <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={roomNum} onChange={(e) => setRoomNum(e.currentTarget.value)} disabled={!isFacilityFormEditable} className={isFacilityFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Unit/Wing/Zone <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={unit} onChange={(e) => setUnit(e.currentTarget.value)} disabled={!isFacilityFormEditable} className={isFacilityFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Bed Number <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={bedNum} onChange={(e) => setBedNum(e.currentTarget.value)} disabled={!isFacilityFormEditable} className={isFacilityFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.divFooter}>
                            {showReqWarn && <div className={styles.consentWarningDiv}>
                                Please fill out the required fields marked with an *
                            </div>}
                            {isFacilityFormEditable && (<div className={styles.pageButtonsDiv}>
                                <div className={styles.cancelButton} onClick={cancelEdit}>Cancel</div>
                                <div className={styles.saveButton} onClick={handleSaveFacility}>Save</div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </>)}
        </>
    );
});

export default PatientFacility;