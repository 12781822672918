export const patientConstants = {
    GET_PATIENTS_LIST: 'GET_PATIENTS_LIST',
    GET_PATIENTS_LIST_SUCCESS: 'GET_PATIENTS_LIST_SUCCESS',
    GET_PATIENTS_LIST_FAILURE: 'GET_PATIENTS_LIST_FAILURE',

    //PatientVitals
    GET_PATIENT_VITALS_LIST: 'GET_PATIENT_VITALS_LIST',
    GET_PATIENT_VITALS_LIST_SUCCESS: 'GET_PATIENT_VITALS_LIST_SUCCESS',
    GET_PATIENT_VITALS_LIST_FAILURE: 'GET_PATIENT_VITALS_LIST_FAILURE',

    //SubjectVitals
    GET_SUBJECT_VITALS_LIST: 'GET_SUBJECT_VITALS_LIST',
    GET_SUBJECT_VITALS_LIST_SUCCESS: 'GET_SUBJECT_VITALS_LIST_SUCCESS',
    GET_SUBJECT_VITALS_LIST_FAILURE: 'GET_SUBJECT_VITALS_LIST_FAILURE',

    //Subject ECG Plot
    GET_ECG_PLOT: 'GET_ECG_PLOT',
    GET_ECG_PLOT_SUCCESS: 'GET_ECG_PLOT_SUCCESS',
    GET_ECG_PLOT_FAILURE: 'GET_ECG_PLOT_FAILURE',

    //Subject RFM Plot
    GET_RFM_PLOT: 'GET_RFM_PLOT',
    GET_RFM_PLOT_SUCCESS: 'GET_RFM_PLOT_SUCCESS',
    GET_RFM_PLOT_FAILURE: 'GET_RFM_PLOT_FAILURE',

    //Subject LFG Plot
    GET_LFG_PLOT: 'GET_LFG_PLOT',
    GET_LFG_PLOT_SUCCESS: 'GET_LFG_PLOT_SUCCESS',
    GET_LFG_PLOT_FAILURE: 'GET_LFG_PLOT_FAILURE',

    //Subject MouthLab Logs
    GET_MOUTHLAB_LOGS: 'GET_MOUTHLAB_LOGS',
    GET_MOUTHLAB_LOGS_SUCCESS: 'GET_MOUTHLAB_LOGS_SUCCESS',
    GET_MOUTHLAB_LOGS_FAILURE: 'GET_MOUTHLAB_LOGS_FAILURE',

    //Add Subject
    GET_ADD_SUBJECT: 'GET_ADD_SUBJECT',
    GET_ADD_SUBJECT_SUCCESS: 'GET_ADD_SUBJECT_SUCCESS',
    GET_ADD_SUBJECT_FAILURE: 'GET_ADD_SUBJECT_FAILURE',

    //Timer related
    SET_TIMER_STATUS: 'SET_TIMER_STATUS',
    SET_TIMER_COUNT: 'SET_TIMER_COUNT',
    PUT_START_TIMER: 'PUT_START_TIMER',
    PUT_START_TIMER_SUCCESS: 'PUT_START_TIMER_SUCCESS',
    PUT_START_TIMER_FAILURE: 'PUT_START_TIMER_FAILURE',
    PUT_END_TIMER: 'PUT_END_TIMER',
    PUT_END_TIMER_SUCCESS: 'PUT_END_TIMER_SUCCESS',
    PUT_END_TIMER_FAILURE: 'PUT_END_TIMER_FAILURE',
    PUT_ABORT_END_TIMER: 'PUT_ABORT_END_TIMER',
    PUT_ABORT_END_TIMER_SUCCESS: 'PUT_ABORT_END_TIMER_SUCCESS',
    PUT_ABORT_END_TIMER_FAILURE: 'PUT_ABORT_END_TIMER_FAILURE',

    //PatientBaseline (Thresholds)
    GET_LATEST_BASELINE: 'GET_LATEST_BASELINE',
    GET_LATEST_BASELINE_SUCCESS: 'GET_LATEST_BASELINE_SUCCESS',
    GET_LATEST_BASELINE_FAILURE: 'GET_LATEST_BASELINE_FAILURE',
    SET_TEMP_EDIT: 'SET_TEMP_EDIT',
    SET_SBP_EDIT: 'SET_SBP_EDIT',
    SET_DBP_EDIT: 'SET_DBP_EDIT',
    SET_PR_EDIT: 'SET_PR_EDIT',
    SET_SPO2_EDIT: 'SET_SPO2_EDIT',
    SET_HR_EDIT: 'SET_HR_EDIT',
    SET_HRV_EDIT: 'SET_HRV_EDIT',
    SET_RR_EDIT: 'SET_RR_EDIT',
    SET_FEV1_EDIT: 'SET_FEV1_EDIT',
    SET_PEF_EDIT: 'SET_PEF_EDIT',
    SET_SUGAR_EDIT: 'SET_SUGAR_EDIT',
    SET_PAIN_EDIT: 'SET_PAIN_EDIT',
    SET_WEIGHT_EDIT: 'SET_WEIGHT_EDIT',
    SET_ALL_EDIT: 'SET_ALL_EDIT',
    SET_DISABLE_ALL_EDIT_BUTTON: 'SET_DISABLE_ALL_EDIT_BUTTON',

    //TRENDS SBP & DBP
    GET_SBP_PAYLOAD: 'GET_SBP_PAYLOAD',
    GET_SBP_PAYLOAD_SUCCESS: 'GET_SBP_PAYLOAD_SUCCESS',
    GET_SBP_PAYLOAD_FAILURE: 'GET_SBP_PAYLOAD_FAILURE',

    GET_DBP_PAYLOAD: 'GET_DBP_PAYLOAD',
    GET_DBP_PAYLOAD_SUCCESS: 'GET_DBP_PAYLOAD_SUCCESS',
    GET_DBP_PAYLOAD_FAILURE: 'GET_DBP_PAYLOAD_FAILURE',

    GET_SPO2_PAYLOAD: 'GET_SPO2_PAYLOAD',
    GET_SPO2_PAYLOAD_SUCCESS: 'GET_SPO2_PAYLOAD_SUCCESS',
    GET_SPO2_PAYLOAD_FAILURE: 'GET_SPO2_PAYLOAD_FAILURE',

    GET_BR_PAYLOAD: 'GET_BR_PAYLOAD',
    GET_BR_PAYLOAD_SUCCESS: 'GET_BR_PAYLOAD_SUCCESS',
    GET_BR_PAYLOAD_FAILURE: 'GET_BR_PAYLOAD_FAILURE',

    GET_HR_PAYLOAD: 'GET_HR_PAYLOAD',
    GET_HR_PAYLOAD_SUCCESS: 'GET_HR_PAYLOAD_SUCCESS',
    GET_HR_PAYLOAD_FAILURE: 'GET_HR_PAYLOAD_FAILURE',

    GET_HRV_PAYLOAD: 'GET_HRV_PAYLOAD',
    GET_HRV_PAYLOAD_SUCCESS: 'GET_HRV_PAYLOAD_SUCCESS',
    GET_HRV_PAYLOAD_FAILURE: 'GET_HRV_PAYLOAD_FAILURE',

    GET_TEMP_PAYLOAD: 'GET_TEMP_PAYLOAD',
    GET_TEMP_PAYLOAD_SUCCESS: 'GET_TEMP_PAYLOAD_SUCCESS',
    GET_TEMP_PAYLOAD_FAILURE: 'GET_TEMP_PAYLOAD_FAILURE',

    GET_PR_PAYLOAD: 'GET_PR_PAYLOAD',
    GET_PR_PAYLOAD_SUCCESS: 'GET_PR_PAYLOAD_SUCCESS',
    GET_PR_PAYLOAD_FAILURE: 'GET_PR_PAYLOAD_FAILURE',

    GET_FEV_PAYLOAD: 'GET_FEV_PAYLOAD',
    GET_FEV_PAYLOAD_SUCCESS: 'GET_FEV_PAYLOAD_SUCCESS',
    GET_FEV_PAYLOAD_FAILURE: 'GET_FEV_PAYLOAD_FAILURE',

    GET_PEF_PAYLOAD: 'GET_PEF_PAYLOAD',
    GET_PEF_PAYLOAD_SUCCESS: 'GET_PEF_PAYLOAD_SUCCESS',
    GET_PEF_PAYLOAD_FAILURE: 'GET_PEF_PAYLOAD_FAILURE',

    GET_GLUCOSE_PAYLOAD: 'GET_GLUCOSE_PAYLOAD',
    GET_GLUCOSE_PAYLOAD_SUCCESS: 'GET_GLUCOSE_PAYLOAD_SUCCESS',
    GET_GLUCOSE_PAYLOAD_FAILURE: 'GET_GLUCOSE_PAYLOAD_FAILURE',

    GET_WEIGHT_PAYLOAD: 'GET_WEIGHT_PAYLOAD',
    GET_WEIGHT_PAYLOAD_SUCCESS: 'GET_WEIGHT_PAYLOAD_SUCCESS',
    GET_WEIGHT_PAYLOAD_FAILURE: 'GET_WEIGHT_PAYLOAD_FAILURE',

    GET_PAIN_PAYLOAD: 'GET_PAIN_PAYLOAD',
    GET_PAIN_PAYLOAD_SUCCESS: 'GET_PAIN_PAYLOAD_SUCCESS',
    GET_PAIN_PAYLOAD_FAILURE: 'GET_PAIN_PAYLOAD_FAILURE',

    SET_NEW_THRESHOLD: 'SET_NEW_THRESHOLD',
    SET_NEW_THRESHOLD_SUCCESS: 'SET_NEW_THRESHOLD_SUCCESS',
    SET_NEW_THRESHOLD_FAILURE: 'SET_NEW_THRESHOLD_FAILURE',

    SET_BP_COMPUTED: 'SET_BP_COMPUTED',
    SET_SPO2_BR_COMPUTED: 'SET_SPO2_BR_COMPUTED',
    SET_HR_HRV_COMPUTED: 'SET_HR_HRV_COMPUTED',
    SET_TEMP_PR_COMPUTED: 'SET_TEMP_PR_COMPUTED',
    SET_FEV_PEF_COMPUTED: 'SET_FEV_PEF_COMPUTED',
    SET_GWP_COMPUTED: 'SET_GWP_COMPUTED',

    SET_SHOW_CONFIRMATION_BANNER: 'SET_SHOW_CONFIRMATION_BANNER',

    //PATIENT REGISTRATION
    REGISTER_NEW_PATIENT: 'REGISTER_NEW_PATIENT',
    REGISTER_NEW_PATIENT_SUCCESS: 'REGISTER_NEW_PATIENT_SUCCESS',
    REGISTER_NEW_PATIENT_FAILURE: 'REGISTER_NEW_PATIENT_FAILURE',
    GET_FACILITY_INFO: 'GET_FACILITY_INFO',
    GET_FACILITY_INFO_SUCCESS: 'GET_FACILITY_INFO_SUCCESS',
    GET_FACILITY_INFO_FAILURE: 'GET_FACILITY_INFO_FAILURE',
    GET_IF_EMAIL_ALREADY_EXISTS: 'GET_IF_EMAIL_ALREADY_EXISTS',
    GET_IF_EMAIL_ALREADY_EXISTS_SUCCESS: 'GET_IF_EMAIL_ALREADY_EXISTS_SUCCESS',
    GET_IF_EMAIL_ALREADY_EXISTS_FAILURE: 'GET_IF_EMAIL_ALREADY_EXISTS_FAILURE',

    GET_PATIENT_INFO: 'GET_PATIENT_INFO',
    GET_PATIENT_INFO_SUCCESS: 'GET_PATIENT_INFO_SUCCESS',
    GET_PATIENT_INFO_FAILURE: 'GET_PATIENT_INFO_FAILURE',

    SET_GENERAL_FORM_EDIT: 'SET_GENERAL_FORM_EDIT',
    SET_PAYER_FORM_EDIT_1: 'SET_PAYER_FORM_EDIT_1',
    SET_PAYER_FORM_EDIT_2: 'SET_PAYER_FORM_EDIT_2',
    SET_PAYER_FORM_EDIT_3: 'SET_PAYER_FORM_EDIT_3',
    SET_FAMILY_FORM_EDIT_1: 'SET_FAMILY_FORM_EDIT_1',
    SET_FAMILY_FORM_EDIT_2: 'SET_FAMILY_FORM_EDIT_2',
    SET_FAMILY_FORM_EDIT_3: 'SET_FAMILY_FORM_EDIT_3',
    SET_FACILITY_FORM_EDIT: 'SET_FACILITY_FORM_EDIT',
    SET_CARETEAM_FORM_EDIT_1: 'SET_CARETEAM_FORM_EDIT_1',
    SET_CARETEAM_FORM_EDIT_2: 'SET_CARETEAM_FORM_EDIT_2',
    SET_CARETEAM_FORM_EDIT_3: 'SET_CARETEAM_FORM_EDIT_3',

    SET_PATIENT_STATUS: 'SET_PATIENT_STATUS',
    SET_PATIENT_STATUS_SUCCESS: 'SET_PATIENT_STATUS_SUCCESS',
    SET_PATIENT_STATUS_FAILURE: 'SET_PATIENT_STATUS_FAILURE',

    GET_DIAGNOSES_PAYLOAD: 'GET_DIAGNOSES_PAYLOAD',
    GET_DIAGNOSES_PAYLOAD_SUCCESS: 'GET_DIAGNOSES_PAYLOAD_SUCCESS',
    GET_DIAGNOSES_PAYLOAD_FAILURE: 'GET_DIAGNOSES_PAYLOAD_FAILURE',

    GET_FACILITY_REPORT: 'GET_FACILITY_REPORT',
    GET_FACILITY_REPORT_SUCCESS: 'GET_FACILITY_REPORT_SUCCESS',
    GET_FACILITY_REPORT_FAILURE: 'GET_FACILITY_REPORT_FAILURE',

    SET_DOWNLOAD_REPORT_LINK: 'SET_DOWNLOAD_REPORT_LINK',

    GET_FACILITY_BPH: 'GET_FACILITY_BPH',
    GET_FACILITY_BPH_SUCCESS: 'GET_FACILITY_BPH_SUCCESS',
    GET_FACILITY_BPH_FAILURE: 'GET_FACILITY_BPH_FAILURE',

    GET_FACILITY_BPH_DROPDOWN_LIST: 'GET_FACILITY_BPH_DROPDOWN_LIST',

    GET_AVAILABLE_DIAGNOSIS_RANKS: 'GET_AVAILABLE_DIAGNOSIS_RANKS',
    GET_AVAILABLE_DIAGNOSIS_RANKS_SUCCESS: 'GET_AVAILABLE_DIAGNOSIS_RANKS_SUCCESS',
    GET_AVAILABLE_DIAGNOSIS_RANKS_FAILURE: 'GET_AVAILABLE_DIAGNOSIS_RANKS_FAILURE',

    SET_ADMISSION_DATE: 'SET_ADMISSION_DATE',

    ADD_NEW_DIAGNOSIS: 'ADD_NEW_DIAGNOSIS',
    ADD_NEW_DIAGNOSIS_SUCCESS: 'ADD_NEW_DIAGNOSIS_SUCCESS',
    ADD_NEW_DIAGNOSIS_FAILURE: 'ADD_NEW_DIAGNOSIS_FAILURE',

    UPDATE_DIAGNOSIS: 'UPDATE_DIAGNOSIS',
    UPDATE_DIAGNOSIS_SUCCESS: 'UPDATE_DIAGNOSIS_SUCCESS',
    UPDATE_DIAGNOSIS_FAILURE: 'UPDATE_DIAGNOSIS_FAILURE',

    UPDATE_GENERAL_INFO: 'UPDATE_GENERAL_INFO',
    UPDATE_GENERAL_INFO_SUCCESS: 'UPDATE_GENERAL_INFO_SUCCESS',
    UPDATE_GENERAL_INFO_FAILURE: 'UPDATE_GENERAL_INFO_FAILURE',

    UPDATE_PAYER_INFO: 'UPDATE_PAYER_INFO',
    UPDATE_PAYER_INFO_SUCCESS: 'UPDATE_PAYER_INFO_SUCCESS',
    UPDATE_PAYER_INFO_FAILURE: 'UPDATE_PAYER_INFO_FAILURE',

    UPDATE_FACILITY_INFO: 'UPDATE_FACILITY_INFO',
    UPDATE_FACILITY_INFO_SUCCESS: 'UPDATE_FACILITY_INFO_SUCCESS',
    UPDATE_FACILITY_INFO_FAILURE: 'UPDATE_FACILITY_INFO_FAILURE',

    UPDATE_FAMILY_INFO: 'UPDATE_FAMILY_INFO',
    UPDATE_FAMILY_INFO_SUCCESS: 'UPDATE_FAMILY_INFO_SUCCESS',
    UPDATE_FAMILY_INFO_FAILURE: 'UPDATE_FAMILY_INFO_FAILURE',

    UPDATE_CARETEAM_INFO: 'UPDATE_CARETEAM_INFO',
    UPDATE_CARETEAM_INFO_SUCCESS: 'UPDATE_CARETEAM_INFO_SUCCESS',
    UPDATE_CARETEAM_INFO_FAILURE: 'UPDATE_CARETEAM_INFO_FAILURE',

    GET_REPORT_STATUS: 'GET_REPORT_STATUS',
}