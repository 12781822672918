import React from "react";
import Input from "./TextInput/Input";
import TextArea from "./TextAreaInput/TextArea";
import RadioButton from "./RadioButtonInput/RadioButton";
import CheckBox from "./CheckBoxInput/CheckBox";
import DropDown from "./DropDownInput/DropDown";
import Select from "./SelectInput/Select";
import DynamicSelect from "./DynamicSelectInput/DynamicSelect";
import SmallIP from "./TextInput/SmallInput/SmallIP";
import MultiSelect from "./MultiSelect/MultiSelect";

export default function FormikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "smallIp":
      return <SmallIP {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "multiselect":
      return <MultiSelect {...rest} />;
    case "textarea":
      return <TextArea {...rest} />;
    case "radio":
      return <RadioButton {...rest} />;
    case "checkbox":
      return <CheckBox {...rest} />;
    case "dropdown":
      return <DropDown {...rest} />;
    case "dynamicSelect":
      return <DynamicSelect {...rest} />;
    //   case "dynamicDropdown":
    //     return <DynamicDropDown {...rest} />;
    //   case "date":

    default:
      return null;
  }
  return <div></div>;
}
