import { createAction } from "@reduxjs/toolkit";
import { timerConstants } from "../Constants";

export const getTimerInfo = createAction(
    timerConstants.GET_TIMER_INFO,
    (payload) => ({ payload })
);

export const getTimerInfoSuccess = createAction(
    timerConstants.GET_TIMER_INFO_SUCCESS,
    (payload) => ({ payload })
);
export const getTimerInfoFailure = createAction(timerConstants.GET_TIMER_INFO_FAILURE);

export const addTimerWithNotes = createAction(
    timerConstants.ADD_TIMER_WITH_NOTES,
    (payload) => ({ payload })
);

export const addTimerWithNotesSuccess = createAction(
    timerConstants.ADD_TIMER_WITH_NOTES_SUCCESS,
    (payload) => ({ payload })
);

export const addTimerWithNotesFailure = createAction(
    timerConstants.ADD_TIMER_WITH_NOTES_FAILURE,
);

export const setLatestMonthTimerInfo = createAction(
    timerConstants.SET_LATEST_MONTH_TIMER_INFO,
    (payload) => ({ payload })
);

export const setPreviousMonthTimerInfo = createAction(
    timerConstants.SET_PREVIOUS_MONTH_TIMER_INFO,
    (payload) => ({ payload })
);

export const getIsLastTimerIncomplete = createAction(
    timerConstants.GET_IS_LAST_TIMER_INCOMPLETE,
    (payload) => ({ payload })
);

export const getIsLastTimerIncompleteSuccess = createAction(
    timerConstants.GET_IS_LAST_TIMER_INCOMPLETE_SUCCESS,
    (payload) => ({ payload })
);

export const getIsLastTimerIncompleteFailure = createAction(
    timerConstants.GET_IS_LAST_TIMER_INCOMPLETE_FAILURE,
    (payload) => ({ payload })
);